import React, { useEffect } from 'react';

import {
    Button,
    Space,
    Form,
    Input,
    Descriptions,
    Rate,
    Image,
    Drawer,
    Checkbox,
} from 'antd';
import moment from 'moment';
import { DATE_FORMAT } from 'constants/date';
import { useDispatch } from 'react-redux';
import { editReviews } from 'ducks/reviews/actions';

import { Reviews as ReviewsType } from 'types/reviews';

import styles from './styles.module.scss';

type Props = {
    visible: boolean;
    data?: ReviewsType;
    onClose: () => void;
};

export const Modal = React.memo(({ visible, data, onClose }: Props) => {
    const put = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({ ...data });
    }, [data]);

    const onFinish = () => {
        form.validateFields().then((values) => {
            put(editReviews(values, onClose));
        });
    };

    return (
        <Drawer
            title={ `Отзыв №${data?.id}` }
            placement="right"
            width={ 800 }
            onClose={ onClose }
            visible={ visible }
            extra={ (
                <Space>
                    <Button onClick={ onClose }>Отмена</Button>
                    <Button type="primary" onClick={ onFinish }>
                        Сохранить
                    </Button>
                </Space>
            ) }
        >
            { (data?.picture && data?.picture.length && (
                <div className={ styles.previewGroup }>
                    <Image.PreviewGroup key={ data?.id }>
                        { data?.picture.split(',').map((url: string) => (
                            <Image
                                className={ styles.small_img }
                                src={ `https://hairtone.ru/${url}` }
                                preview={
                                    { mask: false }
                                }
                                key={ url }
                            />
                        )) }
                    </Image.PreviewGroup>
                </div>
            )) }
            <Descriptions size="small" column={ 1 } bordered={ true }>
                <Descriptions.Item label="Автор">{ data?.userName }</Descriptions.Item>
                <Descriptions.Item label="Дата создания">{ moment(data?.createDate).format(DATE_FORMAT.DATE_TIME) }</Descriptions.Item>
                <Descriptions.Item label="Рейтинг"><Rate disabled={ true } value={ data?.rating } /></Descriptions.Item>
                <Descriptions.Item label="Сообщение">{ data?.message }</Descriptions.Item>
            </Descriptions>
            <Form
                layout="vertical"
                initialValues={ { ...data } }
                form={ form }
            >
                <Form.Item name="id" hidden={ true }>
                    <Input />
                </Form.Item>
                <div className={ styles.formItemTitle }>Ваша благодарность</div>
                <Form.Item
                    name="answer"
                >
                    <Input.TextArea
                        placeholder="Напишите ответ на отзыв"
                        autoSize={ { minRows: 2, maxRows: 6 } }
                    />
                </Form.Item>
                <Form.Item
                    name="active"
                    valuePropName="checked"
                >
                    <Checkbox>
                        Отображать на странице сайта
                    </Checkbox>
                </Form.Item>
            </Form>
        </Drawer>
    );
});
