import {
    GetStatisticsPayload,
} from 'types/api';
import { StatisticsActionTypes } from './action-types';

export const getStatistics = (payload: GetStatisticsPayload) => ({
    type: StatisticsActionTypes.GET_STATISTICS_START,
    payload,
});

export const getStatisticsError = () => ({
    type: StatisticsActionTypes.GET_STATISTICS_ERROR,
});

export const getStatisticsFinish = (response: any) => ({
    type: StatisticsActionTypes.GET_STATISTICS_FINISH,
    response,
});
