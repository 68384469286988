import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import { appReducer } from './app/reducer';
import { ordersReducer } from './orders/reducer';
import { infoReducer } from './info/reducer';
import { userReducer } from './user/reducer';
import { promoCodeReducer } from './promocode/reducer';
import { priceReducer } from './price/reducer';
import { ticketsReducer } from './tickets/reducer';
import { statisticsReducer } from './statistics/reducer';
import { reviewsReducer } from './reviews/reducer';
import { faqReducer } from './faq/reducer';
import { blogReducer } from './blog/reducer';
import { orderFeedbackReducer } from './orderFeedback/reducer';

import { ApplicationState } from './application-state';
import { AdvertisingReducer } from './advertising/reducer';

export default (history: History) => combineReducers<ApplicationState>({
    app: appReducer,
    router: connectRouter(history),
    orders: ordersReducer,
    orderFeedback: orderFeedbackReducer,
    info: infoReducer,
    user: userReducer,
    faq: faqReducer,
    promocode: promoCodeReducer,
    price: priceReducer,
    tickets: ticketsReducer,
    statistics: statisticsReducer,
    reviews: reviewsReducer,
    blog: blogReducer,
    advertising: AdvertisingReducer,
});
