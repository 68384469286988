export const PriceActionTypes = {
    GET_PRICE_START: 'GET_PRICE_START',
    GET_PRICE_ERROR: 'GET_PRICE_ERROR',
    GET_PRICE_FINISH: 'GET_PRICE_FINISH',

    CREATE_PRICE_START: 'CREATE_PRICE_START',
    CREATE_PRICE_ERROR: 'CREATE_PRICE_ERROR',
    CREATE_PRICE_FINISH: 'CREATE_PRICE_FINISH',

    EDIT_PRICE_START: 'EDIT_PRICE_START',
    EDIT_PRICE_ERROR: 'EDIT_PRICE_ERROR',
    EDIT_PRICE_FINISH: 'EDIT_PRICE_FINISH',

    REMOVE_PRICE_START: 'REMOVE_PRICE_START',
    REMOVE_PRICE_ERROR: 'REMOVE_PRICE_ERROR',
    REMOVE_PRICE_FINISH: 'REMOVE_PRICE_FINISH',
} as const;
