import { Order } from './orders';
import { ShortUser } from './user';

export type Ticket = {
    dateCreate: string;
    id: number;
    idOrders: number;
    manager_id: number;
    message: string;
    status: TicketStatus;
    thema: string;
    user: ShortUser;
    user_id: number;
    orders?: Order;
    seeType: TicketSeeType;
    manager?: ShortUser;
};

export enum TicketSeeType {
    all = 'all',
    client = 'client',
    manager = 'manager',
}

export enum TicketStatus {
    new = 'new',
    open = 'open',
    closed = 'closed',
}

export enum TicketStatusText {
    new = 'Новый',
    open = 'Открытый',
    closed = 'Закрытый',
}

export const TicketStatusList = [TicketStatus.new, TicketStatus.open, TicketStatus.closed];

export enum TicketStatusColor {
    new = '#5275f5',
    open = '#2e2e2e',
    closed = '#1DA57A',
}

export type TicketMessage = {
    id: number;
    idTheme: number;
    message: string;
    createDate: Date;
    user: ShortUser;
};
