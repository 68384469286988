import { message } from 'antd';
import { call, put } from 'redux-saga/effects';
import { addAdvertising, addAdvertisingError, addAdvertisingFinish } from 'ducks/advertising/actions';

import { fetchers } from 'api';

export function* createAdvertisingPromocodeRequest({ payload }: ReturnType<typeof addAdvertising>) {
    try {
        const res = yield call(fetchers.createAdvertisingPromocode, payload);

        message.open({ ...res, content: res.message });
        yield put(addAdvertisingFinish());
    } catch (error) {
        yield put(addAdvertisingError());
    }
}
