import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from 'ducks/user/selectors';
import { FloatMenu } from 'components/floating';
import { FormOutlined, TagOutlined } from '@ant-design/icons';
import { setVisibleBlogTags } from 'ducks/blog/actions';
import { selectBlogEditTags } from 'ducks/blog/selectors';
import { BlogAdd } from './add';

export const BlogFloatMenu = () => {
    const put = useDispatch();
    const user = useSelector(selectProfile);
    const editTag = useSelector(selectBlogEditTags);
    const [blogAddVisible, setBlogAddVisible] = React.useState(false);

    const blogAddToggle = (v: any = false) => {
        setBlogAddVisible(v);
    };

    const onEditTags = () => {
        put(setVisibleBlogTags(!editTag));
    };

    const menu = [];

    if (user && user.access === 'ADMIN') {
        menu.push({
            icon: <FormOutlined />,
            tooltip: 'Добавить статью',
            onClick: () => blogAddToggle(true),
        });
        menu.push({
            icon: <TagOutlined />,
            tooltip: 'Редактировать теги',
            onClick: () => onEditTags(),
        });
    } else {
        return null;
    }

    return (
        <React.Fragment>
            <FloatMenu menu={ menu } />
            { blogAddToggle && (<BlogAdd visible={ blogAddVisible } onCancel={ () => blogAddToggle(false) } />) }
        </React.Fragment>
    );
};
