import React from 'react';
import { Formik } from 'formik';
import {
    Form,
    Input,
    Modal,
} from 'antd';

import { PriceItem } from 'types/api';

import { getError, getErrorStatus } from 'form-helpers/validation';
import { schema } from 'form-helpers/price/schema';
import { PriceForm } from 'form-helpers/price/types';

import styles from './styles.module.scss';

type Props = {
    initialData: PriceItem | null;
    onCancel: () => void;
    onSubmit: (values: PriceForm) => void;
    loading: boolean;
};

const itemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export const EditPriceModal = ({
    initialData,
    onCancel,
    onSubmit,
    loading,
}: Props) => {
    const initialValues = schema.cast({ ...initialData });

    return (
        <Formik<PriceForm>
            key="PriceForm"
            initialValues={ initialValues }
            onSubmit={ onSubmit }
            validationSchema={ schema }
        >
            { ({
                handleSubmit,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
            }) => {
                const errorList = {
                    code: getError(errors, touched, true, 'code'),
                    name: getError(errors, touched, true, 'name'),
                    price: getError(errors, touched, true, 'price'),
                };

                const handleBlurNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const { name, value } = e.target;

                    if (value === '') {
                        setFieldValue(name, 0);
                    }

                    handleBlur(e);
                };

                return (
                    <Modal
                        visible={ true }
                        onCancel={ onCancel }
                        onOk={ () => handleSubmit() }
                        confirmLoading={ loading }
                    >
                        <div className={ styles.container }>
                            <Form
                                labelAlign="left"
                                { ...itemLayout }
                            >
                                <Form.Item
                                    label="Код"
                                    extra={ errorList.code }
                                    validateStatus={ getErrorStatus(!!errorList.code) }
                                >
                                    <Input
                                        name="code"
                                        placeholder="Код"
                                        onChange={ handleChange }
                                        onBlur={ handleBlur }
                                        value={ values.code }
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Наименование"
                                    extra={ errorList.name }
                                    validateStatus={ getErrorStatus(!!errorList.name) }
                                >
                                    <Input
                                        name="name"
                                        placeholder="Наименование"
                                        onChange={ handleChange }
                                        onBlur={ handleBlur }
                                        value={ values.name }
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Цена"
                                    extra={ errorList.price }
                                    validateStatus={ getErrorStatus(!!errorList.price) }
                                >
                                    <Input
                                        name="price"
                                        placeholder="Цена"
                                        onChange={ handleChange }
                                        onBlur={ handleBlurNumber }
                                        value={ values.price }
                                        type="number"
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    </Modal>
                );
            } }
        </Formik>
    );
};
