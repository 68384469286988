import React from 'react';
import { Link } from 'react-router-dom';
import DownloadLink from 'components/ui/downloadLink';

import logoFooter from 'assets/logoWhite.svg';
import paymentVisa from 'assets/payment-visa.png';
import paymentMasterCard from 'assets/payment-master-card.png';
import paymentMir from 'assets/payment-mir.png';

import socialVk from 'assets/social-vk.png';
import socialTelegram from 'assets/telegram_white.svg';

import appStore from 'assets/app-store.png';
import googlePlay from 'assets/google-play.png';
import { clearToken } from 'utils/token';
import { ROUTES } from 'constants/routes';

import cl from './style.module.css';

export const Footer = ({ auth, onRegister, onLogin }: any) => {
    const onExit = () => {
        clearToken();
        window.location.reload();
    };

    return (
        <footer className="footer" id="footer">
            <div className="container">
                <div className="footer-content">
                    <div className="footer-content__item footer-content__item--logo">
                        <img src={ logoFooter } className="logoFooter" onClick={ () => window.scrollTo(0, 0) } aria-hidden="true" alt="" />
                        <ul className="footer-menu">
                            <li className="footer-menu__item">
                                <Link to="mailto:info@hairtone.ru">info@hairtone.ru</Link>
                            </li>
                            <li className="footer-menu__item small">
                                <Link target="_blank" to="/Soglasheniepolzovatelskoe.pdf">Пользовательское соглашение</Link>
                            </li>
                            <li className="footer-menu__item small">
                                <Link target="_blank" to="/Politikakonfidencialnosti.pdf">Политика конфиденциальности</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-content__item footer-content__item--menu">
                        <div className="footer-title">Мой кабинет</div>
                        <ul className="footer-menu">
                            { auth ? (
                                <React.Fragment>
                                    <li className="footer-menu__item">
                                        <Link to={ ROUTES.MAIN.path }><button type="button" className="btn btn--null">Мой профиль</button></Link>
                                    </li>
                                    <li className="footer-menu__item">
                                        <button type="button" onClick={ () => onExit() } className="btn btn--null">Выйти</button>
                                    </li>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <li className="footer-menu__item">
                                        <button type="button" onClick={ () => onLogin(true) } className="btn btn--null">Вход</button>
                                    </li>
                                    <li className="footer-menu__item">
                                        <button type="button" onClick={ () => onRegister(true) } className="btn btn--null">Регистрация</button>
                                    </li>
                                </React.Fragment>
                            ) }
                        </ul>
                    </div>
                    <div className="footer-content__item footer-content__item--links">
                        <div className="footer-links">
                            <div className="footer-title">Способы оплаты:</div>
                            <div className="footer-links__item">
                                <Link to="/">
                                    <img src={ paymentVisa } alt="" />
                                </Link>
                            </div>
                            <div className="footer-links__item">
                                <Link to="/">
                                    <img src={ paymentMasterCard } alt="" />
                                </Link>
                            </div>
                            <div className="footer-links__item">
                                <Link to="/">
                                    <img src={ paymentMir } alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="footer-content__item footer-content__item--links">
                        <div className="footer-links">
                            <div className="footer-title">Присоединяйтесь:</div>
                            <div className="footer-links__item">
                                <DownloadLink to="https://vk.com/hairtone" target="_blank" className="banner__social-item">
                                    <img src={ socialVk } alt="" />
                                </DownloadLink>
                            </div>
                            <div className="footer-links__item">
                                <DownloadLink to="https://t.me/hairtone" target="_blank" className="banner__social-item">
                                    <img src={ socialTelegram } alt="" />
                                </DownloadLink>
                            </div>
                        </div>
                    </div>
                    <div className="footer-content__item footer-content__item--links">
                        <div className="footer-links">
                            <div className="footer-title">Доступно:</div>
                            <div className="footer-links__item">
                                <Link to="/">
                                    <img src={ appStore } alt="" />
                                </Link>
                            </div>
                            <div className="footer-links__item">
                                <Link to="/">
                                    <img src={ googlePlay } alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="footer-content__item footer-content__item--copyright">
                        <p className={ cl.company }>
                            ООО &quot;Аналитик&quot;, ОГРН 1185053013408, ИНН 5032296790, КПП 503201001
                        </p>
                        <p className="copyright">© 2021 Hairtone | All is simple. Все права защищены</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};
