import { put, call } from 'redux-saga/effects';
import { message } from 'antd';
import {
    applyPromocodeToOrder,
    applyPromocodeToOrderError,
    applyPromocodeToOrderFinish,
} from 'ducks/orders/actions';

import { fetchers } from 'api';

export function* applyPromocodeToOrderRequest({ data }: ReturnType<typeof applyPromocodeToOrder>) {
    try {
        const response = yield call(fetchers.applyPromocodeToOrder, data);

        yield put(applyPromocodeToOrderFinish(response.data));
        message.success(response.message);
    } catch (error) {
        yield put(applyPromocodeToOrderError());
    }
}
