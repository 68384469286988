import React from 'react';
import cn from 'classnames';
import set from 'lodash/set';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import {
    Select,
    Typography,
    Form,
    Row,
    Col,
    Radio,
    Checkbox,
    RadioChangeEvent,
} from 'antd';
import { sendExpertOrderForm } from 'ducks/orders/actions';
import { selectColors, selectInfo, selectColoringtintingInstructions } from 'ducks/info/selectors';
import { selectOrder, selectOrdersLoading } from 'ducks/orders/selectors';
import { selectProfile } from 'ducks/user/selectors';

import { Order } from 'types/orders';
import {
    ExpertOrderFormPayload, GetColorsResponse, Instruction,
} from 'types/api';
import { ExpertForm } from 'form-helpers/expert-form-coloringtinting/types';

import { getInitialValues } from 'form-helpers/expert-form-coloringtinting/mapping';
import { checkEditExpertField } from 'utils/orders';
import { DEFAULT } from 'constants/order';

import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Status } from 'types/status';
import { FirstRowEditable } from './firstRowEditable';
import { LengthEditable } from './lengthEditable';
import { ViewExpertForm } from './viewExpertForm';

import styles from './styles.module.scss';

const { Option } = Select;
const { Title } = Typography;

const colProps = {
    lg: 4,
    xs: 4,
};

const colProps1 = {
    lg: 3,
    xs: 3,
};

const colProps2 = {
    lg: 5,
    xs: 5,
};

export const OrderExpertFormColoringtinting = () => {
    const put = useDispatch();
    const colors = useSelector(selectColors) as GetColorsResponse;
    const order = useSelector(selectOrder) as Order;
    const info = useSelector(selectInfo);
    const user = useSelector(selectProfile);
    const { sendExpertForm } = useSelector(selectOrdersLoading);
    const instructions = useSelector(selectColoringtintingInstructions) as Instruction[];

    const handleUpdateExpertForm = (values: any) => {
        const onlyFields: { [key: string]: any } = {};

        Object.keys(values).forEach((key) => {
            onlyFields[key] = values[key];
        });

        put(sendExpertOrderForm({
            id: order.id,
            ...onlyFields,
        } as ExpertOrderFormPayload));
    };

    return (
        <div className={ styles.container }>
            <Formik<ExpertForm>
                key="ExpertForm"
                initialValues={ getInitialValues(order, colors) }
                onSubmit={ () => {} }
            >
                { ({
                    values,
                    setValues,
                }) => {
                    const colorantType = values.brand;

                    const updateForm = (newValues?: any) => {
                        handleUpdateExpertForm(newValues || values);
                    };

                    const onChangeSelect = (name: string) => (value: any, option: any) => {
                        let newValues = { ...values };

                        set(newValues, name, value);

                        if (name === 'subTypeColor') {
                            const currentColorSubtype = colors[colorantType].color.find((item: any) => item.id === value);

                            newValues = {
                                ...newValues,
                                colorsList: currentColorSubtype!.list,
                                subTypeColorName: currentColorSubtype!.name,
                                colors: { ...DEFAULT.colors },
                                colorsName: { ...DEFAULT.colorsName },
                            };
                        }

                        if (name === 'lengthColorsSubType') {
                            const currentColorSubtype = colors[colorantType].color.find((item: any) => item.id === value);

                            newValues = {
                                ...newValues,
                                lengthColorsList: currentColorSubtype!.list,
                                lengthColorsSubTypeName: currentColorSubtype!.name,
                                lengthColors: { ...DEFAULT.colors },
                                lengthColorsName: { ...DEFAULT.colorsName },
                            };
                        }

                        if (name === 'subTypeOxidizer') {
                            const currentColorSubtype = colors[colorantType].oxidizer.find((item: any) => item.id === value);

                            newValues = {
                                ...newValues,
                                oxidizerList: currentColorSubtype!.list,
                                subTypeOxidizerName: currentColorSubtype!.name,
                                oxidizer: '',
                            };
                        }

                        if (name === 'lengthOxidizerSubType') {
                            const currentColorSubtype = colors[colorantType].oxidizer.find((item: any) => item.id === value);

                            newValues = {
                                ...newValues,
                                lengthOxidizerList: currentColorSubtype!.list,
                                lengthOxidizerName: currentColorSubtype!.name,
                                lengthOxidizer: null,
                            };
                        }

                        if (name.includes('colors.')) {
                            const field = name.split('.')[1];

                            set(newValues, `colorsName.${field}`, option.children);
                        }

                        setValues(newValues);
                        updateForm(newValues);
                    };

                    const onChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
                        const { name, value } = e.target;

                        setValues({
                            ...values,
                            [name]: value ? +value : '',
                        });
                    };

                    const onChangeBrand = (e: any) => {
                        const { name, value } = e.target;

                        const updValues = {
                            ...values,
                            subTypeOxidizer: 0,
                            subTypeOxidizerName: '',
                            oxidizer: 0,
                            oxidizerName: '',
                            oxidizerList: [],
                            subTypeColor: 0,
                            subTypeColorName: '',
                            colors: {
                                natural: null,
                                desired: null,
                                enhancer: null,
                            },
                            colorsName: { ...DEFAULT.colorsName },
                            colorsList: [],

                            lengthColorsSubType: null,
                            lengthColorsSubTypeName: '',
                            lengthColors: { ...DEFAULT.colors },
                            lengthColorsList: [],
                            lengthColorsName: { ...DEFAULT.colorsName },
                            lengthOxidizer: null,
                            lengthOxidizerSubType: null,
                            [name]: value,
                        };

                        setValues(updValues);
                        updateForm(updValues);
                    };

                    const onChangeRadio = (e: RadioChangeEvent) => {
                        const { name, value } = e.target;

                        const updValues = {
                            ...values,
                            [name as string]: value,
                        };

                        if (name === 'grayHair') {
                            updValues.colors = {
                                ...updValues.colors,
                                natural: null,
                            };
                            updValues.colorsName = {
                                ...updValues.colorsName,
                                natural: '',
                            };
                            updValues.lengthColors = {
                                ...updValues.lengthColors,
                                natural: null,
                            };
                            updValues.lengthColorsName = {
                                ...updValues.lengthColorsName,
                                natural: '',
                            };
                        }

                        setValues(updValues);
                        updateForm(updValues);
                    };

                    const handleChangeIsLengthNeeded = (e: CheckboxChangeEvent) => {
                        const { name, checked } = e.target;

                        const updValues = {
                            ...values,
                            [name as string]: checked ? 1 : 0,
                        };

                        if (!checked) {
                            updValues.lengthColors = { ...DEFAULT.colors };
                            updValues.lengthColorsList = [];
                            updValues.lengthColorsName = { ...DEFAULT.colorsName };
                            updValues.lengthColorsSubType = null;
                            updValues.lengthColorsSubTypeName = '';
                            updValues.lengthOxidizer = null;
                            updValues.lengthOxidizerSubType = null;
                            updValues.lengthOxidizerList = [];
                            updValues.lengthOxidizerSubTypeName = '';
                            updValues.weightDesiredColorLength = 0;
                            updValues.oxidizerWeightLengthRatio = 0;
                            updValues.weightRoots = updValues.weight;
                        }

                        setValues(updValues);
                        updateForm(updValues);
                    };

                    const onChangeIsLengthEnhancerNeeded = (e: any) => {
                        const { name, checked } = e.target;

                        const updValues = {
                            ...values,
                            [name]: checked ? 1 : 0,
                        };

                        if (!checked) {
                            updValues.lengthColors.enhancer = null;
                            updValues.lengthColorsName.enhancer = '';
                            updValues.weightDesiredColorLength = values.weight - values.weightRoots;
                        }

                        setValues(updValues);
                        updateForm(updValues);
                    };

                    const instruction = instructions.find((item) => item.id === values.instruction);

                    const editableMainInfo = (
                        <React.Fragment>
                            <div className={ styles.itemGroup }>
                                <Title level={ 5 }>Бренд</Title>
                                <Radio.Group
                                    name="brand"
                                    size="large"
                                    buttonStyle="solid"
                                    value={ values.brand }
                                >
                                    { info?.brand?.filter((item) => item.value !== 'other')
                                        .map((item) => (
                                            <Radio.Button
                                                value={ item.value }
                                                onChange={ onChangeBrand }
                                            >
                                                { item.name }
                                            </Radio.Button>
                                        )) }
                                </Radio.Group>
                            </div>
                            <div className={ styles.itemGroup }>
                                <Title level={ 5 }>Седина</Title>
                                <Form.Item
                                    className={ styles.formItem }
                                    label="Седина на корни"
                                >
                                    <Radio.Group
                                        name="grayHair"
                                        size="large"
                                        buttonStyle="solid"
                                        defaultValue={ values.grayHair }
                                    >
                                        { info?.grayHair?.map(
                                            (item) => (
                                                <Radio.Button
                                                    value={ item.value }
                                                    onChange={ onChangeRadio }
                                                >
                                                    { item.name }
                                                </Radio.Button>
                                            ),
                                        ) }
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    className={ styles.formItem }
                                    label="Седина на длину"
                                >
                                    <Radio.Group
                                        name="lengthGrayHair"
                                        size="large"
                                        buttonStyle="solid"
                                        defaultValue={ values.lengthGrayHair }
                                    >
                                        { info?.grayHair?.map(
                                            (item) => (
                                                <Radio.Button
                                                    value={ item.value }
                                                    onChange={ onChangeRadio }
                                                >
                                                    { item.name }
                                                </Radio.Button>
                                            ),
                                        ) }
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <Form.Item
                                className={ styles.formItem }
                            >
                                <Select
                                    onChange={ onChangeSelect('instruction') }
                                    value={ values.instruction }
                                >
                                    {
                                        instructions.map((item: Instruction) => <Option value={ item.id }>{ item.name }</Option>)
                                    }
                                </Select>
                                { values.instruction && <a href={ instruction?.url } target="_blank" rel="noopener noreferer noreferrer">Ссылка</a> }
                            </Form.Item>
                        </React.Fragment>
                    );

                    return (
                        <Form
                            labelAlign="left"
                            layout="vertical"
                            className={ cn(styles.form, { [styles.formLoading]: sendExpertForm }) }
                        >
                            <div className={ styles.table }>
                                <Row>
                                    <Col
                                        lg={ 14 }
                                        md={ 14 }
                                        sm={ 14 }
                                        xs={ 24 }
                                    >
                                        { checkEditExpertField(
                                            user!.access_list,
                                            order.status,
                                            user!.id,
                                            order.expert_id,
                                            editableMainInfo,
                                            null,
                                        ) }
                                    </Col>
                                </Row>
                                {
                                    order.status === Status.completed
                                        ? <ViewExpertForm instruction={ instruction } values={ values } />
                                        : (
                                            <React.Fragment>
                                                <Row>
                                                    <Col className={ styles.cell } { ...colProps } />
                                                    <Col className={ styles.cell } { ...colProps1 }>Цвет 1 Натуральная база</Col>
                                                    <Col className={ styles.cell } { ...colProps1 }>Цвет 2 Желаемый цвет</Col>
                                                    <Col className={ styles.cell } { ...colProps1 }>Цвет 3 Цвет усилитель / нейтрализатор</Col>
                                                    <Col className={ styles.cell } { ...colProps1 }>Распределение массы красителя корни/длина, гр</Col>
                                                    <Col className={ styles.cell } { ...colProps1 }>Общая масса красителя на окрашивание, гр.</Col>
                                                    <Col className={ styles.cell } { ...colProps2 }>Оксид корни / длинна</Col>
                                                </Row>
                                                <Row>
                                                    <Col className={ styles.cell } lg={ 24 } xs={ 24 }>
                                                        <Title className={ styles.heading } level={ 5 }>Корни</Title>
                                                    </Col>
                                                </Row>
                                                {
                                                    checkEditExpertField(
                                                        user!.access_list,
                                                        order.status,
                                                        user!.id,
                                                        order.expert_id,
                                                        <FirstRowEditable
                                                            colors={ colors }
                                                            onChangeSelect={ onChangeSelect }
                                                            values={ values }
                                                            onChangeNumber={ onChangeNumber }
                                                            updateForm={ updateForm }
                                                            onChangeRadio={ onChangeRadio }
                                                        />,
                                                        null,
                                                    )
                                                }
                                                <Row>
                                                    <Col className={ styles.cell } lg={ 24 } xs={ 24 }>
                                                        <div className={ styles.flx }>
                                                            <Title className={ styles.heading } level={ 5 }>Длина</Title>
                                                            {
                                                                checkEditExpertField(
                                                                    user!.access_list,
                                                                    order.status,
                                                                    user!.id,
                                                                    order.expert_id,
                                                                    <Checkbox
                                                                        name="isLengthNeeded"
                                                                        checked={ Boolean(values.isLengthNeeded) }
                                                                        className={ styles.checkbox }
                                                                        onChange={ handleChangeIsLengthNeeded }
                                                                    />,
                                                                    null,
                                                                )
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {
                                                    values.isLengthNeeded
                                                        ? (
                                                            checkEditExpertField(
                                                                user!.access_list,
                                                                order.status,
                                                                user!.id,
                                                                order.expert_id,
                                                                <LengthEditable
                                                                    colors={ colors }
                                                                    onChangeSelect={ onChangeSelect }
                                                                    values={ values }
                                                                    onChangeNumber={ onChangeNumber }
                                                                    updateForm={ updateForm }
                                                                    onChangeRadio={ onChangeRadio }
                                                                    onChangeIsLengthEnhancerNeeded={ onChangeIsLengthEnhancerNeeded }
                                                                />,
                                                                null,
                                                            )
                                                        )
                                                        : null
                                                }
                                            </React.Fragment>
                                        )
                                }
                            </div>
                        </Form>
                    );
                } }
            </Formik>
        </div>
    );
};
