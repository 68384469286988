import { AdvertisingTypes } from './action-types';

export const addAdvertising = (payload: any) => ({
    type: AdvertisingTypes.ADD_ADVERTISING_START,
    payload,
});

export const addAdvertisingError = () => ({
    type: AdvertisingTypes.ADD_ADVERTISING_ERROR,
});

export const addAdvertisingFinish = () => ({
    type: AdvertisingTypes.ADD_ADVERTISING_FINISH,
});
