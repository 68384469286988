import * as Api from 'types/api';
import {
    OrderFeedbackActionTypes,
} from './action-types';

export const clear = () => ({
    type: OrderFeedbackActionTypes.CLEAR_FEEDBACK,
});

export const getOrderFeedback = (payload: Api.GetOrderFeedbackPayload) => ({
    type: OrderFeedbackActionTypes.FEEDBACK_ORDER_START,
    payload,
});
//
export const getOrderFeedbackError = () => ({
    type: OrderFeedbackActionTypes.FEEDBACK_ORDER_ERROR,
});

export const getOrderFeedbackFinish = (response: Api.GetOrderFeedbackResponse) => ({
    type: OrderFeedbackActionTypes.FEEDBACK_ORDER_FINISH,
    response,
});

export const getOrdersFeedback = (payload: Api.GetOrdersFeedbackPayload) => ({
    type: OrderFeedbackActionTypes.GET_FEEDBACK_ORDERS_START,
    payload,
});
//
export const getOrdersFeedbackError = () => ({
    type: OrderFeedbackActionTypes.GET_FEEDBACK_ORDERS_ERROR,
});

export const getOrdersFeedbackFinish = (response: Api.GetOrdersFeedbackResponse) => ({
    type: OrderFeedbackActionTypes.GET_FEEDBACK_ORDERS_FINISH,
    response,
});

export const sendExpertOrderFeedbackForm = (payload: Api.ExpertOrderFormPayload) => ({
    type: OrderFeedbackActionTypes.EDIT_FEEDBACK_ORDER_START,
    payload,
});

export const sendExpertOrderFeedbackFormError = () => ({
    type: OrderFeedbackActionTypes.EDIT_FEEDBACK_ORDER_ERROR,
});

export const sendExpertOrderFeedbackFormFinish = (response: Api.EditOrderFeedbackResponse) => ({
    type: OrderFeedbackActionTypes.EDIT_FEEDBACK_ORDER_FINISH,
    response,
});
