import { message } from 'antd';
import { put } from 'redux-saga/effects';
import * as Effects from 'redux-saga/effects';

import {
    removeBlog,
    removeBlogError,
    removeBlogFinish,
    getBlog,
} from 'ducks/blog/actions';

import { fetchers } from 'api';
import { ResponseGenerator } from 'types/api';

const call: any = Effects.call;

export function* removeBlogRequest({ id }: ReturnType<typeof removeBlog>) {
    try {
        const response: ResponseGenerator = yield call(fetchers.removeBlog, id);

        message.success('Статья успешно удалена');
        yield put(getBlog({ pagination: { page: 1 } }));
        yield put(removeBlogFinish(response));
    } catch (error) {
        yield put(removeBlogError());
    }
}
