export enum PromoType {
    'price' = 'price',
    'lamination' = 'lamination',
    'coloringtinting' = 'coloringtinting',
    'choosecolor' = 'choosecolor',
    'delivery' = 'delivery',
}

export enum PromoTypeText {
    'price' = 'На все услуги (кроме доставки)',
    'lamination' = 'Ламинирование',
    'coloringtinting' = 'Окрашивание/тонирование',
    'choosecolor' = 'Подобрать цвет',
    'delivery' = 'Доставка',
}

export enum PromoStatus {
    's0' = 's0',
    's1' = 's1',
}

export const PromoStatusText = [
    'Использованный',
    'Активный',
];

export interface Promo {
    id?: number;
    type: string;
    code: string;
    typeDiscont?: string;
    discount?: number;
    discount_price?: number;
    time?: number;
    quantity?: number;
    status?: number;
    dateCreate?: Date;
}

export type PromoCodeModalProps = {
    visible: any;
    onHandle: (a: boolean) => any;
};
