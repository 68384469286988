import React from 'react';
import StackGrid from 'react-stack-grid';
import sizeMe from 'react-sizeme';
import { SimilarItem } from './similarItem';

interface Props {
    list: any;
    size: any;
}

const BlogSimilar = ({ list, size }: Props) => (
    <div className="blog-similar">
        <h2>Вам может быть интересно</h2>
        <StackGrid
            component="div"
            duration={ 2000 }
            // eslint-disable-next-line no-nested-ternary
            columnWidth={ size.width >= 320 && size.width < 480 ? '100%' : size.width >= 480 && size.width < 768 ? '50%' : size.width >= 768 && size.width <= 991 ? '33.3%' : '25%' }
            gutterWidth={ 20 }
            gutterHeight={ 20 }
            monitorImagesLoaded={ true }
        >
            { list && list.map((item: any) => (
                <SimilarItem
                    { ...item }
                    key={ item.id }
                />
            )) }
        </StackGrid>
    </div>
);

export default sizeMe()(BlogSimilar);
