import { put, call, select } from 'redux-saga/effects';

import { GetOrdersFeedbackResponse } from 'types/api';

import {
    getOrdersFeedback,
    getOrdersFeedbackFinish,
    getOrdersFeedbackError,
} from 'ducks/orderFeedback/actions';

import { selectOrdersFeedbackPagination } from 'ducks/orderFeedback/selectors';

import { fetchers } from 'api';

export function* getOrdersFeedbackRequest({ payload }: ReturnType<typeof getOrdersFeedback>) {
    try {
        const { pageSize }: ReturnType<typeof selectOrdersFeedbackPagination> = yield select(selectOrdersFeedbackPagination);
        const response: GetOrdersFeedbackResponse = yield call(fetchers.getOrdersFeedback, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize: payload.pagination.pageSize || pageSize,
            },
        });

        yield put(getOrdersFeedbackFinish({
            data: response.data,
            pagination: {
                total: response.pagination.total,
                current: payload.pagination.page,
            },
        }));
    } catch (error) {
        yield put(getOrdersFeedbackError());
    }
}
