import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import moment from 'moment';
import {
    Typography,
    Spin,
    List,
    Button,
    Input,
    message as antMessage,
    Avatar,
} from 'antd';

import { Layout } from 'components/ui/layout';

import {
    getTicket,
    getTicketMessages,
    changeTicketStatus,
    sendTicketMessage,
} from 'ducks/tickets/actions';
import { selectProfile } from 'ducks/user/selectors';
import {
    selectTicket,
    selectTicketMessages,
    selectTicketsLoading,
} from 'ducks/tickets/selectors';

import { DATE_FORMAT } from 'constants/date';
import { getFio, pluralize } from 'utils/formatters';

import {
    Ticket, TicketStatusText, TicketStatusColor, TicketStatus,
} from 'types/ticket';
import { OrderTypeText } from 'types/orders';

import { ACCESS } from 'constants/access';
import { ROUTES } from 'constants/routes';
import { checkAccess } from 'utils/access';

import { history } from 'App';

import styles from './styles.module.scss';

type MatchParams = {
    id: string;
};

const { Title } = Typography;
const { TextArea } = Input;

export const ViewTicket = React.memo(() => {
    const put = useDispatch();
    const data = useSelector(selectTicket);
    const user = useSelector(selectProfile);
    const messages = useSelector(selectTicketMessages);
    const loading = useSelector(selectTicketsLoading);
    const [message, setMessage] = useState('');

    const { params: { id } } = useRouteMatch<MatchParams>();

    useEffect(() => {
        put(getTicket({ id }));
        put(getTicketMessages({ ticketId: id }));
    }, []);

    const handleChangeStatus = useCallback((status: TicketStatus) => () => {
        put(changeTicketStatus({ idTheme: id, status }));
    }, [id]);

    const backToTickets = useCallback(() => {
        history.push(ROUTES.TICKETS.path);
    }, []);

    const goToProfile = useCallback((userId: number) => () => {
        history.push(ROUTES.USER.getPath({ id: userId }));
    }, []);

    const handleChangeMessage = (e: any) => {
        setMessage(e.target.value);
    };

    const handleSendMessage = useCallback(() => {
        if (message.length > 0) {
            put(sendTicketMessage({ idTheme: id, message }, () => setMessage('')));
        } else {
            antMessage.error('Нельзя отправить пустое сообщение');
        }
    }, [message, id]);

    if (!data || loading.getDetail || loading.getMessages) {
        return (
            <Layout>
                <Title level={ 2 }>Тикет №</Title>
                <Spin size="large" spinning={ true } />
            </Layout>
        );
    }

    const ticket = { ...data } as Ticket;

    const isAllowToWorkStatus = ticket.status === TicketStatus.new && !loading.changeStatus;
    const isAllowToClosedStatus = ticket.status === TicketStatus.open && !loading.changeStatus;
    const isAllowSendMessage = ticket.status === TicketStatus.open && !loading.changeStatus;

    return (
        <Layout>
            <Title level={ 2 }>
                Тикет №{ ticket.id }
                <span style={ { color: TicketStatusColor[ticket.status] } }>
                    ({ TicketStatusText[ticket.status] })
                </span>
            </Title>
            {
                checkAccess(user?.access_list, ACCESS.UPDATE_STATUS_SUPPORT) && (
                    <div className={ styles.buttons }>
                        <Button
                            type="primary"
                            disabled={ !isAllowToWorkStatus }
                            onClick={ handleChangeStatus(TicketStatus.open) }
                        >
                            Взять в работу
                        </Button>
                        <Button
                            type="primary"
                            disabled={ !isAllowToClosedStatus }
                            onClick={ handleChangeStatus(TicketStatus.closed) }
                        >
                            Закрыть
                        </Button>
                    </div>
                )
            }

            <div className={ styles.cart }>
                <div className={ styles.infoHeading }>
                    <b>Пользователь:</b>&nbsp;
                    <button
                        onClick={ goToProfile(ticket.user.id) }
                        type="button"
                        className={ styles.link }
                    >
                        { getFio(ticket.user.lastName, ticket.user.firstName) }
                    </button>
                </div>
                <div className={ styles.infoHeading }><b>Дата создания:</b> { moment(ticket.dateCreate).format(DATE_FORMAT.DATE_TIME) }</div>
                <div className={ styles.infoHeading }><b>Тема:</b> { ticket.thema }</div>
                <div className={ styles.infoHeading }><b>Сообщение:</b> { ticket.message }</div>
                <div className={ styles.infoHeading }>
                    <b>Заказ:</b> №{ ticket.orders!.id } - { OrderTypeText[ticket.orders!.formType] }
                &nbsp;от { moment(ticket.orders!.dateCreate).format(DATE_FORMAT.DATE_TIME) }
                </div>

            </div>

            <List
                header={ `${messages.length} ${pluralize(messages.length, ['сообщение', 'сообщения', 'сообщений'])} в теме` }
                itemLayout="horizontal"
                dataSource={ messages }
                renderItem={ (item) => (
                    <li className={ styles.comment }>
                        <div className={ styles.comment__ava }>
                            <Avatar src={ item.user.avatar } />
                        </div>
                        <div className={ styles.comment_content }>
                            <div className={ styles.comment_contact }>
                                <button
                                    className={ styles.link }
                                    onClick={ goToProfile(item.user.id) }
                                    type="button"
                                >
                                    { item.user.lastName } { item.user.firstName }
                                </button>
                                <span className={ styles.date }>{ moment(item.createDate).format(DATE_FORMAT.DATE_TIME) }</span>
                            </div>
                            <div className={ styles.comment_text }>{ item.message }</div>
                        </div>
                    </li>
                ) }
            />
            {
                isAllowSendMessage && (
                    <div className={ styles.messageBlock }>
                        <div className={ styles.messageInput }>
                            <TextArea
                                autoSize={ { minRows: 4, maxRows: 6 } }
                                placeholder="Напишите ваще сообщение"
                                disabled={ loading.sendMessage }
                                onChange={ handleChangeMessage }
                                value={ message }
                            />
                        </div>
                        <Button
                            type="primary"
                            ghost={ true }
                            onClick={ handleSendMessage }
                            loading={ loading.sendMessage }
                        >
                            Отправить сообщение
                        </Button>
                    </div>
                )
            }
            <Button
                onClick={ backToTickets }
            >
                Назад к списку обращений
            </Button>
        </Layout>
    );
});
