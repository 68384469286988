import { put } from 'redux-saga/effects';
import * as Effects from 'redux-saga/effects';

import {
    getBlogSimilar,
    getBlogSimilarError,
    getBlogSimilarFinish,
} from 'ducks/blog/actions';

import { fetchers } from 'api';
import { ResponseGenerator } from 'types/api';

const call: any = Effects.call;

export function* getBlogSimilarRequest({ payload }: ReturnType<typeof getBlogSimilar>) {
    try {
        const response: ResponseGenerator = yield call(fetchers.getBlogSimilar, payload);

        yield put(getBlogSimilarFinish(response));
    } catch (error) {
        yield put(getBlogSimilarError());
    }
}
