import { InferValueTypes } from 'types/common';
import { Statistics } from 'types/statistics';
import { StatisticsActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type StatisticsState = {
    loading: boolean;
    data: Statistics | null;
    filter: {
        dataCreate?: string;
    };
};

export const initialState: StatisticsState = {
    loading: false,
    data: null,
    filter: {},
};

export function statisticsReducer(state = initialState, action: ActionTypes): StatisticsState {
    switch (action.type) {
        case StatisticsActionTypes.GET_STATISTICS_START:
            return {
                ...state,
                loading: true,
                filter: {
                    ...action.payload.filter,
                },
            };
        case StatisticsActionTypes.GET_STATISTICS_FINISH:
            return {
                ...state,
                data: action.response,
                loading: false,
            };

        case StatisticsActionTypes.GET_STATISTICS_ERROR:
            return {
                ...state,
                loading: false,
            };

        default: {
            return state;
        }
    }
}
