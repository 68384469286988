import { put } from 'redux-saga/effects';
import * as Effects from 'redux-saga/effects';
import {
    getBlogDetail,
    getBlogDetailError,
    getBlogDetailFinish,
} from 'ducks/blog/actions';

import { fetchers } from 'api';
import { ResponseGenerator } from 'types/api';

const call: any = Effects.call;

export function* getBlogDetailRequest({ payload }: ReturnType<typeof getBlogDetail>) {
    try {
        const response: ResponseGenerator = yield call(fetchers.getBlogDetail, payload);

        yield put(getBlogDetailFinish(response));
    } catch (error) {
        yield put(getBlogDetailError());
    }
}
