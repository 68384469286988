import React, { useCallback, useState } from 'react';
import {
    Modal, ModalFuncProps, Form, Typography, Button, Tooltip, Upload, Input, Select, Tabs,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { CKEditor } from 'ckeditor4-react';

import { useDispatch, useSelector } from 'react-redux';
import { createBlog } from 'ducks/blog/actions';
import { selectBlogCategories, selectBlogTagsList } from 'ducks/blog/selectors';

const { Title } = Typography;
const { Option } = Select;

type Props = ModalFuncProps;

export const BlogAdd = ({
    title, visible, onCancel,
}: Props): any => {
    const [form] = Form.useForm();
    const [description, setDescription] = useState('');
    const categories = useSelector(selectBlogCategories);
    const tagsList = useSelector(selectBlogTagsList);
    const put = useDispatch();

    const selectTagsOption = tagsList?.map((tag) => ({
        value: tag,
        label: tag,
    }));

    const onOk = useCallback(() => {
        form
            .validateFields()
            .then((values) => {
                const fields = values;

                if (values.previewImage) {
                    const previewImage = [];

                    for (let i = 0; i < values.previewImage.fileList.length; i++) {
                        const files = values.previewImage.fileList[i];

                        previewImage.push(files.originFileObj);
                    }
                    fields.previewImage = previewImage;
                }

                fields.description = description;

                put(createBlog(fields, form.resetFields()));
            });
    }, [description]);

    return (
        <React.Fragment>
            <Modal
                title={ title || undefined }
                visible={ visible }
                onCancel={ onCancel }
                onOk={ onOk }
                keyboard={ false }
                maskClosable={ false }
                width={ 900 }
                footer={ (
                    <Button key="submit" type="primary" onClick={ () => onOk() }>
                        Добавить
                    </Button>
                ) }
            >
                <Title level={ 2 }>Добавить статью</Title>
                <Form
                    form={ form }
                    name="BlogAdd"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Tabs>
                        <Tabs.TabPane tab="Информация" key="info">
                            <Form.Item
                                label="Заголовок статьи"
                                name="title"
                                rules={ [{ required: true, message: 'Обязательно нужно заполнить' }] }
                            >
                                <Input placeholder="Укажите заголовок статьи" />
                            </Form.Item>
                            <Form.Item
                                label="Краткое описание"
                                name="previewText"
                                rules={ [{ required: true, message: 'Обязательно нужно заполнить' }] }
                            >
                                <Input.TextArea placeholder="Укажите краткое описание статьи" />
                            </Form.Item>
                            <Form.Item
                                label="Превью статьи"
                                name="previewImage"
                                valuePropName="previewImage"
                                rules={ [{ required: true, message: 'Загрузите картинку' }] }
                            >
                                <Upload
                                    accept="image/*"
                                    beforeUpload={ () => false }
                                    maxCount={ 1 }
                                >
                                    <Tooltip title="Загрузите картинку">
                                        <Button icon={ <UploadOutlined /> }>Загрузить</Button>
                                    </Tooltip>
                                </Upload>
                            </Form.Item>
                            <Form.Item
                                label="Категория"
                                name="categoryId"
                                rules={ [{ required: true, message: 'Выберите категорию' }] }
                            >
                                <Select
                                    placeholder="Категория"
                                    mode="multiple"
                                >
                                    {
                                        categories && categories.map((item) => <Option key={ item.id } value={ item.id }>{ item.name }</Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Теги"
                                name="tags"
                                rules={ [{ required: true, message: 'Начните вводить теги' }] }
                            >
                                <Select
                                    mode="tags"
                                    style={ { width: '100%' } }
                                    tokenSeparators={ [','] }
                                    options={ selectTagsOption }
                                />
                            </Form.Item>
                            <Form.Item
                                label="Статья"
                                name="description"
                                rules={ [{ required: true, message: 'Напишите статью' }] }
                            >
                                <CKEditor
                                    onChange={ (evt) => setDescription(evt.editor.getData()) }
                                    config={ {
                                        filebrowserUploadUrl: '/api/uploadFiles',
                                        uploadUrl: '/api/uploadFiles/?responseType=json',
                                    } }
                                />
                            </Form.Item>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Seo" key="seo">
                            <Form.Item
                                label="Краткое описание для сео"
                                name="meta_description"
                            >
                                <Input placeholder="Напите краткое описание для сео" />
                            </Form.Item>
                            <Form.Item
                                label="Ключевые слова"
                                name="meta_keywords"
                            >
                                <Select placeholder="Ключевые слова" mode="tags" style={ { width: '100%' } } tokenSeparators={ [','] } />
                            </Form.Item>
                        </Tabs.TabPane>
                    </Tabs>
                </Form>
            </Modal>
        </React.Fragment>
    );
};
