import { ApplicationState } from 'ducks/application-state';

export const selectInfoPromoCode = (state: ApplicationState) => state.promocode.data;
export const selectInfoPromoCodeLoading = (state: ApplicationState) => state.promocode.loadingFilter;

export const selectPromoCodeLending = (state: ApplicationState) => state.promocode.promo;
export const selectPromoCodeLendingLoading = (state: ApplicationState) => state.promocode.loading.lending;

export const selectPromoCode = (state: ApplicationState) => state.promocode.list;
export const selectPromoCodeLoading = (state: ApplicationState) => state.promocode.loading;
export const selectPromoCodePagination = (state: ApplicationState) => state.promocode.pagination;
export const selectPromoCodeId = (state: ApplicationState) => state.promocode.detail;
export const selectPromoCodeIdLoading = (state: ApplicationState) => state.promocode.loading;
