import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getBlogCategories } from 'ducks/blog/actions';
import { selectBlogCategories } from 'ducks/blog/selectors';
import { Category } from 'types/blog';
import cls from './style.module.scss';

export const BlogMenu = () => {
    const put = useDispatch();
    const categories = useSelector(selectBlogCategories);

    useEffect(() => {
        put(getBlogCategories());
    }, []);

    return (
        <div className={ cls.MenuCategories }>
            <ul>
                <li><a href="/blog">Все</a></li>
                { categories && categories.map((item: Category) => <li key={ item.id }><a href={ `/blog?categoryId=${item.id}` }>{ item.name }</a></li>) }
            </ul>
        </div>
    );
};
