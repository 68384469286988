type Props = {
    title?: string;
    description?: string;
    keywords?: string;
    img?: string;
    type: 'article' | 'website';
};

export const getMeta = (props: Props) => {
    const defaultTitle = 'Сервис для окрашивания, тонирования и ламинирования волос профессиональными красителями в домашних условиях';
    const defaultImage = `${window.location.origin}/img/banner.png`;

    let keywords = 'Сервис для окрашивания, тонирования и ламинирования волос профессиональными красителями в домашних условиях';

    if (props.keywords) {
        keywords = props.keywords.split(/[ ,]+/).filter((v) => v !== '').join(',');
    } else if (props.description) {
        keywords = props.description.split(/[ ,]+/).filter((v) => v !== '').join(',');
    }

    const meta = {
        title: props.title || defaultTitle,
        description: props.description || defaultTitle,
        canonical: window.location.href,
        meta: {
            charset: 'utf-8',
            name: {
                keywords,
            },
            itemProp: {
                name: props.title || defaultTitle,
                description: props.description || defaultTitle,
                image: props.img ? `${window.location.origin}/${props.img}` : defaultImage,
            },
            property: {
                'og:title': props.title || defaultTitle,
                'og:type': props.type || 'website',
                'og:image': props.img ? `${window.location.origin}/${props.img}` : defaultImage,
                'og:site_name': props.title || defaultTitle,
                'twitter:card': 'summary_large_image',
                'twitter:title': props.title || defaultTitle,
            },
        },
        auto: {
            ograph: true,
        },
        extends: true,
    };

    return meta;
};
