import React, { useCallback } from 'react';
import {
    Modal, ModalFuncProps, Form, Input, Typography, Rate, Tooltip, Upload, message,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Title } = Typography;

interface Props extends ModalFuncProps {
    onSubmit: any;
}

export const ReviewsAdd = ({
    title, visible, onCancel, onSubmit,
}: Props): any => {
    const [form] = Form.useForm();

    const onOk = useCallback(() => {
        form
            .validateFields()
            .then((values) => {
                const fields = values;

                if (values.files) {
                    const files = [];

                    for (let i = 0; i < values.files.fileList.length; i++) {
                        const file = values.files.fileList[i];

                        files.push(file.originFileObj);
                    }
                    fields.files = files;
                }

                onSubmit(fields);
                form.resetFields();
            });
    }, []);

    function bdeforeUpload(file: any) {
        // Ограничить типы загружаемых файлов
        const imgType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        const videoType = file.type === 'video/mp4' || file.type === 'video/mpeg' || file.type === 'video/mov' || file.type === 'video/webm' || file.type === 'x-msvideo' || file.type === 'video/MP2T';

        if (!imgType && !videoType) {
            message.warning('Загружайте изображения только в формате JPG / PNG / JPEG!');
        }
        // Ограничиваем размер загружаемого файла
        const imgSize = file.size / 1024 / 1024 > 15;

        if (imgSize && imgType) {
            message.warning('Загружаемые изображения должны быть не больше 5 Мбайт');

            return Upload.LIST_IGNORE;
        }
        // Ограничиваем размер загружаемого файла
        const videoSize = file.size / 1024 / 1024 > 40;

        if (videoSize && videoType) {
            message.warning('Загружаемое видео должны быть не больше 40 Мбайт');

            return Upload.LIST_IGNORE;
        }

        return false;
    }

    return (
        <Modal
            title={ title || undefined }
            visible={ visible }
            onCancel={ onCancel }
            onOk={ onOk }
            okText="Оставить отзыв"
        >
            <Title level={ 2 }>Оставьте ваш отзыв</Title>
            <Form
                form={ form }
                name="ReviewsAdd"
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item
                    label="Имя"
                    name="userName"
                    rules={ [
                        { required: true, message: 'Укажите свое Имя' },
                        { min: 4, message: 'Минимально кол-во символов 4' },
                        { whitespace: true, message: 'Пробелы запрещены' },
                    ] }
                >
                    <Input placeholder="Укажите свое Имя" />
                </Form.Item>

                <Form.Item
                    label="Рейтинг"
                    name="rating"
                >
                    <Rate />
                </Form.Item>

                <Form.Item
                    label="Отзыв"
                    name="message"
                    rules={ [
                        { required: true, message: 'Пожалуйста напишите свой отзыв' },
                        { min: 10, message: 'Минимально кол-во символов 10' },
                        { whitespace: true, message: 'Пробелы запрещены' },
                    ] }
                >
                    <TextArea
                        placeholder="Ваш отзыв"
                        autoSize={ { minRows: 2, maxRows: 6 } }
                    />
                </Form.Item>
                <Form.Item label="Загрузите фото или видео до и после" name="files">
                    <Upload
                        accept="image/*, video/*"
                        onPreview={ () => false }
                        // beforeUpload={ () => false }
                        beforeUpload={ (file) => bdeforeUpload(file) }
                        maxCount={ 4 }
                        listType="picture-card"
                    >
                        <Tooltip title="Загрузите фото или видео до и после">
                            <div>
                                <UploadOutlined />
                                <div style={ { marginTop: 8 } }>Загрузить</div>
                            </div>
                        </Tooltip>
                    </Upload>
                </Form.Item>

            </Form>
        </Modal>
    );
};
