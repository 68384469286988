import { put, call } from 'redux-saga/effects';

import {
    getStatistics,
    getStatisticsError,
    getStatisticsFinish,
} from 'ducks/statistics/actions';

import { fetchers } from 'api';

export function* getStatisticsRequest({ payload }: ReturnType<typeof getStatistics>) {
    try {
        const response = yield call(fetchers.getStatistics, payload);

        yield put(getStatisticsFinish(response));
    } catch (error) {
        yield put(getStatisticsError());
    }
}
