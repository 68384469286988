import React from 'react';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import { Faq } from 'components/faq';
import { Reviews } from 'components/reviews';
import DownloadLink from 'components/ui/downloadLink';
import socialTelegram from 'assets/telegram_black.svg';
import { buttonTracking } from 'utils/buttonTracking';
import { BlogSlider } from 'components/blog/slider';
import { LayoutLending } from 'components/ui/layout-lendiing';
import { OrderAdd } from 'components/order/add';

const price = [
    {
        img: '/img/consumable-1.png',
        title: 'Wella Professional',
        items: [
            { name: 'Краситель 60мл', price: 'от 1100' },
            { name: 'Окислитель 1000мл', price: 'от 1500' },
        ],
    },
    {
        img: '/img/consumable-2.png',
        title: 'Londa Professional',
        items: [
            { name: 'Краситель 60мл', price: 'от 580' },
            { name: 'Окислитель 1000мл', price: 'от 800' },
        ],
    },
    {
        img: '/img/consumable-3.png',
        title: 'Estel Professional',
        items: [
            { name: 'Краситель 60мл', price: 'от 300' },
            { name: 'Окислитель 1000мл', price: 'от 560' },
        ],
    },
    {
        img: '/img/consumable-4.png',
        title: 'Schwarzkopf Professional',
        items: [
            { name: 'Краситель 60мл', price: 'от 580' },
            { name: 'Окислитель 1000мл', price: 'от 980' },
        ],
    },
    {
        img: '/img/consumable-5.png',
        title: 'Kapous Professional',
        items: [
            { name: 'Краситель 60мл', price: 'от 260' },
            { name: 'Окислитель 1000мл', price: 'от 460' },
        ],
    },
    {
        img: '/img/consumable-6.png',
        title: 'Keune',
        items: [
            { name: 'Краситель 60мл', price: 'от 1460' },
            { name: 'Окислитель 1000мл', price: 'от 1560' },
        ],
    },
];

const DeliveryComponents = React.memo(() => {
    const [orderAddVisible, setorderAddVisible] = React.useState(false);

    const orderAddToggle = (v: any = false) => {
        setorderAddVisible(v);
        if (v) {
            buttonTracking('header', 'Заказать форму');
        }
    };

    const warning = () => {
        Modal.warning({
            title: <h3>Выбрать красители</h3>,
            content: (<h4>Раздел в разработке. <br />Для заказа напишите в чат</h4>),
        });
        buttonTracking('header', 'Заказать компоненты (новая страница)');
    };

    const warningType = (value: string) => {
        Modal.warning({
            title: <h3>Выбрать красители</h3>,
            content: (<h4>Раздел в разработке. <br />Для заказа напишите в чат</h4>),
        });
        buttonTracking('brand', value);
    };

    return (
        <LayoutLending>
            <div className="banner">
                <div className="banner__image">
                    <picture>
                        <source srcSet="/img/banner-component.png" media="(min-width: 992px)" />
                        <source srcSet="/img/banner-component-md.png" media="(min-width: 768px)" />
                        <source srcSet="/img/banner-component-md2.png" media="(min-width: 321px)" />
                        <img src="/img/banner-component-xs.png" alt="" />
                    </picture>
                </div>
                <div className="banner__content align-items-center">
                    <div className="container">
                        <div className="banner__content-inner">
                            <h1>Hairtone</h1>
                            <p>Купить краски для волос от профессиональных брендов</p>
                            <button type="button" onClick={ warning } className="btn">Выбрать красители</button>
                        </div>
                    </div>
                </div>
                <div className="banner__absolute">
                    <div className="banner__social">
                        <DownloadLink to="https://instagram.com/hairtone.ru?utm_medium=copy_link" target="_blank" className="banner__social-item">
                            <img src="/img/banner-insta.png" alt="" />
                        </DownloadLink>
                        <DownloadLink to="https://vk.com/hairtone" target="_blank" className="banner__social-item">
                            <img src="/img/banner-vk.png" alt="" />
                        </DownloadLink>
                        <DownloadLink to="https://t.me/hairtone" target="_blank" className="banner__social-item">
                            <img src={ socialTelegram } alt="" />
                        </DownloadLink>
                    </div>
                </div>
            </div>

            <section className="section consumables-section">
                <div className="container">
                    <h2>СТОИМОСТЬ КОМПОНЕНТОВ</h2>
                    <div className="consumables">
                        { price.map((item) => (
                            <div className="consumables__item">
                                <div className="consumables__title">
                                    <img src={ item.img } alt="" />
                                    <span>{ item.title }</span>
                                </div>
                                { item.items && (
                                    <div className="consumables__price">
                                        <div className="price">
                                            { item.items.map((list) => (
                                                <div className="price__item">
                                                    <div className="price__title">{ list.name }</div>
                                                    <div className="price__number">{ list.price } ₽</div>
                                                </div>
                                            )) }
                                        </div>
                                        <button type="button" onClick={ () => warningType(item.title) } className="btn btn-small">Перейти в каталог товаров для заказа</button>
                                    </div>
                                ) }
                            </div>
                        )) }
                    </div>
                </div>
            </section>

            <section className="section accordion-section" id="faq">
                <div className="container">
                    <h2>КАК ПОДОБРАТЬ ЦВЕТ ДЛЯ ОКРАШИВАНИЯ</h2>
                    <p>Мы занимаемся профессиональным подбором цвета красителей (разработкой формулы окрашивания) для окрашивания, тонирования и ламинирования. Подробнее о том как работает сервис <Link to="/">смотри на главной</Link></p>
                    <button type="button" onClick={ orderAddToggle } className="btn">Заказать формулу</button>
                    { orderAddVisible && (<OrderAdd visible={ orderAddVisible } onCancel={ () => orderAddToggle(false) } />) }
                </div>
            </section>

            <section className="section accordion-section" id="faq">
                <div className="container">
                    <h2>Часто задаваемые <br /> вопросы</h2>
                    <Faq />
                </div>
            </section>
            <Reviews />
            <BlogSlider />
        </LayoutLending>
    );
});

export default DeliveryComponents;
