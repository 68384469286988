import React from 'react';
import {
    Select,
    Form,
    Row,
    Col,
    Input,
    Radio,
    Checkbox,
    RadioChangeEvent,
} from 'antd';

import { ExpertForm } from 'form-helpers/expert-form-coloringtinting/types';

import { oxidizerProporcial } from 'form-helpers/expert-form-coloringtinting/mapping';
import { getCalculatedWeight } from 'utils/orders';
import { COLS } from 'constants/order';

import { GetColorsResponse } from 'types/api';

import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import styles from './styles.module.scss';

const { Option } = Select;

type Props = {
    onChangeSelect: any;
    values: ExpertForm;
    colors: GetColorsResponse;
    onChangeNumber: (e: React.ChangeEvent<HTMLInputElement>) => void;
    updateForm: (values?: ExpertForm) => void;
    onChangeRadio: (e: RadioChangeEvent) => void;
    onChangeIsLengthEnhancerNeeded: (e: CheckboxChangeEvent) => void;
};

export const LengthEditable = ({
    onChangeSelect,
    values,
    colors,
    updateForm,
    onChangeNumber,
    onChangeRadio,
    onChangeIsLengthEnhancerNeeded,
}: Props) => {
    const colorantType = values.brand;

    const subTypeColorOptions = colors[colorantType]
        ? colors[colorantType].color.map((item: any) => <Option value={ item.id }>{ item.name }</Option>)
        : null;

    const oxidizerSubtypeOptions = colors[colorantType]
        ? colors[colorantType].oxidizer.map((item: any) => <Option value={ item.id }>{ item.name }</Option>)
        : null;

    const { length } = getCalculatedWeight(values);

    const enhancerColorLabel = (
        <React.Fragment>
            Цвет
            <Checkbox
                name="isLengthColorEnhancerNeeded"
                checked={ Boolean(values.isLengthColorEnhancerNeeded) }
                className={ styles.checkbox }
                onChange={ onChangeIsLengthEnhancerNeeded }
            />
        </React.Fragment>
    );

    return (
        <Row>
            <Col className={ styles.cell } { ...COLS.middle }>
                <Form.Item
                    className={ styles.formItem }
                    label="Подтип красителя на длину"
                >
                    <Select
                        onChange={ onChangeSelect('lengthColorsSubType') }
                        value={ values.lengthColorsSubType || '' }
                    >
                        { subTypeColorOptions }
                    </Select>
                </Form.Item>
            </Col>
            <Col className={ styles.cell } { ...COLS.small }>
                <Form.Item
                    className={ styles.formItem }
                    label="Цвет"
                >
                    <Select
                        onChange={ onChangeSelect('lengthColors.natural') }
                        value={ values.lengthColors.natural || '' }
                        disabled={ !length.grayHairKoef }
                    >
                        {
                            values.lengthColorsList.map((item: any) => <Option value={ item.id }>{ item.color }</Option>)
                        }
                    </Select>
                </Form.Item>
                <div className={ styles.formItem }>Масса({ values.lengthColorsName.natural }): { length.natural } гр.</div>
            </Col>
            <Col className={ styles.cell } { ...COLS.small }>
                <Form.Item
                    className={ styles.formItem }
                    label="Цвет"
                >
                    <Select
                        onChange={ onChangeSelect('lengthColors.desired') }
                        value={ values.lengthColors.desired || '' }
                    >
                        {
                            values.lengthColorsList.map((item: any) => <Option value={ item.id }>{ item.color }</Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    className={ styles.formItem }
                    label="Масса"
                >
                    <Input
                        size="small"
                        type="number"
                        value={ values.weightDesiredColorLength }
                        placeholder="Масса"
                        name="weightDesiredColorLength"
                        onChange={ onChangeNumber }
                        onBlur={ () => updateForm() }
                        disabled={ !values.isLengthColorEnhancerNeeded }
                    />
                </Form.Item>
                <div className={ styles.formItem }>Масса({ values.lengthColorsName.desired }): { length.desired } гр.</div>
            </Col>
            <Col className={ styles.cell } { ...COLS.small }>
                <Form.Item
                    className={ styles.formItem }
                    label={ enhancerColorLabel }
                >
                    <Select
                        onChange={ onChangeSelect('lengthColors.enhancer') }
                        value={ values.lengthColors.enhancer || '' }
                        disabled={ !values.isLengthColorEnhancerNeeded }
                    >
                        {
                            values.lengthColorsList.map((item: any) => <Option value={ item.id }>{ item.color }</Option>)
                        }
                    </Select>
                </Form.Item>
                <div className={ styles.formItem }>Масса({ values.lengthColorsName.enhancer }): { length.enhancer } гр.</div>
            </Col>
            <Col className={ styles.cell } { ...COLS.small }>
                <div className={ styles.formItem }>Масса на длину: { length.weightLength } гр.</div>
            </Col>
            <Col className={ styles.cell } { ...COLS.small } />
            <Col className={ styles.cell } { ...COLS.big }>
                <Form.Item
                    className={ styles.formItem }
                    label="Подтип окислителя"
                >
                    <Select
                        onChange={ onChangeSelect('lengthOxidizerSubType') }
                        value={ values.lengthOxidizerSubType || '' }
                    >
                        { oxidizerSubtypeOptions }
                    </Select>
                </Form.Item>
                <Form.Item
                    className={ styles.formItem }
                    label="Окислитель"
                >
                    <Select
                        onChange={ onChangeSelect('lengthOxidizer') }
                        value={ values.lengthOxidizer || '' }
                    >
                        {
                            values.lengthOxidizerList.map((item: any) => <Option value={ item.id }>{ item.value }</Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    className={ styles.formItem }
                    label={ `Масса окислителя на длину: ${length.oxidizer} гр.` }
                >
                    <Radio.Group
                        name="oxidizerWeightLengthRatio"
                        size="small"
                        buttonStyle="solid"
                        value={ values.oxidizerWeightLengthRatio }
                    >
                        {
                            oxidizerProporcial.map((item) => (
                                <Radio.Button
                                    value={ item.value }
                                    onChange={ onChangeRadio }
                                >
                                    { item.text }
                                </Radio.Button>
                            ))
                        }
                    </Radio.Group>
                </Form.Item>
            </Col>
        </Row>
    );
};
