export const orderFormFields = [
    'id',
    'form',
    'formType',
    'image_after',
    'image_before',
    'hairType',
    'density',
    'shadeOfGloss',
    'brand',
    'shade',
    'shade_text',
    'grayHair',
    'lastTimePainted',
    'theMainProblem',
    'theMainProblemImg',
    'aboutChooseColor',
];
