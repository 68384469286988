import { put, call } from 'redux-saga/effects';
import {
    getTicketMessages,
    getTicketMessagesError,
    getTicketMessagesFinish,
} from 'ducks/tickets/actions';

import { fetchers } from 'api';

export function* getTicketMessagesRequest(action: ReturnType<typeof getTicketMessages>) {
    try {
        const response = yield call(fetchers.getTicketMessages, action.payload);

        yield put(getTicketMessagesFinish(response));
    } catch (error) {
        yield put(getTicketMessagesError());
    }
}
