import React, {
    useCallback,
    useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Select,
    DatePicker,
    Button,
    Space,
} from 'antd';

import { getOrders } from 'ducks/orders/actions';
import {
    selectOrdersLoading,
} from 'ducks/orders/selectors';
import { selectInfo, selectInfoLoading } from 'ducks/info/selectors';

import { Filter } from 'components/ui/filter';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { selectProfile } from 'ducks/user/selectors';
import { checkAccess } from 'utils/access';
import { ACCESS } from 'constants/access';
import styles from './styles.module.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

const defaultFilter = {
    formType: undefined,
    brand: undefined,
    status: undefined,
    dateCreate: undefined,
    payment: undefined,
    isTest: undefined,
};

export const OrderFilter = () => {
    const put = useDispatch();
    const info = useSelector(selectInfo);
    const user = useSelector(selectProfile);
    const infoLoading = useSelector(selectInfoLoading);
    const loading = useSelector(selectOrdersLoading);

    const [filter, setFilter] = useState(defaultFilter);

    const isAllow = checkAccess(user?.access_list, ACCESS.VIEW_ORDER_SECRET_FILTER);

    const handleChangeFilter = useCallback((name: string) => (value: any) => {
        setFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const handleChangeFilterCheckbox = useCallback((e: any) => {
        const { name, checked } = e.target;

        setFilter((prev) => ({
            ...prev,
            [name]: checked ? 1 : undefined,
        }));
    }, []);

    const handleResetFilter = useCallback(() => {
        setFilter(defaultFilter);
        put(getOrders({ pagination: { page: 1 }, filter: defaultFilter }));
    }, []);

    const applyFilter = useCallback(() => {
        put(getOrders({ pagination: { page: 1 }, filter }));
    }, [filter]);

    return (
        <Filter
            extra={ (
                <Space size="middle">
                    <Button
                        type="link"
                        onClick={ handleResetFilter }
                        loading={ loading.get }
                    >
                        Сбросить
                    </Button>
                    <Button
                        type="primary"
                        onClick={ applyFilter }
                        loading={ loading.get }
                    >
                        Искать
                    </Button>
                </Space>
            ) }
        >
            <div className={ styles.filter }>
                <div className={ styles.filterBase }>
                    <div className={ styles.filterItemFormType }>
                        <Select
                            placeholder="Тип"
                            allowClear={ true }
                            className={ styles.Select }
                            loading={ infoLoading }
                            onChange={ handleChangeFilter('formType') }
                            value={ filter.formType }
                        >
                            { info?.formType?.map((item) => (<Option value={ item.value } key={ item.value }>{ item.name }</Option>)) }
                        </Select>
                    </div>
                    <div className={ styles.filterItemBrand }>
                        <Select
                            placeholder="Тип красителя"
                            allowClear={ true }
                            loading={ infoLoading }
                            className={ styles.Select }
                            onChange={ handleChangeFilter('brand') }
                            value={ filter.brand }
                        >
                            { info?.brand?.map((item) => (<Option value={ item.value } key={ item.value }>{ item.name }</Option>)) }
                        </Select>
                    </div>
                    <div className={ styles.filterItemStatus }>
                        <Select
                            placeholder="Статус"
                            allowClear={ true }
                            loading={ infoLoading }
                            className={ styles.Select }
                            id="status"
                            onChange={ handleChangeFilter('status') }
                            value={ filter.status }
                        >
                            { info?.status?.map((item) => (<Option value={ item.value } key={ item.value }>{ item.name }</Option>)) }
                        </Select>
                    </div>
                    <div className={ styles.filterItemStatus }>
                        <Select
                            placeholder="Оплата"
                            allowClear={ true }
                            loading={ infoLoading }
                            className={ styles.Select }
                            id="payment"
                            onChange={ handleChangeFilter('payment') }
                            value={ filter.payment }
                        >
                            <Option value="1" key="1">Оплачено</Option>
                            <Option value="0" key="0">Не оплачено</Option>
                        </Select>
                    </div>
                    <div className={ styles.filterItemDatePicker }>
                        <RangePicker
                            placeholder={ ['Дата от', 'Дата до'] }
                            value={ filter.dateCreate }
                            allowClear={ true }
                            className={ styles.Select }
                            onChange={ handleChangeFilter('dateCreate') }
                        />
                    </div>
                </div>
                { isAllow && (
                    <div className={ styles.filterBase }>
                        <div>
                            <Checkbox
                                value={ filter.isTest }
                                name="isTest"
                                onChange={ (e) => handleChangeFilterCheckbox(e) }
                            >
                                Показать тестовые
                            </Checkbox>
                        </div>
                    </div>
                ) }
            </div>
        </Filter>
    );
};
