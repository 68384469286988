import * as Api from 'types/api';
import { Status } from 'types/status';
import {
    OrdersActionTypes,
} from './action-types';

export const clearDetailData = () => ({
    type: OrdersActionTypes.CLEAR_DETAIL_DATA,
});

export const getOrders = (payload: Api.GetOrdersPayload) => ({
    type: OrdersActionTypes.GET_ORDERS_START,
    payload,
});

export const getOrdersError = () => ({
    type: OrdersActionTypes.GET_ORDERS_ERROR,
});

export const getOrdersFinish = (response: Api.GetOrdersResponse) => ({
    type: OrdersActionTypes.GET_ORDERS_FINISH,
    response,
});

export const createOrder = (payload: Api.CreateOrderPayload, Callback?: any) => ({
    type: OrdersActionTypes.CREATE_ORDER_START,
    payload,
    Callback,
});

export const createOrderError = () => ({
    type: OrdersActionTypes.CREATE_ORDER_ERROR,
});

export const createOrderFinish = () => ({
    type: OrdersActionTypes.CREATE_ORDER_FINISH,
});

export const editOrder = (payload: Api.CreateOrderPayload) => ({
    type: OrdersActionTypes.EDIT_ORDER_START,
    payload,
});

export const editOrderError = () => ({
    type: OrdersActionTypes.EDIT_ORDER_ERROR,
});

export const editOrderFinish = () => ({
    type: OrdersActionTypes.EDIT_ORDER_FINISH,
});

export const getOrder = (payload: Api.GetOrderPayload) => ({
    type: OrdersActionTypes.GET_ORDER_START,
    payload,
});

export const getOrderError = () => ({
    type: OrdersActionTypes.GET_ORDER_ERROR,
});

export const getOrderFinish = (response: Api.GetOrderResponse) => ({
    type: OrdersActionTypes.GET_ORDER_FINISH,
    response,
});

export const removeOrder = (payload: Api.RemoveOrderPayload) => ({
    type: OrdersActionTypes.REMOVE_ORDER_START,
    payload,
});

export const removeOrderError = () => ({
    type: OrdersActionTypes.REMOVE_ORDER_ERROR,
});

export const removeOrderFinish = () => ({
    type: OrdersActionTypes.REMOVE_ORDER_FINISH,
});

export const changeOrderStatus = (payload: Api.ChangeOrderStatusPayload) => ({
    type: OrdersActionTypes.CHANGE_ORDER_STATUS_START,
    payload,
});

export const changeOrderStatusError = () => ({
    type: OrdersActionTypes.CHANGE_ORDER_STATUS_ERROR,
});

export const changeOrderStatusFinish = (status: Status, expertId: number) => ({
    type: OrdersActionTypes.CHANGE_ORDER_STATUS_FINISH,
    status,
    expertId,
});

export const sendExpertOrderForm = (payload: Api.ExpertOrderFormPayload) => ({
    type: OrdersActionTypes.SEND_EXPERT_ORDER_FORM_START,
    payload,
});

export const sendExpertOrderFormError = () => ({
    type: OrdersActionTypes.SEND_EXPERT_ORDER_FORM_ERROR,
});

export const sendExpertOrderFormFinish = (data: Api.ExpertOrderFormPayload) => ({
    type: OrdersActionTypes.SEND_EXPERT_ORDER_FORM_FINISH,
    data,
});

export const applyPromocodeToOrder = (data: Api.ApplyPromocodeToOrderPayload) => ({
    type: OrdersActionTypes.APPLY_PROMOCODE_TO_ORDER_START,
    data,
});

export const applyPromocodeToOrderError = () => ({
    type: OrdersActionTypes.APPLY_PROMOCODE_TO_ORDER_ERROR,
});

export const applyPromocodeToOrderFinish = (data: Api.ApplyPromocodeToOrderResponse) => ({
    type: OrdersActionTypes.APPLY_PROMOCODE_TO_ORDER_FINISH,
    data,
});

export const payOrderOnline = (data: Api.PayOrderOnlinePayload) => ({
    type: OrdersActionTypes.PAY_ORDER_ONLINE_START,
    data,
});

export const payOrderOnlineError = () => ({
    type: OrdersActionTypes.PAY_ORDER_ONLINE_ERROR,
});

export const payOrderOnlineFinish = (data: Api.PayOrderOnlineResponse['data']) => ({
    type: OrdersActionTypes.PAY_ORDER_ONLINE_FINISH,
    data,
});
