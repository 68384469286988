import { put, call, select } from 'redux-saga/effects';

import { GetOrdersResponse } from 'types/api';

import {
    getOrders,
    getOrdersError,
    getOrdersFinish,
} from 'ducks/orders/actions';

import { selectOrdersPagination } from 'ducks/orders/selectors';

import { fetchers } from 'api';

export function* getOrdersRequest({ payload }: ReturnType<typeof getOrders>) {
    try {
        const { pageSize }: ReturnType<typeof selectOrdersPagination> = yield select(selectOrdersPagination);
        const response: GetOrdersResponse = yield call(fetchers.getOrders, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize: payload.pagination.pageSize || pageSize,
            },
        });

        yield put(getOrdersFinish({
            data: response.data,
            pagination: {
                total: response.pagination.total,
                current: payload.pagination.page,
            },
        }));
    } catch (error) {
        yield put(getOrdersError());
    }
}
