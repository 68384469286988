import React, { useState } from 'react';
import { CloseOutlined, VerticalAlignTopOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { random } from 'lodash';
import styles from './styles.module.scss';

type Props = {
    menu: Array<{
        icon: JSX.Element;
        tooltip?: string;
        onClick: () => void;
    }>;
};

export const FloatMenu = ({ menu }: Props) => {
    const [open, setOpen] = useState(false);
    const styleWrapper = classNames([styles.wrapper], {
        [styles.open]: open,
    });

    const toggle = () => {
        setOpen(!open);
    };

    return (
        <div className={ styles.float }>
            <div className={ styleWrapper }>
                { menu.map((item) => (
                    <div key={ random(0, 100) } className={ styles.item } onClick={ item.onClick } aria-hidden="true">
                        <Tooltip title={ item.tooltip }>
                            { item.icon }
                        </Tooltip>
                    </div>
                )) }
            </div>
            <div
                className={ styles.btn }
                onClick={ () => toggle() }
                role="button"
                aria-hidden="true"
            >
                { open ? (<CloseOutlined />) : (<VerticalAlignTopOutlined />) }
            </div>
        </div>
    );
};
