import React from 'react';
import moment from 'moment';

import {
    StatusColor,
    Status,
    StatusText,
} from 'types/status';

import {
    OrderType,
    OrderTypeText,
    OrderTypeBrand,
    OrderBrandText,
    ShortOrder,
} from 'types/orders';

import { DATE_FORMAT } from 'constants/date';
import { getFio } from 'utils/formatters';

import styles from './styles.module.scss';

export const columns = [
    {
        title: 'Номер',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Тип',
        dataIndex: 'formType',
        key: 'formType',
        render: (value: OrderType) => OrderTypeText[value],
    },
    {
        title: 'Тип красителя',
        dataIndex: 'brand',
        key: 'brand',
        render: (value: OrderTypeBrand) => OrderBrandText[value],
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (statusId: Status) => (
            <span className={ styles.status } style={ { color: StatusColor[statusId] } }>
                { StatusText[statusId] }
            </span>
        ),
    },
    {
        title: 'Дата заказа',
        dataIndex: 'dateCreate',
        key: 'dateCreate',
        render: (value: string) => moment(value).format(DATE_FORMAT.DATE_TIME),
    },
    {
        title: 'Эксперт',
        dataIndex: '',
        key: 'expert',
        render: (data: ShortOrder) => (data.expert_id ? getFio(data.expert!.lastName, data.expert!.firstName) : null),
    },
];
