import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Typography,
    Select,
    Button,
    Table,
    Pagination,
    Space,
} from 'antd';

import {
    getPromoCode,
    getInfoPromoCode,
    removePromoCode,
} from 'ducks/promocode/actions';

import {
    selectPromoCode,
    selectPromoCodeLoading,
    selectPromoCodePagination,
    selectInfoPromoCode,
    selectInfoPromoCodeLoading,
} from 'ducks/promocode/selectors';

import { selectProfile } from 'ducks/user/selectors';

import { Layout } from 'components/ui/layout';

import { checkAccess } from 'utils/access';

import { Cart } from 'components/ui/cart';
import { Filter } from 'components/ui/filter';
import { PromoCodeModal } from './modal';

import { getColumns } from './columns';
import styles from './styles.module.scss';

const { Title } = Typography;
const { Option } = Select;

const defaultFilter = {
    type: undefined,
    discount: undefined,
    time: undefined,
    quantity: undefined,
    status: undefined,
};

export const PromoCode = React.memo(() => {
    const put = useDispatch();
    const promocodeInfo = useSelector(selectInfoPromoCode);
    const infoLoading = useSelector(selectInfoPromoCodeLoading);
    const orders = useSelector(selectPromoCode);
    const loading = useSelector(selectPromoCodeLoading);
    const pagination = useSelector(selectPromoCodePagination);
    const user = useSelector(selectProfile);

    const [filter, setFilter] = useState(defaultFilter);
    const [isModalVisible, setIsModalVisible] = useState<any>(false);

    useEffect(() => {
        put(getPromoCode({ pagination: { page: 1 } }));
        put(getInfoPromoCode());
    }, []);

    const handleModal = useCallback((val: boolean) => {
        setIsModalVisible(val);
    }, []);

    const goToCreatePromo = useCallback(() => {
        setIsModalVisible(!isModalVisible);
    }, [isModalVisible]);

    const onChangePagination = useCallback((page: number) => {
        put(getPromoCode({ pagination: { page } }));
    }, []);

    const goToEdit = useCallback((e: MouseEvent, id: number) => {
        e.stopPropagation();
        setIsModalVisible({ id });
    }, [isModalVisible]);

    const handleDeleteOrder = useCallback((e: any, id: number) => {
        e.stopPropagation();
        put(removePromoCode({ orderId: id }));
    }, []);

    const handleChangeFilter = useCallback((name: string) => (value: any) => {
        setFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const handleResetFilter = useCallback(() => {
        setFilter(defaultFilter);
        put(getPromoCode({ pagination: { page: 1 } }));
    }, []);

    const applyFilter = useCallback(() => {
        put(getPromoCode({ pagination: { page: 1 }, filter }));
    }, [filter]);

    const isCreateAllow = checkAccess(user?.access_list, 'CREATE_PROMOCODE');
    const isEditAllow = checkAccess(user?.access_list, 'UPDATE_PROMOCODE');
    const isDeleteAllow = checkAccess(user?.access_list, 'DELETE_PROMOCODE');

    return (
        <Layout>
            <div className={ styles.header }>
                <Title level={ 2 }>Промокоды</Title>
                { isCreateAllow && (
                    <Button
                        type="primary"
                        onClick={ goToCreatePromo }
                    >
                        Добавить промокод
                    </Button>
                ) }
            </div>

            <Filter
                extra={ (
                    <Space size="middle">
                        <Button
                            type="link"
                            onClick={ handleResetFilter }
                            loading={ loading.get }
                        >
                            Сбросить
                        </Button>
                        <Button
                            type="primary"
                            onClick={ applyFilter }
                            loading={ loading.get }
                        >
                            Искать
                        </Button>
                    </Space>
                ) }
            >
                <div className={ styles.filterItemFormType }>
                    <Select
                        placeholder="Тип"
                        allowClear={ true }
                        className={ styles.Select }
                        loading={ infoLoading }
                        onChange={ handleChangeFilter('type') }
                        value={ filter.type }
                    >
                        { promocodeInfo?.type.map(({ value, name }) => <Option key={ value } value={ value } title={ name }>{ name }</Option>) }
                    </Select>
                </div>
                <div className={ styles.filterItemDiscount }>
                    <Select
                        placeholder="Cкидка"
                        allowClear={ true }
                        loading={ infoLoading }
                        className={ styles.Select }
                        value={ filter.discount }
                        onChange={ handleChangeFilter('discount') }
                    >
                        { promocodeInfo?.discount.map(({ value, name }) => <Option key={ value } value={ `${value}` } title={ name }>{ name }</Option>) }
                    </Select>
                </div>
                <div className={ styles.filterItemTime }>
                    <Select
                        placeholder="Время"
                        allowClear={ true }
                        loading={ infoLoading }
                        className={ styles.Select }
                        id="time"
                        value={ filter.time }
                        onChange={ handleChangeFilter('time') }
                    >
                        { promocodeInfo?.time.map(({ value, name }) => <Option key={ value } value={ `${value}` } title={ name }>{ name }</Option>) }
                    </Select>

                </div>
                <div className={ styles.filterItemStatus }>
                    <Select
                        placeholder="Статус"
                        allowClear={ true }
                        loading={ infoLoading }
                        className={ styles.Select }
                        id="status"
                        value={ filter.status }
                        onChange={ handleChangeFilter('status') }
                    >
                        { promocodeInfo?.status.map(({ value, name }) => <Option key={ value } value={ `${value}` } title={ name }>{ name }</Option>) }
                    </Select>
                </div>
            </Filter>

            <Cart
                footer={ (
                    <div className={ styles.pagination }>
                        <Pagination
                            total={ pagination.total * pagination.pageSize }
                            pageSize={ pagination.pageSize }
                            current={ pagination.current }
                            onChange={ onChangePagination }
                        />
                    </div>
                ) }
            >
                <Table
                    loading={ loading.get }
                    dataSource={ orders }
                    columns={
                        getColumns(
                            { handleDeleteOrder, goToEdit },
                            { remove: loading.remove },
                            { isEditAllow, isDeleteAllow },
                        )
                    }
                    rowKey="id"
                    pagination={ false }
                />
            </Cart>
            { isModalVisible && (<PromoCodeModal visible={ isModalVisible } onHandle={ handleModal } />) }
        </Layout>
    );
});
