import * as Api from 'types/api';
import {
    UserActionTypes,
} from './action-types';

export const getProfile = ({ userId }: Api.GetUserPayload) => ({
    type: UserActionTypes.GET_PROFILE_START,
    userId,
});

export const getProfileError = () => ({
    type: UserActionTypes.GET_PROFILE_ERROR,
});

export const getProfileFinish = (response: Api.GetUserResponse) => ({
    type: UserActionTypes.GET_PROFILE_FINISH,
    response,
});

export const getUser = ({ userId }: Api.GetUserPayload) => ({
    type: UserActionTypes.GET_USER_START,
    userId,
});

export const getUserError = () => ({
    type: UserActionTypes.GET_USER_ERROR,
});

export const getUserFinish = (response: Api.GetUserResponse) => ({
    type: UserActionTypes.GET_USER_FINISH,
    response,
});

export const saveUser = (payload: Api.SaveUserPayload) => ({
    type: UserActionTypes.SAVE_USER_START,
    payload,
});

export const saveUserError = () => ({
    type: UserActionTypes.SAVE_USER_ERROR,
});

export const saveUserFinish = () => ({
    type: UserActionTypes.SAVE_USER_FINISH,
});

export const getUsers = (payload: Api.GetUsersPayload) => ({
    type: UserActionTypes.GET_USERS_START,
    payload,
});

export const getUsersError = () => ({
    type: UserActionTypes.GET_USERS_ERROR,
});

export const getUsersFinish = (response: Api.GetUsersResponse) => ({
    type: UserActionTypes.GET_USERS_FINISH,
    response,
});
