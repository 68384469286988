export const UserActionTypes = {
    GET_PROFILE_START: 'GET_PROFILE_START',
    GET_PROFILE_FINISH: 'GET_PROFILE_FINISH',
    GET_PROFILE_ERROR: 'GET_PROFILE_ERROR',

    GET_USER_START: 'GET_USER_START',
    GET_USER_FINISH: 'GET_USER_FINISH',
    GET_USER_ERROR: 'GET_USER_ERROR',

    SAVE_USER_START: 'SAVE_USER_START',
    SAVE_USER_FINISH: 'SAVE_USER_FINISH',
    SAVE_USER_ERROR: 'SAVE_USER_ERROR',

    GET_USERS_START: 'GET_USERS_START',
    GET_USERS_FINISH: 'GET_USERS_FINISH',
    GET_USERS_ERROR: 'GET_USERS_ERROR',
} as const;
