import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBlogEditTags, selectBlogTagsList } from 'ducks/blog/selectors';
import { Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { removeBlogTags, setVisibleBlogTags } from 'ducks/blog/actions';
import cls from './style.module.scss';

const { CheckableTag } = Tag;

export const BlogTags = ({ selectedTags, onSelectTags }: any) => {
    const put = useDispatch();
    const [tags, setTags] = useState<any>([]);
    const tagsList = useSelector(selectBlogTagsList);
    const editTags = useSelector(selectBlogEditTags);

    const deleteTags = (tag: any) => {
        setTags((prev: []) => ([...prev, tag]));
    };

    const onCloseTags = () => {
        put(setVisibleBlogTags(false));
        setTags([]);
    };

    const removeTags = () => {
        put(removeBlogTags(tags));
        setTags([]);
    };

    if (editTags) {
        return (
            <div className={ cls.tagsList }>
                { tagsList && tagsList.map((tag) => (
                    <Tag
                        key={ tag }
                        closable={ true }
                        onClose={ () => deleteTags(tag) }
                        className={ cls.noborder }
                    >
                        { tag }
                    </Tag>
                )) }
                { tags?.length > 0 && (
                    <div className={ cls.deleteTags }>
                        <CloseOutlined onClick={ onCloseTags } className={ cls.deleteTags_close } />
                        <span className={ cls.deleteTags_text }><b>Будет удалено:</b> <br /> { tags.join(', ') }</span>
                        <button className={ cls.btnDelete } onClick={ removeTags } type="button">Удалить</button>
                        <div className={ cls.deleteTags_desc }>При удалении данных тегов, вы подтверждаете, что они будут удалены также из самих статей.</div>
                    </div>
                ) }
            </div>
        );
    }

    return (
        <div className={ cls.tagsList }>
            { tagsList && tagsList.map((tag) => (
                <CheckableTag
                    key={ tag }
                    checked={ selectedTags.indexOf(tag) > -1 }
                    onChange={ (checked: boolean) => onSelectTags(tag, checked) }
                >
                    { tag }
                </CheckableTag>
            )) }
        </div>
    );
};
