import { Order } from 'types/orders';

export const expertFields = [
    'id',
    'status',
    'imageManager',
    'messageManagerForClient',
];

export const notRequiredField = [
    'imageManager', 'imageManagerDelete',
];

export const getInitialValues = (
    order: Order,
) => ({
    imageManager: order.imageManager,
    messageManagerForClient: order.messageManagerForClient,
});
