import axios from 'axios';
import {
    ApplyPromocodeToOrderPayload,
    ChangeOrderStatusPayload,
    ChangeTicketStatusPayload,
    CreateBlogPayload,
    CreateOrderPayload,
    CreatePromoCodePayload,
    CreateTicketPayload,
    DeleteBlogPayload,
    EditPromocodePayload,
    EditReviewsPayload,
    ExpertOrderFormPayload,
    GetBlogDetailPayload,
    GetBlogPayload,
    GetFaqPayload,
    GetOrderFeedbackPayload,
    GetOrderPayload,
    GetOrdersFeedbackPayload,
    GetOrdersPayload,
    GetPromoCodePayload,
    GetStatisticsPayload,
    GetTicketMessagePayload,
    GetTicketPayload,
    GetTicketsPayload,
    GetUserPayload,
    GetUsersPayload,
    PasswordRecoveryPayload,
    PayOrderOnlinePayload,
    PriceItem,
    PromoCodePayload,
    RefreshTokenResponse,
    RemoveOrderPayload,
    RemovePromoCodePayload,
    ReviewsPayload,
    SaveUserPayload,
    SendTicketMessagePayload,
    SignInPayload,
    SignUpPayload,
} from 'types/api';

import { ContentTypes, getBody } from 'form-helpers/utils';
import { PriceForm } from 'form-helpers/price/types';
import { RemoveBlogTagsPayload } from 'types/blog';

export const fetchers = {
    createAdvertisingPromocode: (data: any) => axios.post('/api/advertising', data),
    setTracking: (data: any) => axios.post('/api/tracking', data),
    getReviewsLending: (params: GetFaqPayload) => axios.get('/api/reviews',
        {
            params: {
                ...params.pagination,
                ...params.filter,
                ...params.sort,
            },
        }),
    getReviews: (params: ReviewsPayload) => axios.get('/api/reviews/manager',
        {
            params: {
                ...params.pagination,
                ...params.sort,
            },
        }),
    createReviews: (data: any) => axios.post('/api/reviews', getBody(
        data,
        ContentTypes.MPFD,
    ), {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
    editReview: ({ id, ...res }: EditReviewsPayload) => axios.put(`/api/reviews/manager/${id}`, res),
    signUp: (data: SignUpPayload) => axios.post('/api/signup', data),
    signIn: (data: SignInPayload) => axios.post('/api/signin', data),
    refreshToken: (data: RefreshTokenResponse) => axios.post('/api/token', data),
    passwordRecovery: (data: PasswordRecoveryPayload) => axios.post('/api/recovery', data),

    // инфа для заявок
    getInfo: () => axios.get('/api/order/form/info'),
    getColors: () => axios.get('/api/order/form/color'),

    // промокоды
    editPromocode: (data: EditPromocodePayload) => axios.put('/api/company/promocode', data),
    getPromocodeLending: () => axios.get('/api/company/promocode'),
    getPromocode: (params: GetPromoCodePayload) => axios.get('/api/promocode',
        {
            params: {
                ...params.pagination,
                ...params.filter,
                ...params.sort,
            },
        }),
    removePromocode: ({ orderId }: RemovePromoCodePayload) => axios.delete(`/api/promocode/${orderId}`),
    getInfoPromocode: () => axios.get('/api/promocode/info'),
    getPromoCodeId: ({ orderId }: PromoCodePayload) => axios.get(`/api/promocode/${orderId}`),
    createPromocode: (data: CreatePromoCodePayload) => axios.post('/api/promocode', data),
    updatePromocode: (data: CreatePromoCodePayload) => axios.put(`/api/promocode/${data.id}`, data),

    getFaq: (params: GetFaqPayload) => axios.get('/api/faq',
        {
            params: {
                ...params.pagination,
                ...params.filter,
                ...params.sort,
            },
        }),

    // заявки
    getOrders: (params: GetOrdersPayload) => axios.get('/api/order',
        {
            params: {
                ...params.pagination,
                ...params.filter,
                ...params.sort,
            },
        }),

    createOrder: (data: CreateOrderPayload) => axios.post('/api/order', getBody(
        {
            ...data,
        },
        ContentTypes.MPFD,
    ), {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
    editOrder: ({ id, ...restProps }: CreateOrderPayload) => axios.put(`/api/order/${id}`, getBody(
        {
            ...restProps,
        },
        ContentTypes.MPFD,
    ), {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
    getOrder: ({ orderId }: GetOrderPayload) => axios.get(`/api/order/${orderId}`),

    removeOrder: ({ orderId }: RemoveOrderPayload) => axios.delete(`/api/order/${orderId}`),
    changeOrderStatus: (data: ChangeOrderStatusPayload) => axios.post('/api/order/status', data),
    sendExpertOrderForm: ({ id, ...data }: ExpertOrderFormPayload) => axios.put(`/api/order/expert/${id}`, data),
    applyPromocodeToOrder: (data: ApplyPromocodeToOrderPayload) => axios.post('/api/promocode/active', data),
    payOrderOnline: (data: PayOrderOnlinePayload) => axios.post('/api/payment', data),

    getOrderFeedbak: (params: GetOrderFeedbackPayload) => axios.get('/api/order/feedback', {
        params,
    }),
    getOrdersFeedback: (params: GetOrdersFeedbackPayload) => axios.get('/api/order/feedback',
        {
            params: {
                ...params.pagination,
                ...params.filter,
                ...params.sort,
            },
        }),
    sendExpertOrderFeedbackForm: (data: ExpertOrderFormPayload) => axios.post('/api/order/feedback/', data),

    // Цены
    getPrice: () => axios.get('/api/price'),
    createPrice: (data: PriceForm) => axios.post('/api/price', data),
    editPrice: ({ id, ...data }: PriceItem) => axios.put(`/api/price/${id}`, data),
    deletePrice: (priceId: number) => axios.delete(`/api/price/${priceId}`),

    // Техподдержка
    getTickets: (params: GetTicketsPayload) => axios.get('/api/support/theme',
        {
            params: {
                ...params.pagination,
                ...params.filter,
                ...params.sort,
            },
        }),
    getTicket: ({ id }: GetTicketPayload) => axios.get(`/api/support/theme/${id}`),
    createTicket: (data: CreateTicketPayload) => axios.post('/api/support/theme', data),
    getOrdersForTicket: () => axios.post('/api/support'),
    getTicketMessages: ({ ticketId }: GetTicketMessagePayload) => axios.get(`/api/support/message/${ticketId}`),
    sendTicketMessage: (data: SendTicketMessagePayload) => axios.post('/api/support/message', data),
    changeTicketStatus: ({ status, ...restProps }: ChangeTicketStatusPayload) => axios.post(`/api/support/theme/status/${status}`, restProps),
    getCountNewMessage: () => axios.get('/api/support/theme/count'),

    // Статистика
    getStatistics: (params: GetStatisticsPayload) => axios.get('/api/statistics', {
        params: {
            ...params.filter,
        },
    }),

    // Пользователь
    getUser: ({ userId }: GetUserPayload) => axios.get(`/api/users/${userId}`),
    getUsers: (params: GetUsersPayload) => axios.get('/api/users',
        {
            params: {
                ...params.pagination,
                ...params.sort,
            },
        }),
    saveUser: ({ id, ...restProps }: SaveUserPayload) => axios.put(`/api/users/${id}`, getBody(
        {
            ...restProps,
        },
        ContentTypes.MPFD,
    ), {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),

    // Блог
    getBlog: (params: GetBlogPayload) => axios.get('/api/blog',
        {
            params: {
                ...params.pagination,
                ...params.filter,
                ...params.sort,
            },
        }),
    getBlogCategories: () => axios.get('/api/blog/categories'),
    getBlogDetail: (payload: GetBlogDetailPayload) => axios.get(`/api/blog/${payload.id}`),
    getBlogSimilar: (data: GetBlogDetailPayload) => axios.get(`/api/blog/similar/${data.id}`),
    createBlog: (data: CreateBlogPayload) => axios.post('/api/blog', getBody(
        data,
        ContentTypes.MPFD,
    ), {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
    editBlog: (data: CreateBlogPayload) => axios.put(`/api/blog/${data.id}`, getBody(
        data,
        ContentTypes.MPFD,
    ), {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
    removeTagBlog: (tags: RemoveBlogTagsPayload): any => axios.post('/api/blog/delete-tags', { tags }),
    removeBlog: (id: DeleteBlogPayload): any => axios.delete(`/api/blog/${id}`),
    likeBlog: (id: number | string) => axios.post('/api/blog/like', { id }),
};
