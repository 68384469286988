import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconCash } from '@tabler/icons-react';
import {
    Button, Divider, Input, message, Typography,
} from 'antd';

import { ACCESS } from 'constants/access';
import { checkAccess } from 'utils/access';
import { applyPromocodeToOrder, payOrderOnline } from 'ducks/orders/actions';
import { selectOrder, selectOrdersLoading } from 'ducks/orders/selectors';
import { selectProfile } from 'ducks/user/selectors';

import { Order } from 'types/orders';
import styles from './styles.module.scss';

const { Title } = Typography;

export const OrderPayment = React.memo(() => {
    const put = useDispatch();
    const order = useSelector(selectOrder) as Order;
    const orderLoading = useSelector(selectOrdersLoading);
    const user = useSelector(selectProfile);

    const [code, setCode] = useState(order.paymentInfo.promocode || '');

    const applyPromocode = useCallback(() => {
        if (code.length > 0) {
            put(applyPromocodeToOrder({ type: order.formType, orderId: order.id, code }));
        } else {
            message.error('Введите промокод');
        }
    }, [order.id, code]);

    const handleChangeCode = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value);
    }, []);

    const payOnline = useCallback(() => {
        put(payOrderOnline({ orderId: order.id }));
    }, [order.id]);

    const isAccessCheck = checkAccess(user!.access_list, ACCESS.UPDATE_STATUS_ORDER)
        && order.expert_id === user?.id;

    const isPaid = Boolean(order?.payment);
    const isRefund = Boolean(order?.paymentInfo.refund);

    const orderPaidStatus = isRefund
        ? '/ Возврат осуществлен'
        : isPaid && '/ Оплачен';

    if (isAccessCheck && isPaid) {
        return (
            <div className={ styles.blockPromocode }>
                <div><b>Цена</b>: { order.paymentInfo.price } руб. <Divider type="vertical" /></div>
                <div><b>Промокод</b>: { order.paymentInfo.promocode || 'Не указан' }</div>
            </div>
        );
    }

    if (user?.id !== order.user_id) return null;

    return (
        <div className={ styles.cart }>
            <div className={ styles.cart_paycode }>
                { !isPaid && (
                    <React.Fragment>
                        <div className={ styles.payment }>
                            <Title level={ 4 } style={ { marginBottom: 0 } }>Оплата</Title>
                            <Title level={ 4 } style={ { marginTop: 0 } }>{ order.paymentInfo.defaultPrice } ₽</Title>
                        </div>
                        <Title level={ 5 } style={ { marginBottom: 0 } }>Промокод</Title>
                        <div className={ styles.promocode }>
                            <Input
                                placeholder="Введите промокод"
                                size="large"
                                onChange={ handleChangeCode }
                                value={ code }
                            />
                            <Button
                                size="large"
                                type="primary"
                                danger={ true }
                                onClick={ applyPromocode }
                                loading={ orderLoading.applyPromocode }
                            >
                                Применить
                            </Button>
                        </div>
                    </React.Fragment>
                ) }
            </div>
            <div className={ styles.cart_line } />
            <div className={ styles.cart_pay }>
                <Title level={ 4 }>Итоговая цена: { order.paymentInfo.price } ₽ { orderPaidStatus }</Title>
                { !isPaid && (
                    <Button
                        size="large"
                        type="primary"
                        className={ styles.paidButton }
                        onClick={ payOnline }
                        loading={ orderLoading.payOnline }
                    >
                        <IconCash />
                        Оплатить онлайн
                    </Button>
                ) }
            </div>
        </div>
    );
});
