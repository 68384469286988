import { put, call, select } from 'redux-saga/effects';

import {
    getTickets,
    getTicketsError,
    getTicketsFinish,
} from 'ducks/tickets/actions';

import { selectTicketsPagination } from 'ducks/tickets/selectors';

import { fetchers } from 'api';

export function* getTicketsRequest({ payload }: ReturnType<typeof getTickets>) {
    try {
        const { pageSize } = yield select(selectTicketsPagination);
        const response = yield call(fetchers.getTickets, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize: payload.pagination.pageSize || pageSize,
            },
        });

        yield put(getTicketsFinish({
            data: response.data,
            pagination: {
                total: response.pagination.total,
                page: payload.pagination.page,
            },
        }));
    } catch (error) {
        yield put(getTicketsError());
    }
}
