import * as Api from 'types/api';
import {
    SignUpActionTypes,
    SignInActionTypes,
    PasswordRecoveryActionTypes,
} from './action-types';

export const signUp = (values: Api.SignUpPayload, onCallback?: any) => ({
    type: SignUpActionTypes.SIGN_UP_START,
    values,
    onCallback,
});

export const signUpError = () => ({
    type: SignUpActionTypes.SIGN_UP_ERROR,
});

export const signUpFinish = () => ({
    type: SignUpActionTypes.SIGN_UP_FINISH,
});

export const signIn = (values: Api.SignInPayload, onCallback?: any) => ({
    type: SignInActionTypes.SIGN_IN_START,
    values,
    onCallback,
});

export const signInError = () => ({
    type: SignInActionTypes.SIGN_IN_ERROR,
});

export const signInFinish = () => ({
    type: SignInActionTypes.SIGN_IN_FINISH,
});

export const passwordRecovery = (values: Api.PasswordRecoveryPayload) => ({
    type: PasswordRecoveryActionTypes.PASSWORD_RECOVERY_START,
    values,
});

export const passwordRecoveryError = () => ({
    type: PasswordRecoveryActionTypes.PASSWORD_RECOVERY_ERROR,
});

export const passwordRecoveryFinish = () => ({
    type: PasswordRecoveryActionTypes.PASSWORD_RECOVERY_FINISH,
});
