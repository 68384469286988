import React from 'react';
import {
    Typography,
    Row,
    Col,
} from 'antd';

import { ExpertForm } from 'form-helpers/expert-form-coloringtinting/types';
import { OrderBrandText } from 'types/orders';
import { getCalculatedWeight } from 'utils/orders';

import styles from './styles.module.scss';

type Props = {
    values: ExpertForm;
    instruction: any;
};

const { Title } = Typography;

export const ViewExpertForm = ({ values, instruction }: Props) => {
    const { length, roots } = getCalculatedWeight(values);

    return (
        <React.Fragment>
            <Title level={ 3 }>{ OrderBrandText[values.brand] }</Title>
            <Title level={ 4 }>Корни</Title>
            <div className={ styles.formItem }>Краситель: <b>{ values.subTypeColorName }</b></div>
            <div className={ styles.formItem }>
                Цвет: &nbsp;
                {
                    values.colorsName.natural && (
                        <b>{ values.colorsName.natural }({ roots.natural } гр.) + </b>
                    )
                }
                <b>{ values.colorsName.desired }({ roots.desired } гр.) + </b>
                <b>{ values.colorsName.enhancer }({ roots.enhancer } гр.)</b>
            </div>
            <div className={ styles.formItem }>Масса на корни: <b>{ roots.weightRoots } гр.</b></div>

            <div className={ styles.formItem }>Подтип окислителя: <b>{ values.subTypeOxidizerName }</b></div>
            <div className={ styles.formItem }>Окислитель: <b>{ values.oxidizerName }</b></div>
            <div className={ styles.formItem }>Масса окислителя на корни: <b>{ roots.oxidizer } гр.</b></div>
            {
                values.isLengthNeeded ? (
                    <React.Fragment>
                        <Title level={ 4 }>Длина</Title>
                        <div className={ styles.formItem }>Краситель: <b>{ values.lengthColorsSubTypeName }</b></div>
                        <div className={ styles.formItem }>
                            Цвет: &nbsp;
                            {
                                values.lengthColorsName.natural && (
                                    <b>{ values.lengthColorsName.natural }({ length.natural } гр.) + </b>
                                )
                            }
                            <b>{ values.lengthColorsName.desired }({ length.desired } гр.)</b>
                            {
                                values.lengthColorsName.enhancer && (
                                    <b> + { values.lengthColorsName.enhancer }({ length.enhancer } гр.)</b>
                                )
                            }
                        </div>
                        <div className={ styles.formItem }>Масса на длину: <b>{ length.weightLength } гр.</b></div>

                        <div className={ styles.formItem }>Подтип окислителя: <b>{ values.lengthOxidizerSubTypeName }</b></div>
                        <div className={ styles.formItem }>Окислитель: <b>{ values.lengthOxidizerName }</b></div>
                        <div className={ styles.formItem }>Масса окислителя на длину: <b>{ length.oxidizer } гр.</b></div>
                    </React.Fragment>
                ) : null
            }
            <Row>
                <Col
                    lg={ 14 }
                    md={ 14 }
                    sm={ 14 }
                    xs={ 24 }
                >
                    <div className={ styles.itemGroup }>
                        <Title level={ 5 }>Инструкция</Title>
                        <a href={ instruction?.url } target="_blank" rel="noopener noreferer noreferrer">
                            Скачать инструкцию - { instruction?.name }
                        </a>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};
