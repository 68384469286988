import { message } from 'antd';
import { put, call } from 'redux-saga/effects';
import {
    editBlog,
    editBlogError,
    editBlogFinish,
    getBlog,
} from 'ducks/blog/actions';
import { fetchers } from 'api';

export function* editBlogRequest({ payload, Callback }: ReturnType<typeof editBlog>) {
    try {
        const response = yield call(fetchers.editBlog, payload);

        if (response.type === 'success') {
            message.success(response.message);
            if (Callback) Callback();
            yield put(editBlogFinish(response.data));
            yield put(getBlog({ pagination: { page: 1 } }));
        } else {
            message.error(response.message);
        }
    } catch (error) {
        yield put(editBlogError());
    }
}
