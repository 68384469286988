/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import { Upload, Modal } from 'antd';
import { UploadFile as UploadFileType, UploadChangeParam, UploadFile as UploadFileProps } from 'antd/lib/upload/interface';
import { CloseOutlined } from '@ant-design/icons';
import { Image } from 'types/orders';
import noImage from 'assets/camera.png';

import styles from './styles.module.scss';

type Props = {
    onChange: (file: any) => void;
    count: number;
    defaultValue: Image[];
    disabled?: boolean;
};

export const UploadFile = ({
    onChange,
    count,
    defaultValue,
    disabled,
}: Props) => {
    const [preview, setPreview] = useState<string>();
    // @ts-ignore
    const [fileList, setFileList] = useState<UploadFileType[]>(defaultValue || []);

    const uploadButton = fileList.length === count ? null : (
        <div className={ styles.image }>
            <img src={ noImage } alt="" />
        </div>
    );

    const beforeUpload = () => false;

    const onChangeFile = (info: UploadChangeParam) => {
        setFileList(info.fileList);
        const files = info.fileList.map((item) => item.originFileObj || item);

        onChange(count === 1 ? files[0] : files);
    };

    const onPreview = (file: UploadFileProps) => {
        const url = file.url || URL.createObjectURL(file.originFileObj);

        setPreview(url);
    };

    return (
        <div>
            <Upload
                fileList={ fileList }
                listType="picture-card"
                beforeUpload={ beforeUpload }
                onPreview={ onPreview }
                onChange={ onChangeFile }
                disabled={ disabled }
            >
                { uploadButton }
            </Upload>
            <Modal
                visible={ Boolean(preview) }
                footer={ null }
                onCancel={ () => setPreview(undefined) }
                bodyStyle={ { padding: 0 } }
                closeIcon={ <CloseOutlined style={ { backgroundColor: '#fff', padding: 20 } } /> }
            >
                <img style={ { width: '100%' } } src={ preview } alt="" />
            </Modal>
        </div>
    );
};
