import { put, call } from 'redux-saga/effects';

import {
    getPromoCodeLendingError,
    getPromoCodeLendingFinish,
} from 'ducks/promocode/actions';

import { fetchers } from 'api';

export function* getPromocodeLendingRequest() {
    try {
        const response = yield call(fetchers.getPromocodeLending);

        yield put(getPromoCodeLendingFinish(response));
    } catch (error) {
        yield put(getPromoCodeLendingError());
    }
}
