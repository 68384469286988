import React, { useCallback } from 'react';
import {
    Button, Form, Input, Modal, ModalFuncProps, Typography, Upload,
} from 'antd';
import ym from 'react-yandex-metrika';
import { UploadOutlined } from '@ant-design/icons';
import { AuthLogin } from 'components/auth';
import { useDispatch } from 'react-redux';
import { createOrder } from 'ducks/orders/actions';
import { getToken } from 'utils/token';
import { buttonTracking } from 'utils/buttonTracking';
import { OrderFormType, OrderType } from 'types/orders';
import cls from './style.module.scss';

const { Title, Text } = Typography;

type Props = ModalFuncProps;

export const OrderAddChooseColor = ({
    title, visible, onCancel,
}: Props): any => {
    const [form] = Form.useForm();
    const put = useDispatch();

    const [authLogin, setAuthLogin] = React.useState(false);

    const onOk = useCallback(() => {
        form
            .validateFields()
            .then((values) => {
                if (getToken()) {
                    const fields = values;

                    if (values.image_before) {
                        const imageBefore = [];

                        for (let i = 0; i < values.image_before.fileList.length; i++) {
                            const files = values.image_before.fileList[i];

                            imageBefore.push(files.originFileObj);
                        }
                        fields.image_before = imageBefore;
                    }

                    fields.form = OrderFormType.choosecolor;
                    fields.formType = OrderType.choosecolor;

                    put(createOrder(fields, form.resetFields()));
                    buttonTracking('popup-register', 'Отправка заказа');
                    ym('reachGoal', 'push_order');
                } else {
                    setAuthLogin(true);
                    buttonTracking('popup-login', 'Авторизация');
                }
            });
    }, []);

    return (
        <React.Fragment>
            <Modal
                title={ title || undefined }
                visible={ visible }
                onCancel={ onCancel }
                onOk={ onOk }
                keyboard={ false }
                maskClosable={ false }
                footer={ (
                    <Button key="submit" type="primary" onClick={ () => onOk() }>
                        Заказать
                    </Button>
                ) }
            >
                <Title level={ 2 }>Подобрать цвет</Title>
                <Form
                    form={ form }
                    name="OrderAddChouseColor"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item
                        label="Загрузите 3 фотографии"
                        name="image_before"
                        valuePropName="image_before"
                        tooltip="Загрузите 3 фотографии, на которых четко видно цвет и длину ваших волос, а также лицо, можно с легким дневным макияжем"
                    >
                        <Upload
                            accept="image/*"
                            beforeUpload={ () => false }
                            listType="picture-card"
                            multiple={ true }
                        >
                            <div className={ cls.uploadImage }>
                                <UploadOutlined />
                                <div className={ cls.uploadImage__title }>Загрузить</div>
                            </div>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        label="Комментарий"
                        name="aboutChooseColor"
                        tooltip="Информация, которая поможет нам узнать вас немного лучше: расскажите о предыдущих прическах (удачных и неудачных), с какими оттенками вы чувствуете себя наиболее комфортно или наименее комфортно, какой стиль одежды и цветовую гамму чаще всего предпочитаете, ваши хобби. Если есть определенные пожелания, опишите их"
                    >
                        <Input.TextArea
                            id="aboutChooseColor"
                            name="aboutChooseColor"
                            autoSize={ { minRows: 2, maxRows: 6 } }
                            placeholder="Информация, которая поможет нам узнать вас немного лучше"
                        />
                    </Form.Item>

                </Form>
            </Modal>
            <AuthLogin
                discription={ <Text type="danger">Для того чтобы зарегистрировать заказ, Вам необходимо авторизоваться или зарегистироваться</Text> }
                visible={ authLogin }
                isRegister={ true }
                onCancel={ () => setAuthLogin(false) }
                onCallback={ () => setAuthLogin(false) }
            />
        </React.Fragment>
    );
};
