import * as Api from 'types/api';
import { PriceActionTypes } from './action-types';

export const getPrice = () => ({
    type: PriceActionTypes.GET_PRICE_START,
});

export const getPriceError = () => ({
    type: PriceActionTypes.GET_PRICE_ERROR,
});

export const getPriceFinish = (response: Api.PriceResponse) => ({
    type: PriceActionTypes.GET_PRICE_FINISH,
    response,
});

export const createPrice = (data: Api.CreatePricePayload, callback: () => void) => ({
    type: PriceActionTypes.CREATE_PRICE_START,
    data,
    callback,
});

export const createPriceError = () => ({
    type: PriceActionTypes.CREATE_PRICE_ERROR,
});

export const createPriceFinish = () => ({
    type: PriceActionTypes.CREATE_PRICE_FINISH,
});

export const editPrice = (data: Api.PriceItem, callback: () => void) => ({
    type: PriceActionTypes.EDIT_PRICE_START,
    data,
    callback,
});

export const editPriceError = () => ({
    type: PriceActionTypes.EDIT_PRICE_ERROR,
});

export const editPriceFinish = () => ({
    type: PriceActionTypes.EDIT_PRICE_FINISH,
});

export const removePrice = (priceId: number) => ({
    type: PriceActionTypes.REMOVE_PRICE_START,
    priceId,
});

export const removePriceError = (priceId: number) => ({
    type: PriceActionTypes.REMOVE_PRICE_ERROR,
    priceId,
});

export const removePriceFinish = (priceId: number) => ({
    type: PriceActionTypes.REMOVE_PRICE_FINISH,
    priceId,
});
