import { GetFaqPayload } from 'types/faq';
import { FaqActionTypes } from './action-types';

export const getFaq = (payload: GetFaqPayload) => ({
    type: FaqActionTypes.GET_FAQ_START,
    payload,
});

export const getFaqError = () => ({
    type: FaqActionTypes.GET_FAQ_ERROR,
});

export const getFaqFinish = (response: any) => ({
    type: FaqActionTypes.GET_FAQ_FINISH,
    response,
});
