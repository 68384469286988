import React from 'react';
import { Button, Popconfirm } from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {
    PromoType,
    PromoTypeText,
    PromoStatusText,
} from 'types/promocode';

import { DATE_FORMAT } from 'constants/date';

export const getColumns = (
    actions: {
        goToEdit: any;
        handleDeleteOrder: any;
    },
    loading: {
        remove: boolean;
    },
    access: {
        isEditAllow: boolean;
        isDeleteAllow: boolean;
    },
) => [
    {
        title: 'Номер',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Тип',
        dataIndex: 'type',
        key: 'type',
        render: (value: PromoType) => PromoTypeText[value],
    },
    {
        title: 'Cкидка/Цена',
        dataIndex: 'discount',
        key: 'discount',
        render: (value: string, row: any) => (value ? `${value}%` : `${row.discount_price} Р`),
    },
    {
        title: 'Дней',
        dataIndex: 'time',
        key: 'time',
        render: (value: string) => `${value} дн.`,
    },
    {
        title: 'Кол-во',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (value: number) => (value === 0 ? (<span>&#8734;</span>) : value),

    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (value: any) => PromoStatusText[value],
    },
    {
        title: 'Действует',
        dataIndex: 'endTime',
        key: 'endTime',
        render: (value: string, row: any) => `${moment(row.dateCreate).format(DATE_FORMAT.DATE_TIME)} - ${moment(value).format(DATE_FORMAT.DATE_TIME)}`,
    },
    {
        title: '',
        dataIndex: '',
        key: 'actions',
        render: (data: any) => (
            <React.Fragment>
                { access.isEditAllow && (
                    <Button
                        onClick={ (e) => actions.goToEdit(e, data.id) }
                    >
                        <EditOutlined />
                    </Button>
                ) }
                {
                    access.isDeleteAllow && (
                        <Popconfirm
                            title="Вы уверены, что хотите удалить заказ?"
                            onConfirm={ (e: any) => actions.handleDeleteOrder(e, data.id) }
                            onCancel={ (e: any) => e.stopPropagation() }
                            okText="Да"
                            cancelText="Нет"
                        >
                            <Button
                                onClick={ (e) => e.stopPropagation() }
                                type="primary"
                                danger={ true }
                                loading={ loading.remove }
                            >
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    )
                }
            </React.Fragment>
        ),
    },
];
