import moment from 'moment';
import { DATE_FORMAT } from 'constants/date';

export const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Фамилия',
        dataIndex: 'lastName',
        key: 'lastName',
    },
    {
        title: 'Имя',
        dataIndex: 'firstName',
        key: 'firstName',
    },
    {
        title: 'Дата регистрации',
        dataIndex: 'createDate',
        key: 'createDate',
        render: (value: string) => moment(value).format(DATE_FORMAT.DATE_TIME),
    },
    {
        title: 'Дата последнего заказа',
        dataIndex: 'lastDateCreateOrders',
        key: 'lastDateCreateOrders',
        render: (value: string) => value && moment(value).format(DATE_FORMAT.DATE_TIME),
    },
    {
        title: 'Количество заказов',
        dataIndex: 'countOrders',
        key: 'countOrders',
    },
];
