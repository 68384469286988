import { put, call, select } from 'redux-saga/effects';

import {
    getInfoPromoCodeError,
    getInfoPromoCodeFinish,
} from 'ducks/promocode/actions';

import { selectInfoPromoCode } from 'ducks/promocode/selectors';

import { fetchers } from 'api';

export function* getInfoPromoCodeRequest() {
    try {
        const info = yield select(selectInfoPromoCode);

        if (info) {
            yield put(getInfoPromoCodeFinish(info));
        } else {
            const response = yield call(fetchers.getInfoPromocode);

            yield put(getInfoPromoCodeFinish(response));
        }
    } catch (error) {
        yield put(getInfoPromoCodeError());
    }
}
