import { put, call } from 'redux-saga/effects';
import { message } from 'antd';

import {
    signIn,
    signInError,
    signInFinish,
} from 'ducks/app/actions';

import { SignInResponse } from 'types/api';

import { fetchers } from 'api';
import { setToken } from 'utils/token';

export function* signInRequest({ values, onCallback }: ReturnType<typeof signIn>) {
    try {
        const res: SignInResponse = yield call(fetchers.signIn, values);

        if (res.type === 'success') {
            setToken(res.token, res.refreshToken);
            message.success(res.message);
        }

        if (onCallback && res.token && res.refreshToken) {
            onCallback();
        } else {
            window.location.replace('/');
        }

        yield put(signInFinish());
    } catch (error) {
        yield put(signInError());
    }
}
