import React from 'react';
import { Modal } from 'antd';
import { put, call } from 'redux-saga/effects';
import {
    createOrder,
    createOrderError,
    createOrderFinish,
    clearDetailData,
} from 'ducks/orders/actions';

import { fetchers } from 'api';

import { history } from 'App';

import { ROUTES } from 'constants/routes';

export function* createOrderRequest({ payload, Callback }: ReturnType<typeof createOrder>) {
    const modal = Modal.warning({
        title: 'Ожидайте',
        content: <div>Идет создание заказа...</div>,
    });

    try {
        const response = yield call(fetchers.createOrder, payload);

        const txt = `Ваш заказ зарегистрирован, вам необходимо его оплатить на <b><a href="/order/${response.id}/view">детальной странице заказа</a></b>`;

        yield put(clearDetailData());

        modal.update((prev) => ({
            ...prev,
            type: 'success',
            title: 'Заказ успешно оформлен',
            content: <div dangerouslySetInnerHTML={ { __html: txt } } />,
            onOk: () => window.location.assign(`/order/${response.id}/view`),
            okText: 'Оплатить заказ',
            closable: true,
            onCancel: () => window.location.reload(),
        }));

        setTimeout(() => {
            history.push(ROUTES.VIEW_ORDER.getPath({ id: response.id }));
        }, 30000);

        if (Callback) Callback();
        yield put(createOrderFinish());
    } catch (error) {
        modal.update((prev) => ({
            ...prev,
            type: error.status,
            title: 'Создание заказа',
            content: <div>Ошибка создания заказа</div>,
        }));
        yield put(createOrderError());
    }
}
