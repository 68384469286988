import React from 'react';
import { Reviews } from 'types/reviews';
import { Image } from 'antd';
import cl from './style.module.scss';
import { ReviewsItemRating } from './rating';
import Video from './video';

export const ReviewsItem = ({
    id, message, picture, video, rating, answer,
}: Reviews) => {
    const imgList = picture?.split(',') || null;
    const videoList = video?.split(',') || null;

    return (
        <div className="reviews__item">
            <div className="reviews__item-top">
                <ReviewsItemRating rating={ rating } />
            </div>
            <p className="reviews__text">{ message }</p>
            <div className="reviews__imgvideo">
                { imgList && imgList.length && (
                    <Image.PreviewGroup key={ id }>
                        { imgList.map((url) => (
                            <Image
                                className="reviews__images-item"
                                src={ url }
                                preview={
                                    { mask: false }
                                }
                                key={ url }
                            />
                        )) }
                    </Image.PreviewGroup>
                ) }
                { videoList && videoList.length &&
                    videoList.map((url) => (
                        <Video url={ url } key={ url } />
                    )) }
            </div>
            { answer && (
                <div className={ cl.answer }>
                    <div className={ cl.answer__author }>Ответ:</div>
                    <p>{ answer }</p>
                </div>
            ) }
        </div>
    );
};
