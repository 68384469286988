import React from 'react';
import { Link as LinkNav } from 'react-router-dom';
import { Anchor } from 'antd';

import { ROUTES } from 'constants/routes';

const { Link } = Anchor;

export const MenuLending = React.memo(({
    auth,
    closeMenu,
    onExit,
    onLogin,
    onRegister,
}: any) => (
    <Anchor affix={ false } onClick={ closeMenu }>
        <ul className="menu">
            <li className="menu__item">
                <Link href="/#how_it_works" className="menu__link scrollTo" title="Как это работает" />
            </li>
            <li className="menu__item">
                <Link href="/#price" className="menu__link scrollTo" title="ЦЕНЫ" />
            </li>
            <li className="menu__item">
                <Link href="/#faq" className="menu__link scrollTo" title="Вопросы и ответы" />
            </li>
            <li className="menu__item">
                <Link href="/#reviews" className="menu__link scrollTo" title="Отзывы" />
            </li>
            <li className="menu__item">
                <Link href="/blog" className="menu__link" title="Блог" />
            </li>
            <li className="menu__item">
                <Link href="/career" className="menu__link" title="Карьера в Hairtone" />
            </li>
            <li className="menu__item">
                <Link href="/#footer" className="menu__link scrollTo" title="Контакты" />
            </li>
        </ul>
        { auth ? (
            <div className="mobile-btn-auth">
                <LinkNav to={ ROUTES.MAIN.path }><button type="button" className="btn">Профиль</button></LinkNav>
                <button type="button" onClick={ () => onExit() } className="btn btn--null">Выйти</button>
            </div>
        ) : (
            <div className="mobile-btn-auth">
                <button type="button" onClick={ () => onLogin(true) } className="btn">Вход</button>
                <button type="button" onClick={ () => onRegister(true) } className="btn btn--null">Регистрация</button>
            </div>
        ) }
    </Anchor>
));
