import React from 'react';
import { declOfNum } from 'utils/formatters';

import styles from './styles.module.scss';

type Image = {
    name: string;
    uid: number;
    url: string;
};

type Props = {
    size: number;
    value: Image[];
};

export const InfoItemNotLoader = ({ size, value }: Props) => (value.length < size ?
    <div className={ styles.darken }>Не загружено { size - value.length } { declOfNum(size - value.length, ['фото', 'фотографий', 'фотографий']) }</div>
    : null);
