export const CONDITIONERS: { [key: string]: string } = {
    wella: 'Wella Professional Invigo Color Brilliance',
    londa: 'Londa Color Radiance Conditioner',
    estel: 'Estel Princess Essex Balsam Color Save',
};

export const COLS = {
    middle: {
        lg: 4,
        xs: 4,
    },
    small: {
        lg: 3,
        xs: 3,
    },
    big: {
        lg: 5,
        xs: 5,
    },
};

export const colorsKeys = ['colors', 'lengthColors'];
export const lengthIgnoreFields = [
    'weightDesiredColorLength',
    'oxidizerWeightLengthRatio',
    'lengthOxidizerSubType',
    'lengthOxidizer',
    'lengthColorsSubType',
    'lengthColors',
];

export const DEFAULT = {
    colors: {
        natural: null,
        desired: null,
        enhancer: null,
    },
    colorsName: {
        natural: '',
        desired: '',
        enhancer: '',
    },
};
