import { Order, OrderTypeBrand } from 'types/orders';
import { GetColorsResponse } from 'types/api';

export const expertFields = [
    'id',
    'status',
    'brand',
    'colors',
    'subTypeColor',
    'subTypeOxidizer',
    'weight',
    'weightRoots',
    'oxidizer',
    'oxidizerWeightRootsRatio',
    'oxidizerWeightLengthRatio',
    'grayHair',
    'lengthGrayHair',
    'instruction',
    'lengthColors',
    'lengthColorsSubType',
    'lengthOxidizerSubType',
    'lengthOxidizer',
    'weightDesiredColorLength',
    'weightDesiredColorRoots',
    'isLengthNeeded',
    'isLengthColorEnhancerNeeded',
];

export const notRequiredField = [
    'isLengthNeeded',
    'isLengthColorEnhancerNeeded',
];

export const oxidizerProporcial = [
    {
        value: 1,
        text: '1 к 1',
    },
    {
        value: 2,
        text: '1 к 2',
    },
];

const getColorsName = (color: any, currentSubtype: any, brand: any) => {
    if (!currentSubtype || !brand) {
        return {
            natural: '',
            desired: '',
            enhancer: '',
        };
    }

    return {
        natural: currentSubtype.list.find((item: any) => item.id === color.natural)?.color,
        desired: currentSubtype.list.find((item: any) => item.id === color.desired)?.color,
        enhancer: currentSubtype.list.find((item: any) => item.id === color.enhancer)?.color,
    };
};

const getOxidizersName = (oxidizer: any, oxidizerSubtype: any, colors: any) => {
    if (!oxidizerSubtype || !colors) {
        return ['', ''];
    }

    return oxidizerSubtype.list.find((item: any) => item.id === oxidizer)?.value;
};

export const getInitialValues = (
    order: Order,
    colors: GetColorsResponse,
) => {
    const currentSubtype = order.brand && order.subTypeColor
        ? colors[order.brand].color.find((item: any) => item.id === order.subTypeColor)
        : null;

    const colorsName = getColorsName(order.colors, currentSubtype, order.brand);

    const oxidizerSubtype = order.brand && order.subTypeOxidizer
        ? colors[order.brand].oxidizer.find((item: any) => item.id === order.subTypeOxidizer)
        : null;

    const oxidizerName = getOxidizersName(order.oxidizer, oxidizerSubtype, order.colors);

    // длина
    const currentLengthSubtype = order.brand && order.lengthColorsSubType
        ? colors[order.brand].color.find((item: any) => item.id === order.lengthColorsSubType)
        : null;

    const lengthColorsName = getColorsName(order.lengthColors, currentLengthSubtype, order.brand);

    const lengthOxidizerSubtype = order.brand && order.lengthOxidizerSubType
        ? colors[order.brand].oxidizer.find((item: any) => item.id === order.lengthOxidizerSubType)
        : null;

    const lengthOxidizerName = getOxidizersName(order.lengthOxidizer, lengthOxidizerSubtype, order.lengthColors);

    return {
        weight: order.weight,
        conditioner: order.conditioner,
        isbrand: order.isbrand,
        brand: order.brand as OrderTypeBrand,
        grayHair: order.grayHair,
        lengthGrayHair: order.lengthGrayHair,
        instruction: order.instruction || '',

        // корни
        subTypeColor: order.subTypeColor,
        subTypeColorName: currentSubtype?.name,
        colors: order.colors,
        colorsName,
        colorsList: currentSubtype?.list || [],
        subTypeOxidizer: order.subTypeOxidizer || '',
        subTypeOxidizerName: oxidizerSubtype?.name,
        oxidizer: order.oxidizer,
        oxidizerName,
        oxidizerList: oxidizerSubtype?.list || [],
        oxidizerWeightRootsRatio: order.oxidizerWeightRootsRatio,
        weightRoots: order.weightRoots,

        // длина
        oxidizerWeightLengthRatio: order.oxidizerWeightLengthRatio,
        weightDesiredColorRoots: order.weightDesiredColorRoots,
        lengthColors: order.lengthColors,
        lengthColorsSubType: order.lengthColorsSubType,
        lengthColorsSubTypeName: currentLengthSubtype?.name,
        weightDesiredColorLength: order.weightDesiredColorLength,
        lengthColorsName,
        lengthColorsList: currentLengthSubtype?.list || [],

        lengthOxidizerSubType: order.lengthOxidizerSubType,
        lengthOxidizerSubTypeName: lengthOxidizerSubtype?.name,
        lengthOxidizer: order.lengthOxidizer,
        lengthOxidizerName,
        lengthOxidizerList: lengthOxidizerSubtype?.list || [],

        isLengthNeeded: order.isLengthNeeded,
        isLengthColorEnhancerNeeded: order.isLengthColorEnhancerNeeded,
    };
};
