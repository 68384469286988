import { put, call } from 'redux-saga/effects';
import { message } from 'antd';
import {
    createTicket,
    createTicketError,
    createTicketFinish,
    getTickets,
} from 'ducks/tickets/actions';

import { fetchers } from 'api';

export function* createTicketRequest({ data, callback, isFromOrder }: ReturnType<typeof createTicket>) {
    try {
        yield call(fetchers.createTicket, data);
        yield put(createTicketFinish());
        callback();
        if (isFromOrder) {
            message.success('Ваше обращение создано!');
        } else {
            yield put(getTickets({ pagination: { page: 1 } }));
        }
    } catch (error) {
        yield put(createTicketError());
    }
}
