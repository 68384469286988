import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from 'ducks/user/selectors';
import { Button } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import { getPromoCodeLending } from 'ducks/promocode/actions';
import { selectPromoCodeLending } from 'ducks/promocode/selectors';
import { PromocodeEdit } from './add';

export const Promocode = () => {
    const [promocodeEditVisible, setPromocodeEditVisible] = useState(false);
    const put = useDispatch();
    const promocode = useSelector(selectPromoCodeLending);
    const user = useSelector(selectProfile);

    const promocodeEditToggle = (v: any) => {
        setPromocodeEditVisible(v || !promocodeEditVisible);
    };

    useEffect(() => {
        put(getPromoCodeLending());
    }, []);

    return (
        <React.Fragment>
            {
                (promocode && promocode.visible) || (user && user.access === 'ADMIN') ?
                    (
                        <div className="promocode">
                            <div className="container">
                                <div className="promocode__content">
                                    { promocode && (<div className="promocode__text">{ promocode.message }</div>) }
                                    {
                                        user && user.access === 'ADMIN' ?
                                            (<Button className="promocode__btn" onClick={ promocodeEditToggle } icon={ <FormOutlined /> } />)
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    )
                    : null
            }
            { promocodeEditVisible && (<PromocodeEdit data={ promocode } visible={ promocodeEditVisible } onCancel={ () => promocodeEditToggle(false) } />) }
        </React.Fragment>
    );
};
