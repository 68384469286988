import { put, call } from 'redux-saga/effects';
import {
    createPrice,
    createPriceError,
    createPriceFinish,
    getPrice,
} from 'ducks/price/actions';

import { fetchers } from 'api';

export function* createPriceRequest({ data, callback }: ReturnType<typeof createPrice>) {
    try {
        yield call(fetchers.createPrice, data);
        yield put(createPriceFinish());
        callback();
        yield put(getPrice());
    } catch (error) {
        yield put(createPriceError());
    }
}
