import React from 'react';

import styles from './styles.module.scss';

type Props = {
    title?: string;
    children: React.ReactNode;
};

export const FormLayout = ({ title, children }: Props) => (
    <div className={ styles.container }>
        <div className={ styles.form }>
            { title && <h1 className={ styles.title }>{ title }</h1> }
            { children }
        </div>
    </div>
);
