import { put, call } from 'redux-saga/effects';
import {
    likeBlog,
    likeBlogError,
    likeBlogFinish,
} from 'ducks/blog/actions';
import { fetchers } from 'api';
import { ResponseGenerator } from 'types/api';

export function* likeBlogRequest({ id }: ReturnType<typeof likeBlog>) {
    try {
        const response: ResponseGenerator = yield call(fetchers.likeBlog, id);

        yield put(likeBlogFinish(response));
    } catch (error) {
        yield put(likeBlogError());
    }
}
