import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Tooltip,
    Modal,
    Input,
    message as antMessage,
} from 'antd';

import { changeOrderStatus, sendExpertOrderForm } from 'ducks/orders/actions';
import { selectOrder, selectOrdersLoading } from 'ducks/orders/selectors';
import { selectProfile } from 'ducks/user/selectors';

import { HELPER_TEXT } from 'constants/text';
import { ACCESS } from 'constants/access';

import { checkAccess, checkAllowedChangeStatus } from 'utils/access';

import { Order } from 'types/orders';
import { Status } from 'types/status';
import { ExpertOrderFormPayload } from 'types/api';

import styles from './styles.module.scss';

const { TextArea } = Input;

export const OrderStatusList = React.memo(() => {
    const put = useDispatch();
    const user = useSelector(selectProfile);
    const order = useSelector(selectOrder) as Order;
    const ordersLoading = useSelector(selectOrdersLoading);
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleModal1, setVisibleModal1] = useState(false);
    const [comment, setComment] = useState('');
    const [fillInFields, setFillInFields] = useState('');

    const isChangeStatusAllowed = checkAccess(user!.access_list, ACCESS.UPDATE_STATUS_ORDER)
        && (order.status === Status.new ? true : order.expert_id === user?.id)
        && order.status !== Status.completed
        && order.status !== Status.denied;

    const isChangeStatusAllowedRefund = checkAccess(user!.access_list, ACCESS.UPDATE_STATUS_ORDER)
        && (order.status === Status.completed ? true : order.expert_id === user?.id)
        && order.status !== Status.inOperation;

    const allowedStatus = checkAllowedChangeStatus(user, order, ordersLoading.changeStatus);

    const handleChangeStatus = (
        status: Status,
        errorFieldsImg?: string,
        message?: string,
    ) => () => {
        put(changeOrderStatus({
            id: order.id,
            status,
            errorFieldsImg,
            message,
        }));
    };

    const handleChangeVisibilityModal = (value: boolean) => () => setVisibleModal(value);
    const handleChangeVisibilityModal1 = (value: boolean) => () => setVisibleModal1(value);

    const onDeniedOrder = () => {
        if (comment.length) {
            handleChangeStatus(Status.denied, undefined, comment)();
            setComment('');
            setVisibleModal(false);
        } else {
            antMessage.error('Введите причину отклонения');
        }
    };

    const onIsTest = () => {
        const isTest = order.isTest ? 0 : 1;

        put(sendExpertOrderForm({
            id: order.id,
            isTest,
        } as ExpertOrderFormPayload));
    };

    const onFillInFieldsOrder = () => {
        if (fillInFields.length) {
            handleChangeStatus(Status.notFilledFields, undefined, fillInFields)();
            setFillInFields('');
            setVisibleModal1(false);
        } else {
            antMessage.error('Укажите что необходимо заполнить клиенту');
        }
    };

    const handleChangeComment = (e: any) => {
        setComment(e.target.value);
    };

    const handleChangeFillInFields = (e: any) => {
        setFillInFields(e.target.value);
    };

    if (isChangeStatusAllowed || isChangeStatusAllowedRefund) {
        return (
            <div className={ styles.cart }>
                { isChangeStatusAllowed && (
                    <React.Fragment>
                        <Tooltip
                            title={ !allowedStatus.inOperation && HELPER_TEXT.STATUS_IN_OPERATION_DISABLED }
                            trigger="hover"
                        >
                            <Button
                                disabled={ !allowedStatus.inOperation }
                                onClick={ handleChangeStatus(Status.inOperation) }
                            >
                                Взять в работу
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title={ !allowedStatus.denied && HELPER_TEXT.STATUS_IN_MASTER_DOUBT_DISABLED }
                            trigger="hover"
                        >
                            <Button
                                disabled={ !allowedStatus.denied }
                                onClick={ handleChangeVisibilityModal1(true) }
                                type="primary"
                            >
                                Попросить заполнить данные
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title={ !allowedStatus.masterDoubt && HELPER_TEXT.STATUS_IN_MASTER_DOUBT_DISABLED }
                            trigger="hover"
                        >
                            <Button
                                disabled={ !allowedStatus.masterDoubt }
                                onClick={ handleChangeStatus(Status.masterDoubt) }
                                type="primary"
                                ghost={ true }
                            >
                                Мастер сомневается
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title={ !allowedStatus.denied && HELPER_TEXT.STATUS_IN_MASTER_DOUBT_DISABLED }
                            trigger="hover"
                        >
                            <Button
                                disabled={ !allowedStatus.denied }
                                onClick={ handleChangeVisibilityModal(true) }
                                danger={ true }
                                type="primary"
                            >
                                Отклонить
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title={ !allowedStatus.completed && HELPER_TEXT.STATUS_IN_COMPLETED_DISABLED }
                            trigger="hover"
                        >
                            <Button
                                disabled={ !allowedStatus.completed }
                                onClick={ handleChangeStatus(Status.completed) }
                                type="primary"
                            >
                                Завершить заявку
                            </Button>
                        </Tooltip>
                    </React.Fragment>
                ) }
                { isChangeStatusAllowedRefund && (
                    <Tooltip
                        title={ HELPER_TEXT.STATUS_IN_OPERATION_FROM_IN_COMPLETED_DISABLED }
                        trigger="hover"
                    >
                        <Button
                            onClick={ handleChangeStatus(Status.inOperation) }
                            type="primary"
                        >
                            Вернуть в работу
                        </Button>
                    </Tooltip>
                ) }
                <Tooltip
                    title={ allowedStatus.isTest ? HELPER_TEXT.STATUS_TEST_ENABLE_TOOLTIP : HELPER_TEXT.STATUS_TEST_TOOLTIP }
                    trigger="hover"
                >
                    <Button
                        onClick={ onIsTest }
                    >
                        { allowedStatus.isTest ? 'Актуальная заявка' : 'Тестовая заявка' }
                    </Button>
                </Tooltip>
                <Modal
                    visible={ visibleModal }
                    onOk={ onDeniedOrder }
                    onCancel={ handleChangeVisibilityModal(false) }
                    confirmLoading={ ordersLoading.changeStatus }
                >
                    <TextArea
                        style={ { marginTop: 32 } }
                        onChange={ handleChangeComment }
                        autoSize={ { minRows: 4, maxRows: 6 } }
                        placeholder="Причина отклонения"
                    />
                </Modal>
                <Modal
                    visible={ visibleModal1 }
                    onOk={ onFillInFieldsOrder }
                    onCancel={ handleChangeVisibilityModal1(false) }
                    confirmLoading={ ordersLoading.changeStatus }
                >
                    <TextArea
                        style={ { marginTop: 32 } }
                        onChange={ handleChangeFillInFields }
                        autoSize={ { minRows: 4, maxRows: 6 } }
                        placeholder="Напишите текст сообщения что необходимо заполнить. Чтобы отобразить на странице заказа клиенту"
                    />
                </Modal>
            </div>
        );
    }

    return null;
});
