import { put, call } from 'redux-saga/effects';
import {
    getTicket,
    getTicketError,
    getTicketFinish,
} from 'ducks/tickets/actions';

import { fetchers } from 'api';

export function* getTicketRequest(action: ReturnType<typeof getTicket>) {
    try {
        const response = yield call(fetchers.getTicket, action.payload);

        yield put(getTicketFinish(response));
    } catch (error) {
        yield put(getTicketError());
    }
}
