import { put, call, select } from 'redux-saga/effects';

import {
    getReviewsLending,
    getReviewsLendingError,
    getReviewsLendingFinish,
} from 'ducks/reviews/actions';

import { selectReviewsPagination } from 'ducks/reviews/selectors';

import { fetchers } from 'api';
import { ResponseGenerator } from 'types/api';

export function* getReviewsLendingRequest({ payload }: ReturnType<typeof getReviewsLending>) {
    try {
        const { pageSize } = yield select(selectReviewsPagination);
        const response: ResponseGenerator = yield call(fetchers.getReviewsLending, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize: payload.pagination.pageSize || pageSize,
            },
        });

        yield put(getReviewsLendingFinish({
            data: response.data,
            size: response.size,
            averageRating: response.averageRating,
            pagination: {
                page: payload.pagination.page,
                total: response.pagination.total,
            },
        }));
    } catch (error) {
        yield put(getReviewsLendingError());
    }
}
