export const HELPER_TEXT = {
    EDIT_ORDER_DENIED: 'Редактирование заказа запрещено в текущем статусе, либо она в работе у другого менеджера',
    REMOVE_ORDER_DENIED: 'Удаление заказа в текущем статусе запрещено, либо заказ оплачен',
    STATUS_IN_OPERATION_DISABLED: 'Нельзя взять в работу неоплаченный заказ, либо в текущем статусе',
    STATUS_IN_MASTER_DOUBT_DISABLED: 'Нельзя перевести в данный статус из текущего статуса',
    STATUS_IN_DENIED_DISABLED: 'Нельзя перевести в данный статус из текущего статуса',
    STATUS_IN_COMPLETED_DISABLED: 'Завершить заказ можно только заполнив форму',
    STATUS_IN_OPERATION_FROM_IN_COMPLETED_DISABLED: 'После нажатие вам откроется снова форма для редактирование. Не забудьте сообщить клиенту что вы исправили формулу.',

    ORDER_IMAGE_TOOLTIP: 'Загрузите фото со спины и в профиль (в кадре обязательно должно быть видно всю длину волос), а так же фото прикорневой зоны (для этого разделите волосы прямым пробором посередине). Фото должно быть хорошего качества, без фильтров и при дневном освещении. ВАЖНО! Следите за тем, что бы прямые солнечные лучи не попали в кадр и на ваши волосы',
    ORDER_IMAGE_TOOLTIP_FOR_COLOR: 'Загрузите 3 фотографии, на которых четко видно цвет и длину ваших волос, а также лицо, можно с легким дневным макияжем',
    ORDER_IMAGE_AFTER_TOOLTIP: 'Загрузите 3 фотографии результата, который вы хотите получить в итоге. Фотографии могут быть из разных источников, но иметь одно направление. Например, вы бы хотели покраситься в “холодный русый” цвет, значит, вы должны загрузить 3 фото, которые отображают, что именно для вас означает “холодный русый” и как вы его для себя представляете. Это поможет нам лучше понять ваши вкусовые предпочтения.',
    STATUS_TEST_ENABLE_TOOLTIP: 'Пометить заявку как актуальную',
    STATUS_TEST_TOOLTIP: 'Пометить заявку как тестовую',
};
