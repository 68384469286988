import { put, call, select } from 'redux-saga/effects';

import {
    getInfoError,
    getInfoFinish,
} from 'ducks/info/actions';

import { selectInfo } from 'ducks/info/selectors';

import { fetchers } from 'api';

export function* getInfoRequest() {
    try {
        const info = yield select(selectInfo);

        if (info) {
            yield put(getInfoFinish(info));
        } else {
            const response = yield call(fetchers.getInfo);

            yield put(getInfoFinish(response));
        }
    } catch (error) {
        yield put(getInfoError());
    }
}
