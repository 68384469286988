/* eslint-disable no-nested-ternary */
import React, {
    ChangeEvent, InputHTMLAttributes, useRef, useState,
} from 'react';
import { Image, Spin, Modal } from 'antd';
import axios from 'axios';
import { IconPhotoPlus } from '@tabler/icons-react';
import styles from './styles.module.scss';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'multiple' | 'disabled' | 'name' | 'action' | 'id' | 'multiple'> {
    onRemove?: any;
    defaultList?: any;
    disabled?: boolean;
    multiple?: boolean;
    deleted?: boolean;
    count?: number;
    name?: string;
    callbackAfterLoad?: () => void;
    action: {
        table: 'order' | 'orderFeedback';
        id: number;
        orderId?: number;
        field: 'images' | 'image_before' | 'image_after' | 'imageManager' | 'theMainProblemImg';
    };
}

const error = (title: string, content = '') => {
    Modal.error({
        title,
        content,
    });
};

export const UploadFileANek = ({
    name = 'upload',
    multiple = false,
    disabled = false,
    deleted = false,
    count = 1,
    action,
    defaultList,
    callbackAfterLoad,
    ...props
}: Props) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState(defaultList || []);
    const actionUrl = action ? `/api/upload?table=${action.table}&id=${action.id}&field=${action.field}` : '/api/upload';

    const onDelete = (uid: string) => {
        const newFiles = fileList.filter((list: any) => list.uid !== uid);
        const deleteData = {
            field: action.field,
            id: action.id,
            table: action.table,
            fileId: uid,
        };

        setLoading(true);

        axios.put(actionUrl, deleteData)
            .then(() => {
                setFileList(newFiles);
                setLoading(false);
                if (callbackAfterLoad) callbackAfterLoad();
            })
            // eslint-disable-next-line @typescript-eslint/no-shadow
            .catch((error: any) => {
                // eslint-disable-next-line no-console
                console.log(`Error fetching profile ${error}`);
            });
    };

    const handleUploadClick = () => {
        inputRef.current?.click();
    };

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files: FileList | null = e.target.files;

        const size = count - fileList.length;

        if (!files) {
            return;
        }

        if (files.length > size) {
            error('Вы выбрали слишком много файлов', `Вам разрешено выбрать еще ${size} файл(а).`);

            return;
        }

        setLoading(true);
        const data = new FormData();

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            data.append(`${action.field}`, file, file.name);
        }

        data.append('field', action.field);
        data.append('id', String(action.id));
        data.append('table', action.table);

        axios.post(actionUrl, data)
            .then((file) => {
                if (Array.isArray(file)) {
                    setFileList([...fileList, ...file]);
                } else {
                    setFileList([...fileList, file]);
                }
                setLoading(false);
                if (callbackAfterLoad) callbackAfterLoad();
            })
            // eslint-disable-next-line @typescript-eslint/no-shadow
            .catch((error: any) => {
                // eslint-disable-next-line no-console
                console.log(`Error fetching profile ${error}`);
            });
    };

    const btnLoad = (
        <div onClick={ handleUploadClick } aria-hidden="true" className={ styles.upload }>
            <IconPhotoPlus />
            Загрузить
        </div>
    );
    const btnLoading = (
        <div className={ styles.upload__loading }>
            <Spin />
        </div>
    );

    return (
        <div>
            <div className={ styles.imagesList }>
                <Image.PreviewGroup>
                    { fileList.map(({ uid, url }: any) => (
                        <div className={ styles.imagesList_item }>
                            <Image className={ styles.image__img } key={ uid } src={ url } />
                            { !deleted && (<div className={ styles.delete } onClick={ () => onDelete(uid) } aria-hidden="true">Удалить</div>) }
                        </div>
                    )) }
                </Image.PreviewGroup>
                { (fileList.length >= count || disabled) ? null : loading ? btnLoading : btnLoad }
            </div>

            <input
                { ...props }
                ref={ inputRef }
                type="file"
                name={ name }
                disabled={ disabled }
                multiple={ multiple }
                style={ { display: 'none' } }
                onChange={ onFileChange }
            />
        </div>
    );
};
