export enum ACCESS {
    CREATE_USER = 'CREATE_USER',
    UPDATE_USER = 'UPDATE_USER',
    DELETE_USER = 'DELETE_USER',
    VIEWS_USER = 'VIEWS_USER',
    EDIT_STATUS_USER = 'EDIT_STATUS_USER',
    CREATE_PROMOCODE = 'CREATE_PROMOCODE',
    UPDATE_PROMOCODE = 'UPDATE_PROMOCODE',
    DELETE_PROMOCODE = 'DELETE_PROMOCODE',
    VIEW_PROMOCODE = 'VIEW_PROMOCODE',
    CREATE_PRICE = 'CREATE_PRICE',
    UPDATE_PRICE = 'UPDATE_PRICE',
    DELETE_PRICE = 'DELETE_PRICE',
    VIEW_PRICE = 'VIEW_PRICE',
    CREATE_ORDER = 'CREATE_ORDER',
    UPDATE_STATUS_ORDER = 'UPDATE_STATUS_ORDER',
    UPDATE_ORDER = 'UPDATE_ORDER',
    DELETE_ORDER = 'DELETE_ORDER',
    VIEW_ORDER_SECRET_FILTER = 'VIEW_ORDER_SECRET_FILTER',
    VIEW_ORDER = 'VIEW_ORDER',
    CREATE_DELIVERY = 'CREATE_DELIVERY',
    UPDATE_DELIVERY = 'UPDATE_DELIVERY',
    DELETE_DELIVERY = 'DELETE_DELIVERY',
    VIEW_DELIVERY = 'VIEW_DELIVERY',
    CREATE_SUPPORT = 'CREATE_SUPPORT',
    UPDATE_SUPPORT = 'UPDATE_SUPPORT',
    DELETE_SUPPORT = 'DELETE_SUPPORT',
    UPDATE_STATUS_SUPPORT = 'UPDATE_STATUS_SUPPORT',
    VIEW_SUPPORT = 'VIEW_SUPPORT',
    VIEW_STATIC = 'VIEW_STATIC',
    VIEW_REVIEWS = 'VIEW_REVIEWS',
    VIEW_ADMINMENU = 'VIEW_ADMINMENU',
}
