import React, { useState } from 'react';
import { Modal } from 'antd';
import cls from './style.module.scss';

function Video({ url }: any) {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <React.Fragment>
            <div onClick={ () => showModal() } className={ cls.itemVideo } role="presentation">
                <img src="img/play.jpg" alt={ url } />
            </div>

            <Modal className="VideoModal" title="Просмотр видео" visible={ isModalVisible } onOk={ handleOk } onCancel={ handleCancel } footer={ false }>
                <video
                    width="100%"
                    height="auto"
                    controls={ true }
                    controlsList="nodownload"
                    autoPlay={ false }
                    src={ url }
                />
            </Modal>
        </React.Fragment>
    );
}

export default Video;
