import React, { useCallback } from 'react';
import {
    Button, Form, Input, message, Modal, ModalFuncProps, Typography,
} from 'antd';

import { useDispatch } from 'react-redux';
import ym from 'react-yandex-metrika';
import { signIn } from 'ducks/app/actions';
import { AuthRegister } from './register';
import { Recovery } from './recovery';

const { Title } = Typography;

interface Props extends ModalFuncProps {
    isRegister?: boolean;
    discription?: string | React.ReactNode;
    onCallback?: (...args: any[]) => any;
}

export const AuthLogin = ({
    title, discription, visible, onCancel, onCallback, isRegister = false,
}: Props): any => {
    const [form] = Form.useForm();
    const put = useDispatch();
    const [authRegister, setAuthRegister] = React.useState(false);
    const [authRecovery, setAuthRecovery] = React.useState(false);

    const AuthRegisterToggle = (v: any = false) => {
        setAuthRegister(v);
    };

    const onOk = useCallback(() => {
        form
            .validateFields()
            .then((values) => {
                put(signIn(values, onCallback));
                ym('reachGoal', 'push_entrance');
            });
    }, []);

    const onRecovery = useCallback((v: boolean) => {
        setAuthRecovery(v);
    }, []);

    // Закрываем окно авторизации и открываем регистрацию
    const onRegister = useCallback((cb: any) => {
        cb();
        AuthRegisterToggle(true);
    }, []);
    const onRegisterOnCallback = useCallback((cb: any) => {
        cb();
        AuthRegisterToggle(false);
        message.success(<span>Нажмите на кнопку <b>заказать</b> чтобы зарегистрировать заказ</span>);
    }, []);

    return (
        <React.Fragment>
            <Modal
                title={ title || undefined }
                visible={ visible }
                onCancel={ onCancel }
                keyboard={ false }
                maskClosable={ false }
                footer={ (
                    isRegister ? (
                        [
                            <Button key="reg" type="dashed" onClick={ () => onRegister(onCancel) }>
                                Регистрация
                            </Button>,
                            <Button key="submit" type="primary" onClick={ () => onOk() }>
                                Войти
                            </Button>,
                        ]
                    ) :
                        [
                            <Button key="Recovery" type="primary" onClick={ () => onRecovery(true) }>
                                Восстановить пароль
                            </Button>,
                            <Button key="submit" type="primary" onClick={ () => onOk() }>
                                Войти
                            </Button>,
                        ]
                ) }
            >
                <Title level={ 2 }>Авторизация</Title>
                { discription && discription }
                <Form
                    form={ form }
                    name="AuthLogin"
                    layout="vertical"
                >
                    <Form.Item
                        label="Ваш E-mail"
                        name="email"
                        rules={ [{ type: 'email', message: 'Укажите правильно E-mail' }, { required: true, message: 'Обязательно заполнить' }] }
                    >
                        <Input type="email" placeholder="Ваш E-mail" />
                    </Form.Item>
                    <Form.Item
                        label="Пароль"
                        name="password"
                        rules={ [{ required: true, message: 'Обязательно заполнить' }] }
                    >
                        <Input type="password" placeholder="Пароль" />
                    </Form.Item>
                </Form>
            </Modal>
            { authRecovery && (<Recovery visible={ authRecovery } onCancel={ () => onRecovery(false) } />) }
            { authRegister && (<AuthRegister visible={ authRegister } onCancel={ () => AuthRegisterToggle(false) } onCallback={ () => onRegisterOnCallback(onCallback) } />) }
        </React.Fragment>
    );
};
