import { message } from 'antd';
import { put, call } from 'redux-saga/effects';
import {
    editPromocode,
    editPromocodeError,
    editPromocodeFinish,
} from 'ducks/promocode/actions';

import { fetchers } from 'api';

export function* editPromocodeRequest({ payload, Callback }: ReturnType<typeof editPromocode>) {
    try {
        const response = yield call(fetchers.editPromocode, payload);

        message.success('Блок с промокодом изменен');
        if (Callback) Callback();
        yield put(editPromocodeFinish(response));
    } catch (error) {
        yield put(editPromocodeError());
    }
}
