import React from 'react';
import {
    Image,
} from 'antd';

import styles from './styles.module.scss';

type Props = {
    images: any[];
};

export const ImageList = ({ images }: Props) => (
    <div className={ styles.imagesList }>
        <Image.PreviewGroup>
            {
                images.map((item) => (
                    <Image
                        src={ item.url }
                        key={ item.uid }
                        alt={ item.name }
                        wrapperClassName={ styles.wrapperImage }
                        className={ styles.image }
                    />
                ))
            }
        </Image.PreviewGroup>
    </div>
);
