import React, { useCallback } from 'react';
import {
    Modal, ModalFuncProps, Form, Typography, Input,
} from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { passwordRecovery } from 'ducks/app/actions';

import { selectPasswordRecovery } from 'ducks/app/selectors';

const { Title } = Typography;

export const Recovery = ({
    visible, onCancel,
}: ModalFuncProps): any => {
    const { loading, complete } = useSelector(selectPasswordRecovery);
    const [form] = Form.useForm();
    const put = useDispatch();

    const onOk = useCallback(() => {
        form
            .validateFields()
            .then((values) => {
                put(passwordRecovery(values));
            });
    }, []);

    if (complete) {
        return (
            <React.Fragment>
                <Modal
                    visible={ visible }
                    onCancel={ onCancel }
                    footer={ false }
                    keyboard={ false }
                    maskClosable={ false }
                    confirmLoading={ loading }
                >
                    <Title level={ 2 }>Письмо с новым паролем направлено на вашу почту</Title>
                </Modal>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Modal
                visible={ visible }
                onCancel={ onCancel }
                okText="Восстановить пароль"
                onOk={ onOk }
                keyboard={ false }
                maskClosable={ false }
                confirmLoading={ loading }
            >
                <Title level={ 2 }>Восстановить пароль</Title>
                <Form
                    form={ form }
                    name="AuthLogin"
                    layout="vertical"
                >
                    <Form.Item
                        label="Ваш E-mail"
                        name="email"
                        rules={ [{ type: 'email', message: 'Укажите правильно E-mail' }, { required: true, message: 'Обязательно заполнить' }] }
                    >
                        <Input type="email" placeholder="Ваш E-mail" />
                    </Form.Item>
                </Form>
            </Modal>
        </React.Fragment>
    );
};
