import { put, call } from 'redux-saga/effects';
import {
    editReviews,
    editReviewsError,
    editReviewsFinish,
} from 'ducks/reviews/actions';

import { fetchers } from 'api';

export function* editReviewsRequest({ data, callback }: ReturnType<typeof editReviews>) {
    try {
        const res = yield call(fetchers.editReview, data);

        yield put(editReviewsFinish(res));
        if (callback) {
            callback();
        }
    } catch (error) {
        yield put(editReviewsError());
    }
}
