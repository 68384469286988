export const TicketsActionTypes = {
    GET_TICKETS_START: 'GET_TICKETS_START',
    GET_TICKETS_ERROR: 'GET_TICKETS_ERROR',
    GET_TICKETS_FINISH: 'GET_TICKETS_FINISH',

    GET_TICKET_START: 'GET_TICKET_START',
    GET_TICKET_ERROR: 'GET_TICKET_ERROR',
    GET_TICKET_FINISH: 'GET_TICKET_FINISH',

    CREATE_TICKET_START: 'CREATE_TICKET_START',
    CREATE_TICKET_ERROR: 'CREATE_TICKET_ERROR',
    CREATE_TICKET_FINISH: 'CREATE_TICKET_FINISH',

    GET_TICKET_MESSAGES_START: 'GET_TICKET_MESSAGES_START',
    GET_TICKET_MESSAGES_ERROR: 'GET_TICKET_MESSAGES_ERROR',
    GET_TICKET_MESSAGES_FINISH: 'GET_TICKET_MESSAGES_FINISH',

    SEND_TICKET_MESSAGES_START: 'SEND_TICKET_MESSAGES_START',
    SEND_TICKET_MESSAGES_ERROR: 'SEND_TICKET_MESSAGES_ERROR',
    SEND_TICKET_MESSAGES_FINISH: 'SEND_TICKET_MESSAGES_FINISH',

    CHANGE_TICKET_STATUS_START: 'CHANGE_TICKET_STATUS_START',
    CHANGE_TICKET_STATUS_ERROR: 'CHANGE_TICKET_STATUS_ERROR',
    CHANGE_TICKET_STATUS_FINISH: 'CHANGE_TICKET_STATUS_FINISH',

    GET_COUNT_NEW_MESSAGE_START: 'GET_COUNT_NEW_MESSAGE_START',
    GET_COUNT_NEW_MESSAGE_ERROR: 'GET_COUNT_NEW_MESSAGE_ERROR',
    GET_COUNT_NEW_MESSAGE_FINISH: 'GET_COUNT_NEW_MESSAGE_FINISH',
} as const;
