import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Typography } from 'antd';
import { useRouteMatch } from 'react-router';
import cn from 'classnames';
import { getInfo } from 'ducks/info/actions';
import { getOrder } from 'ducks/orders/actions';
import { selectInfo, selectInfoLoading } from 'ducks/info/selectors';
import { selectOrdersLoading, selectOrder } from 'ducks/orders/selectors';
import { Layout } from 'components/ui/layout';

import coloring from 'assets/order/coloring.png';
import choosecolor from 'assets/order/choose-color.png';
import lamination from 'assets/order/lamination.png';

import { OrderType, OrderTypeText } from 'types/orders';
import { CreateOrderLamination } from './lamination';
import { CreateOrderColoringtinting } from './coloringtinting';
import { CreateOrderChoosecolor } from './choosecolor';
import styles from './styles.module.scss';

const { Title } = Typography;

type MatchParams = {
    id?: string;
};

export const CreateOrder = () => {
    const put = useDispatch();
    const info = useSelector(selectInfo);
    const infoLoading = useSelector(selectInfoLoading);
    const ordersLoading = useSelector(selectOrdersLoading);
    const order = useSelector(selectOrder);
    const [orderType, setOrderType] = useState('');

    const { params: { id: orderId } } = useRouteMatch<MatchParams>();

    // eslint-disable-next-line no-nested-ternary, no-negated-condition
    const title = (orderType === '') ? 'Выберите тип' : orderId ? 'Редактирование заказа' : 'Создание заказа';

    useEffect(() => {
        put(getInfo());
        if (orderId) {
            put(getOrder({ orderId: +orderId }));
        }
    }, []);
    useEffect(() => {
        if (order) {
            setOrderType(order.formType);
        }
    }, [order]);

    if (infoLoading || ordersLoading.getDetail) {
        return (
            <Layout>
                <Title level={ 2 }>{ title }</Title>
                <Spin size="large" spinning={ true } />
            </Layout>
        );
    }

    return (
        <Layout>
            <Title level={ 2 }>{ title }</Title>
            <div
                className={ cn(styles.typeOrder,
                    { [`${styles.typeOrder_all_active}`]: orderType !== '' }) }
            >
                <button
                    type="button"
                    disabled={ Boolean(orderId) }
                    className={ cn(styles.typeOrder_item,
                        { [`${styles.typeOrder_active}`]: orderType === OrderType.choosecolor }) }
                    onClick={ () => setOrderType(OrderType.choosecolor) }
                >
                    <img src={ choosecolor } alt="" />
                    <div className={ styles.typeOrder__title }>
                        { OrderTypeText.choosecolor }
                    </div>
                </button>
                <button
                    type="button"
                    disabled={ Boolean(orderId) }
                    className={ cn(styles.typeOrder_item,
                        { [`${styles.typeOrder_active}`]: orderType === OrderType.coloringtinting }) }
                    onClick={ () => setOrderType(OrderType.coloringtinting) }
                >
                    <img src={ coloring } alt="" />
                    <div className={ styles.typeOrder__title }>
                        { OrderTypeText.coloringtinting }
                    </div>
                </button>
                <button
                    type="button"
                    disabled={ Boolean(orderId) }
                    className={ cn(styles.typeOrder_item,
                        { [`${styles.typeOrder_active}`]: orderType === OrderType.lamination }) }
                    onClick={ () => setOrderType(OrderType.lamination) }
                >
                    <img src={ lamination } alt="" />
                    <div className={ styles.typeOrder__title }>
                        { OrderTypeText.lamination }
                    </div>
                </button>
            </div>

            { (orderType === OrderType.lamination || (order && order.formType === OrderType.lamination)) && (
                <CreateOrderLamination
                    info={ info }
                    infoLoading={ infoLoading }
                    ordersLoading={ ordersLoading }
                    order={ order }
                />
            ) }
            { (orderType === OrderType.coloringtinting || (order && order.formType === OrderType.coloringtinting)) && (
                <CreateOrderColoringtinting
                    info={ info }
                    infoLoading={ infoLoading }
                    ordersLoading={ ordersLoading }
                    order={ order }
                />
            ) }
            { (orderType === OrderType.choosecolor || (order && order.formType === OrderType.choosecolor)) && (
                <CreateOrderChoosecolor
                    ordersLoading={ ordersLoading }
                    order={ order }
                />
            ) }
        </Layout>
    );
};
