import * as Api from 'types/api';
import {
    PromoCodeActionTypes,
} from './action-types';

export const getInfoPromoCode = () => ({
    type: PromoCodeActionTypes.GET_PROMOCODE_START,
});

export const getInfoPromoCodeError = () => ({
    type: PromoCodeActionTypes.GET_PROMOCODE_ERROR,
});

export const getInfoPromoCodeFinish = (response: Api.PromoCodeResponse) => ({
    type: PromoCodeActionTypes.GET_PROMOCODE_FINISH,
    response,
});

export const getPromoCodeLending = () => ({
    type: PromoCodeActionTypes.GET_PROMOCODE_LENDING_START,
});

export const getPromoCodeLendingError = () => ({
    type: PromoCodeActionTypes.GET_PROMOCODE_LENDING_ERROR,
});

export const getPromoCodeLendingFinish = (response: any) => ({
    type: PromoCodeActionTypes.GET_PROMOCODE_LENDING_FINISH,
    response,
});

export const getPromoCode = (payload: Api.GetPromoCodePayload) => ({
    type: PromoCodeActionTypes.GET_PROMO_START,
    payload,
});

export const getPromoCodeError = () => ({
    type: PromoCodeActionTypes.GET_PROMO_ERROR,
});

export const getPromoCodeFinish = (response: Api.GetPromoCodeResponse) => ({
    type: PromoCodeActionTypes.GET_PROMO_FINISH,
    response,
});

export const removePromoCode = (payload: Api.RemovePromoCodePayload) => ({
    type: PromoCodeActionTypes.REMOVE_PROMO_START,
    payload,
});

export const removePromoCodeError = () => ({
    type: PromoCodeActionTypes.REMOVE_PROMO_ERROR,
});

export const removePromoCodeFinish = () => ({
    type: PromoCodeActionTypes.REMOVE_PROMO_FINISH,
});

export const getPromoCodeId = (payload: Api.PromoCodePayload) => ({
    type: PromoCodeActionTypes.GET_PROMO_DATAIL_START,
    payload,
});

export const getPromoCodeIdFinish = (response: Api.GetPromoCodeIdResponse) => ({
    type: PromoCodeActionTypes.GET_PROMO_DATAIL_FINISH,
    response,
});

export const getPromoCodeIdError = () => ({
    type: PromoCodeActionTypes.GET_PROMO_DATAIL_ERROR,
});

export const updatePromoCode = (payload: Api.CreatePromoCodePayload, cb: any) => ({
    type: PromoCodeActionTypes.UPDATE_PROMOCODE_START,
    payload,
    cb,
});

export const updatePromoCodeFinish = () => ({
    type: PromoCodeActionTypes.UPDATE_PROMOCODE_FINISH,
});

export const updatePromoCodeError = () => ({
    type: PromoCodeActionTypes.UPDATE_PROMOCODE_ERROR,
});

export const createPromoCode = (payload: Api.CreatePromoCodePayload, cb: any) => ({
    type: PromoCodeActionTypes.CREATE_PROMOCODE_START,
    payload,
    cb,
});

export const createPromoCodeFinish = () => ({
    type: PromoCodeActionTypes.CREATE_PROMOCODE_FINISH,
});

export const createPromoCodeError = () => ({
    type: PromoCodeActionTypes.CREATE_PROMOCODE_ERROR,
});

export const clearDetailData = () => ({
    type: PromoCodeActionTypes.CLEAR_DATAIL_START,
});

export const editPromocode = (payload: Api.EditPromocodePayload, Callback?: any) => ({
    type: PromoCodeActionTypes.EDIT_PROMOCODE_START,
    payload,
    Callback,
});

export const editPromocodeError = () => ({
    type: PromoCodeActionTypes.EDIT_PROMOCODE_ERROR,
});

export const editPromocodeFinish = (response: any) => ({
    type: PromoCodeActionTypes.EDIT_PROMOCODE_FINISH,
    response,
});
