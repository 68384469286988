import { put, call } from 'redux-saga/effects';
import {
    createReviews,
    createReviewsError,
    createReviewsFinish,
} from 'ducks/reviews/actions';

import { fetchers } from 'api';
import { Modal } from 'antd';
import { ResponseGenerator } from 'types/api';

export function* createReviewsRequest({ data }: ReturnType<typeof createReviews>) {
    try {
        const r: ResponseGenerator = yield call(fetchers.createReviews, data);

        if (r.type === 'success') {
            Modal.success({ content: r.message });
        } else {
            Modal.error({ content: r.message });
        }
        yield put(createReviewsFinish());
    } catch (error) {
        yield put(createReviewsError());
    }
}
