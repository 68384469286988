import { put, call } from 'redux-saga/effects';

import {
    getBlogCategoriesError,
    getBlogCategoriesFinish,
} from 'ducks/blog/actions';

import { fetchers } from 'api';

export function* getBlogCategoriesRequest() {
    try {
        const response = yield call(fetchers.getBlogCategories);

        yield put(getBlogCategoriesFinish(response));
    } catch (error) {
        yield put(getBlogCategoriesError());
    }
}
