/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import times from 'lodash/times';

import { Spin } from 'antd';
import {
    PieChart,
    Pie,
    ResponsiveContainer,
    Cell,
} from 'recharts';
import cn from 'classnames';

import { getInfo } from 'ducks/info/actions';
import { getStatistics } from 'ducks/statistics/actions';
import { selectStatistics, selectStatisticsLoading } from 'ducks/statistics/selectors';
import { selectInfo, selectInfoLoading } from 'ducks/info/selectors';

import { pluralize } from 'utils/formatters';

import { OrderBrandText } from 'types/orders';
import styles from './styles.module.scss';

const COLORS = [
    '#8967f6',
    '#64a5ff',
    '#be69e4',
    '#45d9ef',
    '#c54d4d',
    '#5d1e1e',
    '#4d7ac5',
    '#4dc5ac',
    '#c5c44d',
    '#2c1e5d',
    '#1e5d3e',
    '#5dc54d',
];

export const StatisticsContent = React.memo(() => {
    const put = useDispatch();
    const loading = useSelector(selectStatisticsLoading);
    const infoLoading = useSelector(selectInfoLoading);
    const info = useSelector(selectInfo);
    const data = useSelector(selectStatistics);

    useEffect(() => {
        put(getStatistics({}));
        put(getInfo());
    }, []);

    if (loading || infoLoading) {
        return <Spin spinning={ true } size="large" />;
    }

    const summedBrandSize = data?.brandList.reduce((accumulator, item) => accumulator + item.size, 0) || 0;
    const summedExpertSize = data?.expertList.reduce((accumulator, item) => accumulator + item.orderSize, 0) || 0;
    const summedFormTypeSize = data?.formType.reduce((accumulator, item) => accumulator + item.size, 0) || 0;

    return (
        <React.Fragment>

            <div className={ cn(styles.statistic, styles.statistic__horizon, styles.statistic_mb) }>
                <div className={ styles.statistic_title }>
                    <div className={ styles.statistic__title }>Статистика брендов</div>
                </div>
                <div className={ styles.statistic_body }>
                    <div className={ styles.statistic__content }>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={ data?.brandList }
                                    dataKey="size"
                                    outerRadius={ 150 }
                                    label={ true }
                                    labelLine={ true }
                                >
                                    {
                                        times(data?.brandList.length || 0, (i) => <Cell key={ `expert-${i}` } fill={ COLORS[i] } />)
                                    }
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <div className={ styles.statistic__text }>
                        {
                            data?.brandList.map((item, i) => {
                                const name = OrderBrandText[item.name];
                                const percent = ((100 / summedBrandSize) * item.size).toFixed(2);
                                const countText = pluralize(item.size, ['заявка', 'заявки', 'заявок']);

                                return (
                                    <div className={ styles.statistic_values } key={ i }>
                                        <div className={ styles.colorItem } style={ { backgroundColor: COLORS[i] } } />
                                        <div className={ styles.statistic_value__content }>
                                            <div>{ name || 'Не выбрано' }</div>
                                            <div>{ item.size } { countText } - { percent }% </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>

            <div className={ styles.group }>
                <div className={ styles.statistic }>
                    <div className={ styles.statistic_title }>
                        <div className={ styles.statistic__title }>Статистика экспертов</div>
                    </div>
                    <div className={ styles.statistic_body }>
                        <div className={ styles.statistic__content }>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart width={ 300 } height={ 250 }>
                                    <Pie
                                        data={ data?.expertList }
                                        dataKey="orderSize"
                                        outerRadius={ 100 }
                                        label={ true }
                                        labelLine={ true }
                                    >
                                        {
                                            times(data?.expertList.length || 0, (i) => <Cell key={ `expert-${i}` } fill={ COLORS[i] } />)
                                        }
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <div className={ styles.statistic__text }>
                            {

                                data?.expertList.map((item, i) => {
                                    const percent = ((100 / summedExpertSize) * item.orderSize).toFixed(2);
                                    const countText = pluralize(item.orderSize, ['заявка', 'заявки', 'заявок']);

                                    return (
                                        <div className={ styles.statistic_values } key={ i }>
                                            <div className={ styles.colorItem } style={ { backgroundColor: COLORS[i] } } />
                                            <div className={ styles.statistic_value__content }>
                                                <div>{ item.lastName } { item.firstName } </div>
                                                <div>{ item.orderSize } { countText } - { percent }% </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className={ styles.statistic }>
                    <div className={ styles.statistic_title }>
                        <div className={ styles.statistic__title }>Статистика типов заявок</div>
                    </div>
                    <div className={ styles.statistic_body }>
                        <div className={ styles.statistic__content }>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        data={ data?.formType }
                                        outerRadius={ 100 }
                                        label={ true }
                                        labelLine={ true }
                                        dataKey="size"
                                    >
                                        {
                                            times(data?.formType.length || 0, (i) => <Cell key={ `expert-${i}` } fill={ COLORS[i] } />)
                                        }
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <div className={ styles.statistic__text }>
                            {
                                data?.formType.map((item, i) => {
                                    const name = info?.formType.find((el) => el.value === item.name)?.name;
                                    const percent = ((100 / summedFormTypeSize) * item.size).toFixed(2);
                                    const countText = pluralize(item.size, ['заявка', 'заявки', 'заявок']);

                                    return (
                                        <div className={ styles.statistic_values } key={ i }>
                                            <div className={ styles.colorItem } style={ { backgroundColor: COLORS[i] } } />
                                            <div className={ styles.statistic_value__content }>
                                                <div>{ name }</div>
                                                <div>{ item.size } { countText } - { percent }% </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
});
