import React from 'react';
import DocumentMeta from 'react-document-meta';
import { LayoutLending } from 'components/ui/layout-lendiing';
import { getMeta } from 'constants/document';
import { ModalActionRecipeForLamination } from 'components/ui/modal/action/recipe-for-lamination';
import cl from './style.module.scss';

const rabota = [
    {
        name: 'Эксперт-колорист Estel Professional',
        time: 'Удаленная работа. Полный рабочий день.',
        graf: 'График работы 5/2',
        treb: 'Знание и практический опыт работы на красителе Estel Professional от 3 лет. Умение решать задачи по колористике. Портфолио в социальных сетях. Внимательность и ответственность. Наличие компьютера или планшета.',
    },
    {
        name: 'Эксперт-колорист Kuene Professional',
        time: 'Удаленная работа. Полный рабочий день.',
        graf: 'График работы 5/2',
        treb: 'Знание и практический опыт работы на красителе Kuene Professional от 3 лет. Умение решать задачи по колористике. Портфолио в социальных сетях. Внимательность и ответственность. Наличие компьютера или планшета.',
    },
    {
        name: 'Эксперт-колорист OLLIN Professional',
        time: 'Удаленная работа. Полный рабочий день.',
        graf: 'График работы 5/2',
        treb: 'Знание и практический опыт работы на красителе OLLIN Professional от 3 лет. Умение решать задачи по колористике. Портфолио в социальных сетях. Внимательность и ответственность.Наличие компьютера или планшета.',
    },
    {
        name: 'Эксперт-колорист Kapous Professional',
        time: 'Удаленная работа. Полный рабочий день.',
        graf: 'График работы 5/2',
        treb: 'Знание и практический опыт работы на красителе Kapous Professional от 3 лет. Умение решать задачи по колористике. Портфолио в социальных сетях. Внимательность и ответственность. Наличие компьютера или планшета.',
    },
    {
        name: 'Эксперт-колорист Schwarzkopf Professional',
        time: 'Удаленная работа. Полный рабочий день.',
        graf: 'График работы 5/2',
        treb: 'Знание и практический опыт работы на красителе Schwarzkopf Professional от 3 лет. Умение решать задачи по колористике. Портфолио в социальных сетях. Внимательность и ответственность.Наличие компьютера или планшета.',
    },
    {
        name: 'CММ  специалист',
        time: null,
        graf: null,
        treb: 'Анализ трендов в социальных сетях (Инстаграмм, Вконтакте). Разработка контент-плана на 2 недели вперед. Умение быстро реагировать на новостные повестки. Организация сьемок и создание визуального контента по плану. Умение работать в команде. Успешный опыт продвижения профилей (наличие кейсов!!!).',
    },
    {
        name: 'Автор статей',
        time: null,
        graf: null,
        treb: 'Написание полезных и интересных статей для сайта и социальных сетей на различные тематики в сфере бьюти: окрашивание волос, уход за волосами, тренды и обзоры на новинки, популярные продукты и т.д. Ссылки на публикации приветствуются.  <br />Оплата сдельная.',
    },
];

const meta = getMeta({
    title: 'Карьера в Hairtone',
    type: 'website',
});

const Career = React.memo(() => (
    <DocumentMeta { ...meta }>
        <LayoutLending>
            <div className="blog-top container">
                <h2>Карьера в Hairtone</h2>
                <div className={ cl.rezume }>
                    { rabota.map((item) => (
                        <div className={ cl.block }>
                            <div className={ cl.block_name }>
                                { item.name }
                            </div>
                            { item.time && (
                                <div className={ cl.block_time }>
                                    { item.time }
                                </div>
                            ) }
                            { item.graf && (
                                <div className={ cl.block_graf }>
                                    { item.graf }
                                </div>
                            ) }
                            { item.treb && (
                                <div className={ cl.block_treb } dangerouslySetInnerHTML={ { __html: item.treb } } />
                            ) }
                        </div>
                    )) }
                </div>
                <div className={ cl.rezume_email }>Резюме высылать на почту info@hairtone.ru</div>
            </div>
        </LayoutLending>
        <ModalActionRecipeForLamination />
    </DocumentMeta>
));

export default Career;
