import { InferValueTypes } from 'types/common';
import { User } from 'types/user';
import { UserActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type UserState = {
    loading: {
        profile: boolean;
        user: boolean;
        save: boolean;
        getList: boolean;
    };
    profile: User | null;
    user: User | null;
    list: any;
    pagination: {
        pageSize: number;
        total: number;
        page: number;
    };
    sort: {
        sortField?: string;
        sortOrder?: string;
    };
};

export const initialState: UserState = {
    loading: {
        profile: false,
        user: false,
        save: false,
        getList: false,
    },
    profile: null,
    user: null,
    list: [],
    pagination: {
        pageSize: 10,
        total: 0,
        page: 1,
    },
    sort: {
        sortField: 'createDate',
        sortOrder: 'desc',
    },
};

export function userReducer(state = initialState, action: ActionTypes): UserState {
    switch (action.type) {
        case UserActionTypes.GET_PROFILE_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    profile: true,
                },
            };
        case UserActionTypes.GET_PROFILE_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    profile: false,
                },
                profile: action.response,
            };

        case UserActionTypes.GET_PROFILE_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    profile: false,
                },
            };

        case UserActionTypes.GET_USER_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    user: true,
                },
            };
        case UserActionTypes.GET_USER_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    user: false,
                },
                user: action.response,
            };

        case UserActionTypes.GET_USER_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    user: false,
                },
            };

        case UserActionTypes.SAVE_USER_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    save: true,
                },
            };
        case UserActionTypes.SAVE_USER_FINISH:
        case UserActionTypes.SAVE_USER_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    save: false,
                },
            };

        case UserActionTypes.GET_USERS_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getList: true,
                },
            };

        case UserActionTypes.GET_USERS_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getList: false,
                },
                list: action.response.data,
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
                sort: action.response.sort,
            };

        case UserActionTypes.GET_USERS_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getList: false,
                },
            };

        default: {
            return state;
        }
    }
}
