import { put, call, select } from 'redux-saga/effects';

import { ReviewsResponse } from 'types/api';

import {
    getReviews,
    getReviewsError,
    getReviewsFinish,
} from 'ducks/reviews/actions';

import { selectReviewsPagination, selectReviewsSort } from 'ducks/reviews/selectors';

import { fetchers } from 'api';

export function* getReviewsRequest({ payload }: ReturnType<typeof getReviews>) {
    try {
        const { pageSize }: ReturnType<typeof selectReviewsPagination> = yield select(selectReviewsPagination);
        const sort: ReturnType<typeof selectReviewsSort> = yield select(selectReviewsSort);

        const response: ReviewsResponse = yield call(fetchers.getReviews, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize: payload.pagination.pageSize || pageSize,
            },
        });

        yield put(getReviewsFinish({
            data: response.data,
            pagination: {
                total: response.pagination.total,
                page: payload.pagination.page,
            },
            sort: response.sort || sort,
        }));
    } catch (error) {
        yield put(getReviewsError());
    }
}
