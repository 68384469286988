export const BlogActionTypes = {
    GET_BLOG_START: 'GET_BLOG_START',
    GET_BLOG_ERROR: 'GET_BLOG_ERROR',
    GET_BLOG_FINISH: 'GET_BLOG_FINISH',

    REMOVE_BLOG_START: 'REMOVE_BLOG_START',
    REMOVE_BLOG_ERROR: 'REMOVE_BLOG_ERROR',
    REMOVE_BLOG_FINISH: 'REMOVE_BLOG_FINISH',

    CREATE_BLOG_START: 'CREATE_BLOG_START',
    CREATE_BLOG_ERROR: 'CREATE_BLOG_ERROR',
    CREATE_BLOG_FINISH: 'CREATE_BLOG_FINISH',

    EDIT_BLOG_START: 'EDIT_BLOG_START',
    EDIT_BLOG_ERROR: 'EDIT_BLOG_ERROR',
    EDIT_BLOG_FINISH: 'EDIT_BLOG_FINISH',

    GET_BLOG_DETAIL_START: 'GET_BLOG_DETAIL_START',
    GET_BLOG_DETAIL_ERROR: 'GET_BLOG_DETAIL_ERROR',
    GET_BLOG_DETAIL_FINISH: 'GET_BLOG_DETAIL_FINISH',

    GET_BLOG_CATEGORIES_START: 'GET_BLOG_CATEGORIES_START',
    GET_BLOG_CATEGORIES_ERROR: 'GET_BLOG_CATEGORIES_ERROR',
    GET_BLOG_CATEGORIES_FINISH: 'GET_BLOG_CATEGORIES_FINISH',

    GET_BLOG_SIMILAR_START: 'GET_BLOG_SIMILAR_START',
    GET_BLOG_SIMILAR_ERROR: 'GET_BLOG_SIMILAR_ERROR',
    GET_BLOG_SIMILAR_FINISH: 'GET_BLOG_SIMILAR_FINISH',

    LIKE_BLOG_START: 'LIKE_BLOG_START',
    LIKE_BLOG_ERROR: 'LIKE_BLOG_ERROR',
    LIKE_BLOG_FINISH: 'LIKE_BLOG_FINISH',

    SET_VISIBLE_BLOG_TAGS: 'SET_VISIBLE_BLOG_TAGS',

    DELETE_BLOG_TAG_START: 'DELETE_BLOG_TAG_START',
    DELETE_BLOG_TAG_ERROR: 'DELETE_BLOG_TAG_ERROR',
    DELETE_BLOG_TAG_FINISH: 'DELETE_BLOG_TAG_FINISH',
} as const;
