import { put, call } from 'redux-saga/effects';
import {
    sendTicketMessage,
    sendTicketMessageError,
    sendTicketMessageFinish,
    getCountNewMessage,
} from 'ducks/tickets/actions';

import { fetchers } from 'api';

export function* sendTicketMessageRequest(action: ReturnType<typeof sendTicketMessage>) {
    try {
        const response = yield call(fetchers.sendTicketMessage, action.payload);

        action.callback();
        yield put(sendTicketMessageFinish(response.data));
        yield put(getCountNewMessage());
    } catch (error) {
        yield put(sendTicketMessageError());
    }
}
