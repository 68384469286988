import React, { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
    title?: string | React.ReactNode;
    extra?: string | React.ReactNode;
    footer?: string | React.ReactNode;
};

export const Cart: FC<Props> = ({
    title, extra, footer, children,
}) => (
    <div className={ styles.cart }>
        { (title || extra) && (
            <div className={ styles.cart_header }>
                { title && (<div className={ styles.title }>{ title }</div>) }
                <div className={ styles.line } />
                { extra && (
                    <div className={ styles.extra }>{ extra }</div>
                ) }
            </div>
        ) }
        <div className={ styles.cart_body }>
            { children }
        </div>
        { footer && (<div className={ styles.cart_pagination }>{ footer }</div>) }
    </div>
);
