import React from 'react';
import { ConfigProvider } from 'antd';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import { createBrowserHistory } from 'history';
import locale from 'antd/lib/locale/ru_RU';
import 'moment/locale/ru';

import rootReducer from 'ducks';
import rootSaga from 'sagas';

import { Routing } from './routes';

import setupInterceptors from './utils/axios';

import 'antd/dist/antd.less';
import 'styles/main.scss';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer(history),
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);
setupInterceptors();

export const App = () => (
    <Provider store={ store }>
        <ConnectedRouter history={ history }>
            <ConfigProvider locale={ locale }>
                <Routing />
            </ConfigProvider>
        </ConnectedRouter>
    </Provider>
);
