import React, {
    useCallback,
    useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Select,
    Button,
    Space,
    Checkbox,
} from 'antd';

import { selectInfo, selectInfoLoading } from 'ducks/info/selectors';
import { getOrdersFeedback } from 'ducks/orderFeedback/actions';
import { Filter } from 'components/ui/filter';
import { selectOrdersFeedbackLoading } from 'ducks/orderFeedback/selectors';
import styles from './styles.module.scss';

const { Option } = Select;

const defaultFilter = {
    brand: undefined,
    lengthTone: undefined,
    rootTone: undefined,
    shade: undefined,
    all: undefined,
};

export const OrderFeedbackFilter = () => {
    const put = useDispatch();
    const info = useSelector(selectInfo);
    const infoLoading = useSelector(selectInfoLoading);
    const loading = useSelector(selectOrdersFeedbackLoading);
    const [filter, setFilter] = useState(defaultFilter);

    const variants = [2, 3, 4, 5, 6, 7, 8, 9, 10, 12];

    const handleChangeFilter = useCallback((name: string) => (value: any) => {
        setFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const handleChangeFilterCheckbox = useCallback((e: any) => {
        const { name, checked } = e.target;

        setFilter((prev) => ({
            ...prev,
            [name]: checked ? 1 : undefined,
        }));
    }, []);

    const handleResetFilter = useCallback(() => {
        setFilter(defaultFilter);
        put(getOrdersFeedback({ pagination: { page: 1 }, filter: defaultFilter }));
    }, []);

    const applyFilter = useCallback(() => {
        put(getOrdersFeedback({ pagination: { page: 1 }, filter }));
    }, [filter]);

    return (
        <Filter
            extra={ (
                <Space size="middle">
                    <Button
                        type="link"
                        onClick={ handleResetFilter }
                        loading={ loading.get }
                    >
                        Сбросить
                    </Button>
                    <Button
                        type="primary"
                        onClick={ applyFilter }
                        loading={ loading.get }
                    >
                        Искать
                    </Button>
                </Space>
            ) }
        >
            <div className={ styles.filterItemBrand }>
                <Select
                    placeholder="Бренд"
                    allowClear={ true }
                    loading={ infoLoading }
                    className={ styles.Select }
                    onChange={ handleChangeFilter('brand') }
                    value={ filter.brand }
                >
                    { info?.brand?.map((item) => (<Option value={ item.value } key={ item.value }>{ item.name }</Option>)) }
                </Select>
            </div>
            <div className={ styles.filterItemStatus }>
                <Select
                    placeholder="Уровень тона (Корни)"
                    allowClear={ true }
                    loading={ infoLoading }
                    className={ styles.Select }
                    id="rootTone"
                    onChange={ handleChangeFilter('rootTone') }
                    value={ filter.rootTone }
                >
                    { variants.map((val) => (<Option value={ val } key={ val }>{ val }</Option>)) }
                </Select>
            </div>
            <div className={ styles.filterItemTone }>
                <Select
                    placeholder="Уровень тона (Длина)"
                    allowClear={ true }
                    loading={ infoLoading }
                    className={ styles.Select }
                    id="lengthTone"
                    value={ filter.lengthTone }
                    onChange={ handleChangeFilter('lengthTone') }
                >
                    { variants.map((val) => (<Option value={ val } key={ val }>{ val }</Option>)) }
                </Select>
            </div>
            <div className={ styles.filterItemShade }>
                <Select
                    placeholder="Оттенок"
                    allowClear={ true }
                    loading={ infoLoading }
                    className={ styles.Select }
                    id="shade"
                    value={ filter.shade }
                    onChange={ handleChangeFilter('shade') }
                >
                    <Option value="warm" key="warm">Теплый</Option>
                    <Option value="cold" key="cold">Холодный</Option>
                </Select>
            </div>
            <div className={ styles.filterItemAll }>
                <div>
                    <Checkbox
                        value={ filter.all }
                        name="all"
                        onChange={ (e) => handleChangeFilterCheckbox(e) }
                    >
                        Все заявки
                    </Checkbox>
                </div>
            </div>
        </Filter>
    );
};
