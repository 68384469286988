import React from 'react';
import { Table, Typography } from 'antd';

import { Layout } from 'components/ui/layout';

import { ColumnsType } from 'antd/lib/table';
import styles from './styles.module.scss';

const { Title } = Typography;

interface DSource {
    key: number;
    brand: string;
    lamination: number;
    tinting: number;
    coloring: number;
}

const dataSource: DSource[] = [
    {
        key: 1,
        brand: 'Wella Professional',
        lamination: 900,
        tinting: 1270,
        coloring: 2400,
    },
    {
        key: 2,
        brand: 'Londa Professional',
        lamination: 375,
        tinting: 535,
        coloring: 985,
    },
    {
        key: 3,
        brand: 'Estel Professional',
        lamination: 160,
        tinting: 235,
        coloring: 420,
    },
];

const columns: ColumnsType<DSource> = [
    {
        title: 'Бренд',
        dataIndex: 'brand',
        key: 'brand',
    },
    {
        title: 'Ламинирование 40г/40г/40г',
        dataIndex: 'lamination',
        key: 'lamination',
    },
    {
        title: 'Тонирование 60г/120г',
        dataIndex: 'tinting',
        key: 'tinting',
    },
    {
        title: 'Окрашивание 120г/120г',
        dataIndex: 'coloring',
        key: 'coloring',
    },
];

export const AboutService = React.memo(() => (
    <Layout>
        <div className={ styles.header }>
            <Title level={ 2 }>О сервисе</Title>
        </div>
        <p className={ styles.about }>
            <b>Hairtone</b> - сервис для окрашивания, тонирования и ламинирования волос профессиональными красителями в домашних условиях.
        </p>

        <div className={ styles.title }>Мы команда профессионалов</div>
        <div className={ styles.desc }>Колористы с многолетним опытом работы, которые помогли тысячам женщин найти и преобразовать свой цвет волос в точном соответствии с их желаниями.</div>

        <div className={ styles.about }><b>Наш сервис</b> это механизм, позволяющий осуществлять окрашивание, тонирование, ламинирование высокого качества с гарантией получения необходимого цвета или оттенка самостоятельно в домашних условиях.</div>
        <div className={ styles.title }>Что конкретно мы предлагаем?</div>
        <ul className={ styles.list }>
            <li>Готовое решение для изменения цвета ваших волос в желаемый</li>
            <li>Разработку уникальной формулы цвета</li>
            <li>Индивидуальное соотношение нескольких цветов красителя, которое гарантирует, что вы получите желаемый цвет/оттенок при окрашивании, тонировании или ламинировании, за счет учета вашего текущего цвета и состояния волос - по фотографиям ваших волос и результата, который хотите получить</li>
        </ul>
        <div className={ styles.title }>При заказе формулы вы получаете:</div>
        <ul className={ styles.list }>
            <li>Решение своей цветовой задачи, которое можете использовать сколько угодно раз, покупая красители в любом магазине косметики</li>
            <li>Платите только за экспертизу мастера по подбору соотношения красителей, которое гарантирует получение нужного вам цвета</li>
        </ul>
    </Layout>
));

export const HowItWorks = React.memo(() => (
    <Layout>
        <div className={ styles.header }>
            <Title level={ 2 }>Как работает?</Title>
        </div>

        <div className={ styles.title }>Как это работает</div>
        <ul className={ `${styles.list} ${styles.list_decimal}` }>
            <li>Вы прикрепляете фото своих волос и результата, который хотите получить</li>
            <li>Наши эксперты колористы разрабатывают уникальную формулу, которая гарантирует получение желаемого цвета/оттенка (формула направляется вам по электронной почте)</li>
        </ul>
        <div className={ styles.desc }>Все компоненты можно приобрести в любом магазине профессиональной косметики в вашем городе или онлайн.</div>
        <div className={ styles.title }>Цены</div>
        <div className={ styles.desc }>Разработка формулы (окрашивание/тонирование/ламинирование) – 700р.</div>

    </Layout>
));

export const WhatIsTheAdvantage = React.memo(() => (
    <Layout>
        <Title level={ 2 }>В чем преимущество</Title>
        <Title level={ 3 }>Выгода при использовании сервиса:</Title>

        <div className={ styles.title }>Результат гарантирован</div>
        <div className={ styles.desc }>Не играете в лотерею, пытаясь угадать какой цвет получиться, как при использовании одноцветного красителя – получаете уникальный рецепт из 2-3 цветов, который учитывает Ваш исходный цвет волос и гарантирует достижение желаемого результата.</div>

        <div className={ styles.title }>Экономите деньги и время</div>
        <div className={ styles.desc }>С формулой желаемый оттенок можно получать без посещения салона - волосы будут нужного цвета независимо от того, кто наносит на них краситель мастер или вы сами.</div>

        <div className={ styles.title }>Уникальная формула может быть использована многократно</div>
        <div className={ styles.desc }>Если не планируете менять цвет при повторных окрашиваниях потребуется только готовая смесь красителей или нужные компоненты – не платите повторно за решение той же самой цветовой задачи</div>

        <div className={ styles.title }>Стоимость расходников, которые вам потребуются для окрашивания</div>

        <Table
            className={ styles.table }
            dataSource={ dataSource }
            columns={ columns }
            pagination={ false }
            scroll={ { x: 530 } }
        />

        <div className={ styles.desc }>* В таблице приведены средние рыночные цены в расчете на среднюю длину волос и среднюю густоту.</div>

        <div className={ styles.title }>Дополнительные плюсы от использования сервиса</div>
        <ul className={ styles.list }>
            <li>Вы больше не привязаны к времени записи - краситесь тогда, когда вам удобно, там, где вам удобно, можно даже взять набор с собой в дорогу.</li>
            <li>Не тратите время на бессмысленную езду, очереди, записи</li>
            <li>Не нужно думать с кем оставить детей, пока вы сидите в кресле мастера, можно нанести краситель на волосы самому, тогда, когда есть время</li>
            <li>Если вы начинающий мастер, можете пользоваться нашим сервисом разработки формул, чтобы не совершать ошибок, когда сталкиваетесь со сложными цветовыми задачами</li>
        </ul>

    </Layout>
));
