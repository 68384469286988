import axios from 'axios';
import { Modal, notification } from 'antd';

import { fetchers } from 'api';

import { history } from 'App';
import { clearToken, getToken, setToken } from './token';

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
axios.defaults.headers.common['Cache-Control'] = 'no-cache';

const setupInterceptors = () => {
    let isRefreshing = false;
    const subscribers: any[] = [];

    function cleanStorage() {
        clearToken();
        history.push('/auth');
    }

    function subscribeTokenRefresh(cb: any) {
        subscribers.push(cb);
    }

    function onRefreshed(token: string) {
        subscribers.map((cb) => cb(token));
    }

    axios.interceptors.request.use(
        (config: any) => {
            const token = getToken();
            const newConfig = config;

            if (isRefreshing) {
                delete newConfig.headers.common.Authorization;
            } else if (token) {
                newConfig.headers.common.Authorization = token;
            }

            return newConfig;
        },
        (error: any) => Promise.reject(error),
    );
    axios.interceptors.response.use(
        (response: any) => response.data,
        (error: any) => {
            const {
                config,
                response: { status },
            } = error;

            if (
                status === 401 &&
                !config.url.includes('/refresh')
            ) {
                const refreshToken = localStorage.getItem('refreshToken');

                if (refreshToken) {
                    if (!isRefreshing) {
                        isRefreshing = true;
                        fetchers.refreshToken({ refreshToken }).then(
                            ({ token, refreshToken: refresh }: any) => {
                                isRefreshing = false;
                                onRefreshed(token);
                                setToken(token, refresh);
                                subscribers.length = 0;
                            },
                            () => cleanStorage(),
                        );
                    }

                    return new Promise((resolve) => {
                        subscribeTokenRefresh((token: string) => {
                            config.headers.Authorization = token;
                            resolve(axios(config));
                        });
                    });
                }
                cleanStorage();
            }
            if (status === 500) {
                Modal.error({
                    title: 'Ошибка сервера',
                    content: error.response?.data?.message || error.response?.message || 'Произошла ошибка на сервере. Попробуйте перезагрузить страницу. Если проблема не решается, свяжитесь с нами через чат для получения дальнейшей помощи',
                    keyboard: false,
                });
            }

            if (status === 403) {
                Modal.error({
                    title: 'Сессия истекла',
                    content: 'Время вашего пребывания на сайте истекло. Пожалуйста, авторизуйтесь заново для продолжения работы. Сессия автоматически завершена после бездействия для вашей безопасности. Приносим извинения за возможные неудобства и благодарим вас за понимание',
                    keyboard: false,
                });
            }
            // console.log(error.response);

            if (status !== 500 && status !== 403) {
                notification.error({
                    message: false,
                    description: error.response?.data?.message || error.response?.data || error.message,
                });
            }

            return Promise.reject(error.response);
        },
    );
};

export default setupInterceptors;
