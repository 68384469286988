import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    Modal, ModalFuncProps, Form, Typography, Button, Checkbox, Input,
} from 'antd';
import { editPromocode } from 'ducks/promocode/actions';

const { Title } = Typography;

interface Props extends ModalFuncProps {
    data?: any;
    onCancel?: any;
}

export const PromocodeEdit = ({
    data, visible, onCancel,
}: Props): any => {
    const [form] = Form.useForm();
    const put = useDispatch();

    const onOk = useCallback(() => {
        form
            .validateFields()
            .then((values) => {
                put(editPromocode(values, () => {
                    form.resetFields();
                    onCancel(false);
                }));
            });
    }, []);

    return (
        <React.Fragment>
            <Modal
                visible={ visible }
                onCancel={ onCancel }
                onOk={ onOk }
                keyboard={ false }
                maskClosable={ false }
                width={ 900 }
                footer={ (
                    <Button key="submit" type="primary" onClick={ () => onOk() }>
                        Добавить
                    </Button>
                ) }
            >
                <Title level={ 2 }>Редактирование блока с промокодом</Title>
                <Form
                    form={ form }
                    name="PromocodeEdit"
                    autoComplete="off"
                    layout="vertical"
                    initialValues={ {
                        ...data,
                    } }
                >
                    <Form.Item
                        label="Отображение блока"
                        name="visible"
                        valuePropName="checked"
                    >
                        <Checkbox>Показывать</Checkbox>
                    </Form.Item>
                    <Form.Item
                        label="Текст с промокодом"
                        name="message"
                        rules={ [{ required: true, message: 'Обязательно нужно заполнить' }] }
                    >
                        <Input placeholder="Укажите текст с промокодом" />
                    </Form.Item>
                </Form>
            </Modal>
        </React.Fragment>
    );
};
