import * as yup from 'yup';
import { PromoType } from 'types/promocode';

import { REQUIRED_FIELD } from 'form-helpers/message';

export const schema = yup.object().shape({
    type: yup.string().required(REQUIRED_FIELD).default(PromoType.price),
    typeDiscont: yup.string().required(REQUIRED_FIELD).default('Скидка'),
    discount: yup.string().when('typeDiscont', {
        is: 'Скидка', // если typeDiscont равно 'Скидка'
        then: yup.string().required(REQUIRED_FIELD).default(''),
        otherwise: yup.string().notRequired().nullable().default(null),
    }),
    discount_price: yup.string().when('typeDiscont', {
        is: 'Скидка',
        then: yup.string().nullable().transform(() => null), // очищаем значение
        otherwise: yup.string().required(REQUIRED_FIELD).default(''),
    }),
    quantity: yup.number().required(REQUIRED_FIELD).default(1),
    time: yup.number().required(REQUIRED_FIELD).default(7),
    status: yup.number().required(REQUIRED_FIELD).default(1),
    code: yup.string().required(REQUIRED_FIELD).default(''),
});
