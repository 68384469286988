import React from 'react';
import moment from 'moment';
import { Tag } from 'antd';

import { DATE_FORMAT } from 'constants/date';
import {
    Ticket,
    TicketStatusColor,
    TicketStatusText,
    TicketSeeType,
} from 'types/ticket';

import { getFio } from 'utils/formatters';
import styles from './styles.module.scss';

export const getColumns = (userId: number) => [
    {
        title: 'Номер',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Дата создания',
        dataIndex: 'createDate',
        key: 'createDate',
        render: (value: string) => moment(value).format(DATE_FORMAT.DATE_TIME),
    },
    {
        title: 'Тема',
        dataIndex: 'thema',
        key: 'thema',
    },
    {
        title: 'Менеджер',
        dataIndex: '',
        key: 'manager',
        render: (data: Ticket) => (data.manager_id ? getFio(data.manager!.lastName, data.manager!.firstName) : null),
    },
    {
        title: 'Статус',
        dataIndex: '',
        key: 'status',
        render: (data: Ticket) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { status, user_id, manager_id } = data;

            let tag: React.ReactNode = null;

            switch (data.seeType) {
                case TicketSeeType.client:
                    tag = userId === user_id ? <Tag color="#5275f5">Ожидание вашего ответа</Tag> : null;
                    break;

                case TicketSeeType.manager:
                    tag = userId === manager_id ? <Tag color="#5275f5">Ожидание вашего ответа</Tag> : null;
                    break;
            }

            return (
                <React.Fragment>
                    <span className={ styles.status } style={ { color: TicketStatusColor[status] } }>
                        { TicketStatusText[status] }
                    </span>
                    { tag }
                </React.Fragment>
            );
        },
    },
];
