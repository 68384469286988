import { CreateOrderPayload } from 'types/api';

export const getInitialValues = (
    initialValues: { [key: string]: any },
    order: CreateOrderPayload,
) => ({
    ...initialValues,
    ...order,
    hairType: order?.hairType || initialValues.hairType,
});
