import { message } from 'antd';
import { put, call } from 'redux-saga/effects';
import {
    getPromoCode,
    createPromoCode,
    createPromoCodeFinish,
    createPromoCodeError,
} from 'ducks/promocode/actions';

import { fetchers } from 'api';

export function* createPromocodeRequest({ payload, cb }: ReturnType<typeof createPromoCode>) {
    try {
        const res = yield call(fetchers.createPromocode, payload);

        message.open({ type: 'success', content: res });
        yield put(createPromoCodeFinish());
        yield put(getPromoCode({ pagination: { page: 1 } }));
        cb(false);
    } catch (error) {
        yield put(createPromoCodeError());
    }
}
