import { put, call } from 'redux-saga/effects';
import {
    getOrder,
    getOrderError,
    getOrderFinish,
} from 'ducks/orders/actions';

import { fetchers } from 'api';

export function* getOrderRequest({ payload }: ReturnType<typeof getOrder>) {
    try {
        const response = yield call(fetchers.getOrder, payload);

        yield put(getOrderFinish(response));
    } catch (error) {
        yield put(getOrderError());
    }
}
