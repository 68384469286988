import { put, call } from 'redux-saga/effects';
import {
    sendExpertOrderForm,
    sendExpertOrderFormError,
    sendExpertOrderFormFinish,
} from 'ducks/orders/actions';

import { fetchers } from 'api';

export function* sendExpertOrderFormRequest({ payload }: ReturnType<typeof sendExpertOrderForm>) {
    try {
        const data = yield call(fetchers.sendExpertOrderForm, payload);

        yield put(sendExpertOrderFormFinish({
            ...data,
        }));
    } catch (error) {
        yield put(sendExpertOrderFormError());
    }
}
