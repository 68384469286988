import React, { useRef, useState } from 'react';
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import cn from 'classnames';

import cl from './styles.module.scss';

export const Video = React.memo(() => {
    const video = useRef<HTMLVideoElement>(null);
    const [isPlay, setPlay] = useState(false);

    const play = () => {
        video.current?.play();
        setPlay(true);
    };
    const stop = () => {
        video.current?.pause();
        setPlay(false);
    };

    const videoStyleContent = cn({
        [cl.video_content]: true,
        [cl.video_content__hide]: isPlay,
    });

    return (
        <div className={ videoStyleContent }>
            <div aria-hidden="true" onClick={ isPlay ? () => stop() : () => play() } className={ cl.video_icon }>
                { isPlay ? (
                    <PauseCircleOutlined className={ cl.video_play } />
                ) : (
                    <PlayCircleOutlined className={ cl.video_play } />
                ) }
            </div>
            <video
                aria-hidden="true"
                ref={ video }
                preload="false"
                loop={ true }
                className={ cl.video }
                poster="/video/hairtone.png"
            >
                <source src="/video/nastya.mp4" type="video/mp4" />
                <source src="/video/nastya.ogv" type="video/ogg" />
                <source src="/video/nastya.webm" type="video/webm" />
            </video>
        </div>
    );
});
