import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { List } from 'antd';

import { createReviews, getReviewsLending } from 'ducks/reviews/actions';
import {
    selectReviewsAverageRating,
    selectReviewsLending,
    selectReviewsLendingLoading,
    selectReviewsSize,
} from 'ducks/reviews/selectors';

import ReactGA from 'react-ga';
import ym from 'react-yandex-metrika';
import { ReviewsAdd } from './create';
import { ReviewsRatingAll } from './ratingAll';
import { ReviewsButton } from './btn';
import { ReviewsItem } from './listItem';

export const Reviews = React.memo(() => {
    const put = useDispatch();
    const listReviews = useSelector(selectReviewsLending);
    const loadingReviews = useSelector(selectReviewsLendingLoading);
    const sizeReviews = useSelector(selectReviewsSize);
    const averageRating = useSelector(selectReviewsAverageRating);

    const [visible, setvisible] = React.useState(false);

    // eslint-disable-next-line no-negated-condition
    const loadReviewMore = !loadingReviews.lending ? (<ReviewsButton />) : null;

    useEffect(() => {
        put(getReviewsLending({ pagination: { page: 1, pageSize: 5 } }));
    }, []);

    const toggleForm = (val: boolean) => {
        setvisible(val);
        ym('reachGoal', 'push_review');
        ReactGA.event({
            action: 'push_review',
            category: 'review',
            label: 'review_1',
        });
    };

    const onSubmit = (val: any) => {
        put(createReviews(val));
        setvisible(false);
    };

    return (
        <section className="section reviews-section" id="reviews">
            <div className="container">
                <h2>Отзывы</h2>
                <div className="reviews">
                    <div className="reviews__info">
                        <ReviewsRatingAll rating={ averageRating } size={ sizeReviews } />
                        <button type="button" className="btn" onClick={ () => toggleForm(true) }>Написать отзыв
                        </button>
                        { visible && (
                            <ReviewsAdd
                                visible={ visible }
                                onSubmit={ onSubmit }
                                onCancel={ () => toggleForm(false) }
                            />
                        ) }
                    </div>
                    <div className="reviews__content">
                        <List
                            className="demo-loadmore-list"
                            loading={ loadingReviews.lending }
                            itemLayout="horizontal"
                            loadMore={ loadReviewMore }
                            dataSource={ listReviews }
                            renderItem={ (item) => (<ReviewsItem { ...item } key={ item.id } />) }
                        />
                    </div>
                </div>
            </div>
        </section>
    );
});
