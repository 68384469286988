import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Table,
    Typography,
    Button,
} from 'antd';

import {
    getPrice,
    removePrice,
    editPrice,
    createPrice,
} from 'ducks/price/actions';

import {
    selectPrice,
    selectPriceLoading,
} from 'ducks/price/selectors';
import { selectProfile } from 'ducks/user/selectors';

import { Layout } from 'components/ui/layout';
import { EditPriceModal } from 'components/edit-price-modal';

import { ACCESS } from 'constants/access';
import { checkAccess } from 'utils/access';

import { PriceItem } from 'types/api';

import { PriceForm } from 'form-helpers/price/types';
import { getColumns } from './columns';

import styles from './styles.module.scss';

const { Title } = Typography;

export const Price = React.memo(() => {
    const put = useDispatch();
    const [visibleModal, setVisibleModal] = React.useState(false);
    const [initialModalData, setInitialModalData] = React.useState<PriceItem | null>(null);
    const loading = useSelector(selectPriceLoading);
    const list = useSelector(selectPrice);
    const user = useSelector(selectProfile);

    useEffect(() => {
        put(getPrice());
    }, []);

    const isCreateAllow = checkAccess(user?.access_list, ACCESS.CREATE_PRICE);
    const isEditAllow = checkAccess(user?.access_list, ACCESS.UPDATE_PRICE);
    const isDeleteAllow = checkAccess(user?.access_list, ACCESS.DELETE_PRICE);

    const onChangeVisibilityForm = (visible: boolean) => () => setVisibleModal(visible);

    const onEditClick = (data: PriceItem) => {
        setVisibleModal(true);
        setInitialModalData(data);
    };

    const onRemoveClick = (priceId: number) => {
        put(removePrice(priceId));
    };

    const columns = useMemo(() => getColumns(
        { isEditAllow, isDeleteAllow },
        { onEditClick, onRemoveClick },
        loading,
    ), [loading]);

    const onCloseForm = () => {
        setInitialModalData(null);
        onChangeVisibilityForm(false)();
    };

    const onSubmitForm = (values: PriceForm) => {
        if (values.id) {
            put(editPrice(values as PriceItem, onCloseForm));
        } else {
            put(createPrice(values, onCloseForm));
        }
    };

    return (
        <Layout>
            <div className={ styles.header }>
                <Title level={ 2 }>Цены</Title>
                { isCreateAllow && (
                    <Button
                        type="primary"
                        onClick={ onChangeVisibilityForm(true) }
                    >
                        Добавить цену
                    </Button>
                ) }
            </div>
            <Table
                loading={ loading.get }
                dataSource={ list }
                rowKey="id"
                pagination={ false }
                className={ styles.table }
                columns={ columns }
            />
            {
                visibleModal && (
                    <EditPriceModal
                        onSubmit={ onSubmitForm }
                        onCancel={ onCloseForm }
                        initialData={ initialModalData }
                        loading={ loading.edit }
                    />
                )
            }
        </Layout>
    );
});
