import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

type Props = {
    label: string;
    value?: string;
    type?: 'big' | 'normal';
};

export const InfoItem = ({ label, value, type }: Props) => (
    <div
        className={
            cn(
                styles.infoItem,
                { [styles.infoItemBig]: type === 'big' },
            )
        }
    >
        <span className={ styles.label }>{ label }:&nbsp;</span>
        { value ?
            (<span className={ styles.value } dangerouslySetInnerHTML={ { __html: value } } />) :
            (<span className={ styles.value }><div className={ styles.darken }>не заполнено</div></span>) }
    </div>
);
