import { Order } from 'types/orders';
import { GetColorsResponse, Instruction } from 'types/api';

export const expertFields = [
    'id',
    'status',
    'brand',
    'colors',
    'subTypeColor',
    'subTypeOxidizer',
    'weight',
    'oxidizer',
    'conditioner',
    'instruction',
];

const brandDefault = ['schwarzkopfProf', 'kapousProf', 'keune'];

export const getInitialValues = (
    order: Order,
    colors: GetColorsResponse,
    instructions: Instruction[],
) => {
    const colorId = order.colors.natural;
    const brandType = brandDefault.includes(order.brand) ? 'wella' : order.brand;

    const currentSubtype = order.brand && order.subTypeColor
        ? colors[brandType].color.find((item: any) => item.id === order.subTypeColor)
        : null;

    const colorsName = currentSubtype && colorId
        ? currentSubtype.list.find((item: any) => item.id === colorId)?.color
        : '';

    const oxidizerSubtype = order.brand && order.subTypeOxidizer
        ? colors[brandType].oxidizer.find((item: any) => item.id === order.subTypeOxidizer)
        : null;

    const oxidizerName = oxidizerSubtype && colorId
        ? oxidizerSubtype.list.find((item: any) => item.id === order.oxidizer)?.value
        : '';

    return {
        subTypeColor: order.subTypeColor,
        subTypeColorName: currentSubtype?.name,
        colors: {
            ...order.colors,
            natural: order.colors.natural,
        },
        colorsName,
        colorsList: currentSubtype?.list || [],

        subTypeOxidizer: order.subTypeOxidizer || '',
        subTypeOxidizerName: oxidizerSubtype?.name,
        oxidizer: order.oxidizer,
        oxidizerName,
        oxidizerList: oxidizerSubtype?.list || [],

        weight: order.weight,
        conditioner: order.conditioner,
        isbrand: order.isbrand,
        brand: order.brand,

        grayHair: order.grayHair,
        instruction: order.instruction || instructions[0].id,
    };
};
