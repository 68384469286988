import { put, call, select } from 'redux-saga/effects';

import {
    getBlog,
    getBlogError,
    getBlogFinish,
} from 'ducks/blog/actions';

import { selectBlogPagination } from 'ducks/blog/selectors';

import { fetchers } from 'api';
import { ResponseGenerator } from 'types/api';

export function* getBlogRequest({ payload }: ReturnType<typeof getBlog>) {
    try {
        const { pageSize } = yield select(selectBlogPagination);
        const response: ResponseGenerator = yield call(fetchers.getBlog, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize: payload.pagination.pageSize || pageSize,
            },
        });

        yield put(getBlogFinish({
            data: response.data,
            pagination: {
                page: payload.pagination.page,
                total: response.pagination.total,
            },
        }));
    } catch (error) {
        yield put(getBlogError());
    }
}
