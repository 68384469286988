import React from 'react';
import { notification, Spin } from 'antd';
import { put, call } from 'redux-saga/effects';
import {
    payOrderOnline,
    payOrderOnlineError,
    payOrderOnlineFinish,
} from 'ducks/orders/actions';

import { fetchers } from 'api';

export function* payOrderOnlineRequest({ data }: ReturnType<typeof payOrderOnline>) {
    try {
        const response = yield call(fetchers.payOrderOnline, data);

        notification.success({
            message: 'Сейчас вы будете перенаправлены на страницу оплаты...',
            icon: <Spin spinning={ true } />,
        });

        setTimeout(() => {
            window.location.replace(response.data.paymentUrl);
        }, 2000);

        yield put(payOrderOnlineFinish(response));
    } catch (error) {
        yield put(payOrderOnlineError());
    }
}
