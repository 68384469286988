import { put, call } from 'redux-saga/effects';

import {
    removePrice,
    removePriceError,
    removePriceFinish,
} from 'ducks/price/actions';

import { fetchers } from 'api';

export function* removePriceRequest({ priceId }: ReturnType<typeof removePrice>) {
    try {
        yield call(fetchers.deletePrice, priceId);

        yield put(removePriceFinish(priceId));
    } catch (error) {
        yield put(removePriceError(priceId));
    }
}
