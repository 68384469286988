import React, {
    useEffect, useCallback, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Table,
    Typography,
    Pagination,
} from 'antd';

import { getReviews } from 'ducks/reviews/actions';

import {
    selectReviews,
    selectReviewsLoading,
    selectReviewsPagination,
} from 'ducks/reviews/selectors';

import { Layout } from 'components/ui/layout';

import { Reviews as ReviewsType } from 'types/reviews';

import { Cart } from 'components/ui/cart';
import { getColumns } from './columns';

import styles from './styles.module.scss';
import { Modal } from './modal';

const { Title } = Typography;

export const Reviews = React.memo(() => {
    const put = useDispatch();
    const loading = useSelector(selectReviewsLoading);
    const list = useSelector(selectReviews);
    const pagination = useSelector(selectReviewsPagination);
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState<ReviewsType>();

    useEffect(() => {
        put(getReviews({ pagination: { page: 1 } }));
    }, []);

    const showDrawer = useCallback((review: ReviewsType) => () => {
        setData(review);
        setVisible(true);
    }, []);

    const onClose = () => {
        setVisible(false);
        setData(undefined);
    };

    const onChangePagination = useCallback((page: number) => {
        // put(getTickets({ pagination: { page }, filter }));
        put(getReviews({ pagination: { page } }));
    }, []);

    return (
        <Layout>
            <div className={ styles.header }>
                <Title level={ 2 }>Отзывы</Title>
            </div>
            <Cart
                footer={ (
                    <div className={ styles.pagination }>
                        <Pagination
                            total={ pagination.total }
                            pageSize={ pagination.pageSize }
                            current={ pagination.page }
                            onChange={ onChangePagination }
                        />
                    </div>
                ) }
            >
                <Table
                    loading={ loading.get }
                    dataSource={ list }
                    rowKey="id"
                    pagination={ false }
                    className={ styles.table }
                    columns={ getColumns() }
                    onRow={ (record: any) => ({
                        onClick: showDrawer(record),
                    }) }
                />
            </Cart>
            <Modal
                visible={ visible }
                data={ data }
                onClose={ onClose }
            />
        </Layout>
    );
});
