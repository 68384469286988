import React from 'react';
import {
    Typography,
} from 'antd';

import { Layout } from 'components/ui/layout';

import { StatisticsFilter } from 'components/statistics-filter';
import { StatisticsContent } from 'components/statistics-content';

const { Title } = Typography;

export const Statistics = React.memo(() => (
    <Layout>
        <Title level={ 2 }>Просмотр статистики</Title>
        <StatisticsFilter />
        <StatisticsContent />
    </Layout>
));
