import React from 'react';
import { Image, Typography } from 'antd';

import { ExpertForm } from 'form-helpers/expert-form-choosecolor/types';

import styles from './styles.module.scss';

type Props = {
    values: ExpertForm;
};

const { Title } = Typography;

export const ViewExpertForm = ({ values }: Props) => (
    <React.Fragment>
        <Title level={ 3 }>Результат:</Title>
        { (values.imageManager.length ? (
            <React.Fragment>
                <Title level={ 5 }>ПОДОБРАНО КОЛОРИСТОМ</Title>
                <div className={ styles.formItemImages }>
                    <Image.PreviewGroup key={ values.imageManager }>
                        { values.imageManager.map(({ uid, url }: any) => (
                            <Image
                                className={ styles.small_img }
                                src={ url }
                                preview={
                                    { mask: false }
                                }
                                key={ uid }
                            />
                        )) }
                    </Image.PreviewGroup>
                </div>
            </React.Fragment>
        ) : null) }
        <Title level={ 5 }>Комментарий колориста</Title>
        <div className={ styles.formItem }>{ values.messageManagerForClient || 'Не указан' }</div>
    </React.Fragment>
);
