export const ReviewsActionTypes = {
    GET_REVIEWS_START: 'GET_REVIEWS_START',
    GET_REVIEWS_ERROR: 'GET_REVIEWS_ERROR',
    GET_REVIEWS_FINISH: 'GET_REVIEWS_FINISH',

    GET_REVIEWS_LENDING_START: 'GET_REVIEWS_LENDING_START',
    GET_REVIEWS_LENDING_ERROR: 'GET_REVIEWS_LENDING_ERROR',
    GET_REVIEWS_LENDING_FINISH: 'GET_REVIEWS_LENDING_FINISH',

    EDIT_REVIEWS_START: 'EDIT_REVIEWS_START',
    EDIT_REVIEWS_ERROR: 'EDIT_REVIEWS_ERROR',
    EDIT_REVIEWS_FINISH: 'EDIT_REVIEWS_FINISH',

    CREATE_REVIEWS_START: 'CREATE_REVIEWS_START',
    CREATE_REVIEWS_ERROR: 'CREATE_REVIEWS_ERROR',
    CREATE_REVIEWS_FINISH: 'CREATE_REVIEWS_FINISH',
} as const;
