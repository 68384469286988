/**
* Удаляем сессию токен и рефреш
*/
const clearToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
};

/**
 * Выводим token
 * @returns token
 */
const getToken = () => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');

    if (token === undefined || refreshToken === undefined) {
        clearToken();

        return token;
    }

    return token;
};

/**
 * Обновляем токен и куки
 * @param token токен
 * @param refresh рефреш токен
 */
const setToken = (token: string, refresh: string) => {
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refresh);
};

/**
 * Выводим refreshToken
 * @returns refreshToken
 */
const refreshToken = () => localStorage.getItem('refreshToken');

export {
    clearToken,
    getToken,
    setToken,
    refreshToken,
};
