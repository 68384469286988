import { InferValueTypes } from 'types/common';
import { PromoCodeResponse } from 'types/api';
import { Promo } from 'types/promocode';
import { PromoCodeActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type PromoCodeState = {
    loadingFilter: boolean;
    data: PromoCodeResponse | null;
    loading: {
        lending: boolean;
        get: boolean;
        create: boolean;
        edit: boolean;
        closeForm: boolean;
        getDetail: boolean;
        remove: boolean;
    };
    detail: Promo | null;
    list: any;
    pagination: {
        pageSize: number;
        total: number;
        current: number;
    };
    filter: {
        type?: string;
        discount?: number;
        time?: number;
        quantity?: number;
        status?: number;
    };
    promo: {
        visible?: boolean;
        message?: string;
    } | null;
};

export const initialState: PromoCodeState = {
    loadingFilter: false,
    data: null,
    loading: {
        lending: false,
        get: false,
        create: false,
        edit: false,
        closeForm: false,
        getDetail: false,
        remove: false,
    },
    promo: null,
    detail: null,
    list: [],
    pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
    },
    filter: {},
};

export function promoCodeReducer(state = initialState, action: ActionTypes): PromoCodeState {
    switch (action.type) {
        case PromoCodeActionTypes.GET_PROMOCODE_LENDING_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    lending: true,
                },
            };

        case PromoCodeActionTypes.GET_PROMOCODE_LENDING_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    lending: false,
                },
            };
        case PromoCodeActionTypes.GET_PROMOCODE_LENDING_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    lending: false,
                },
                promo: action.response,
            };
        case PromoCodeActionTypes.GET_PROMOCODE_START:
            return {
                ...state,
                loadingFilter: true,
            };
        case PromoCodeActionTypes.GET_PROMOCODE_FINISH:
            return {
                ...state,
                loadingFilter: false,
                data: action.response,
            };

        case PromoCodeActionTypes.GET_PROMOCODE_ERROR:
            return {
                ...state,
                loadingFilter: false,
            };

        case PromoCodeActionTypes.GET_PROMO_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
                filter: {
                    ...state.filter,
                    ...action.payload.filter,
                },
            };
        case PromoCodeActionTypes.GET_PROMO_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                list: action.response.data,
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
            };

        case PromoCodeActionTypes.GET_PROMO_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };
        case PromoCodeActionTypes.REMOVE_PROMO_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    remove: true,
                },
            };

        case PromoCodeActionTypes.REMOVE_PROMO_ERROR:
        case PromoCodeActionTypes.REMOVE_PROMO_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    remove: false,
                },
            };
        case PromoCodeActionTypes.GET_PROMO_DATAIL_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getDetail: true,
                },
            };
        case PromoCodeActionTypes.GET_PROMO_DATAIL_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getDetail: false,
                },
                detail: action.response,
            };

        case PromoCodeActionTypes.GET_PROMO_DATAIL_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getDetail: false,
                },
            };

        case PromoCodeActionTypes.UPDATE_PROMOCODE_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: true,
                },
            };

        case PromoCodeActionTypes.UPDATE_PROMOCODE_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: true,
                },
            };

        case PromoCodeActionTypes.UPDATE_PROMOCODE_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,
                },
            };

        case PromoCodeActionTypes.CREATE_PROMOCODE_START:
        case PromoCodeActionTypes.CREATE_PROMOCODE_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: true,
                },
            };

        case PromoCodeActionTypes.CREATE_PROMOCODE_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: false,
                },
            };

        case PromoCodeActionTypes.CLEAR_DATAIL_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getDetail: false,
                },
                detail: null,
            };
        case PromoCodeActionTypes.EDIT_PROMOCODE_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: true,
                },
            };

        case PromoCodeActionTypes.EDIT_PROMOCODE_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,
                },
            };
        case PromoCodeActionTypes.EDIT_PROMOCODE_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,
                },
                data: action.response,
            };

        default: {
            return state;
        }
    }
}
