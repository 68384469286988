import { InferValueTypes } from 'types/common';
import { PriceResponse } from 'types/api';
import { PriceActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type PriceState = {
    loading: {
        get: boolean;
        edit: boolean;
        remove: { [key: number]: boolean };
    };
    list: PriceResponse;
};

export const initialState: PriceState = {
    loading: {
        get: false,
        edit: false,
        remove: {},
    },
    list: [],
};

export function priceReducer(state = initialState, action: ActionTypes): PriceState {
    switch (action.type) {
        case PriceActionTypes.GET_PRICE_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
            };
        case PriceActionTypes.GET_PRICE_FINISH:
            return {
                ...state,
                list: action.response,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case PriceActionTypes.GET_PRICE_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case PriceActionTypes.EDIT_PRICE_START:
        case PriceActionTypes.CREATE_PRICE_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: true,
                },
            };
        case PriceActionTypes.EDIT_PRICE_ERROR:
        case PriceActionTypes.EDIT_PRICE_FINISH:
        case PriceActionTypes.CREATE_PRICE_ERROR:
        case PriceActionTypes.CREATE_PRICE_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,
                },
            };

        case PriceActionTypes.REMOVE_PRICE_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    remove: {
                        ...state.loading.remove,
                        [action.priceId]: true,
                    },
                },
            };

        case PriceActionTypes.REMOVE_PRICE_ERROR:
        case PriceActionTypes.REMOVE_PRICE_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    remove: {
                        ...state.loading.remove,
                        [action.priceId]: false,
                    },
                },
                list: state.list.filter((item) => item.id !== action.priceId),
            };

        default: {
            return state;
        }
    }
}
