import { Order, OrderType } from 'types/orders';
import { Status } from 'types/status';
import { User } from 'types/user';

import { expertFields as expertFieldsLamination } from 'form-helpers/expert-form-lamination/mapping';
import { expertFields as expertFieldsColoringtinting, notRequiredField } from 'form-helpers/expert-form-coloringtinting/mapping';
import { expertFields as expertFieldsChoosecolor, notRequiredField as notRequiredFieldChoosecolor } from 'form-helpers/expert-form-choosecolor/mapping';
import { getGrayHairKoef } from 'utils/orders';
import { colorsKeys, lengthIgnoreFields } from 'constants/order';

export const checkAccess = (
    accessList: string[] | undefined,
    access: string,
) => Boolean(accessList && accessList.includes(access));

export const checkAllowedChangeStatus = (
    user: User | null,
    order: Order,
    loading: boolean,
) => {
    const orderStatus = order.status;
    const isPaid = Boolean(order.payment);

    const grayKoef = getGrayHairKoef(order.grayHair);
    const lengthGrayKoef = getGrayHairKoef(order.lengthGrayHair);

    const isLamination = order.formType === OrderType.lamination;
    const { isLengthNeeded } = order;

    // eslint-disable-next-line no-nested-ternary
    let fields = order.formType === OrderType.lamination ? expertFieldsLamination : (order.formType === OrderType.coloringtinting) ? expertFieldsColoringtinting : expertFieldsChoosecolor;

    if (order.formType === OrderType.coloringtinting) {
        fields = fields.filter((key) => !notRequiredField.includes(key));
    }

    if (order.formType === OrderType.choosecolor) {
        fields = fields.filter((key) => !notRequiredFieldChoosecolor.includes(key));
    }

    const isCompleteDisabled = fields.some((key: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const value = order[key];

        if (!isLengthNeeded && lengthIgnoreFields.includes(key)) {
            return false;
        }

        if (colorsKeys.includes(key)) {
            const { natural, desired, enhancer } = value;

            const koef = key === 'lengthColors' ? lengthGrayKoef : grayKoef;

            const isNaturalNeed = koef ? Boolean(natural) === false : false;

            // eslint-disable-next-line no-nested-ternary
            const isEnhancerNeed = key === 'lengthColors'
                ? !!order.isLengthColorEnhancerNeeded && Boolean(enhancer) === false
                : Boolean(enhancer) === false;

            return isLamination
                ? Boolean(natural) === false
                : Boolean(desired) === false || isEnhancerNeed || isNaturalNeed;
        }

        return Boolean(value) === false;
    });

    const isCurrentExpert = user?.id === order.expert_id;

    const list = {
        [Status.new]: false,
        [Status.inOperation]: false,
        [Status.masterDoubt]: false,
        [Status.errorImages]: false,
        [Status.completed]: false,
        [Status.denied]: false,
        isTest: order.isTest,
    };

    if (loading) return list;

    switch (orderStatus) {
        case Status.new:
            list[Status.inOperation] = isPaid;
            list.isTest = order.isTest;
            break;

        case Status.inOperation:
            list[Status.masterDoubt] = isCurrentExpert;
            list[Status.errorImages] = isCurrentExpert;
            list[Status.denied] = isCurrentExpert;
            list[Status.completed] = !isCompleteDisabled && isCurrentExpert;
            list.isTest = order.isTest;
            break;

        case Status.errorImages:
            list[Status.new] = isCurrentExpert;
            list.isTest = order.isTest;
            break;

        default:
            break;
    }

    return list;
};
