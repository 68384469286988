import { put, call, select } from 'redux-saga/effects';

import {
    getColorsError,
    getColorsFinish,
} from 'ducks/info/actions';

import { selectColors } from 'ducks/info/selectors';

import { fetchers } from 'api';

export function* getColorsRequest() {
    try {
        const info = yield select(selectColors);

        if (info) {
            yield put(getColorsFinish(info));
        } else {
            const response = yield call(fetchers.getColors);

            yield put(getColorsFinish(response));
        }
    } catch (error) {
        yield put(getColorsError());
    }
}
