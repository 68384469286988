import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import styles from './styles.module.scss';

export const ModalActionRecipeForLamination = () => {
    const [open, setOpen] = useState(false);
    const [firstClose, setFirstClose] = useState(false);
    const isClose = window.localStorage.getItem('modalreclam') || '0';

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (isClose !== '1') {
            const firstTimeout = setTimeout(() => {
                setOpen(true);
            }, 20000);

            const secondTimeout = firstClose ? setTimeout(() => {
                setOpen(true);
            }, 90000) : null;

            return () => {
                clearTimeout(firstTimeout);
                if (secondTimeout) clearTimeout(secondTimeout);
            };
        }
    }, [firstClose, isClose]);

    const onClose = () => {
        // eslint-disable-next-line no-negated-condition
        if (!firstClose) {
            setOpen(false);
            setFirstClose(true);
        } else {
            setOpen(false);
            window.localStorage.setItem('modalreclam', '1');
        }
    };

    return (
        <Modal
            visible={ open }
            width={ 640 }
            onCancel={ onClose }
            footer={ false }
        >
            <div className={ styles.container }>
                <div className={ styles.content }>
                    <div className={ styles.heard } />
                    <div className={ styles.title }>
                        Рецепт для ламинирования волос всего за 1₽
                    </div>
                    <div className={ styles.desc }>
                        Подпишитесь на нас в социальных сетях, репостните одну из записей и получите промокод на рецепт для ламинирования волос ВСЕГО ЗА 1₽!
                    </div>
                    <a
                        aria-label="vk"
                        href="https://vk.com/hairtone"
                        target="_blank"
                        rel="noreferrer"
                        className={ styles.btn }
                    >
                        Получить промокод
                    </a>
                    <div className={ styles.social }>
                        <a aria-label="vk" className={ styles.vk } href="https://vk.com/hairtone" target="_blank" rel="noreferrer"><span /></a>
                        <a aria-label="telegram" className={ styles.tg } href="https://t.me/hairtone" target="_blank" rel="noreferrer"><span /></a>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
