import React, { useEffect, useCallback } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import {
    Typography,
    Spin,
    Descriptions,
    Table,
} from 'antd';

import { Layout } from 'components/ui/layout';

import { getUser } from 'ducks/user/actions';
import {
    getOrders,
} from 'ducks/orders/actions';
import { selectUser, selectUserLoading } from 'ducks/user/selectors';
import {
    selectOrdersLoading,
    selectOrders,
} from 'ducks/orders/selectors';

import { ShortOrder } from 'types/orders';

import { history } from 'App';
import { ROUTES } from 'constants/routes';
import { DATE_FORMAT } from 'constants/date';

import { columns } from './columns';

import styles from './styles.module.scss';

type MatchParams = {
    id: string;
};

const { Title } = Typography;
const { Item } = Descriptions;

export const User = React.memo(() => {
    const put = useDispatch();
    const user = useSelector(selectUser);
    const loading = useSelector(selectUserLoading);
    const orders = useSelector(selectOrders);
    const ordersLoading = useSelector(selectOrdersLoading);

    const { params: { id: userId } } = useRouteMatch<MatchParams>();

    useEffect(() => {
        put(getUser({ userId: +userId }));
        put(getOrders({
            pagination: { pageSize: 100, page: 1 },
            filter: { user_id: +userId },
            sort: { sortField: 'orders.id', sortOrder: 'desc' },
        }));
    }, []);

    const onClickRow = useCallback((data: ShortOrder) => () => {
        history.push(ROUTES.VIEW_ORDER.getPath({ id: data.id }));
    }, []);

    if (!user || loading.user || ordersLoading.get) {
        return (
            <Layout>
                <Title level={ 2 }>Страница пользователя</Title>
                <Spin size="large" spinning={ true } />
            </Layout>
        );
    }

    return (
        <Layout>
            <Title level={ 2 }>Страница пользователя</Title>
            <div className={ styles.cart }>
                <div className={ styles.group }>
                    <Title level={ 4 }>Общая информация</Title>
                    <Descriptions
                        bordered={ true }
                        column={ 1 }
                    >
                        <Item label="Фамилия">{ user.lastName }</Item>
                        <Item label="Имя">{ user.firstName }</Item>
                        <Item label="Дата регистрации">{ moment(user.createDate).format(DATE_FORMAT.DATE_TIME) }</Item>
                        <Item label="Дата последнего заказа">
                            { user.lastDateCreateOrders && moment(user.lastDateCreateOrders).format(DATE_FORMAT.DATE_TIME) }
                        </Item>
                        <Item label="Количество заказов">{ user.countOrders }</Item>
                    </Descriptions>
                </div>
                <div className={ styles.group }>
                    <Title level={ 4 }>Контакты</Title>
                    <Descriptions
                        bordered={ true }
                        column={ 1 }
                    >
                        <Item label="Email">{ user.email }</Item>
                        <Item label="Телефон">{ user.phone }</Item>
                    </Descriptions>
                </div>
                <div className={ styles.group }>
                    <Title level={ 4 }>Заказы пользователя</Title>
                    <Table
                        columns={ columns }
                        dataSource={ orders }
                        rowKey="id"
                        pagination={ false }
                        className={ styles.table }
                        onRow={ (record: ShortOrder) => ({
                            onClick: onClickRow(record),
                        }) }
                    />
                </div>
            </div>
        </Layout>
    );
});
