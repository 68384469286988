import * as Api from 'types/api';
import { GetReviewsPayload, ReviewsCreate } from 'types/reviews';
import { ReviewsActionTypes } from './action-types';

export const getReviewsLending = (payload: GetReviewsPayload) => ({
    type: ReviewsActionTypes.GET_REVIEWS_LENDING_START,
    payload,
});

export const getReviewsLendingError = () => ({
    type: ReviewsActionTypes.GET_REVIEWS_LENDING_ERROR,
});

export const getReviewsLendingFinish = (response: any) => ({
    type: ReviewsActionTypes.GET_REVIEWS_LENDING_FINISH,
    response,
});

export const getReviews = (payload: Api.ReviewsPayload) => ({
    type: ReviewsActionTypes.GET_REVIEWS_START,
    payload,
});

export const getReviewsError = () => ({
    type: ReviewsActionTypes.GET_REVIEWS_ERROR,
});

export const getReviewsFinish = (response: Api.ReviewsResponse) => ({
    type: ReviewsActionTypes.GET_REVIEWS_FINISH,
    response,
});

export const editReviews = (data: Api.EditReviewsPayload, callback: () => void) => ({
    type: ReviewsActionTypes.EDIT_REVIEWS_START,
    data,
    callback,
});

export const editReviewsError = () => ({
    type: ReviewsActionTypes.EDIT_REVIEWS_ERROR,
});

export const editReviewsFinish = (item: Api.EditReviewsResponse) => ({
    type: ReviewsActionTypes.EDIT_REVIEWS_FINISH,
    item,
});

export const createReviews = (data: ReviewsCreate) => ({
    type: ReviewsActionTypes.CREATE_REVIEWS_START,
    data,
});

export const createReviewsError = () => ({
    type: ReviewsActionTypes.CREATE_REVIEWS_ERROR,
});

export const createReviewsFinish = () => ({
    type: ReviewsActionTypes.CREATE_REVIEWS_FINISH,
});
