import React from 'react';
import { Button, Popconfirm } from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
} from '@ant-design/icons';

import { PriceItem } from 'types/api';
import { PriceState } from 'ducks/price/reducer';

import styles from './styles.module.scss';

export const getColumns = (
    access: {
        isEditAllow: boolean;
        isDeleteAllow: boolean;
    },
    actions: {
        onEditClick: (data: PriceItem) => void;
        onRemoveClick: (priceId: number) => void;
    },
    loading: PriceState['loading'],
) => [
    {
        title: 'Номер',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Цена(руб)',
        dataIndex: 'price',
        key: 'price',
    },
    {
        title: '',
        dataIndex: '',
        key: 'actions',
        render: (data: PriceItem) => (
            <div className={ styles.actions }>
                {
                    access.isEditAllow && (
                        <Button
                            onClick={ () => actions.onEditClick(data) }
                        >
                            <EditOutlined />
                        </Button>
                    )
                }
                {
                    access.isDeleteAllow && (
                        <Popconfirm
                            title="Вы уверены, что хотите удалить цену?"
                            onConfirm={ () => actions.onRemoveClick(data.id) }
                            onCancel={ (e: any) => e.stopPropagation() }
                            okText="Да"
                            cancelText="Нет"
                        >
                            <Button
                                onClick={ (e) => e.stopPropagation() }
                                type="primary"
                                danger={ true }
                                loading={ loading.remove[data.id] }
                            >
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    )
                }
            </div>
        ),
    },
];
