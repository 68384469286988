import {
    GetBlogPayload, GetBlogDetailPayload, RemoveBlogPayload, GetBlogSimilarPayload, RemoveBlogTagsPayload,
} from 'types/blog';
import * as Api from 'types/api';

import { BlogActionTypes } from './action-types';

export const getBlog = (payload: GetBlogPayload) => ({
    type: BlogActionTypes.GET_BLOG_START,
    payload,
});

export const getBlogError = () => ({
    type: BlogActionTypes.GET_BLOG_ERROR,
});

export const getBlogFinish = (response: any) => ({
    type: BlogActionTypes.GET_BLOG_FINISH,
    response,
});

export const createBlog = (payload: Api.CreateBlogPayload, Callback?: any) => ({
    type: BlogActionTypes.CREATE_BLOG_START,
    payload,
    Callback,
});

export const createBlogError = () => ({
    type: BlogActionTypes.CREATE_BLOG_ERROR,
});

export const createBlogFinish = (response: any) => ({
    type: BlogActionTypes.CREATE_BLOG_FINISH,
    response,
});

export const editBlog = (payload: Api.CreateBlogPayload, Callback?: any) => ({
    type: BlogActionTypes.EDIT_BLOG_START,
    payload,
    Callback,
});

export const editBlogError = () => ({
    type: BlogActionTypes.EDIT_BLOG_ERROR,
});

export const editBlogFinish = (response: any) => ({
    type: BlogActionTypes.EDIT_BLOG_FINISH,
    response,
});

export const getBlogCategories = () => ({
    type: BlogActionTypes.GET_BLOG_CATEGORIES_START,
});

export const getBlogCategoriesError = () => ({
    type: BlogActionTypes.GET_BLOG_CATEGORIES_ERROR,
});

export const getBlogCategoriesFinish = (response: any) => ({
    type: BlogActionTypes.GET_BLOG_CATEGORIES_FINISH,
    response,
});

export const getBlogDetail = (payload: GetBlogDetailPayload) => ({
    type: BlogActionTypes.GET_BLOG_DETAIL_START,
    payload,
});

export const getBlogDetailError = () => ({
    type: BlogActionTypes.GET_BLOG_DETAIL_ERROR,
});

export const getBlogDetailFinish = (response: any) => ({
    type: BlogActionTypes.GET_BLOG_DETAIL_FINISH,
    response,
});

export const getBlogSimilar = (payload: GetBlogSimilarPayload) => ({
    type: BlogActionTypes.GET_BLOG_SIMILAR_START,
    payload,
});

export const getBlogSimilarError = () => ({
    type: BlogActionTypes.GET_BLOG_SIMILAR_ERROR,
});

export const getBlogSimilarFinish = (response: any) => ({
    type: BlogActionTypes.GET_BLOG_SIMILAR_FINISH,
    response,
});

export const removeBlog = (id: RemoveBlogPayload) => ({
    type: BlogActionTypes.REMOVE_BLOG_START,
    id,
});

export const removeBlogError = () => ({
    type: BlogActionTypes.REMOVE_BLOG_ERROR,
});

export const removeBlogFinish = (response: any) => ({
    type: BlogActionTypes.REMOVE_BLOG_FINISH,
    response,
});

export const removeBlogTags = (tags: RemoveBlogTagsPayload) => ({
    type: BlogActionTypes.DELETE_BLOG_TAG_START,
    tags,
});

export const removeBlogTagsError = () => ({
    type: BlogActionTypes.DELETE_BLOG_TAG_ERROR,
});

export const removeBlogTagsFinish = (response: any) => ({
    type: BlogActionTypes.DELETE_BLOG_TAG_FINISH,
    response,
});

export const likeBlog = (id: number | string) => ({
    type: BlogActionTypes.LIKE_BLOG_START,
    id,
});

export const likeBlogError = () => ({
    type: BlogActionTypes.LIKE_BLOG_ERROR,
});

export const likeBlogFinish = (response: any) => ({
    type: BlogActionTypes.LIKE_BLOG_FINISH,
    response,
});

export const setVisibleBlogTags = (status: boolean) => ({
    type: BlogActionTypes.SET_VISIBLE_BLOG_TAGS,
    status,
});
