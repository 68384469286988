import React, {
    useEffect, useRef, useState,
} from 'react';
import { Avatar } from 'antd';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { useSelector } from 'react-redux';
import { selectProfile } from 'ducks/user/selectors';
import { ROUTES } from 'constants/routes';
import { logout } from 'utils/app';
import { getAvatar } from 'utils/formatters';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import styles from './styles.module.scss';

export const Profile = React.memo(() => {
    const user = useSelector(selectProfile);
    const ref = useRef<HTMLDivElement | null>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleOpen = () => setIsMenuOpen(!isMenuOpen);

    const stylePopap = cn(styles.popap_menu, {
        [styles.popap_open]: isMenuOpen,
    });

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    if (!user) {
        return null;
    }

    return (
        <div className={ styles.popap } ref={ ref }>
            <div
                className={ styles.profile }
                onClick={ toggleOpen }
                role="button"
                aria-hidden="true"
            >
                <Avatar shape="square" src={ getAvatar(user?.avatar) } size={ 30 } />
                <div className={ styles.profileName }>
                    { user?.lastName } <br /> { user?.firstName }
                </div>
                { isMenuOpen ? (<IconChevronUp className={ styles.profileAngle } size={ 16 } />) : (<IconChevronDown className={ styles.profileAngle } size={ 16 } />) }
            </div>
            { isMenuOpen && (
                <div className={ stylePopap }>
                    <NavLink className={ styles.popap__link } activeClassName={ styles.popap__link_active } to={ ROUTES.PROFILE.path }>Мой профиль</NavLink>
                    <div className={ styles.popap__link } aria-hidden="true" onClick={ logout }>Выйти</div>
                </div>
            ) }
        </div>
    );
});
