import { message } from 'antd';
import { put, call } from 'redux-saga/effects';
import {
    removeOrder,
    removeOrderError,
    removeOrderFinish,
    getOrders,
} from 'ducks/orders/actions';

import { fetchers } from 'api';

export function* removeOrderRequest({ payload }: ReturnType<typeof removeOrder>) {
    try {
        yield call(fetchers.removeOrder, payload);
        message.success('Заказ успешно удален');
        yield put(getOrders({ pagination: { page: 1 } }));
        yield put(removeOrderFinish());
    } catch (error) {
        yield put(removeOrderError());
    }
}
