import { InferValueTypes } from 'types/common';
import { Blog, Similar } from 'types/blog';
import { BlogActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type BlogState = {
    loading: {
        get: boolean;
        getCategories: boolean;
        getSemilar: boolean;
        create: boolean;
        edit: boolean;
        delete: boolean;
        like: boolean;
        delete_tag: boolean;
    };
    data: any[] | null;
    categories: any[] | null;
    tags: string[] | null;
    detail: Blog | null;
    similar: Similar[];
    pagination: {
        pageSize: number;
        page: number;
        total: number;
    };
    filter: {
        categoryId?: number | [];
    };
    editTags: boolean;
};

export const initialState: BlogState = {
    loading: {
        get: false,
        getCategories: false,
        getSemilar: false,
        create: false,
        edit: false,
        delete: false,
        like: false,
        delete_tag: false,
    },
    data: null,
    similar: [],
    categories: null,
    tags: null,
    detail: null,
    pagination: {
        pageSize: 12,
        page: 1,
        total: 0,
    },
    filter: {},
    editTags: false,
};

export function blogReducer(state = initialState, action: ActionTypes): BlogState {
    switch (action.type) {
        case BlogActionTypes.GET_BLOG_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
                filter: {
                    ...action.payload.filter,
                },
            };
        case BlogActionTypes.GET_BLOG_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case BlogActionTypes.GET_BLOG_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                data: action.response.data,
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
            };
        case BlogActionTypes.LIKE_BLOG_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    like: true,
                },
            };
        case BlogActionTypes.LIKE_BLOG_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    like: false,
                },
            };

        case BlogActionTypes.LIKE_BLOG_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    like: false,
                },
                data: state.data?.map((item: any) => (item.id === action.response.id ? { ...item, likesAdd: action.response.likesAdd, likes: action.response.likes } : item)) || null,
                detail: state.detail ? { ...state.detail, likesAdd: action.response.likesAdd, likes: action.response.likes } : null,
            };

        case BlogActionTypes.GET_BLOG_CATEGORIES_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getCategories: true,
                },
            };

        case BlogActionTypes.GET_BLOG_CATEGORIES_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getCategories: false,
                },
            };

        case BlogActionTypes.GET_BLOG_CATEGORIES_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getCategories: false,
                },
                categories: action.response.categories,
                tags: action.response.tags,
            };

        case BlogActionTypes.GET_BLOG_DETAIL_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
            };

        case BlogActionTypes.GET_BLOG_DETAIL_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case BlogActionTypes.GET_BLOG_DETAIL_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                detail: action.response,
            };

        case BlogActionTypes.GET_BLOG_SIMILAR_FINISH:
            return {
                ...state,
                similar: action.response,
            };

        case BlogActionTypes.CREATE_BLOG_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: true,
                },
            };

        case BlogActionTypes.CREATE_BLOG_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: false,
                },
            };
        case BlogActionTypes.SET_VISIBLE_BLOG_TAGS:
            return {
                ...state,
                editTags: action.status,
            };

        case BlogActionTypes.CREATE_BLOG_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: false,
                },
                data: Array.isArray(state.data) ? [...state.data, ...action.response.data] : action.response,
            };

        case BlogActionTypes.DELETE_BLOG_TAG_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    delete_tag: true,
                },
            };

        case BlogActionTypes.DELETE_BLOG_TAG_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    delete_tag: false,
                },
            };

        case BlogActionTypes.DELETE_BLOG_TAG_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    delete_tag: false,
                },
                editTags: false,
                tags: state.tags && state.tags?.filter((tag) => !action.response.includes(tag)),
            };

        default: {
            return state;
        }
    }
}
