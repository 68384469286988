import React, { FC, useState } from 'react';
import cn from 'classnames';
import { IconChevronUp, IconChevronDown } from '@tabler/icons-react';

import styles from './styles.module.scss';

type Props = {
    extra?: string | React.ReactNode;
};

export const Filter: FC<Props> = ({ children, extra }) => {
    const [open, setOpen] = useState(false);

    const toggleOpen = () => setOpen(!open);

    const styleContent = cn(styles.filter_content, {
        [styles.open]: open,
    });

    return (
        <div className={ styles.filter }>

            <div
                role="button"
                className={ styles.filter_title }
                onClick={ toggleOpen }
                aria-hidden="true"
            >
                Фильтры { open ? (<IconChevronUp />) : (<IconChevronDown />) }
            </div>
            <div className={ styleContent }>
                <div className={ styles.filter_fields }>
                    { children }
                </div>
                <div className={ styles.filter_extra }>
                    { extra }
                </div>
            </div>
        </div>
    );
};
