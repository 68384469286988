import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Typography,
    Form,
    Radio,
    Switch,
    Button,
    Space,
} from 'antd';
import { selectOrder } from 'ducks/orders/selectors';
import { selectProfile } from 'ducks/user/selectors';

import { Order, OrderFeedbackType, OrderType } from 'types/orders';
import { UploadFileANek } from 'components/ui/upload-file';
import { selectOrderFeedback } from 'ducks/orderFeedback/selectors';
import { Status } from 'types/status';
import { Formik } from 'formik';
import { getInitialValues } from 'form-helpers/orderFeedback/mapping-initial-values';
import { ExpertFormFeedback } from 'form-helpers/orderFeedback/types';
import { ExpertOrderFormPayload } from 'types/api';
import { sendExpertOrderFeedbackForm } from 'ducks/orderFeedback/actions';
import styles from './styles.module.scss';

const { Title } = Typography;

export const OrderFeedback = () => {
    const put = useDispatch();
    const order = useSelector(selectOrder) as Order;
    const user = useSelector(selectProfile);
    const orderFeedback = useSelector(selectOrderFeedback);

    const handleUpdateExpertForm = (values: any) => {
        const onlyFields: { [key: string]: any } = {};

        Object.keys(values).forEach((key) => {
            onlyFields[key] = values[key];
        });

        put(sendExpertOrderFeedbackForm({
            orderId: order.id,
            brand: order.brand,
            ...onlyFields,
        } as ExpertOrderFormPayload));
    };

    if (order.status !== Status.completed) {
        return null;
    }

    const title = order.user_id === user?.id ? 'Ваш результат:' : 'Результат клиента';
    const access = order.expert_id === user?.id && (orderFeedback?.images && orderFeedback?.images.length) && order.formType === OrderType.coloringtinting;
    const disableEditForm = orderFeedback?.status === OrderFeedbackType.completed;
    const accessDeleteImage = (orderFeedback?.status !== OrderFeedbackType.loading);
    const isDisabledUpload = orderFeedback?.status && (orderFeedback?.status !== OrderFeedbackType.loading);

    const isViewImage = order.user_id === user?.id || (orderFeedback?.images && orderFeedback?.images.length);
    const isAuthor = order.user_id === user?.id;

    const variants = [2, 3, 4, 5, 6, 7, 8, 9, 10, 12];

    return (
        <div className={ styles.container }>
            <Title level={ 3 }>{ title }</Title>
            <div className={ styles.wrapper }>
                <div className={ styles.block }>
                    { isViewImage ? (
                        <UploadFileANek
                            action={
                                {
                                    id: order.id,
                                    table: 'orderFeedback',
                                    field: 'images',
                                }
                            }
                            count={ 5 }
                            multiple={ true }
                            disabled={ !isAuthor || isDisabledUpload }
                            deleted={ user?.id !== order.user_id || accessDeleteImage }
                            accept="image/*"
                            defaultList={ orderFeedback?.images || [] }
                        />
                    ) : 'Результат еще не загрузили' }
                    { (isAuthor && !isDisabledUpload) && (<Title level={ 5 }>Загрузите пожалуйста свои фотографии чтобы мы могли оценить результат</Title>) }
                </div>
                { access && (
                    <div className={ styles.blockForm }>
                        <Formik<ExpertFormFeedback>
                            initialValues={ getInitialValues(orderFeedback || {}) }
                            onSubmit={ () => { } }
                        >
                            { ({
                                values,
                                setValues,
                            }) => {
                                const updateForm = (newValues?: any) => {
                                    handleUpdateExpertForm(newValues || values);
                                };

                                const onChangeGroup = (e: any) => {
                                    const { name, value } = e.target;
                                    const updValues = {
                                        ...values,
                                        status: OrderFeedbackType.inwork,
                                        [name]: value,
                                    };

                                    setValues(updValues);
                                    updateForm(updValues);
                                };
                                const onChangeSwitch = (name: string) => (value: boolean) => {
                                    if (name === 'status') {
                                        const updValues = {
                                            ...values,
                                            [name]: value ? OrderFeedbackType.completed : OrderFeedbackType.inwork,
                                        };

                                        setValues(updValues);
                                        updateForm(updValues);
                                    } else {
                                        const updValues = {
                                            ...values,
                                            status: OrderFeedbackType.inwork,
                                            [name]: value,
                                        };

                                        setValues(updValues);
                                        updateForm(updValues);
                                    }
                                };

                                return (
                                    <Form
                                        name="orderFeedback"
                                        layout="vertical"
                                        autoComplete="off"
                                    >
                                        <Title level={ 5 }>Удачная формула</Title>
                                        <Form.Item>
                                            <Switch
                                                onChange={ onChangeSwitch('luckyFormula') }
                                                checkedChildren="Да"
                                                unCheckedChildren="Нет"
                                                defaultChecked={ !!values.luckyFormula }
                                                disabled={ disableEditForm }
                                            />
                                        </Form.Item>
                                        <Title level={ 5 }>Уровень тона (Корни)</Title>
                                        <Form.Item
                                            name="rootTone"
                                        >
                                            <Radio.Group
                                                disabled={ disableEditForm }
                                                name="rootTone"
                                                buttonStyle="solid"
                                                defaultValue={ values.rootTone }
                                                onChange={ onChangeGroup }
                                            >
                                                { variants.map((val) => (<Radio.Button value={ val }>{ val }</Radio.Button>)) }
                                            </Radio.Group>
                                        </Form.Item>
                                        <Title level={ 5 }>Уровень тона (Длина)</Title>
                                        <Form.Item
                                            name="lengthTone"
                                        >
                                            <Radio.Group
                                                disabled={ disableEditForm }
                                                name="lengthTone"
                                                buttonStyle="solid"
                                                defaultValue={ values.lengthTone }
                                                onChange={ onChangeGroup }
                                            >
                                                { variants.map((val) => (<Radio.Button value={ val }>{ val }</Radio.Button>)) }
                                            </Radio.Group>
                                        </Form.Item>
                                        <Title level={ 5 }>Оттенок</Title>
                                        <Form.Item
                                            name="shade"
                                        >
                                            <Radio.Group
                                                name="shade"
                                                buttonStyle="solid"
                                                defaultValue={ values.shade }
                                                onChange={ onChangeGroup }
                                                disabled={ disableEditForm }
                                            >
                                                <Radio.Button value="warm">Теплый</Radio.Button>
                                                <Radio.Button value="cold">Холодный</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item>
                                            <Space>
                                                <Button
                                                    type="primary"
                                                    disabled={ disableEditForm }
                                                    onClick={ () => onChangeSwitch('status')(true) }
                                                >
                                                    Сохранить
                                                </Button>
                                                <Button
                                                    type="text"
                                                    disabled={ values.status === OrderFeedbackType.inwork }
                                                    onClick={ () => onChangeSwitch('status')(false) }
                                                >
                                                    Изменить
                                                </Button>
                                            </Space>
                                        </Form.Item>
                                    </Form>
                                );
                            } }
                        </Formik>
                    </div>
                ) }
            </div>
        </div>
    );
};

// rootTone
// lengthTone
// shade
// brand
