import { put, call } from 'redux-saga/effects';
import {
    changeTicketStatus,
    changeTicketStatusError,
    changeTicketStatusFinish,
} from 'ducks/tickets/actions';

import { fetchers } from 'api';

export function* changeTicketStatusRequest(action: ReturnType<typeof changeTicketStatus>) {
    try {
        yield call(fetchers.changeTicketStatus, action.payload);

        yield put(changeTicketStatusFinish(action.payload.status));
    } catch (error) {
        yield put(changeTicketStatusError());
    }
}
