import React from 'react';
import { Blog } from 'types/blog';
import { DATE_FORMAT } from 'constants/date';
import moment from 'moment';
import cls from './style.module.scss';

export const SimilarItem = ({
    id,
    title,
    previewImage,
    previewText,
    created,
}: Blog) => (
    <div className={ `grid-item ${cls.item}` } itemScope={ true } itemType="http://schema.org/ImageObject">
        <a href={ `/blog/${id}` } className={ cls.title }>{ title } </a>
        <div className={ cls.header }>
            <div className={ cls.category }>
                { moment(created).format(DATE_FORMAT.DATE_TIME_NAME) }
            </div>
        </div>
        <div className={ cls.content }>
            <picture className={ cls.image }>
                <img itemProp="contentUrl" src={ `../${previewImage}` } alt={ title } />
            </picture>
            <div className={ cls.preview }>
                <p itemProp="description" className={ cls.text }>{ previewText } </p>
            </div>
        </div>
        <div className={ cls.nextpage }>
            <a href={ `/blog/${id}` }>Читать статью</a>
        </div>
    </div>
);
