import React, {
    useEffect,
    useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Typography,
    Table,
    Pagination,
} from 'antd';

import { getInfo } from 'ducks/info/actions';
import { Layout } from 'components/ui/layout';
import { OrderFeedbackFilter } from 'components/order-feedback-filter';
import { Cart } from 'components/ui/cart';
import { history } from 'App';
import { ROUTES } from 'constants/routes';
import {
    selectOrderFeedbackList,
    selectOrdersFeedbackLoading,
    selectOrdersFeedbackPagination,
    selectOrdersFeedbackFilter,
} from 'ducks/orderFeedback/selectors';
import { getOrdersFeedback } from 'ducks/orderFeedback/actions';
import { getColumns } from './columns';
import styles from './styles.module.scss';

const { Title } = Typography;

export const OrderFeedback = React.memo(() => {
    const put = useDispatch();
    const ordersFeedback = useSelector(selectOrderFeedbackList);
    const loading = useSelector(selectOrdersFeedbackLoading);
    const pagination = useSelector(selectOrdersFeedbackPagination);
    const filter = useSelector(selectOrdersFeedbackFilter);

    useEffect(() => {
        put(getOrdersFeedback({ pagination: { page: 1 } }));
        put(getInfo());
    }, []);

    const onChangePagination = useCallback((page: number) => {
        put(getOrdersFeedback({ pagination: { page }, filter }));
    }, [filter]);

    const onClickRow = useCallback((data: any) => () => {
        history.push(ROUTES.VIEW_ORDER.getPath({ id: data.orderId }));
    }, []);

    const columns = getColumns();

    return (
        <Layout>
            <div className={ styles.header }>
                <Title level={ 2 }>Успешные формулы</Title>
            </div>
            <OrderFeedbackFilter />
            <Cart
                footer={ (
                    <div className={ styles.pagination }>
                        <Pagination
                            total={ pagination.total * pagination.pageSize }
                            pageSize={ pagination.pageSize }
                            current={ pagination.current }
                            onChange={ onChangePagination }
                        />
                    </div>
                ) }
            >
                <Table
                    loading={ loading.get }
                    dataSource={ ordersFeedback }
                    columns={ columns }
                    rowKey="id"
                    pagination={ false }
                    onRow={ (record: any) => ({
                        onClick: onClickRow(record),
                    }) }
                    className={ styles.table }
                />
            </Cart>
        </Layout>
    );
});
