import React from 'react';
import { Modal } from 'antd';
import { put, call } from 'redux-saga/effects';
import {
    editOrder,
    editOrderError,
    editOrderFinish,
    clearDetailData,
} from 'ducks/orders/actions';

import { fetchers } from 'api';

import { history } from 'App';

import { ROUTES } from 'constants/routes';

export function* editOrderRequest({ payload }: ReturnType<typeof editOrder>) {
    const modal = Modal.warning({ title: ' Изменяем данные заказа' });

    modal.update((prev) => ({
        ...prev,
        content: <div> Ожидайте.Идет отправка данных...</div>,
    }));

    try {
        yield call(fetchers.editOrder, payload);
        yield put(clearDetailData());
        yield put(editOrderFinish());
        history.push(ROUTES.VIEW_ORDER.getPath({ id: payload.id }));
        modal.destroy();
    } catch (error) {
        modal.update((prev) => ({
            ...prev,
            type: error.status,
            title: 'Извините, но заказ изменить не удалось',
            content: <div dangerouslySetInnerHTML={ { __html: error.data.message } } />,
        }));
        yield put(editOrderError());
    }
}
