export const PromoCodeActionTypes = {
    GET_PROMOCODE_START: 'GET_PROMOCODE_START',
    GET_PROMOCODE_ERROR: 'GET_PROMOCODE_ERROR',
    GET_PROMOCODE_FINISH: 'GET_PROMOCODE_FINISH',

    GET_PROMOCODE_LENDING_START: 'GET_PROMOCODE_LENDING_START',
    GET_PROMOCODE_LENDING_ERROR: 'GET_PROMOCODE_LENDING_ERROR',
    GET_PROMOCODE_LENDING_FINISH: 'GET_PROMOCODE_LENDING_FINISH',

    GET_PROMO_START: 'GET_PROMO_START',
    GET_PROMO_ERROR: 'GET_PROMO_ERROR',
    GET_PROMO_FINISH: 'GET_PROMO_FINISH',

    REMOVE_PROMO_START: 'REMOVE_PROMO_START',
    REMOVE_PROMO_ERROR: 'REMOVE_PROMO_ERROR',
    REMOVE_PROMO_FINISH: 'REMOVE_PROMO_FINISH',

    GET_PROMO_DATAIL_START: 'GET_PROMO_DATAIL_START',
    GET_PROMO_DATAIL_FINISH: 'GET_PROMO_DATAIL_FINISH',
    GET_PROMO_DATAIL_ERROR: 'GET_PROMO_DATAIL_ERROR',

    UPDATE_PROMOCODE_START: 'UPDATE_PROMOCODE_START',
    UPDATE_PROMOCODE_FINISH: 'UPDATE_PROMOCODE_FINISH',
    UPDATE_PROMOCODE_ERROR: 'UPDATE_PROMOCODE_ERROR',

    CREATE_PROMOCODE_START: 'CREATE_PROMOCODE_START',
    CREATE_PROMOCODE_FINISH: 'CREATE_PROMOCODE_FINISH',
    CREATE_PROMOCODE_ERROR: 'CREATE_PROMOCODE_ERROR',

    CLEAR_DATAIL_START: 'CLEAR_DATAIL_START',

    EDIT_PROMOCODE_START: 'EDIT_PROMOCODE_START',
    EDIT_PROMOCODE_ERROR: 'EDIT_PROMOCODE_ERROR',
    EDIT_PROMOCODE_FINISH: 'EDIT_PROMOCODE_FINISH',
} as const;
