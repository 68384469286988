import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
    Modal, Input, Form, Select,
} from 'antd';

import { createTicket } from 'ducks/tickets/actions';
import { selectTicketsLoading } from 'ducks/tickets/selectors';

import { getError, getErrorStatus } from 'form-helpers/validation';

import { schema } from 'form-helpers/tickets/schema';
import { CreateTicketForm } from 'form-helpers/tickets/types';

import { fetchers } from 'api';
import { OrderType, OrderTypeText } from 'types/orders';
import styles from './styles.module.scss';

const itemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const { TextArea } = Input;
const { Option } = Select;

type Props = {
    onClose: () => void;
    orderId?: number;
};

type Order = {
    form: string;
    formType: OrderType;
    id: number;
};

export const CreateTicketModal = ({ onClose, orderId }: Props) => {
    const put = useDispatch();
    const loading = useSelector(selectTicketsLoading);
    const [orders, setOrders] = useState<Order[]>([]);
    const [optionsLoading, setOptionsLoading] = useState(true);

    const getOrdersForTicket = async () => {
        const response = await fetchers.getOrdersForTicket();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setOrders(response as Order[]);
        setOptionsLoading(false);
    };

    useEffect(() => {
        getOrdersForTicket();
    }, []);

    const onSubmit = (data: CreateTicketForm) => {
        put(createTicket(data, Boolean(orderId), onClose));
    };

    return (
        <Formik<CreateTicketForm>
            initialValues={ schema.cast({ idOrders: orderId }) }
            onSubmit={ onSubmit }
            validationSchema={ schema }
        >
            { ({
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
            }) => {
                const errorList = {
                    thema: getError(errors, touched, true, 'thema'),
                    message: getError(errors, touched, true, 'message'),
                    idOrders: getError(errors, touched, true, 'idOrders'),
                };

                const handleChangeTextarea = (e: any) => {
                    const event = {
                        target: {
                            name: e.target.name,
                            value: e.target.value,
                            id: e.target.id,
                        },
                    };

                    handleChange(event);
                };

                const handleChangeSelect = (name: string) => (value: any) => {
                    const event = {
                        target: {
                            name,
                            value,
                            id: name,
                        },
                    };

                    handleChange(event);
                };

                return (
                    <Modal
                        visible={ true }
                        confirmLoading={ loading.create }
                        onCancel={ onClose }
                        onOk={ () => handleSubmit() }
                    >
                        <div className={ styles.container }>
                            <Form
                                labelAlign="left"
                                { ...itemLayout }
                            >
                                <Form.Item
                                    label="Тема"
                                    extra={ errorList.thema }
                                    validateStatus={ getErrorStatus(!!errorList.thema) }
                                >
                                    <Input
                                        name="thema"
                                        placeholder="Тема"
                                        onChange={ handleChange }
                                        onBlur={ handleBlur }
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Ид заказа"
                                    extra={ errorList.idOrders }
                                    validateStatus={ getErrorStatus(!!errorList.idOrders) }
                                >
                                    <Select
                                        id="idOrders"
                                        placeholder="Ид заказа"
                                        defaultValue={ orderId }
                                        onChange={ handleChangeSelect('idOrders') }
                                        loading={ optionsLoading }
                                    >
                                        {
                                            orders.map((item) => <Option key={ item.id } value={ item.id }>{ item.id } - { OrderTypeText[item.formType] }</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Сообщение"
                                    extra={ errorList.message }
                                    validateStatus={ getErrorStatus(!!errorList.message) }
                                >
                                    <TextArea
                                        id="message"
                                        name="message"
                                        onChange={ handleChangeTextarea }
                                        autoSize={ { minRows: 4, maxRows: 6 } }
                                        placeholder="Опишите вашу проблему"
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    </Modal>
                );
            } }
        </Formik>
    );
};
