import React, { ReactNode, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import { Formik } from 'formik';
import {
    Form,
    Radio,
    Typography,
    Spin,
    Space,
    Button,
    Tooltip,
} from 'antd';

import { createOrder, editOrder } from 'ducks/orders/actions';

import { Layout } from 'components/ui/layout';
import { UploadFile } from 'components/upload-file';
import { InfoResponse } from 'types/api';
import {
    Order, OrderLoading, OrderType, Image, OrderFormType,
} from 'types/orders';
import { CreateOrderForm } from 'form-helpers/order/types';
import { getInitialValues } from 'form-helpers/order/mapping-initial-values';
import { orderFormFields } from 'form-helpers/order/fields';
import { schema } from 'form-helpers/order/schema';
import { getError, getErrorStatus } from 'form-helpers/validation';

import { history } from 'App';

import { Status } from 'types/status';

import { ROUTES } from 'constants/routes';
import { getImageErrors } from 'utils/orders';

import { HELPER_TEXT } from 'constants/text';
import { optimizeDataImage } from 'form-helpers/utils';
import styles from './styles.module.scss';

const { Title } = Typography;

const initialValues = schema.cast({});

const getLabel = (text: string) => (
    <Title className={ styles.label } level={ 5 }>{ text }</Title>
);

const getErrorForHairType = (value: string, error: ReactNode) => {
    if (value) return undefined;
    if (error) return error;

    return undefined;
};

const getErrorStatusForHairType = (value: string, error: boolean) => {
    if (!value && error) return 'error';
    if (value && error) return undefined;

    return undefined;
};

type TProps = {
    info: InfoResponse | null;
    infoLoading: boolean;
    ordersLoading: OrderLoading;
    order: Order | null;
};

export const CreateOrderLamination = ({
    order, info, infoLoading, ordersLoading,
}: TProps) => {
    const put = useDispatch();

    const onSubmit = useCallback((values: CreateOrderForm) => {
        const data = cloneDeep(values);

        if (data.image_before) {
            data.image_before = optimizeDataImage(data.image_before);
        }

        if (data.image_after) {
            data.image_after = optimizeDataImage(data.image_after);
        }

        forEach(data, (value, field) => {
            if (!orderFormFields.includes(field)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                delete data[field];
            }
        });

        data.form = OrderFormType.formula;
        data.formType = OrderType.lamination;
        data.brand = values.brand ? values.brand : 'other';
        data.isbrand = data.brand === 'other' ? 1 : 0;

        if (values.id) {
            if (values.status === Status.errorImages) data.status = Status.new;
            if (values.shade === 'no') data.shade_text = '';
            put(editOrder(data));
        } else {
            put(createOrder(data));
        }
    }, []);

    const goToOrdersPage = useCallback(() => history.push(ROUTES.MAIN.path), []);

    if (infoLoading || ordersLoading.getDetail) {
        return (
            <Layout>
                <Spin size="large" spinning={ true } />
            </Layout>
        );
    }

    const isEditDisabled = !!order && order?.status !== Status.new && order?.status !== Status.errorImages && order?.status !== Status.notFilledFields;
    const isChangeImageDisabled = !!order && order?.status !== Status.new && order?.status !== Status.errorImages && order?.status !== Status.notFilledFields;

    const buttonText = order?.status === Status.errorImages ? 'Сохранить и отправить на проверку' : 'Сохранить';
    const imageErrors = getImageErrors(order?.image_before_error, order?.image_after_error, styles.imageError);

    return (
        <Formik<CreateOrderForm>
            key="create-order"
            initialValues={ getInitialValues(initialValues, order as Order) }
            onSubmit={ onSubmit }
            validationSchema={ schema }
        >
            { ({
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                errors,
                touched,
            }) => {
                const errorList = {
                    formType: getError(errors, touched, true, 'formType'),
                    image_before: getError(errors, touched, true, 'image_before'),
                    image_after: getError(errors, touched, true, 'image_after'),
                    density: getError(errors, touched, true, 'density'),
                    shadeOfGloss: getError(errors, touched, true, 'shadeOfGloss'),
                    brand: getError(errors, touched, true, 'brand'),
                    shade: getError(errors, touched, true, 'shade'),
                    shade_text: getError(errors, touched, true, 'shade_text'),
                    grayHair: getError(errors, touched, true, 'grayHair'),
                    hairType: getError(errors, touched, true, 'hairType'),
                    lastTimePainted: getError(errors, touched, true, 'lastTimePainted'),
                    password: getError(errors, touched, true, 'password'),
                    theMainProblem: getError(errors, touched, true, 'theMainProblem'),
                    theMainProblemImg: getError(errors, touched, true, 'theMainProblemImg'),
                };

                const onChangeFile = (name: string) => (file: any) => {
                    setFieldValue(name, file, true);
                };

                return (
                    <Form
                        onFinish={ handleSubmit }
                        labelAlign="left"
                        layout="vertical"
                        className={ styles.creatingOrder }
                    >
                        { imageErrors.any.message }
                        <Form.Item
                            label={ getLabel('Загрузите 2 фотографии ваших волос') }
                            className={ styles.formItem }
                            extra={ errorList.image_before || imageErrors.before.message }
                            validateStatus={ getErrorStatus(!!errorList.image_before) }
                            tooltip={ HELPER_TEXT.ORDER_IMAGE_TOOLTIP }
                        >
                            <UploadFile
                                onChange={ onChangeFile('image_before') }
                                count={ 2 }
                                defaultValue={ values.image_before as Image[] }
                                disabled={ isChangeImageDisabled }
                            />
                        </Form.Item>

                        <Form.Item
                            label={ getLabel('Опишите ваши волосы(выбрав по 1 из вариантов)') }
                            className={ styles.formItem }
                            validateStatus={ getErrorStatusForHairType(values.hairType[0], !!errorList.hairType) }
                            extra={ getErrorForHairType(values.hairType[0], errorList.hairType) }
                        >
                            <Radio.Group
                                size="large"
                                buttonStyle="solid"
                                name="hairType[0]"
                                defaultValue={ values.hairType[0] }
                                disabled={ isEditDisabled }
                            >
                                { info?.hairType?.option1?.map(
                                    (item) => (
                                        <Radio.Button
                                            value={ item.value }
                                            onChange={ handleChange }
                                        >
                                            { item.name }
                                        </Radio.Button>
                                    ),
                                ) }
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            className={ styles.formItem }
                            validateStatus={ getErrorStatusForHairType(values.hairType[1], !!errorList.hairType) }
                            extra={ getErrorForHairType(values.hairType[1], errorList.hairType) }
                        >
                            <Radio.Group
                                name="hairType[1]"
                                size="large"
                                buttonStyle="solid"
                                defaultValue={ values.hairType[1] }
                                disabled={ isEditDisabled }
                            >
                                { info?.hairType?.option2?.map(
                                    (item) => (
                                        <Radio.Button
                                            value={ item.value }
                                            onChange={ handleChange }
                                        >
                                            { item.name }
                                        </Radio.Button>
                                    ),
                                ) }
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            className={ styles.formItem }
                            validateStatus={ getErrorStatusForHairType(values.hairType[2], !!errorList.hairType) }
                            extra={ getErrorForHairType(values.hairType[2], errorList.hairType) }
                        >
                            <Radio.Group
                                name="hairType[2]"
                                size="large"
                                buttonStyle="solid"
                                defaultValue={ values.hairType[2] }
                                disabled={ isEditDisabled }
                            >
                                { info?.hairType?.option3?.map(
                                    (item) => (
                                        <Radio.Button
                                            value={ item.value }
                                            onChange={ handleChange }
                                        >
                                            { item.name }
                                        </Radio.Button>
                                    ),
                                ) }
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label={ getLabel('Какой оттенок блеска хотите получить') }
                            className={ styles.formItem }
                            extra={ errorList.shadeOfGloss }
                            validateStatus={ getErrorStatus(!!errorList.shadeOfGloss) }
                        >
                            <Radio.Group
                                name="shadeOfGloss"
                                size="large"
                                buttonStyle="solid"
                                defaultValue={ values.shadeOfGloss }
                                disabled={ isEditDisabled }
                            >
                                { info?.shadeOfGloss?.map(
                                    (item) => (
                                        <Radio.Button
                                            value={ item.value }
                                            onChange={ handleChange }
                                        >
                                            { item.name }
                                        </Radio.Button>
                                    ),
                                ) }
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label={ getLabel('Оцените густоту') }
                            className={ styles.formItem }
                            extra={ errorList.density }
                            validateStatus={ getErrorStatus(!!errorList.density) }
                        >
                            <Radio.Group
                                name="density"
                                size="large"
                                buttonStyle="solid"
                                defaultValue={ values.density }
                                disabled={ isEditDisabled }
                            >
                                { info?.density?.map(
                                    (item) => (
                                        <Radio.Button
                                            value={ item.value }
                                            onChange={ handleChange }
                                        >
                                            { item.name }
                                        </Radio.Button>
                                    ),
                                ) }
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label={ getLabel('Бренд') }
                            className={ styles.formItem }
                            extra={ errorList.brand }
                            validateStatus={ getErrorStatus(!!errorList.brand) }
                        >
                            <Radio.Group
                                name="brand"
                                size="large"
                                buttonStyle="solid"
                                defaultValue={ values.brand }
                                disabled={ isEditDisabled }
                            >
                                <Space direction="vertical">
                                    { info?.brand?.map(
                                        (item) => (
                                            <Radio
                                                value={ item.value }
                                                key={ item.value }
                                                onChange={ handleChange }
                                            >
                                                { item.description ? (
                                                    <Tooltip placement="right" title={ item.description } trigger="hover">
                                                        { item.name }
                                                    </Tooltip>
                                                ) : item.name }
                                            </Radio>
                                        ),
                                    ) }
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <div className={ styles.creatingOrder_buttons }>
                            <Button
                                size="large"
                                className={ styles.return }
                                onClick={ goToOrdersPage }
                            >
                                Назад
                            </Button>
                            <Button
                                htmlType="submit"
                                type="primary"
                                loading={ ordersLoading.create || ordersLoading.edit }
                                size="large"
                            >
                                { buttonText }
                            </Button>
                        </div>
                    </Form>
                );
            } }
        </Formik>
    );
};
