import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Button, Pagination, Table, Typography,
} from 'antd';

import { getTickets } from 'ducks/tickets/actions';

import { selectProfile } from 'ducks/user/selectors';
import {
    selectTickets,
    selectTicketsFilter,
    selectTicketsLoading,
    selectTicketsPagination,
} from 'ducks/tickets/selectors';

import { Layout } from 'components/ui/layout';
import { CreateTicketModal } from 'components/create-ticket-modal';
import { TicketsFilter } from 'components/tickets-filter';

import { history } from 'App';

import { ROUTES } from 'constants/routes';
import { ACCESS } from 'constants/access';
import { checkAccess } from 'utils/access';

import { Ticket } from 'types/ticket';

import { Cart } from 'components/ui/cart';
import { getColumns } from './columns';

import styles from './styles.module.scss';

const { Title } = Typography;

export const Tickets = React.memo(() => {
    const put = useDispatch();
    const user = useSelector(selectProfile);
    const loading = useSelector(selectTicketsLoading);
    const list = useSelector(selectTickets);
    const pagination = useSelector(selectTicketsPagination);
    const filter = useSelector(selectTicketsFilter);

    const [visibleModal, setVisibleModal] = React.useState(false);

    useEffect(() => {
        put(getTickets({ pagination: { page: 1 } }));
    }, []);

    const isCreateAllow = checkAccess(user?.access_list, ACCESS.CREATE_SUPPORT);

    const columns = useMemo(() => getColumns(user!.id), [user!.id]);

    const handleChangeVisibleModal = (value: boolean) => () => setVisibleModal(value);

    const onClickRow = useCallback((data: Ticket) => () => {
        history.push(ROUTES.VIEW_TICKET.getPath({ id: data.id }));
    }, []);

    const onChangePagination = useCallback((page: number) => {
        put(getTickets({ pagination: { page }, filter }));
    }, [filter]);

    return (
        <Layout>
            <div className={ styles.header }>
                <Title level={ 2 }>Техподдержка</Title>
                { isCreateAllow && (
                    <Button
                        type="primary"
                        onClick={ handleChangeVisibleModal(true) }
                    >
                        Создать обращение
                    </Button>
                ) }
            </div>
            <TicketsFilter />
            <Cart
                footer={ (
                    <div className={ styles.pagination }>
                        <Pagination
                            total={ pagination.total * pagination.pageSize }
                            pageSize={ pagination.pageSize }
                            current={ pagination.current }
                            onChange={ onChangePagination }
                        />
                    </div>
                ) }
            >
                <Table
                    loading={ loading.get }
                    dataSource={ list }
                    rowKey="id"
                    pagination={ false }
                    className={ styles.table }
                    columns={ columns }
                    onRow={ (record: any) => ({
                        onClick: onClickRow(record),
                    }) }
                />
            </Cart>
            { visibleModal && <CreateTicketModal onClose={ handleChangeVisibleModal(false) } /> }
        </Layout>
    );
});
