import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Typography,
    Table,
    Pagination,
    Select,
    Form,
} from 'antd';

import { getUsers } from 'ducks/user/actions';

import {
    selectUsers,
    selectUsersPagination,
    selectUserLoading,
    selectUsersSort,
} from 'ducks/user/selectors';

import { Layout } from 'components/ui/layout';

import { ROUTES } from 'constants/routes';
import { history } from 'App';

import { Cart } from 'components/ui/cart';
import { columns } from './columns';
import styles from './styles.module.scss';

const { Title } = Typography;
const { Option } = Select;

export const Users = React.memo(() => {
    const put = useDispatch();
    const loading = useSelector(selectUserLoading);
    const pagination = useSelector(selectUsersPagination);
    const sort = useSelector(selectUsersSort);
    const users = useSelector(selectUsers);

    useEffect(() => {
        put(getUsers({ pagination: { page: 1 } }));
    }, []);

    const onChangePagination = (page: number) => {
        put(getUsers({ pagination: { page } }));
    };

    const onClickRow = useCallback((data: any) => () => {
        history.push(ROUTES.USER.getPath({ id: data.id }));
    }, []);

    const onChangeSort = (field: string) => (value: string) => {
        put(getUsers({
            pagination: {
                page: 1,
            },
            sort: {
                ...sort,
                [field]: value,
            },
        }));
    };

    return (
        <Layout>
            <Title level={ 2 }>Список пользователей</Title>
            <div className={ styles.sort }>
                <div className={ styles.sortItem }>
                    <Form.Item
                        label="Сортировать по:"
                    >
                        <Select
                            placeholder="Выберите..."
                            onChange={ onChangeSort('sortField') }
                            defaultValue={ sort.sortField }
                        >
                            <Option value="createDate">Дате регистрации</Option>
                            <Option value="lastDateCreateOrders">Дате последнего заказа</Option>
                            <Option value="countOrders">Количеству заказов</Option>
                        </Select>
                    </Form.Item>
                </div>
                <div className={ styles.sortItem }>
                    <Form.Item
                        label="Порядок сортировки:"
                    >
                        <Select
                            placeholder="Выберите..."
                            onChange={ onChangeSort('sortOrder') }
                            defaultValue={ sort.sortOrder }
                        >
                            <Option value="desc">По убыванию</Option>
                            <Option value="asc">По возрастанию</Option>
                        </Select>
                    </Form.Item>
                </div>
            </div>
            <Cart
                footer={ (
                    <div className={ styles.pagination }>
                        <Pagination
                            total={ pagination.total * pagination.pageSize }
                            pageSize={ pagination.pageSize }
                            current={ pagination.page }
                            onChange={ onChangePagination }
                        />
                    </div>
                ) }
            >
                <Table
                    loading={ loading.getList }
                    className={ styles.table }
                    dataSource={ users }
                    columns={ columns }
                    pagination={ false }
                    onRow={ (record: any) => ({
                        onClick: onClickRow(record),
                    }) }
                />
            </Cart>
        </Layout>
    );
});
