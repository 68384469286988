import React, {
    useCallback,
    useState,
} from 'react';

import {
    DatePicker,
    Button,
    Space,
} from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { getStatistics } from 'ducks/statistics/actions';
import { selectStatisticsLoading } from 'ducks/statistics/selectors';

import { Filter } from 'components/ui/filter';
import styles from './styles.module.scss';

const { RangePicker } = DatePicker;

export const StatisticsFilter = React.memo(() => {
    const put = useDispatch();
    const loading = useSelector(selectStatisticsLoading);

    const [filter, setFilter] = useState({});

    const handleChangeFilter = useCallback((name: string) => (value: any) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    }, []);

    const handleResetFilter = useCallback(() => {
        setFilter({});
        put(getStatistics({ filter: {} }));
    }, []);

    const applyFilter = useCallback(() => {
        put(getStatistics({ filter }));
    }, [filter]);

    return (
        <Filter
            extra={ (
                <Space size="middle">
                    <Button
                        type="link"
                        onClick={ handleResetFilter }
                        loading={ loading }
                    >
                        Сбросить
                    </Button>
                    <Button
                        type="primary"
                        onClick={ applyFilter }
                        loading={ loading }
                    >
                        Искать
                    </Button>
                </Space>
            ) }
        >
            <div className={ styles.filterItems }>
                <div className={ styles.filterDate }>
                    <RangePicker
                        placeholder={ ['Дата от', 'Дата до'] }
                        allowClear={ true }
                        onChange={ handleChangeFilter('dateCreate') }
                    />
                </div>
            </div>
        </Filter>
    );
});
