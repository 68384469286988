import { ACCESS } from './access';

export const ROUTES: any = {
    HOME: {
        path: '/',
        exact: true,
        title: 'Главная страница',
    },

    DELIVERY_COMPONENTS: {
        path: '/delivery-components',
        exact: true,
        title: 'Заказать компоненты',
    },
    COOKIE: {
        path: '/cookie',
        exact: true,
        title: 'cookie',
    },
    CAREER: {
        path: '/career',
        exact: true,
        title: 'Карьера',
    },

    BLOG: {
        path: '/blog',
        title: 'Блог',
        exact: true,
    },

    BLOG_DETAIL: {
        path: '/blog/:id',
        exact: true,
        getPath: ({ id }: any) => ROUTES.EDIT_ORDER.path.replace(':id', id),
        title: 'Детальная страница блога',
    },

    MAIN: {
        path: '/order',
        exact: true,
        private: true,
        access: ACCESS.VIEW_ORDER,
        showInMenu: true,
        title: 'Заявки',
    },
    AUTH: {
        path: '/auth',
        exact: true,
        title: 'Авторизация',
    },
    REGISTRATION: {
        path: '/registration',
        exact: true,
        title: 'Регистрация',
    },
    PASSWORD_RECOVERY: {
        path: '/recovery',
        exact: true,
        title: 'Восстановление пароля',
    },
    CREATE_ORDER: {
        path: '/create-order',
        exact: true,
        private: true,
        access: ACCESS.CREATE_ORDER,
        title: 'Создание заявки',
    },
    EDIT_ORDER: {
        path: '/order/:id',
        exact: true,
        private: true,
        getPath: ({ id }: any) => ROUTES.EDIT_ORDER.path.replace(':id', id),
        access: ACCESS.UPDATE_ORDER,
        title: 'Редактирование заявки',
    },
    VIEW_ORDER: {
        path: '/order/:id/view',
        exact: true,
        private: true,
        getPath: ({ id }: any) => ROUTES.VIEW_ORDER.path.replace(':id', id),
        access: ACCESS.VIEW_ORDER,
        title: 'Просмотр заявки',
    },
    ORDER_FEEDBACK: {
        path: '/order-feedback',
        exact: true,
        private: true,
        showInMenu: true,
        access: ACCESS.VIEWS_USER,
        title: 'Успешные формулы',
    },
    ABOUTSERVICE: {
        path: '/about-service',
        exact: true,
        private: true,
        title: 'О сервисе',
    },
    HOWITWORKS: {
        path: '/how-it-works',
        exact: true,
        private: true,
        title: 'Как это работает',
    },
    WHATISTHEADVANTAGE: {
        path: '/whats-the-advantage',
        exact: true,
        private: true,
        title: 'В чем преимущество',
    },
    PROMOCODE: {
        path: '/promocode',
        exact: true,
        private: true,
        access: ACCESS.VIEW_PROMOCODE,
        title: 'Промокоды',
        showInMenu: true,
    },
    PRICE: {
        path: '/price',
        exact: true,
        private: true,
        access: ACCESS.VIEW_PRICE,
        title: 'Цены',
        showInMenu: true,
    },
    REVIEWS: {
        path: '/reviews',
        exact: true,
        private: true,
        access: ACCESS.VIEW_REVIEWS,
        title: 'Отзывы',
        showInMenu: true,
    },
    TICKETS: {
        path: '/tickets',
        exact: true,
        private: true,
        access: ACCESS.VIEW_SUPPORT,
        title: 'Техподдержка',
        showInMenu: true,
    },
    VIEW_TICKET: {
        path: '/tickets/:id',
        exact: true,
        private: true,
        getPath: ({ id }: any) => ROUTES.VIEW_TICKET.path.replace(':id', id),
        access: ACCESS.VIEW_SUPPORT,
        title: 'Просмотр вопроса к техподдержке',
    },
    VIEW_STATISTICS: {
        path: '/statistics',
        exact: true,
        private: true,
        access: ACCESS.VIEW_STATIC,
        title: 'Просмотр статистики',
        showInMenu: true,
    },
    PROFILE: {
        path: '/profile',
        exact: true,
        private: true,
        title: 'Профиль',
    },
    USERS: {
        path: '/users',
        exact: true,
        private: true,
        title: 'Пользователи',
        showInMenu: true,
        access: ACCESS.VIEWS_USER,
    },
    USER: {
        path: '/users/:id',
        exact: true,
        private: true,
        title: 'Пользователи',
        getPath: ({ id }: any) => ROUTES.USER.path.replace(':id', id),
        access: ACCESS.VIEWS_USER,
    },
};

export const ROUTES_KEYS = Object.keys(ROUTES);

export const MENU = [
    { ...ROUTES.MAIN },
    { ...ROUTES.VIEW_ORDER },
    { ...ROUTES.PROMOCODE },
    { ...ROUTES.TICKETS },
    { ...ROUTES.REVIEWS },
    {
        path: '/admin',
        exact: false,
        title: 'Админ панель',
        private: true,
        showInMenu: true,
        access: ACCESS.VIEW_ADMINMENU,
        children: [
            { ...ROUTES.PRICE },
            { ...ROUTES.USERS },
            { ...ROUTES.ORDER_FEEDBACK },
            { ...ROUTES.VIEW_STATISTICS },
        ],
    },
];
