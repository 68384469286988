import React, { useMemo, useState } from 'react';
import { Badge } from 'antd';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectProfile } from 'ducks/user/selectors';
import { selectCountNewMessage } from 'ducks/tickets/selectors';

import { MENU } from 'constants/routes';

import logo from 'assets/logo.svg';
import cn from 'classnames';
import { Profile } from './profile';
import styles from './styles.module.scss';

function getMenu(root: any, user: any, countNewMessage = 0) {
    const menu = [];

    for (let i = 0; i < root.length; i++) {
        const el = root[i];

        const isTiket = el.path === '/tickets' && countNewMessage;

        if (el.showInMenu && el.private && user && (el.access ? user?.access_list.includes(el.access) : true)) {
            if (el.children) {
                const children = getMenu(el.children, user);

                menu.push(
                    <li key={ i }>
                        <div className={ styles.menu__header }>{ el.title }</div>
                        <ul className={ styles.menu__sub } key={ i }>{ children }</ul>
                    </li>,
                );
            } else if (isTiket) {
                menu.push(<li key={ i }><NavLink to={ el.path } activeClassName={ styles.menu__active } className={ styles.menu__link }><Badge count={ countNewMessage }>{ el.title }</Badge></NavLink></li>);
            } else {
                menu.push(<li key={ i }><NavLink to={ el.path } activeClassName={ styles.menu__active } className={ styles.menu__link }>{ el.title }</NavLink></li>);
            }
        }
    }

    return menu;
}

export const Header = () => {
    const user = useSelector(selectProfile);
    const countNewMessage = useSelector(selectCountNewMessage);
    const [open, setOpen] = useState(false);

    const toggleMenu = () => setOpen(!open);

    const styleMenuMobile = cn({ [styles.menu__open]: open });
    const styleMenuBtn = cn(styles.menu__btn, { [styles.menu__btn_active]: open });

    const routes = useMemo(() => getMenu(MENU, user, countNewMessage), [user]);

    return (
        <div className={ styles.header }>
            <div className={ styles.content }>
                <a href="/" className={ styles.logo }><img src={ logo } alt="logo" /></a>
                <div className={ styles.fline } />
                <nav className={ styles.menu }>
                    <button
                        type="button"
                        className={ styleMenuBtn }
                        onClick={ toggleMenu }
                    >
                        <span />
                    </button>
                    <ul className={ styleMenuMobile }>
                        { routes }
                    </ul>
                </nav>
                <Profile />
            </div>
        </div>
    );
};
