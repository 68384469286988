import * as yup from 'yup';

import { REQUIRED_FIELD, IS_EMAIL } from 'form-helpers/message';

export const schema = yup.object().shape({
    id: yup.string().required(REQUIRED_FIELD).default(''),
    firstName: yup.string().required(REQUIRED_FIELD).default(''),
    lastName: yup.string().required(REQUIRED_FIELD).default(''),
    phone: yup.string().required(REQUIRED_FIELD).default(''),
    email: yup.string().email(IS_EMAIL).required(REQUIRED_FIELD).default(''),
    password: yup.string().test('isEqual', REQUIRED_FIELD, function validate(value) {
        if (this.parent.newPassword) {
            return Boolean(value);
        }

        return true;
    }).default(''),
    newPassword: yup.string().default(''),
    newPasswordReply: yup.string().test('isEqual', 'Пароли не совпадают', function validate(value) {
        if (this.parent.newPassword) {
            return this.parent.newPassword === value;
        }

        return true;
    }).default(''),

    avatar: yup.mixed().test('isRequired', 'Загрузите изображение', (value) => {
        const isIssetAnotherFormat = value && value.type ? !(value.type as string).includes('image') : false;

        return value ? !isIssetAnotherFormat : true;
    }),
});
