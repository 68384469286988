import React from 'react';
import {
    Typography,
} from 'antd';
import { ErrorMessage } from 'components/ui/error-message';
import { Status } from 'types/status';
import { ExpertForm } from 'form-helpers/expert-form-coloringtinting/types';

const { Title } = Typography;

export const getImageErrors = (
    before_error: number | undefined,
    after_error: number | undefined,
    className?: string,
) => ({
    any: {
        isError: before_error || after_error,
        message: before_error || after_error ? (
            <Title level={ 5 }>
                <ErrorMessage text="Мастер вернул заявку из-за плохих фотографий" />
            </Title>
        ) : null,
    },
    before: {
        isError: before_error,
        message: before_error ? (
            <ErrorMessage className={ className } text="Плохое качество фото. Загрузите новые и отправьте на проверку" />
        ) : null,
    },
    after: {
        isError: after_error,
        message: after_error ? (
            <ErrorMessage className={ className } text="Плохое качество фото. Загрузите новые и отправьте на проверку" />
        ) : null,
    },
});

export const checkEditExpertField = (
    access_list: string[],
    status: Status,
    userId: number,
    managerId: number,
    input: React.ReactNode,
    infoBlock: React.ReactNode,
) => (
    access_list.includes('UPDATE_EXPERT_FIELDS_ORDER')
    && status === Status.inOperation
    && userId === managerId
        ? input
        : infoBlock
);

export const getGrayHairKoef = (grayHair: string) => {
    switch (grayHair) {
        case 'no':
        case 'min30':
            return 0;

        case '30_50':
            return 0.25;

        case 'more50':
            return 0.5;
    }

    return 0;
};

export const getCalculatedWeight = (values: ExpertForm) => {
    const grayHairKoef = getGrayHairKoef(values.grayHair);
    const weightColorsNaturalRoots = grayHairKoef * values.weightRoots;
    const weightColorsEnhancerRoots = values.weightRoots - weightColorsNaturalRoots - values.weightDesiredColorRoots;

    const lengthGrayHairKoef = getGrayHairKoef(values.lengthGrayHair);
    const weightLength = values.weight - values.weightRoots;
    const weightNaturalColorsLength = lengthGrayHairKoef * weightLength;
    const weightDesiredColorsLength = weightLength - values.weightDesiredColorLength - weightNaturalColorsLength;

    return {
        roots: {
            grayHairKoef,
            weightRoots: values.weightRoots,
            natural: weightColorsNaturalRoots,
            desired: values.weightDesiredColorRoots,
            enhancer: weightColorsEnhancerRoots,
            oxidizer: values.weightRoots * values.oxidizerWeightRootsRatio,
        },
        length: {
            weightLength,
            grayHairKoef: lengthGrayHairKoef,
            natural: weightNaturalColorsLength,
            desired: values.weightDesiredColorLength,
            enhancer: weightDesiredColorsLength,
            oxidizer: weightLength * values.oxidizerWeightLengthRatio,
        },
    };
};
