import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFaq } from 'ducks/faq/actions';
import { selectFaqPagination } from 'ducks/faq/selectors';

export const FaqButton = () => {
    const put = useDispatch();
    const faqPagination = useSelector(selectFaqPagination);
    const [visible, setvisible] = React.useState(true);

    const nextList = () => {
        const col = Number(+faqPagination.page + 1);

        if (faqPagination.total !== undefined && col >= faqPagination.total) {
            setvisible(false);
        }

        if (faqPagination.total !== undefined && col <= faqPagination.total) {
            put(getFaq({ pagination: { page: col } }));
        }
    };

    return visible ? <button type="button" onClick={ () => nextList() } className="btn btn--transparent">Узнать больше</button> : <div />;
};
