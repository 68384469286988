import React from 'react';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import set from 'lodash/set';
import { Formik } from 'formik';
import {
    Select,
    Typography,
    Form,
    Row,
    Col,
    Input,
    Radio,
} from 'antd';
import { sendExpertOrderForm } from 'ducks/orders/actions';
import { selectColors, selectInfo, selectLaminationInstructions } from 'ducks/info/selectors';
import { selectOrder, selectOrdersLoading } from 'ducks/orders/selectors';
import { selectProfile } from 'ducks/user/selectors';

import { Order, OrderBrandText } from 'types/orders';
import { ExpertOrderFormPayload, GetColorsResponse, Instruction } from 'types/api';

import { ExpertForm } from 'form-helpers/expert-form-lamination/types';
import { getInitialValues, expertFields } from 'form-helpers/expert-form-lamination/mapping';
import { checkEditExpertField } from 'utils/orders';
import { CONDITIONERS } from 'constants/order';

import { Status } from 'types/status';
import { ViewExpertForm } from './view';

import styles from './styles.module.scss';

const { Option } = Select;
const { Title } = Typography;

const colProps = {
    lg: 11,
    md: 11,
    sm: 11,
    xs: 24,
};

export const OrderExpertFormLamination = () => {
    const put = useDispatch();
    const colors = useSelector(selectColors) as GetColorsResponse;
    const order = useSelector(selectOrder) as Order;
    const info = useSelector(selectInfo);
    const user = useSelector(selectProfile);
    const { sendExpertForm } = useSelector(selectOrdersLoading);
    const instructions = useSelector(selectLaminationInstructions) as Instruction[];

    const handleUpdateExpertForm = (values: any) => {
        const onlyFields: { [key: string]: any } = {};

        Object.keys(values).forEach((key) => {
            if (expertFields.includes(key)) {
                onlyFields[key] = values[key];
            }
        });

        put(sendExpertOrderForm({
            id: order.id,
            ...onlyFields,
        } as ExpertOrderFormPayload));
    };

    return (
        <div className={ styles.container }>
            <Formik<ExpertForm>
                initialValues={ getInitialValues(order, colors, instructions) }
                onSubmit={ () => { } }
            >
                { ({
                    values,
                    setValues,
                    setFieldValue,
                }) => {
                    const brandDefault = ['schwarzkopfProf', 'kapousProf', 'keune'];
                    const colorantType = brandDefault.includes(values.brand) ? 'wella' : values.brand;

                    const updateForm = (newValues?: any) => {
                        handleUpdateExpertForm(newValues || values);
                    };

                    const onChangeSelect = (name: string) => (value: any) => {
                        let newValues = { ...values };

                        set(newValues, name, value);

                        if (name === 'subTypeColor') {
                            const currentColorSubtype = colors[colorantType].color.find((item: any) => item.id === value);

                            newValues = {
                                ...values,
                                colorsList: currentColorSubtype!.list,
                                subTypeColorName: currentColorSubtype!.name,
                                colors: {
                                    natural: null,
                                    desired: null,
                                    enhancer: null,
                                },
                            };
                            setValues(newValues);
                        }

                        if (name === 'subTypeOxidizer') {
                            const currentColorSubtype = colors[colorantType].oxidizer.find((item: any) => item.id === value);

                            newValues = {
                                ...values,
                                oxidizerList: currentColorSubtype!.list,
                                subTypeOxidizerName: currentColorSubtype!.name,
                                oxidizer: '',
                            };

                            setValues(newValues);
                        }

                        setFieldValue(name, value);
                        updateForm(newValues);
                    };

                    const onChangeWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
                        const { name, value } = e.target;

                        setValues({
                            ...values,
                            [name]: value ? +value : '',
                            conditioner: +(+value / 3).toFixed(0),
                        });
                    };

                    const onChangeBrand = (e: any) => {
                        const { name, value } = e.target;

                        const updValues = {
                            ...values,
                            subTypeOxidizer: 0,
                            subTypeOxidizerName: '',
                            oxidizer: 0,
                            oxidizerName: '',
                            oxidizerList: [],
                            subTypeColor: 0,
                            subTypeColorName: '',
                            colors: {
                                natural: null,
                                desired: null,
                                enhancer: null,
                            },
                            isBrand: 0,
                            colorsName: '',
                            colorsList: [],
                            [name]: value,
                        };

                        setValues(updValues);
                        updateForm(updValues);
                    };

                    const subTypeColorOptions = colors[colorantType]
                        ? colors[colorantType].color.map((item: any) => <Option value={ item.id }>{ item.name }</Option>)
                        : null;

                    const oxidizerSubtypeOptions = colors[colorantType]
                        ? colors[colorantType].oxidizer.map((item: any) => <Option value={ item.id }>{ item.name }</Option>)
                        : null;

                    const weightCalculated = values.weight ? (values.weight / 3).toFixed(0) : '';

                    const instruction = instructions.find((item) => item.id === values.instruction);

                    const editable = (
                        <React.Fragment>
                            <Title level={ 3 }>{ OrderBrandText[values.brand] }</Title>
                            <Row>
                                <Col { ...colProps }>
                                    <div className={ styles.itemGroup }>
                                        <Title level={ 5 }>Краситель</Title>
                                        <Form.Item
                                            className={ styles.formItem }
                                            label="Подтип"
                                        >
                                            <Select
                                                onChange={ onChangeSelect('subTypeColor') }
                                                value={ values.subTypeColor }
                                            >
                                                { subTypeColorOptions }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            className={ styles.formItem }
                                            label="Цвет"
                                        >
                                            <Select
                                                onChange={ onChangeSelect('colors.natural') }
                                                value={ values.colors.natural || '' }
                                            >
                                                {
                                                    values.colorsList.map((item: any) => <Option value={ item.id }>{ item.color }</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                        <div>Масса красителя: { weightCalculated } гр.</div>
                                    </div>
                                    <div className={ styles.itemGroup }>
                                        <Title level={ 5 }>Окислитель</Title>
                                        <Form.Item
                                            className={ styles.formItem }
                                            label="Подтип"
                                        >
                                            <Select
                                                onChange={ onChangeSelect('subTypeOxidizer') }
                                                value={ values.subTypeOxidizer }
                                            >
                                                { oxidizerSubtypeOptions }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            className={ styles.formItem }
                                            label="Окислитель"
                                        >
                                            <Select
                                                onChange={ onChangeSelect('oxidizer') }
                                                value={ values.oxidizer }
                                            >
                                                {
                                                    values.oxidizerList.map((item: any) => <Option value={ item.id }>{ item.value }</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                        <div>Масса окислителя: { weightCalculated } гр.</div>
                                    </div>
                                </Col>
                                <Col lg={ 1 } md={ 1 } sm={ 1 } xs={ 24 } />
                                <Col { ...colProps }>
                                    <div className={ styles.itemGroup }>
                                        <Title level={ 5 }>Кондиционер</Title>
                                        <div>Кондиционер: { CONDITIONERS[values.brand] }</div>
                                        <div>Масса кондиционера: { weightCalculated } гр.</div>
                                    </div>
                                    <div className={ styles.itemGroup }>
                                        <Title level={ 5 }>Масса</Title>
                                        <Form.Item
                                            className={ styles.formItem }
                                            label="Масса суммарная (гр.)"
                                        >
                                            <Input
                                                type="number"
                                                value={ values.weight }
                                                placeholder="Масса суммарная (гр.)"
                                                name="weight"
                                                onChange={ onChangeWeight }
                                                onBlur={ () => updateForm() }
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col { ...colProps }>
                                    <Title level={ 5 }>Инструкция</Title>
                                    <Form.Item
                                        className={ styles.formItem }
                                    >
                                        <Select
                                            value={ values.instruction }
                                        >
                                            {
                                                instructions.map((item: Instruction) => <Option value={ item.id }>{ item.name }</Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </React.Fragment>
                    );

                    return (
                        <Form
                            labelAlign="left"
                            layout="vertical"
                            className={ cn({ [styles.formLoading]: sendExpertForm }) }
                        >
                            {
                                values.isbrand ? (
                                    checkEditExpertField(
                                        user!.access_list,
                                        order.status,
                                        user!.id,
                                        order.expert_id,
                                        <React.Fragment>
                                            <div className={ styles.itemGroup }>
                                                <Title level={ 5 }>Бренд</Title>
                                                <Radio.Group
                                                    name="brand"
                                                    size="large"
                                                    buttonStyle="solid"
                                                    value={ values.brand }
                                                >
                                                    { info?.brand?.filter((item) => item.value !== 'other')
                                                        .map((item) => (
                                                            <Radio.Button
                                                                value={ item.value }
                                                                onChange={ onChangeBrand }
                                                            >
                                                                { item.name }
                                                            </Radio.Button>
                                                        )) }
                                                </Radio.Group>
                                            </div>
                                        </React.Fragment>,
                                        <div className={ styles.itemGroup }>
                                            <Title level={ 5 }>Бренд</Title>
                                            <div>Бренд: { values.brand }</div>
                                        </div>,
                                    )
                                ) : null
                            }
                            {
                                order.status === Status.completed
                                    ? <ViewExpertForm values={ values } instruction={ instruction } />
                                    : checkEditExpertField(
                                        user!.access_list,
                                        order.status,
                                        user!.id,
                                        order.expert_id,
                                        editable,
                                        null,
                                    )
                            }

                        </Form>
                    );
                } }
            </Formik>
        </div>
    );
};
