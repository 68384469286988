import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReviewsLending } from 'ducks/reviews/actions';
import { selectReviewsPagination } from 'ducks/reviews/selectors';

export const ReviewsButton = () => {
    const put = useDispatch();
    const reviewsPagination = useSelector(selectReviewsPagination);
    const [isButton, setIsButton] = React.useState(true);
    const nextList = () => {
        const col = Number(+reviewsPagination.page + 1);

        if (reviewsPagination.total !== undefined && col >= reviewsPagination.total) {
            setIsButton(false);
        }

        if (reviewsPagination.total !== undefined && col <= reviewsPagination.total) {
            put(getReviewsLending({ pagination: { page: col } }));
        }
    };

    return isButton ? <button type="button" onClick={ () => nextList() } className="btn btn--transparent">Показать ещё</button> : <div />;
};
