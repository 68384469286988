import React from 'react';
import moment from 'moment';
import {
    OrderTypeBrand,
    OrderBrandText,
    OrderShadeTypeText,
    OrderShadeType,
    OrderFeedbackTypeText,
    OrderFeedbackType,
} from 'types/orders';

import { DATE_FORMAT } from 'constants/date';
import { StatusColor } from 'types/status';
import styles from './styles.module.scss';

export const getColumns = () => {
    const columns: any = [
        {
            title: '№',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: 'Заказ',
            dataIndex: 'orderId',
            key: 'orderId',
            align: 'center',
            render: (value: any) => value,
        },
        {
            title: 'Бренд',
            dataIndex: 'brand',
            key: 'brand',
            render: (value: OrderTypeBrand) => (OrderBrandText[value] ? OrderBrandText[value] : 'не указан'),
        },
        {
            title: 'Корни',
            dataIndex: 'rootTone',
            key: 'rootTone',
            render: (value: any) => <span>{ value } / 12</span>,
        },
        {
            title: 'Длина',
            dataIndex: 'lengthTone',
            key: 'lengthTone',
            render: (value: any) => <span>{ value } / 12</span>,
        },
        {
            title: 'Оттенок',
            dataIndex: 'shade',
            key: 'shade',
            render: (value: OrderShadeType) => OrderShadeTypeText[value],
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: (value: OrderFeedbackType) => <span className={ styles.status } style={ { background: StatusColor[value] } }>{ OrderFeedbackTypeText[value] }</span>,
        },
        {
            title: 'Дата',
            dataIndex: 'createAt',
            key: 'createAt',
            render: (value: string) => moment(value).format(DATE_FORMAT.DATE_TIME),
        },
    ];

    return columns;
};
