import React from 'react';
import {
    Select,
    Form,
    Row,
    Col,
    Input,
    Radio,
} from 'antd';

import { ExpertForm } from 'form-helpers/expert-form-coloringtinting/types';
import { GetColorsResponse } from 'types/api';

import { oxidizerProporcial } from 'form-helpers/expert-form-coloringtinting/mapping';
import { getCalculatedWeight } from 'utils/orders';
import { COLS } from 'constants/order';

import styles from './styles.module.scss';

const { Option } = Select;

type Props = {
    onChangeSelect: any;
    values: ExpertForm;
    colors: GetColorsResponse;
    onChangeNumber: any;
    updateForm: any;
    onChangeRadio: any;
};

export const FirstRowEditable = ({
    onChangeSelect,
    values,
    colors,
    updateForm,
    onChangeNumber,
    onChangeRadio,
}: Props) => {
    const colorantType = values.brand;

    const subTypeColorOptions = colors[colorantType]
        ? colors[colorantType].color.map((item: any) => <Option value={ item.id }>{ item.name }</Option>)
        : null;

    const oxidizerSubtypeOptions = colors[colorantType]
        ? colors[colorantType].oxidizer.map((item: any) => <Option value={ item.id }>{ item.name }</Option>)
        : null;

    const { roots } = getCalculatedWeight(values);

    return (
        <Row>
            <Col className={ styles.cell } { ...COLS.middle }>
                <Form.Item
                    className={ styles.formItem }
                    label="Подтип красителя"
                >
                    <Select
                        onChange={ onChangeSelect('subTypeColor') }
                        value={ values.subTypeColor }
                    >
                        { subTypeColorOptions }
                    </Select>
                </Form.Item>
            </Col>
            <Col className={ styles.cell } { ...COLS.small }>
                <Form.Item
                    className={ styles.formItem }
                    label="Цвет"
                >
                    <Select
                        onChange={ onChangeSelect('colors.natural') }
                        value={ values.colors.natural || '' }
                        disabled={ !roots.grayHairKoef }
                    >
                        {
                            values.colorsList.map((item: any) => <Option value={ item.id }>{ item.color }</Option>)
                        }
                    </Select>
                </Form.Item>
                <div className={ styles.formItem }>Масса({ values.colorsName.natural }): { roots.natural } гр.</div>
            </Col>
            <Col className={ styles.cell } { ...COLS.small }>
                <Form.Item
                    className={ styles.formItem }
                    label="Цвет"
                >
                    <Select
                        onChange={ onChangeSelect('colors.desired') }
                        value={ values.colors.desired || '' }
                    >
                        {
                            values.colorsList.map((item: any) => <Option value={ item.id }>{ item.color }</Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    className={ styles.formItem }
                    label="Масса"
                >
                    <Input
                        size="small"
                        type="number"
                        value={ values.weightDesiredColorRoots }
                        placeholder="Масса"
                        name="weightDesiredColorRoots"
                        onChange={ onChangeNumber }
                        onBlur={ () => updateForm() }
                    />
                </Form.Item>
            </Col>
            <Col className={ styles.cell } { ...COLS.small }>
                <Form.Item
                    className={ styles.formItem }
                    label="Цвет"
                >
                    <Select
                        onChange={ onChangeSelect('colors.enhancer') }
                        value={ values.colors.enhancer || '' }
                    >
                        {
                            values.colorsList.map((item: any) => <Option value={ item.id }>{ item.color }</Option>)
                        }
                    </Select>
                </Form.Item>
                <div className={ styles.formItem }>Масса({ values.colorsName.enhancer }): { roots.enhancer } гр.</div>
            </Col>
            <Col className={ styles.cell } { ...COLS.small }>
                <Form.Item
                    className={ styles.formItem }
                    label="Масса на корни"
                >
                    <Input
                        size="small"
                        type="number"
                        value={ values.weightRoots }
                        placeholder="Масса на корни"
                        name="weightRoots"
                        onChange={ onChangeNumber }
                        onBlur={ () => updateForm() }
                        disabled={ !values.isLengthNeeded }
                    />
                </Form.Item>
            </Col>
            <Col className={ styles.cell } { ...COLS.small }>
                <Form.Item
                    className={ styles.formItem }
                    label="Масса суммарная (гр.)"
                >
                    <Input
                        size="small"
                        type="number"
                        value={ values.weight }
                        placeholder="Масса суммарная (гр.)"
                        name="weight"
                        onChange={ onChangeNumber }
                        onBlur={ () => updateForm() }
                    />
                </Form.Item>
            </Col>
            <Col className={ styles.cell } { ...COLS.big }>
                <Form.Item
                    className={ styles.formItem }
                    label="Подтип окислителя"
                >
                    <Select
                        onChange={ onChangeSelect('subTypeOxidizer') }
                        value={ values.subTypeOxidizer }
                    >
                        { oxidizerSubtypeOptions }
                    </Select>
                </Form.Item>
                <Form.Item
                    className={ styles.formItem }
                    label="Окислитель"
                >
                    <Select
                        onChange={ onChangeSelect('oxidizer') }
                        value={ values.oxidizer }
                    >
                        {
                            values.oxidizerList.map((item: any) => <Option value={ item.id }>{ item.value }</Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    className={ styles.formItem }
                    label={ `Масса окислителя на корни: ${values.weightRoots * values.oxidizerWeightRootsRatio} гр.` }
                >
                    <Radio.Group
                        name="oxidizerWeightRootsRatio"
                        size="small"
                        buttonStyle="solid"
                        value={ values.oxidizerWeightRootsRatio }
                    >
                        {
                            oxidizerProporcial.map((item) => (
                                <Radio.Button
                                    value={ item.value }
                                    onChange={ onChangeRadio }
                                >
                                    { item.text }
                                </Radio.Button>
                            ))
                        }
                    </Radio.Group>
                </Form.Item>
            </Col>
        </Row>
    );
};
