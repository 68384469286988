/* eslint-disable complexity */
import { InferValueTypes } from 'types/common';
import { TOrderFeedback } from 'types/orders';
import * as actions from './actions';
import { OrderFeedbackActionTypes } from './action-types';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type OrderFeedbackState = {
    loading: {
        get: boolean;
        edit: boolean;
    };
    list: TOrderFeedback[];
    pagination: {
        pageSize: number;
        total: number;
        current: number;
    };
    filter: {
        status?: string;
        brand?: string;
        createAt?: string;
        all?: number;
    };
    feedback: TOrderFeedback | null;
};

export const initialState: OrderFeedbackState = {
    loading: {
        get: false,
        edit: false,
    },
    list: [],
    pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
    },
    filter: {},
    feedback: null,
};

export function orderFeedbackReducer(state = initialState, action: ActionTypes): OrderFeedbackState {
    switch (action.type) {
        case OrderFeedbackActionTypes.GET_FEEDBACK_ORDERS_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
                filter: {
                    ...action.payload.filter,
                },
            };

        case OrderFeedbackActionTypes.GET_FEEDBACK_ORDERS_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                list: action.response.data,
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
            };
        //
        case OrderFeedbackActionTypes.GET_FEEDBACK_ORDERS_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };
        case OrderFeedbackActionTypes.FEEDBACK_ORDER_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
            };

        case OrderFeedbackActionTypes.FEEDBACK_ORDER_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                feedback: action.response,
            };
        //
        case OrderFeedbackActionTypes.FEEDBACK_ORDER_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case OrderFeedbackActionTypes.EDIT_FEEDBACK_ORDER_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: true,
                },
            };

        case OrderFeedbackActionTypes.EDIT_FEEDBACK_ORDER_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,
                },
                feedback: {
                    ...state.feedback,
                    ...action.response,
                },
            };
        //
        case OrderFeedbackActionTypes.EDIT_FEEDBACK_ORDER_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,
                },
            };

        case OrderFeedbackActionTypes.CLEAR_FEEDBACK:
            return {
                ...state,
            };

        default: {
            return state;
        }
    }
}
