import { put, call, select } from 'redux-saga/effects';
import {
    changeOrderStatus,
    changeOrderStatusError,
    changeOrderStatusFinish,
} from 'ducks/orders/actions';

import { selectProfile } from 'ducks/user/selectors';

import { fetchers } from 'api';

export function* changeOrderStatusRequest({ payload }: ReturnType<typeof changeOrderStatus>) {
    const user = yield select(selectProfile);

    try {
        yield call(fetchers.changeOrderStatus, payload);

        yield put(changeOrderStatusFinish(payload.status, user.id));
    } catch (error) {
        yield put(changeOrderStatusError());
    }
}
