import React from 'react';
import {
    IconWallet, IconCalendarDue, IconPencil, IconTrash,
} from '@tabler/icons-react';
import {
    Button,
    Popconfirm,
    Tooltip,
    Tag,
    Space,
} from 'antd';
import moment from 'moment';
import {
    OrderType,
    OrderTypeText,
    OrderTypeBrand,
    OrderBrandText,
    ShortOrder,
} from 'types/orders';
import { InfoResponse } from 'types/api';

import { DATE_FORMAT } from 'constants/date';
import { HELPER_TEXT } from 'constants/text';

import {
    AllowToEditStatuses,
    AllowToRemoveStatuses,
    StatusColor,
} from 'types/status';

import { getFio } from 'utils/formatters';
import styles from './styles.module.scss';

export const getColumns = (
    info: InfoResponse | null,
    actions: {
        goToEdit: any;
        handleDeleteOrder: any;
    },
    loading: {
        remove: boolean;
    },
    access: {
        isEditAllow: boolean;
        isDeleteAllow: boolean;
    },
    userId: number,
    isClient: boolean,
) => {
    const columns: any = [
        {
            title: 'Номер',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: 'Тип',
            dataIndex: 'formType',
            key: 'formType',
            align: 'center',
            render: (value: OrderType) => OrderTypeText[value],
        },
        {
            title: 'Тип красителя',
            dataIndex: 'brand',
            key: 'brand',
            render: (value: OrderTypeBrand) => (OrderBrandText[value] ? OrderBrandText[value] : 'не указан'),
        },
        {
            title: 'Статус',
            dataIndex: '',
            key: 'status',
            render: (data: ShortOrder) => (
                <span className={ styles.status } style={ { color: StatusColor[data.status] } }>
                    { info?.status?.find((item) => item.value === data.status)?.name }
                </span>
            ),
        },
        {
            title: (<IconWallet />),
            align: 'center',
            dataIndex: '',
            key: 'payment',
            render: (data: ShortOrder) => (
                <React.Fragment>
                    {
                        data.payment === 1
                            ? (<Tag style={ { marginLeft: 16 } } color="#49a57b">Оплачено</Tag>)
                            : (<Tag style={ { marginLeft: 16 } } color="#d05252">Не оплачено</Tag>)
                    }
                </React.Fragment>
            ),
        },
        {
            title: (<IconCalendarDue />),
            dataIndex: 'dateCreate',
            key: 'dateCreate',
            render: (value: string) => moment(value).format(DATE_FORMAT.DATE_TIME),
        },
    ];

    if (!isClient) {
        columns.push({
            title: 'Эксперт',
            dataIndex: '',
            key: 'expert',
            render: (data: ShortOrder) => (data.expert_id ? getFio(data.expert!.lastName, data.expert!.firstName) : null),
        });
    }

    columns.push({
        title: '',
        dataIndex: '',
        key: 'actions',
        render: (data: ShortOrder) => {
            const isEditOpen = AllowToEditStatuses.includes(data.status) && data.user_id === userId;
            const isRemoveOpen = AllowToRemoveStatuses.includes(data.status) && !data.payment && data.user_id === userId; // (data.expert_id ? data.expert_id === userId : true);

            const editTooltipText = isEditOpen ? null : HELPER_TEXT.EDIT_ORDER_DENIED;
            const removeTooltipText = isRemoveOpen ? null : HELPER_TEXT.REMOVE_ORDER_DENIED;

            return (
                <Space>
                    {
                        access.isEditAllow && (
                            <Tooltip
                                title={ editTooltipText }
                                trigger="hover"
                                placement="right"
                            >
                                <Button
                                    type="text"
                                    onClick={ (e) => actions.goToEdit(e, data.id) }
                                    disabled={ !isEditOpen }
                                >
                                    <IconPencil />
                                </Button>
                            </Tooltip>

                        )
                    }
                    {
                        access.isDeleteAllow && (
                            <Popconfirm
                                title="Вы уверены, что хотите удалить заказ?"
                                onConfirm={ (e: any) => actions.handleDeleteOrder(e, data.id) }
                                onCancel={ (e: any) => e.stopPropagation() }
                                okText="Да"
                                cancelText="Нет"
                            >
                                <Tooltip
                                    title={ removeTooltipText }
                                    trigger="hover"
                                    placement="right"
                                >
                                    <Button
                                        onClick={ (e) => e.stopPropagation() }
                                        type="text"
                                        danger={ true }
                                        disabled={ !isRemoveOpen }
                                        loading={ loading.remove }
                                    >
                                        <IconTrash />
                                    </Button>
                                </Tooltip>
                            </Popconfirm>
                        )
                    }
                </Space>
            );
        },
    });

    return columns;
};
