import React from 'react';
import moment from 'moment';
import { Badge, Rate, Typography } from 'antd';
import { DATE_FORMAT } from 'constants/date';

const { Text } = Typography;

export const getColumns = () => [
    {
        title: 'Номер',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Активность',
        dataIndex: 'active',
        key: 'active',
        render: (value: boolean) => <Badge text={ value ? 'показывается' : 'скрыто' } color={ value ? 'green' : 'red' } />,
    },
    {
        title: 'Дата создания',
        dataIndex: 'createDate',
        key: 'createDate',
        render: (value: string) => moment(value).format(DATE_FORMAT.DATE_TIME),
    },
    {
        title: 'Автор',
        dataIndex: 'userName',
        key: 'userName',
    },
    {
        title: 'Рейтинг',
        dataIndex: 'rating',
        key: 'rating',
        render: (value: number) => (
            <Rate
                disabled={ true }
                allowHalf={ true }
                defaultValue={ value }
            />
        ),
    },
    {
        title: 'Ответ',
        dataIndex: 'answer',
        key: 'answer',
        render: (value: boolean) => (value ? (<Text type="success">Отвечено</Text>) : (<Text type="warning">Ожидает ответа</Text>)),
    },
];
