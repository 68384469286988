import React, { useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { useSelector } from 'react-redux';
import { put } from 'redux-saga/effects';
import ym, { YMInitializer } from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import { clearToken, getToken } from 'utils/token';
import { AuthLogin, AuthRegister } from 'components/auth';
import { selectProfile } from 'ducks/user/selectors';
import { getProfile } from 'ducks/user/actions';
import { Header } from './Header';
import { Footer } from './Footer';

export const LayoutLending = ({ children }: any) => {
    const user = useSelector(selectProfile);
    const [authRegisterVisible, setAuthRegisterVisible] = React.useState(false);
    const [authLogin, setAuthLogin] = React.useState(false);
    const token = getToken();

    useEffect(() => {
        if (token) {
            const decodedToken: any = jwt_decode(token);

            const id = decodedToken?.data?.id || null;

            if (!user) {
                put(getProfile({ userId: id }));
            }

            if (!id) {
                clearToken();
                window.location.replace('/');
            }
        }
    }, []);

    ReactGA.initialize('UA-208849097-1');

    const AuthRegisterToggle = (v: any = false) => {
        setAuthRegisterVisible(v);
        ym('reachGoal', 'push_ registration');
        ReactGA.event({
            action: 'push_registration',
            category: 'registration',
            label: 'registration_1',
        });
    };

    const AuthLoginToggle = (v: any = false) => {
        setAuthLogin(v);
        ym('reachGoal', 'push_entrance');
        ReactGA.event({
            action: 'push_entrance',
            category: 'entrance',
            label: 'entrance_1',
        });
    };

    return (
        <div>
            <Header onRegister={ AuthRegisterToggle } onLogin={ AuthLoginToggle } auth={ user } />
            { children }
            <Footer onRegister={ AuthRegisterToggle } onLogin={ AuthLoginToggle } auth={ user } />
            { authRegisterVisible && (<AuthRegister visible={ authRegisterVisible } onCancel={ () => AuthRegisterToggle(false) } />) }
            { authLogin && (<AuthLogin visible={ authLogin } onCancel={ () => AuthLoginToggle(false) } />) }
            <YMInitializer accounts={ [85664005] } />
        </div>
    );
};
