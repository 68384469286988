// статусы которые видны пользователю
export enum Status {
    new = 'new',
    inOperation = 'inOperation',
    completed = 'completed',
    errorImages = 'errorImages',
    notFilledFields = 'notFilledFields',
    masterDoubt = 'masterDoubt',
    denied = 'denied',
}

export enum StatusText {
    new = 'Новая',
    inOperation = 'В работе',
    completed = 'Готово',
    errorImages = 'Проблема с фото',
    notFilledFields = 'Не заполненные поля',
    masterDoubt = 'Мастер сомневается',
    denied = 'Отказано',
}

export enum StatusColor {
    new = '#5275f5',
    inOperation = '#2e2e2e',
    completed = '#1DA57A',
    notFilledFields = '#f1ba1c',
    errorImages = '#f1ba1c',
    masterDoubt = '#aa24b9',
    denied = '#e64c4c',

    loading = '#f1ba1c',
    inwork = '#5275f5',
}

export const AllowToEditStatuses = [Status.new, Status.errorImages, Status.notFilledFields];
export const AllowToRemoveStatuses = [Status.new];
