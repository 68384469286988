import { User } from 'types/user';

export const getInitialValues = (
    initialValues: { [key: string]: any },
    user: User,
) => ({
    ...initialValues,
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: user.phone,
    avatar: user.avatar,
    password: initialValues.password,
    newPassword: initialValues.newPassword,
    newPasswordReply: initialValues.newPasswordReply,
});
