import { InferValueTypes } from 'types/common';
import { AdvertisingTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type AdvertisingState = {
    loading: {
        sales: boolean;
    };
};

export const initialState: AdvertisingState = {
    loading: {
        sales: false,
    },
};

export function AdvertisingReducer(state = initialState, action: ActionTypes): AdvertisingState {
    switch (action.type) {
        case AdvertisingTypes.ADD_ADVERTISING_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    sales: true,
                },
            };
        case AdvertisingTypes.ADD_ADVERTISING_FINISH:
        case AdvertisingTypes.ADD_ADVERTISING_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    sales: false,
                },
            };

        default: {
            return state;
        }
    }
}
