import avatar from 'assets/avatar.png';

export function pluralize(
    number: number | string,
    declensions: [string, string, string],
) {
    const [one, two, many] = declensions;
    const parsedNumber =
        typeof number === 'string' ? parseInt(number, 10) : number;
    let result;
    let rest = Math.abs(parsedNumber) % 100;

    if (rest > 10 && rest < 20) {
        result = many;
    } else {
        rest %= 10;
        if (rest > 1 && rest < 5) {
            result = two;
        } else if (rest === 1) {
            result = one;
        } else {
            result = many;
        }
    }

    return result.replace(/%n/g, parsedNumber.toString(10));
}

export const getFio = (lastName: string, firstName: string) => `${lastName} ${firstName}`;

export const getAvatar = (src: string | null): string => (src || avatar);

export const declOfNum = (number: number, words: string[]) => words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
