import React, { useEffect } from 'react';

declare global {
    interface Window {
        Ya?: any;
    }
}

interface YandexShareProps {
    id: number;
    title?: string;
    url?: string;
    description?: string;
}

const YandexShare = ({
    id, url, title, description,
}: YandexShareProps) => {
    useEffect(() => {
        window.Ya.share2(`ya${id}`, {
            theme: {
                services: 'vkontakte,facebook,odnoklassniki,telegram,twitter,viber,whatsapp,skype',
                lang: 'ru',
                limit: 0,
                size: 's',
                bare: false,
                curtain: true,
                moreButtonType: 'short',
            },
            content: {
                url,
                title,
                description,
            },
        });
    }, []);

    return <div id={ `ya${id}` } />;
};

export default YandexShare;
