import React, {
    useCallback,
    useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Select,
    DatePicker,
    Button,
    Space,
} from 'antd';

import { getTickets } from 'ducks/tickets/actions';
import {
    selectTicketsLoading,
} from 'ducks/tickets/selectors';

import { TicketStatusList, TicketStatusText } from 'types/ticket';

import { Filter } from 'components/ui/filter';
import styles from './styles.module.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

const defaultFilter = {
    dateCreate: undefined,
    status: undefined,
};

export const TicketsFilter = () => {
    const put = useDispatch();
    const loading = useSelector(selectTicketsLoading);

    const [filter, setFilter] = useState(defaultFilter);

    const handleChangeFilter = useCallback((name: string) => (value: any) => {
        setFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const handleResetFilter = useCallback(() => {
        setFilter(defaultFilter);
        put(getTickets({ pagination: { page: 1 }, filter: defaultFilter }));
    }, []);

    const applyFilter = useCallback(() => {
        put(getTickets({ pagination: { page: 1 }, filter }));
    }, [filter]);

    return (
        <Filter
            extra={ (
                <Space size="middle">
                    <Button
                        type="link"
                        onClick={ handleResetFilter }
                        loading={ loading.get }
                    >
                        Сбросить
                    </Button>
                    <Button
                        type="primary"
                        onClick={ applyFilter }
                        loading={ loading.get }
                    >
                        Искать
                    </Button>
                </Space>
            ) }
        >
            <div className={ styles.filterItems }>
                <div className={ styles.filterItem }>
                    <Select
                        placeholder="Статус"
                        allowClear={ true }
                        id="status"
                        onChange={ handleChangeFilter('status') }
                        className={ styles.select }
                        value={ filter.status }
                    >
                        {
                            TicketStatusList.map((key) => <Option value={ key }>{ TicketStatusText[key] }</Option>)
                        }
                    </Select>
                </div>
                <div className={ styles.filterDate }>
                    <RangePicker
                        placeholder={ ['Дата от', 'Дата до'] }
                        allowClear={ true }
                        onChange={ handleChangeFilter('dateCreate') }
                        value={ filter.dateCreate }
                    />
                </div>
            </div>
        </Filter>
    );
};
