import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFaq } from 'ducks/faq/actions';
import { selectFaq, selectFaqLoading } from 'ducks/faq/selectors';
import { Spin } from 'antd';
import { AccordionItem } from './Item';
import { FaqButton } from './Button';
import cl from './style.module.css';

export const Faq = () => {
    const put = useDispatch();
    const faqList = useSelector(selectFaq);
    const faqLoading = useSelector(selectFaqLoading);

    useEffect(() => {
        put(getFaq({ pagination: { page: 1 } }));
    }, []);

    return (
        <React.Fragment>
            <div className="accordion__wrap">
                <Spin spinning={ faqLoading } className={ cl.spin }>
                    { faqList.map((data) => (<AccordionItem item={ data } key={ data.id } />)) }
                </Spin>
            </div>
            <FaqButton />
        </React.Fragment>
    );
};
