import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import {
    Form,
    Input,
    Button,
    Typography,
} from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { Layout } from 'components/ui/layout';
import { AppLoading } from 'components/ui/app-loading';
import { UploadFile } from 'components/upload-file';

import { saveUser } from 'ducks/user/actions';
import { selectProfile, selectUserLoading } from 'ducks/user/selectors';

import { schema } from 'form-helpers/profile/schema';
import { ProfileForm } from 'form-helpers/profile/types';
import { getInitialValues } from 'form-helpers/profile/mapping';

import { getError, getErrorStatus } from 'form-helpers/validation';

import { Image } from 'types/orders';
import { Cart } from 'components/ui/cart';
import styles from './styles.module.scss';

const itemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const { Title } = Typography;

const initialValues = schema.cast({});

export const Profile = React.memo(() => {
    const put = useDispatch();
    const user = useSelector(selectProfile);
    const loading = useSelector(selectUserLoading);

    if (!user || loading.profile) {
        return <AppLoading />;
    }

    const onSubmit = (values: ProfileForm) => {
        const newValues = { ...values, phone: `+${values.phone.replaceAll(' ', '')}`, newPasswordReply: undefined };

        put(saveUser(newValues));
    };

    return (
        <Layout>
            <div className={ styles.header }>
                <Title level={ 2 }>Ваш профиль</Title>
            </div>
            <Cart>
                <div className={ styles.profile }>
                    <Formik<ProfileForm>
                        initialValues={ getInitialValues(initialValues, user) }
                        onSubmit={ onSubmit }
                        validationSchema={ schema }
                    >
                        { ({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            errors,
                            touched,
                            values,
                        }) => {
                            const errorList = {
                                firstName: getError(errors, touched, true, 'firstName'),
                                lastName: getError(errors, touched, true, 'lastName'),
                                email: getError(errors, touched, true, 'email'),
                                phone: getError(errors, touched, true, 'phone'),
                                password: getError(errors, touched, true, 'password'),
                                newPassword: getError(errors, touched, true, 'newPassword'),
                                newPasswordReply: getError(errors, touched, true, 'newPasswordReply'),
                                avatar: getError(errors, touched, true, 'avatar'),
                            };

                            const onChangeFile = (name: string) => (file: any) => {
                                setFieldValue(name, file || null, true);
                            };

                            const handleChangePhone = (value: string) => {
                                setFieldValue('phone', value || null, true);
                            };

                            return (
                                <Form
                                    onFinish={ handleSubmit }
                                    labelAlign="left"
                                    { ...itemLayout }
                                >
                                    <Form.Item
                                        label="Аватар"
                                        extra={ errorList.avatar }
                                        validateStatus={ getErrorStatus(!!errorList.avatar) }
                                    >
                                        <UploadFile
                                            onChange={ onChangeFile('avatar') }
                                            count={ 1 }
                                            defaultValue={
                                                values.avatar
                                                    ? [{ url: values.avatar }] as Image[]
                                                    : []
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Ваше имя"
                                        extra={ errorList.firstName }
                                        validateStatus={ getErrorStatus(!!errorList.firstName) }
                                    >
                                        <Input
                                            name="firstName"
                                            placeholder="Ваше имя"
                                            onChange={ handleChange }
                                            onBlur={ handleBlur }
                                            value={ values.firstName }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Ваша фамилия"
                                        extra={ errorList.lastName }
                                        validateStatus={ getErrorStatus(!!errorList.lastName) }
                                    >
                                        <Input
                                            name="lastName"
                                            placeholder="Ваша фамилия"
                                            onChange={ handleChange }
                                            onBlur={ handleBlur }
                                            value={ values.lastName }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Email"
                                        extra={ errorList.email }
                                        validateStatus={ getErrorStatus(!!errorList.email) }
                                    >
                                        <Input
                                            name="email"
                                            placeholder="Email"
                                            onChange={ handleChange }
                                            onBlur={ handleBlur }
                                            value={ values.email }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Телефон"
                                        extra={ errorList.phone }
                                        validateStatus={ getErrorStatus(!!errorList.phone) }
                                    >
                                        <PhoneInput
                                            country="ru"
                                            inputProps={ {
                                                name: 'phone',
                                                required: true,
                                            } }
                                            placeholder="+7(000)-000-00-00"
                                            onChange={ handleChangePhone }
                                            onBlur={ handleBlur }
                                            value={ values.phone }
                                        />
                                    </Form.Item>
                                    <Title level={ 5 }>Смена пароля</Title>
                                    <Form.Item
                                        label="Старый пароль"
                                        extra={ errorList.password }
                                        validateStatus={ getErrorStatus(!!errorList.password) }
                                    >
                                        <Input
                                            name="password"
                                            placeholder="Старый пароль"
                                            onChange={ handleChange }
                                            onBlur={ handleBlur }
                                            value={ values.password }
                                            type="password"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Новый пароль"
                                        extra={ errorList.newPassword }
                                        validateStatus={ getErrorStatus(!!errorList.newPassword) }
                                    >
                                        <Input
                                            name="newPassword"
                                            placeholder="Новый пароль"
                                            onChange={ handleChange }
                                            onBlur={ handleBlur }
                                            value={ values.newPassword }
                                            type="password"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Повторите новый пароль"
                                        extra={ errorList.newPasswordReply }
                                        validateStatus={ getErrorStatus(!!errorList.newPasswordReply) }
                                    >
                                        <Input
                                            name="newPasswordReply"
                                            placeholder="Повторите новый пароль"
                                            onChange={ handleChange }
                                            onBlur={ handleBlur }
                                            value={ values.newPasswordReply }
                                            type="password"
                                        />
                                    </Form.Item>

                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={ loading.save }
                                    >
                                        Сохранить
                                    </Button>
                                </Form>
                            );
                        } }
                    </Formik>
                </div>
            </Cart>
        </Layout>
    );
});
