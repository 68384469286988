import { message } from 'antd';
import { put, call } from 'redux-saga/effects';
import {
    removePromoCode,
    removePromoCodeError,
    removePromoCodeFinish,
    getPromoCode,
} from 'ducks/promocode/actions';

import { fetchers } from 'api';

export function* removePromoCodeRequest({ payload }: ReturnType<typeof removePromoCode>) {
    try {
        yield call(fetchers.removePromocode, payload);
        message.success('Промокод успешно удален');
        yield put(getPromoCode({ pagination: { page: 1 } }));
        yield put(removePromoCodeFinish());
    } catch (error) {
        yield put(removePromoCodeError());
    }
}
