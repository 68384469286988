import React from 'react';
import { Link } from 'react-router-dom';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';

export const Footer = () => (
    <div className={ styles.footer }>
        <div className={ styles.content }>
            <nav className={ styles.menu }>
                { /* <ul>
                    <li>
                        <Link to={ ROUTES.ABOUTSERVICE.path }>О сервисе</Link>
                    </li>
                    <li>
                        <Link to={ ROUTES.HOWITWORKS.path }>Как работает</Link>
                    </li>
                    <li>
                        <Link to={ ROUTES.WHATISTHEADVANTAGE.path }>В чем преимущество </Link>
                    </li>
                </ul> */ }
            </nav>
            <div className={ styles.contact }>
                <Link to="tel:8800" className={ styles.contact_phone }><PhoneOutlined /> 8800</Link>
                <Link to="mailto:info@hairtone.ru" className={ styles.contact_email }><MailOutlined /> info@hairtone.ru</Link>
            </div>
        </div>
    </div>
);
