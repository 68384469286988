import React from 'react';
import { Link as LinkNav } from 'react-router-dom';

import logo from 'assets/logo.svg';
import { clearToken } from 'utils/token';
import { ROUTES } from 'constants/routes';
import { Promocode } from '../../../promocode';
import { MenuLending } from './menu/lending';

export const Header = ({ auth, onRegister, onLogin }: any) => {
    const [isOpenMenu, setisOpenMenu] = React.useState(false);

    const onExit = () => {
        clearToken();
        window.location.replace('/');
    };

    const isBtnOpen = () => {
        setisOpenMenu(!isOpenMenu);
    };

    const closeMenu = () => {
        const headerMenu = document.querySelector('.header__menu');

        headerMenu?.classList.remove('open');
    };

    return (
        <React.Fragment>
            <header className="header">
                <div className="container header__container">
                    <LinkNav to="/" className="header__logo">
                        <img src={ logo } onClick={ () => window.scrollTo(0, 0) } aria-hidden="true" alt="" />
                    </LinkNav>
                    <div className={ isOpenMenu ? 'header__menu open' : 'header__menu' }>
                        <MenuLending
                            closeMenu={ closeMenu }
                            auth={ auth }
                            onExit={ onExit }
                            onLogin={ onLogin }
                            onRegister={ onRegister }
                        />
                    </div>
                    <div className="header__btns">
                        { auth ? (
                            <React.Fragment>
                                <button type="button" onClick={ () => onExit() } className="btn btn--null">Выйти</button>
                                <LinkNav to={ ROUTES.MAIN.path }><button type="button" className="btn">Профиль</button></LinkNav>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <button type="button" onClick={ () => onLogin(true) } className="btn btn--null">Вход</button>
                                <button type="button" onClick={ () => onRegister(true) } className="btn">Регистрация</button>
                            </React.Fragment>
                        ) }
                    </div>
                    <button type="button" onClick={ () => isBtnOpen() } className="burger"> <span /> </button>
                </div>
            </header>
            <Promocode />
        </React.Fragment>
    );
};
