import React from 'react';

type Props = {
    rating: number;
    size: number;
};

export const ReviewsRatingAll = ({ rating, size }: Props) => {
    const Stars = (count: number) => {
        const star = [];

        for (let i = 0; i < count; ++i) {
            star.push(i);
        }

        return star.map((r) => <div className="rating__item" key={ r } />);
    };

    return (
        <React.Fragment>
            <div className="reviews__info-rating">
                <div className="reviews__number">{ rating || 0 }
                </div>
                <div className="rating">
                    { Stars(rating || 0) }
                </div>
            </div>
            <div className="reviews__info-count hidden">
                <div className="reviews__number">{ size }</div>
                <p>Отзывов</p>
            </div>
        </React.Fragment>
    );
};
