import * as Api from 'types/api';
import {
    InfoActionTypes,
} from './action-types';

export const getInfo = () => ({
    type: InfoActionTypes.GET_INFO_START,
});

export const getInfoError = () => ({
    type: InfoActionTypes.GET_INFO_ERROR,
});

export const getInfoFinish = (response: Api.InfoResponse) => ({
    type: InfoActionTypes.GET_INFO_FINISH,
    response,
});

export const getColors = () => ({
    type: InfoActionTypes.GET_COLORS_START,
});

export const getColorsError = () => ({
    type: InfoActionTypes.GET_COLORS_ERROR,
});

export const getColorsFinish = (response: any) => ({
    type: InfoActionTypes.GET_COLORS_FINISH,
    response,
});
