import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';

import { DATE_FORMAT } from 'constants/date';
import moment from 'moment';

import { Link } from 'components/ui/link';

import { selectBlog } from 'ducks/blog/selectors';
import { getBlog } from 'ducks/blog/actions';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import style from './style.module.scss';

export const BlogSlider = () => {
    const put = useDispatch();
    const blogItems = useSelector(selectBlog);

    useEffect(() => {
        put(getBlog({ pagination: { pageSize: 12, page: 1 } }));
    }, []);

    return (
        <section className="section">
            <div className="container">
                <h2>Полезные статьи</h2>
                <Swiper
                    autoplay={ {
                        delay: 5000,
                        disableOnInteraction: false,
                    } }
                    slidesPerView={ 1 }
                    breakpoints={ {
                        480: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                        992: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        },
                    } }
                    className={ style.blogSlider }
                >
                    {
                        blogItems && blogItems.map((slideContent, index) => (
                            <SwiperSlide key={ slideContent.id } virtualIndex={ index }>
                                <a href={ `/blog/${slideContent.id}` } className={ style.title }>{ slideContent.title }</a>
                                <picture className={ style.image }>
                                    <img src={ `../${slideContent.previewImage}` } alt={ slideContent.title } />
                                </picture>
                                <div className={ style.info }>
                                    { moment(slideContent.created).format(DATE_FORMAT.DATE_TIME_NAME) } |
                                    {
                                        slideContent.blogCategories.map((cat: any) => <Link to={ `/blog?categoryId=${cat.id}` } key={ cat.id }> { cat.name }</Link>)
                                    }
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </section>
    );
};
