import React from 'react';

export const AccordionItem = ({ item }: any) => {
    const [active, setActive] = React.useState(false);

    const Show = () => {
        setActive(!active);
    };

    const classOpen = active ? 'accordion active' : 'accordion';

    return (
        <div className={ classOpen } key={ item.id }>
            <div className="accordion__title" onClick={ () => Show() } aria-hidden="true">{ item.title }</div>
            { /* eslint-disable-next-line react/no-danger */ }
            <div className="accordion__content" dangerouslySetInnerHTML={ { __html: item.message } } />
        </div>
    );
};
