import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Form, Input, Modal, Select, Spin, Typography,
} from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { Formik } from 'formik';

import {
    clearDetailData, createPromoCode, getPromoCodeId, updatePromoCode,
} from 'ducks/promocode/actions';
import { selectInfoPromoCode, selectPromoCodeId } from 'ducks/promocode/selectors';

import { CreatePromoCodeForm } from 'form-helpers/promocode/types';
import { getInitialValues } from 'form-helpers/promocode/mapping-initial-values';
import { schema } from 'form-helpers/promocode/schema';
import { getError, getErrorStatus } from 'form-helpers/validation';

import { Promo, PromoCodeModalProps } from 'types/promocode';

import { LoadingOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';

const { Title } = Typography;
const { Option } = Select;

const initialvalues = schema.cast({});

const getLabel = (text: string) => (
    <Title className={ styles.label } level={ 5 }>{ text }</Title>
);

export const PromoCodeModal = ({ visible, onHandle }: PromoCodeModalProps) => {
    const put = useDispatch();
    const promocodeInfo = useSelector(selectInfoPromoCode);
    const promocodeId = useSelector(selectPromoCodeId);
    const title = visible.id ? 'Редактирование промокода' : 'Создание промокода';
    const isNew = !visible.id;

    useEffect(() => {
        put(clearDetailData());
        if (visible.id) {
            put(getPromoCodeId({ orderId: visible.id }));
        }
    }, []);

    const onOk = (values: CreatePromoCodeForm) => {
        const data = cloneDeep(values);

        if (visible.id) {
            put(updatePromoCode(data, onHandle));
        } else {
            put(createPromoCode(data, onHandle));
        }
    };

    const onCancel = useCallback(() => {
        onHandle(false);
    }, []);

    return (
        <Modal
            key="modal-create-promocode"
            title={ title }
            visible={ visible }
            onCancel={ onCancel }
            footer={ [
                <Button type="primary" form="Form-create-promocode1" key="submit" htmlType="submit">
                    { isNew ? 'Создать' : 'Изменить' }
                </Button>,
            ] }
        >
            { (!isNew && promocodeId) || isNew ? (
                <Formik<CreatePromoCodeForm>
                    onSubmit={ onOk }
                    validationSchema={ schema }
                    initialValues={ getInitialValues(initialvalues, promocodeId as Promo) }
                    enableReinitialize={ true }
                >
                    { ({
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                        values,
                        errors,
                        touched,
                    }) => {
                        const errorList = {
                            type: getError(errors, touched, true, 'type'),
                            typeDiscont: getError(errors, touched, true, 'typeDiscont'),
                            discount: getError(errors, touched, true, 'discount'),
                            discount_price: getError(errors, touched, true, 'discount_price'),
                            time: getError(errors, touched, true, 'time'),
                            quantity: getError(errors, touched, true, 'quantity'),
                            status: getError(errors, touched, true, 'status'),
                            code: getError(errors, touched, true, 'code'),
                        };

                        const handleChangeText = (e: any) => {
                            const event = {
                                target: {
                                    name: e.target.name,
                                    value: e.target.value,
                                    id: e.target.id,
                                },
                            };

                            handleChange(event);
                        };
                        const handleChangeSelect = (name: string, value: any) => {
                            const event = {
                                target: {
                                    name,
                                    value,
                                    id: name,
                                },
                            };

                            if (name === 'typeDiscont') {
                                if (value === 'Скидка') {
                                    setFieldValue('discount_price', null);
                                } else {
                                    setFieldValue('discount', null);
                                }
                            }

                            handleChange(event);
                        };

                        return (
                            <Form
                                id="Form-create-promocode1"
                                onFinish={ handleSubmit }
                                labelAlign="left"
                                layout="vertical"
                            >
                                <Form.Item
                                    label={ getLabel('Промокод на услугу') }
                                    className={ styles.formItem }
                                    extra={ errorList.type }
                                    validateStatus={ getErrorStatus(!!errorList.type) }
                                    name="type"
                                >
                                    <Select
                                        defaultValue={ values.type }
                                        value={ values.type }
                                        onChange={ (e) => handleChangeSelect('type', e) }
                                    >
                                        { promocodeInfo?.type?.map(({ value, name }) => (
                                            <Option
                                                key={ value }
                                                value={ `${value}` }
                                                title={ name }
                                            >{ name }
                                            </Option>
                                        )) }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={ getLabel('Тип промокода') }
                                    className={ styles.formItem }
                                    extra={ errorList.typeDiscont }
                                    validateStatus={ getErrorStatus(!!errorList.typeDiscont) }
                                >
                                    <Select
                                        options={ [
                                            { value: 'Скидка', label: <span>Скидка</span> },
                                            { value: 'Цена', label: <span>Цена</span> },
                                        ] }
                                        defaultValue={ values.typeDiscont }
                                        onChange={ (e) => handleChangeSelect('typeDiscont', e) }
                                    />
                                </Form.Item>
                                { values.typeDiscont === 'Скидка' ? (
                                    <Form.Item
                                        label={ getLabel('Скидка') }
                                        className={ styles.formItem }
                                        extra={ errorList.discount }
                                        validateStatus={ getErrorStatus(!!errorList.discount) }
                                    >
                                        <Select
                                            defaultValue={ values.discount }
                                            value={ values.discount }
                                            onChange={ (e) => handleChangeSelect('discount', e) }
                                        >
                                            { promocodeInfo?.discount?.map(({ value, name }) => (
                                                <Option
                                                    value={ value }
                                                    title={ name }
                                                >{ name }
                                                </Option>
                                            )) }
                                        </Select>
                                    </Form.Item>
                                ) : (
                                    <Form.Item
                                        label={ getLabel('Сумма скидки') }
                                        className={ styles.formItem }
                                        extra={ errorList.discount_price }
                                        validateStatus={ getErrorStatus(!!errorList.discount_price) }
                                    >
                                        <Input
                                            value={ values.discount_price }
                                            onChange={ handleChangeText }
                                            onBlur={ handleChangeText }
                                            name="discount_price"
                                        />
                                    </Form.Item>
                                ) }
                                <Form.Item
                                    label={ getLabel('Время') }
                                    className={ styles.formItem }
                                    extra={ errorList.time }
                                    validateStatus={ getErrorStatus(!!errorList.time) }
                                >
                                    <Select
                                        defaultValue={ values.time }
                                        value={ values.time }
                                        onChange={ (e) => handleChangeSelect('time', e) }
                                    >
                                        { promocodeInfo?.time?.map(({ value, name }) => (
                                            <Option
                                                key={ value }
                                                value={ Number(value) }
                                                title={ name }
                                            >{ name }
                                            </Option>
                                        )) }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={ getLabel('Сколько раз разрешено использовать') }
                                    className={ styles.formItem }
                                    extra={ errorList.quantity }
                                    validateStatus={ getErrorStatus(!!errorList.quantity) }
                                >
                                    <Select
                                        defaultValue={ values.quantity }
                                        value={ values.quantity }
                                        onChange={ (e) => handleChangeSelect('quantity', e) }
                                    >
                                        { promocodeInfo?.quantity?.map(({ value, name }) => (
                                            <Option
                                                key={ value }
                                                value={ Number(value) }
                                                title={ name }
                                            >{ name }
                                            </Option>
                                        )) }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={ getLabel('Статус') }
                                    className={ styles.formItem }
                                    extra={ errorList.status }
                                    validateStatus={ getErrorStatus(!!errorList.status) }
                                >
                                    <Select
                                        defaultValue={ values.status }
                                        value={ values.status }
                                        onChange={ (e) => handleChangeSelect('status', e) }
                                    >
                                        { promocodeInfo?.status?.map(({ value, name }) => (
                                            <Option
                                                key={ value }
                                                value={ Number(value) }
                                                title={ name }
                                            >{ name }
                                            </Option>
                                        )) }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={ getLabel('Промокод') }
                                    className={ styles.formItem }
                                    extra={ errorList.code }
                                    validateStatus={ getErrorStatus(!!errorList.code) }
                                >
                                    <Input
                                        value={ values.code }
                                        onChange={ handleChangeText }
                                        onBlur={ handleChangeText }
                                        name="code"
                                    />
                                </Form.Item>
                            </Form>
                        );
                    } }
                </Formik>
            ) : (
                <Spin
                    className={ styles.spin }
                    indicator={ (
                        <LoadingOutlined
                            style={ { fontSize: 100 } }
                            spin={ true }
                        />
                    ) }
                />
            ) }
        </Modal>
    );
};
