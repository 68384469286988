import React from 'react';
import { Blog } from 'types/blog';
import { DATE_FORMAT } from 'constants/date';
import moment from 'moment';
import { Dropdown, Menu } from 'antd';
import { HeartTwoTone, SmallDashOutlined } from '@ant-design/icons';
import YandexShare from 'components/ui/yandexShare';
import { Link } from 'components/ui/link';
import cls from './style.module.scss';

export const BlogItem = ({
    id,
    blogCategories,
    title,
    previewImage,
    previewText,
    likes,
    likesAdd,
    created,
    user,
    onEdit,
    onDelete,
    onLike,
}: Blog) => {
    const menu = (
        <Menu>
            <Menu.Item key="edit" onClick={ () => onEdit(id) }>Редактировать</Menu.Item>
            <Menu.Item key="delete" onClick={ () => onDelete(id) }>Удалить</Menu.Item>
        </Menu>
    );

    return (
        <div className={ `grid-item ${cls.item}` } itemScope={ true } itemType="http://schema.org/ImageObject">
            <a href={ `/blog/${id}` } className={ cls.title }>{ title }</a>
            <div className={ cls.header }>
                <div className={ cls.category }>
                    { moment(created).format(DATE_FORMAT.DATE_TIME_NAME) } |
                    { blogCategories.map((cat: any) => <Link to={ `/blog?categoryId=${cat.id}` } key={ cat.id } itemProp="name"> { cat.name }</Link>) }
                </div>
            </div>
            <div className={ cls.content }>
                <picture className={ cls.image }>
                    <img itemProp="contentUrl" src={ `../${previewImage}` } alt={ title } />
                </picture>
                <div className={ cls.preview }>
                    <p itemProp="description" className={ cls.text }>{ previewText } </p>
                </div>
            </div>
            <div className={ cls.nextpage }>
                <a href={ `/blog/${id}` }>Читать статью</a>
            </div>
            <div className={ cls.footer }>
                <div className={ cls.like }>
                    <HeartTwoTone twoToneColor={ likesAdd ? 'red' : '#000' } onClick={ () => onLike(id) } /> { likes !== 0 && (<div>{ likes }</div>) }
                </div>
                <div className={ cls.share }>
                    <YandexShare
                        id={ id }
                        url={ `www.hairtone.ru/blog/${id}` }
                        title={ title }
                        description={ previewText.substr(0, 100) }
                    />
                </div>
                { user && user?.access === 'ADMIN' ? (
                    <div className={ cls.menu } key={ id }>
                        <Dropdown overlay={ menu } placement="bottomLeft">
                            <SmallDashOutlined />
                        </Dropdown>
                    </div>

                ) : null }

            </div>
        </div>
    );
};
