export const OrdersActionTypes = {
    GET_ORDERS_START: 'GET_ORDERS_START',
    GET_ORDERS_ERROR: 'GET_ORDERS_ERROR',
    GET_ORDERS_FINISH: 'GET_ORDERS_FINISH',

    CREATE_ORDER_START: 'CREATE_ORDER_START',
    CREATE_ORDER_ERROR: 'CREATE_ORDER_ERROR',
    CREATE_ORDER_FINISH: 'CREATE_ORDER_FINISH',

    EDIT_ORDER_START: 'EDIT_ORDER_START',
    EDIT_ORDER_ERROR: 'EDIT_ORDER_ERROR',
    EDIT_ORDER_FINISH: 'EDIT_ORDER_FINISH',

    GET_ORDER_START: 'GET_ORDER_START',
    GET_ORDER_ERROR: 'GET_ORDER_ERROR',
    GET_ORDER_FINISH: 'GET_ORDER_FINISH',

    REMOVE_ORDER_START: 'REMOVE_ORDER_START',
    REMOVE_ORDER_ERROR: 'REMOVE_ORDER_ERROR',
    REMOVE_ORDER_FINISH: 'REMOVE_ORDER_FINISH',

    CLEAR_DETAIL_DATA: 'CLEAR_DETAIL_DATA',

    CHANGE_ORDER_STATUS_START: 'CHANGE_ORDER_STATUS_START',
    CHANGE_ORDER_STATUS_ERROR: 'CHANGE_ORDER_STATUS_ERROR',
    CHANGE_ORDER_STATUS_FINISH: 'CHANGE_ORDER_STATUS_FINISH',

    SEND_EXPERT_ORDER_FORM_START: 'SEND_EXPERT_ORDER_FORM_START',
    SEND_EXPERT_ORDER_FORM_ERROR: 'SEND_EXPERT_ORDER_FORM_ERROR',
    SEND_EXPERT_ORDER_FORM_FINISH: 'SEND_EXPERT_ORDER_FORM_FINISH',

    APPLY_PROMOCODE_TO_ORDER_START: 'APPLY_PROMOCODE_TO_ORDER_START',
    APPLY_PROMOCODE_TO_ORDER_ERROR: 'APPLY_PROMOCODE_TO_ORDER_ERROR',
    APPLY_PROMOCODE_TO_ORDER_FINISH: 'APPLY_PROMOCODE_TO_ORDER_FINISH',

    PAY_ORDER_ONLINE_START: 'PAY_ORDER_ONLINE_START',
    PAY_ORDER_ONLINE_ERROR: 'PAY_ORDER_ONLINE_ERROR',
    PAY_ORDER_ONLINE_FINISH: 'PAY_ORDER_ONLINE_FINISH',
} as const;
