/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import {
    Button, notification, Space, Spin, Typography,
} from 'antd';

import { getColors, getInfo } from 'ducks/info/actions';
import { changeOrderStatus, clearDetailData, getOrder } from 'ducks/orders/actions';

import { selectColors, selectInfo } from 'ducks/info/selectors';
import { selectOrder, selectOrdersLoading } from 'ducks/orders/selectors';
import { selectProfile } from 'ducks/user/selectors';

import { Option } from 'types/api';
import {
    AllowToEditStatuses, Status, StatusColor, StatusText,
} from 'types/status';
import { OrderType, OrderTypeText } from 'types/orders';

import { ImageList } from 'components/image-list';
import { OrderExpertFormLamination } from 'components/order-expert-form-lamination';
import { OrderExpertFormColoringtinting } from 'components/order-expert-form-coloringtinting';
import { OrderExpertFormChoosecolor } from 'components/order-expert-form-choosecolor';
import { Layout } from 'components/ui/layout';
import { InfoItem } from 'components/ui/info-item';
import { InfoItemNotLoader } from 'components/ui/info-item-not-loader';
import { CreateTicketModal } from 'components/create-ticket-modal';
import { OrderPayment } from 'components/order-payment';
import { OrderStatusList } from 'components/order-status-list';

import { ROUTES } from 'constants/routes';
import { ACCESS } from 'constants/access';
import { checkAccess, checkAllowedChangeStatus } from 'utils/access';
import { getImageErrors } from 'utils/orders';

import { history } from 'App';
import cn from 'classnames';
import nofoto from 'assets/no_photo.jpg';

import { IconAlertTriangleFilled, IconBallpen } from '@tabler/icons-react';
import { OrderFeedback } from 'components/order-feedback';
import { clear as clearFeedbackOrder, getOrderFeedback } from 'ducks/orderFeedback/actions';
import styles from './styles.module.scss';

type MatchParams = {
    id: string;
};

const noPhoto = [{ uid: 1, name: 'no_photo.jpg', url: nofoto }];

const { Title } = Typography;

const getValueById = (array: Option[], id: string) => array.find((item) => item.value === id)?.name;

export const ViewOrder = () => {
    const put = useDispatch();
    const info = useSelector(selectInfo);
    const colors = useSelector(selectColors);
    const ordersLoading = useSelector(selectOrdersLoading);
    const order = useSelector(selectOrder);
    const user = useSelector(selectProfile);
    const [visibleModal, setVisibleModal] = React.useState(false);
    const [isNotificationExist, setIsNotificationExist] = React.useState(false);

    const { params: { id: orderId } } = useRouteMatch<MatchParams>();

    useEffect(() => {
        put(getInfo());
        put(getColors());
        put(getOrder({ orderId: +orderId }));

        return () => {
            put(clearDetailData());
            put(clearFeedbackOrder());
        };
    }, []);

    useEffect(() => {
        if (
            !order?.payment
            && order?.user_id === user?.id
            && !isNotificationExist
        ) {
            notification.warning({
                message: 'Заказ не оплачен',
                description: 'Чтобы эксперт начал работу нужно оплатить заказ',
            });
            setIsNotificationExist(true);
        }
    }, [order?.payment, setIsNotificationExist]);

    useEffect(() => {
        put(getOrderFeedback({ orderId: +orderId }));
    }, [orderId]);

    const goToOrdersPage = useCallback(() => history.push(ROUTES.MAIN.path), []);

    const goToEdit = useCallback((e: any, id: number) => {
        e.stopPropagation();
        history.push(ROUTES.EDIT_ORDER.getPath({ id }));
    }, []);

    if (!info || !order || !colors) {
        return (
            <Layout>
                <Title level={ 2 }>Просмотр заявки</Title>
                <Spin size="large" spinning={ true } />
            </Layout>
        );
    }

    const handleChangeStatus = (status: Status, errorFieldsImg?: string) => () => {
        put(changeOrderStatus({ id: order.id, status, errorFieldsImg }));
    };

    const isEditOpen = AllowToEditStatuses.includes(order.status) && order.user_id === user?.id;
    const isChangeStatusAllowed = checkAccess(user?.access_list, ACCESS.UPDATE_STATUS_ORDER);

    const allowedStatus = checkAllowedChangeStatus(user, order, ordersLoading.changeStatus);
    const imageErrors = getImageErrors(order.image_before_error, order.image_after_error, styles.imageError);

    const shadeValue = order.shade && `${getValueById(info.shade, order.shade)}${order.shade === 'yes' ? `<br>(${order.shade_text})` : ''}`;

    const brand = getValueById(info.brand, order.brand);

    const constImageBefore = order.formType === OrderType.choosecolor ? 3 : 2;

    const isCreateTicketAllow = checkAccess(user?.access_list, ACCESS.CREATE_SUPPORT);
    const isUserViewAllow = checkAccess(user?.access_list, ACCESS.VIEWS_USER);

    const handleChangeVisibleModal = (value: boolean) => () => setVisibleModal(value);

    const expertForm = (type: OrderType) => {
        switch (type) {
            case OrderType.coloringtinting:
                return <OrderExpertFormColoringtinting />;

            case OrderType.lamination:
                return <OrderExpertFormLamination />;

            case OrderType.choosecolor:
                return <OrderExpertFormChoosecolor />;

            default:
                return null;
        }
    };

    return (
        <Layout>
            <div className="cartForm">
                <div className={ cn(styles.cart, styles.mb20) }>

                    <div className={ styles.header }>
                        <div className={ styles.header_content }>
                            <div className={ styles.header_title }>
                                <Title level={ 2 } className={ styles.header__title }>
                                    Заявка №{ order.id } / { OrderTypeText[order.formType] }
                                    { ordersLoading.changeStatus && (
                                        <span className={ styles.updateStatusLoading }>
                                            Обновление статуса заявки
                                            <Spin size="small" spinning={ true } style={ { marginLeft: 16 } } />
                                        </span>
                                    ) }
                                </Title>
                            </div>
                            <div className={ styles.header_status }>
                                <span style={ { background: StatusColor[order.status] } }>{ StatusText[order.status] }</span>
                                { order.isTest ? (<span className={ styles.statusTest }>Тестовая заявка</span>) : null }
                            </div>
                        </div>
                        <div className={ styles.header_btn }>
                            <Space size="middle">
                                { isCreateTicketAllow && (
                                    <Button
                                        type="primary"
                                        ghost={ true }
                                        onClick={ handleChangeVisibleModal(true) }
                                    >
                                        Написать в техподдержку
                                    </Button>
                                ) }

                                { isEditOpen && (
                                    <Button
                                        danger={ true }
                                        type="primary"
                                        onClick={ (e) => goToEdit(e, order.id) }
                                        className={ styles.btnIcon }
                                    >
                                        <IconBallpen />   Редактировать заявку
                                    </Button>
                                ) }
                            </Space>
                        </div>
                    </div>

                    { order.status === Status.notFilledFields && (
                        <React.Fragment>
                            <div className={ styles.cart_line } />
                            <Title level={ 5 }>
                                <span className={ styles.rejectionReason }>
                                    <b>Комментарии эксперта</b>: { order.rejectionReason }
                                </span>
                            </Title>
                        </React.Fragment>
                    ) }

                    { order.status !== Status.notFilledFields && order.rejectionReason && (
                        <React.Fragment>
                            <div className={ styles.cart_line } />
                            <Title level={ 5 }>
                                <span className={ styles.rejectionReason }>
                                    Отказано по причине: { order.rejectionReason }
                                </span>
                            </Title>
                        </React.Fragment>
                    ) }

                    { imageErrors.any.message && (
                        <React.Fragment>
                            <div className={ styles.cart_line } />
                            <div className={ styles.imageErrors }>
                                <IconAlertTriangleFilled /> { imageErrors.any.message }
                            </div>
                        </React.Fragment>
                    ) }

                </div>
                <div className={ styles.cart_blank_two }>
                    <div className={ cn(styles.cart, styles['flex:1']) }>
                        <Title level={ 4 } className={ styles.imagesTitle }>
                            Фото до &nbsp;{ imageErrors.before.message }
                        </Title>
                        <div className={ styles.imagesBlock }>
                            { order.image_before ? (<ImageList images={ order.image_before } />) : (<ImageList images={ noPhoto } />) }
                            <InfoItemNotLoader size={ constImageBefore } value={ order.image_before } />
                            {
                                isChangeStatusAllowed
                                && allowedStatus.errorImages
                                && (
                                    <Button
                                        className={ styles.statusBtn }
                                        disabled={ !allowedStatus.errorImages }
                                        onClick={ handleChangeStatus(Status.errorImages, 'image_before_error') }
                                        danger={ true }
                                    >
                                        Плохое качество фото
                                    </Button>
                                )
                            }
                        </div>
                        {
                            order.image_after && (
                                <React.Fragment>
                                    <Title level={ 4 } className={ styles.imagesTitle }>
                                        Фото после &nbsp;{ imageErrors.after.message }
                                    </Title>
                                    <div className={ styles.imagesBlock }>
                                        { order.image_after ? (<ImageList images={ order.image_after } />) : (<ImageList images={ noPhoto } />) }
                                        <InfoItemNotLoader size={ 3 } value={ order.image_after } />
                                        {
                                            isChangeStatusAllowed
                                            && allowedStatus.errorImages
                                            && (
                                                <Button
                                                    className={ styles.statusBtn }
                                                    disabled={ !allowedStatus.errorImages }
                                                    onClick={ handleChangeStatus(Status.errorImages, 'image_after_error') }
                                                    danger={ true }
                                                >
                                                    Плохое качество фото
                                                </Button>
                                            )
                                        }
                                    </div>
                                </React.Fragment>
                            )
                        }
                    </div>
                    <div className={ cn(styles.cart, styles['flex_1:7']) }>
                        {
                            isUserViewAllow && (
                                <Title level={ 4 } style={ { marginBottom: 8 } }>
                                    Клиент:
                                    <Button
                                        type="link"
                                        onClick={ () => history.push(ROUTES.USER.getPath({ id: order.user_id })) }
                                    >
                                        { order.user.lastName } { order.user.firstName }
                                    </Button>
                                </Title>
                            )
                        }
                        <Title style={ { marginTop: 0 } } level={ 4 }>Описание:</Title>
                        { (order.formType === OrderType.choosecolor && order.aboutChooseColor) ?
                            (<div className={ styles.aboutChooseColor }>{ order.aboutChooseColor }</div>) :
                            (order.formType === OrderType.choosecolor && !order.aboutChooseColor) ?
                                (<div className={ cn(styles.aboutChooseColor, styles.darken) }>Не указано</div>) : null }

                        {
                            (order.formType !== OrderType.choosecolor) && (
                                <React.Fragment>
                                    <InfoItem label="Пористость" value={ getValueById(info.hairType.option1, order.hairType[0]) } />
                                    <InfoItem label="Толщина" value={ getValueById(info.hairType.option2, order.hairType[1]) } />
                                    <InfoItem label="Прямота" value={ getValueById(info.hairType.option3, order.hairType[2]) } />
                                    <InfoItem label="Натуральность" value={ getValueById(info.hairType.option4, order.hairType[3]) } />
                                    <InfoItem label="Густота" value={ getValueById(info.density, order.density) } />
                                    <InfoItem label="Оттенок" value={ getValueById(info.shadeOfGloss, order.shadeOfGloss) } />
                                    <InfoItem label="Нежелательный оттенок" value={ shadeValue } />
                                    <InfoItem label="Седина на корни" value={ getValueById(info.grayHair, order.grayHair) } />
                                    <InfoItem label="Седина на длину" value={ getValueById(info.grayHair, order.lengthGrayHair) } />
                                    <InfoItem label="Когда красились" value={ getValueById(info.lastTimePainted, order.lastTimePainted) } />
                                    <InfoItem
                                        label="Тип красителя"
                                        // eslint-disable-next-line no-nested-ternary
                                        value={ order.isbrand
                                            ? order.brand === 'other' ? 'Подобрать' : `Подобрано(${brand})`
                                            : brand }
                                    />
                                    <InfoItem type="big" label="Основная проблема" value={ order.theMainProblem } />
                                    { order.theMainProblemImg && (
                                        <ImageList images={ order.theMainProblemImg } />
                                    ) }
                                </React.Fragment>
                            )
                        }
                    </div>
                </div>

                { /* ФОРМА ЭКСПЕРТА */ }
                {
                    // eslint-disable-next-line no-nested-ternary
                    user?.access === 'CLIENT'
                        ? order.status === Status.completed && expertForm(order.formType)
                        : order.status === Status.completed
                            ? expertForm(order.formType)
                            : order.status === Status.inOperation && user?.id === order.expert_id && expertForm(order.formType)
                }

                { /* Обратная связь от клиента */ }
                <OrderFeedback />

                { /* ОПЛАТА */ }
                <OrderPayment />

                { /* РАБОТА СО СТАТУСАМИ */ }
                <OrderStatusList />

                <Button
                    size="large"
                    className={ styles.return }
                    onClick={ goToOrdersPage }
                >
                    Вернуться к заявкам
                </Button>
            </div>

            {
                visibleModal && (
                    <CreateTicketModal
                        onClose={ handleChangeVisibleModal(false) }
                        orderId={ order.id }
                    />
                )
            }
        </Layout>
    );
};
