import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import {
    Button, Form, Input, Modal, ModalFuncProps, Typography,
} from 'antd';
import { signUp } from 'ducks/app/actions';
import { selectSignUpLoading } from 'ducks/app/selectors';

import { schema } from 'form-helpers/registration/schema';
import { RegistrationFormType } from 'form-helpers/registration/types';
import { getError, getErrorStatus } from 'form-helpers/validation';

import ym from 'react-yandex-metrika';
import styles from './styles.module.scss';

const { Title } = Typography;

const initialValues = schema.cast({});

interface Props extends ModalFuncProps {
    onCallback?: (...args: any[]) => any;
}

export const AuthRegister = ({
    title, visible, onCancel, onCallback,
}: Props): any => {
    const loading = useSelector(selectSignUpLoading);
    const put = useDispatch();

    const itemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const onSubmit = (values: RegistrationFormType) => {
        put(signUp({ ...values, phone: `+${values.phone.replaceAll(' ', '')}` }, onCallback));
        ym('reachGoal', 'push_entrance');
    };

    return (
        <Modal
            title={ title || undefined }
            visible={ visible }
            onCancel={ onCancel }
            okText="Зарегистрироваться"
            keyboard={ false }
            maskClosable={ false }
            footer={ null }
        >
            <Title level={ 2 }>Регистрация</Title>
            <Formik<RegistrationFormType>
                initialValues={ initialValues }
                onSubmit={ onSubmit }
                validationSchema={ schema }
            >
                { ({
                    handleSubmit,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    values,
                }) => {
                    const errorList = {
                        firstName: getError(errors, touched, true, 'firstName'),
                        lastName: getError(errors, touched, true, 'lastName'),
                        phone: getError(errors, touched, true, 'phone'),
                        email: getError(errors, touched, true, 'email'),
                        password: getError(errors, touched, true, 'password'),
                    };

                    const handleChangePhone = (value: string) => {
                        setFieldValue('phone', value || null, true);
                    };

                    return (
                        <Form
                            onFinish={ handleSubmit }
                            labelAlign="left"
                            { ...itemLayout }
                        >
                            <Form.Item
                                label="Ваше имя"
                                extra={ errorList.firstName }
                                validateStatus={ getErrorStatus(!!errorList.firstName) }
                            >
                                <Input
                                    name="firstName"
                                    placeholder="Ваше имя"
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Ваша фамилия"
                                extra={ errorList.lastName }
                                validateStatus={ getErrorStatus(!!errorList.lastName) }
                            >
                                <Input
                                    name="lastName"
                                    placeholder="Ваша фамилия"
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Ваш номер"
                                extra={ errorList.phone }
                                validateStatus={ getErrorStatus(!!errorList.phone) }
                            >
                                <PhoneInput
                                    country="ru"
                                    inputProps={ {
                                        name: 'phone',
                                        required: true,
                                    } }
                                    placeholder="+7(000)-000-00-00"
                                    onChange={ handleChangePhone }
                                    onBlur={ handleBlur }
                                    value={ values.phone }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Ваш E-mail"
                                extra={ errorList.email }
                                validateStatus={ getErrorStatus(!!errorList.email) }
                            >
                                <Input
                                    name="email"
                                    placeholder="Ваш E-mail"
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Пароль"
                                extra={ errorList.password }
                                validateStatus={ getErrorStatus(!!errorList.password) }
                            >
                                <Input
                                    name="password"
                                    placeholder="Пароль"
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                    type="password"
                                />
                            </Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={ loading }
                                className={ styles.btn_center }
                            >
                                Зарегистрироваться
                            </Button>
                            <p className={ styles.info }>
                                Регистрируясь на сайте вы соглашаетесь с &nbsp;
                                <a href="https://hairtone.ru/Soglasheniepolzovatelskoe.pdf" target="_blank" rel="noopener noreferer noreferrer">Пользовательским соглашением</a>&nbsp;
                                и &nbsp;
                                <a href="https://hairtone.ru/Politikakonfidencialnosti.pdf" target="_blank" rel="noopener noreferer noreferrer">Политикой конфедециальности</a>
                            </p>
                        </Form>
                    );
                } }
            </Formik>
        </Modal>
    );
};
