import React from 'react';

type Props = {
    to: string;
    children?: JSX.Element;
    target?: string;
    className?: 'object' | string;
    rest?: any;
};

export const DownloadLink = ({ to, children, ...rest }: Props) => (
    <a { ...rest } href={ to }>
        { children }
    </a>
);

export default DownloadLink;
