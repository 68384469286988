import { put, call } from 'redux-saga/effects';
import {
    getPromoCodeId,
    getPromoCodeIdError,
    getPromoCodeIdFinish,
} from 'ducks/promocode/actions';

import { fetchers } from 'api';

export function* getPromoCodeIdRequest({ payload }: ReturnType<typeof getPromoCodeId>) {
    try {
        const response = yield call(fetchers.getPromoCodeId, payload);

        yield put(getPromoCodeIdFinish(response));
    } catch (error) {
        yield put(getPromoCodeIdError());
    }
}
