import { put, call } from 'redux-saga/effects';

import {
    passwordRecovery,
    passwordRecoveryError,
    passwordRecoveryFinish,
} from 'ducks/app/actions';

import { fetchers } from 'api';

export function* passwordRecoveryRequest({ values }: ReturnType<typeof passwordRecovery>) {
    try {
        yield call(fetchers.passwordRecovery, values);
        yield put(passwordRecoveryFinish());
    } catch (error) {
        yield put(passwordRecoveryError());
    }
}
