import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {
    Form, Input, Button,
} from 'antd';

import { FormLayout } from 'components/ui/form-layout';

import { passwordRecovery } from 'ducks/app/actions';

import { selectPasswordRecovery } from 'ducks/app/selectors';

import { PasswordRecoveryPayload } from 'types/api';
import { getError, getErrorStatus } from 'form-helpers/validation';
import { ROUTES } from 'constants/routes';
import { history } from 'App';

import { getToken } from 'utils/token';

import { schema } from './schema';

const initialValues = schema.cast({});

export const PasswordRecovery = () => {
    const { loading, complete } = useSelector(selectPasswordRecovery);
    const put = useDispatch();

    useEffect(() => {
        if (getToken()) {
            history.push(ROUTES.MAIN.path);
        }
    }, []);

    const itemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const onSubmit = (values: PasswordRecoveryPayload) => {
        put(passwordRecovery(values));
    };

    if (complete) {
        return (
            <FormLayout title="Письмо с новым паролем направлено на вашу почту">
                <Button
                    type="primary"
                    onClick={ () => history.push(ROUTES.AUTH.path) }
                >
                    Вернуться к авторизации
                </Button>
            </FormLayout>
        );
    }

    return (
        <FormLayout title="Восстановление пароля">
            <Formik
                initialValues={ initialValues }
                onSubmit={ onSubmit }
                validationSchema={ schema }
            >
                { ({
                    handleSubmit,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                }) => {
                    const errorList = {
                        email: getError(errors, touched, true, 'email'),
                    };

                    return (
                        <Form
                            onFinish={ handleSubmit }
                            labelAlign="left"
                            { ...itemLayout }
                        >

                            <Form.Item
                                label="Ваш E-mail"
                                extra={ errorList.email }
                                validateStatus={ getErrorStatus(!!errorList.email) }
                            >
                                <Input
                                    name="email"
                                    placeholder="Ваш E-mail"
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                />
                            </Form.Item>

                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={ loading }
                            >
                                Отправить
                            </Button>
                        </Form>
                    );
                } }
            </Formik>
        </FormLayout>
    );
};
