export const OrderFeedbackActionTypes = {
    FEEDBACK_ORDER_START: 'FEEDBACK_ORDER_START',
    FEEDBACK_ORDER_ERROR: 'FEEDBACK_ORDER_ERROR',
    FEEDBACK_ORDER_FINISH: 'FEEDBACK_ORDER_FINISH',

    GET_FEEDBACK_ORDERS_START: 'GET_FEEDBACK_ORDERS_START',
    GET_FEEDBACK_ORDERS_ERROR: 'GET_FEEDBACK_ORDERS_ERROR',
    GET_FEEDBACK_ORDERS_FINISH: 'GET_FEEDBACK_ORDERS_FINISH',

    EDIT_FEEDBACK_ORDER_START: 'EDIT_FEEDBACK_ORDER_START',
    EDIT_FEEDBACK_ORDER_ERROR: 'EDIT_FEEDBACK_ORDER_ERROR',
    EDIT_FEEDBACK_ORDER_FINISH: 'EDIT_FEEDBACK_ORDER_FINISH',

    CLEAR_FEEDBACK: 'CLEAR_FEEDBACK',
} as const;
