import React from 'react';
import {
    Typography,
    Row,
    Col,
} from 'antd';

import { ExpertForm } from 'form-helpers/expert-form-lamination/types';
import { OrderBrandText } from 'types/orders';

import { CONDITIONERS } from 'constants/order';

import styles from './styles.module.scss';

type Props = {
    values: ExpertForm;
    instruction: any;
};

const { Title } = Typography;

export const ViewExpertForm = ({ values, instruction }: Props) => {
    const weightCalculated = values.weight ? (values.weight / 3).toFixed(0) : '';

    return (
        <React.Fragment>
            <Title level={ 3 }>{ OrderBrandText[values.brand] }</Title>
            <div className={ styles.formItem }>Краситель: <b>{ values.subTypeColorName }</b></div>
            <div className={ styles.formItem }>
                Цвет: <b>{ values.colorsName }({ weightCalculated } гр.)</b>
            </div>
            <div className={ styles.formItem }>Кондиционер: <b>{ CONDITIONERS[values.brand] }({ weightCalculated } гр.)</b></div>
            <div className={ styles.formItem }>Подтип окислителя: <b>{ values.subTypeOxidizerName }</b></div>
            <div className={ styles.formItem }>Окислитель: <b>{ values.oxidizerName }({ weightCalculated } гр.)</b></div>

            <Row>
                <Col
                    lg={ 14 }
                    md={ 14 }
                    sm={ 14 }
                    xs={ 24 }
                >
                    <div className={ styles.itemGroup }>
                        <Title level={ 5 }>Инструкция</Title>
                        <a href={ instruction?.url } target="_blank" rel="noopener noreferer noreferrer">
                            Скачать инструкцию - { instruction?.name }
                        </a>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};
