import { Status } from './status';
import { ShortUser } from './user';

export enum OrderFormType {
    'choosecolor' = 'choosecolor',
    'formula' = 'formula',
}
export enum OrderType {
    'choosecolor' = 'choosecolor',
    'coloringtinting' = 'coloringtinting',
    'lamination' = 'lamination',
}

export enum OrderTypeText {
    'choosecolor' = 'Подобрать цвет',
    'coloringtinting' = 'Окрашивание / Тонирование',
    'lamination' = 'Ламинирование',
}

export enum OrderTypeBrand {
    'estel' = 'estel',
    'londa' = 'londa',
    'wella' = 'wella',
    'schwarzkopfProf' = 'schwarzkopfProf',
    'kapousProf' = 'kapousProf',
    'keune' = 'keune',
    'other' = 'other',
}

export const OrderBrandText: any = {
    estel: 'Estel Professional',
    londa: 'Londa Professional',
    wella: 'Wella Professional',
    schwarzkopfProf: 'Schwarzkopf Professional',
    kapousProf: 'Kapous Professional',
    keune: 'Keune',
    other: 'Другое',
};

export type UploadImage = File | Image;

export type UploadList = UploadImage[];

export type Image = {
    name: string;
    uid: number;
    url: string;
};

export type OrderLoading = {
    get: boolean;
    create: boolean;
    edit: boolean;
    getDetail: boolean;
    remove: boolean;
    changeStatus: boolean;
    sendExpertForm: boolean;
    applyPromocode: boolean;
    payOnline: boolean;
    feedback: boolean;
};

export type Order = {
    id: number;
    form: string;
    formType: OrderType;
    image_before: Image[];
    image_after: Image[];
    image_before_error: number;
    image_after_error: number;
    hairType: string[];
    density: string;
    shadeOfGloss: string;
    brand: string;
    shade: string;
    shade_text: string;
    grayHair: string;
    lengthGrayHair: string;
    lastTimePainted: string;
    theMainProblem: string;
    theMainProblemImg: Image[];
    user: ShortUser;
    user_id: number;
    expert_id: number;
    dateLastEdit: string;
    score_id: number;
    dateCreate: Date;
    approximatePrice: number;
    status: Status;
    payment: number;
    isbrand: number;
    rejectionReason: string;
    imageManager?: any;
    messageManagerForClient?: string;
    isTest?: number;

    paymentInfo: {
        UID: string;
        defaultPrice: number;
        payment: number;
        paymentUrl: string | null;
        price: number;
        promocode: string | null;
        refund: number;
    };
    expert?: ShortUser;

    // Экспертные данные
    weight: number;
    conditioner: number | string;
    instruction: number | string;

    // На корни
    colors: {
        natural: number | null;
        desired: number | null;
        enhancer: number | null;
    };
    subTypeOxidizer: number | string;
    subTypeColor: number | string;
    weightRoots: number;
    oxidizer: number | string;
    oxidizerWeightRootsRatio: number;
    weightDesiredColorRoots: number;

    // На длину
    isLengthNeeded: number;
    isLengthColorEnhancerNeeded: number;
    lengthColorsSubType: number | null;
    lengthColors: {
        natural: number | null;
        desired: number | null;
        enhancer: number | null;
    };
    oxidizerWeightLengthRatio: number;
    lengthOxidizerSubType: number | null;
    lengthOxidizer: number | null;
    weightDesiredColorLength: number;
    aboutChooseColor: string;
};

export type ShortOrder = Pick<Order, 'expert' | 'brand' | 'dateCreate' | 'dateLastEdit' | 'expert_id' | 'form' | 'formType' | 'id' | 'payment' | 'score_id' | 'status' | 'user_id'>;

export type TOrderFeedback = {
    id: number;
    orderId: number;
    images: Image[];
    brand?: string;
    rootTone?: number;
    lengthTone?: number;
    shade?: string;
    luckyFormula?: number;
    status?: OrderFeedbackType;
    createAt: Date;
};

export enum OrderFeedbackType {
    'loading' = 'loading',
    'completed' = 'completed',
    'inwork' = 'inwork',
}

export enum OrderFeedbackTypeText {
    'loading' = 'Идет загрузка',
    'inwork' = 'В работе',
    'completed' = 'Готово',
}

export enum OrderShadeType {
    'cold' = 'cold',
    'warm' = 'warm',
    'neutral' = 'neutral',
}
export enum OrderShadeTypeText {
    'cold' = 'Холодный',
    'warm' = 'Теплый',
    'neutral' = 'Нейтральный',
}
