import { OrderFeedbackType } from 'types/orders';
import { ExpertFormFeedback } from './types';

export const getInitialValues = (
    orderFeedback: ExpertFormFeedback,
) => ({
    rootTone: orderFeedback.rootTone || 0,
    lengthTone: orderFeedback.lengthTone || 0,
    shade: orderFeedback.shade || '',
    luckyFormula: orderFeedback.luckyFormula || 0,
    status: orderFeedback.status || OrderFeedbackType.inwork,
});
