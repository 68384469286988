import React from 'react';

export const ReviewsItemRating = ({ rating }: any) => {
    const Stars = (count: number) => {
        const star = [];

        for (let i = 0; i < count; ++i) {
            star.push(i);
        }

        return star.map((item) => <div className="rating__item" key={ item } />);
    };

    return (
        <div className="reviews__item-rating">
            <div className="rating" key={ rating }>
                { Stars(rating) }
            </div>
        </div>
    );
};
