import React from 'react';
import DocumentMeta from 'react-document-meta';
import { LayoutLending } from 'components/ui/layout-lendiing';
import { getMeta } from 'constants/document';

const meta = getMeta({
    title: 'Cookie файлы',
    type: 'website',
});

const Cookie = React.memo(() => (
    <DocumentMeta { ...meta }>
        <LayoutLending>
            <div className="blog-top container">
                <h3>Уведомление об использовании cookie файлов</h3>
                <p>Наш сайт, как и большинство других, использует файлы cookie и другие похожие технологии (пиксельные тэги и т.п.), чтобы предоставлять услуги, наиболее отвечающие Вашим интересам и потребностям, а также собирать статистическую и маркетинговую информацию для анализа и совершенствования наших услуг и сайтов.<br /> При использовании данного сайта, вы подтверждаете свое согласие на использование файлов cookie и других похожих технологий в соответствии с настоящим Уведомлением.<br /> Если Вы не согласны, чтобы мы использовали данный тип файлов, Вы должны соответствующим образом установить настройки Вашего браузера или не использовать наш сайт.<br /> Обращаем Ваше внимание на то, что при блокировании или удалении cookie файлов, мы не можем гарантировать корректную работу нашего сайта в Вашем браузере.<br /> Cookie файлы, которые сохраняются через веб-сайт, не содержат сведений, на основании которых можно Вас идентифицировать.</p>
                <h3>Что такое файл cookie и другие похожие технологии</h3>
                <p>Файл cookie представляет собой небольшой текстовый файл, сохраняемый на вашем компьютере, смартфоне или другом устройстве, которое Вы используете для посещения интернет-сайтов.<br /> Некоторые посещаемые Вами страницы могут также собирать информацию, используя пиксельные тэги и веб-маяки, представляющие собой электронные изображения, называемые одно-пиксельными (1&times;1) или пустыми GIF-изображениями.</p>
                <p>Файлы cookie могут размещаться на вашем устройстве нами (&laquo;собственные&raquo; файлы cookie) или другими операторами (файлы cookie &laquo;третьих лиц&raquo;).</p>
                <p>Мы используем два вида файлов cookie на сайте: &laquo;cookie сессии&raquo; и &laquo;постоянные cookie&raquo;. Cookie сессии &mdash; это временные файлы, которые остаются на устройстве пока вы не покинете сайт. Постоянные cookie остаются на устройстве в течение длительного времени или пока вы вручную не удалите их (как долго cookie останется на вашем устройстве будет зависеть от продолжительности или &laquo;времени жизни&raquo; конкретного файла и настройки вашего браузера).</p>
                <h3>Cookie файлы бывают различных типов:</h3>
                <p><strong><em>Необходимые.</em></strong>&nbsp;Эти файлы нужны для обеспечения правильной работы сайта, использования его функций. Отключение использования таких файлов приведет к падению производительности сайта, невозможности использовать его компоненты и сервисы.<br /> <strong><em>Файлы cookie, относящиеся к производительности, эффективности и аналитике.</em></strong><em>&nbsp;</em>Данные файлы позволяют анализировать взаимодействие посетителей с сайтом, оптимизировать содержание сайта, измерять эффективность рекламных кампаний, предоставляя информацию о количестве посетителей сайта, времени его использования, возникающих ошибках.<br /> <strong><em>Функциональные файлы cookie</em></strong>&nbsp;запоминают пользователей, которые уже заходили на наш сайт, их индивидуальные параметры (такие как язык и регион, например) и предпочтения, и помогают индивидуализировать содержание сайта.</p>
            </div>
        </LayoutLending>
    </DocumentMeta>
));

export default Cookie;
