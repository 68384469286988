import { message } from 'antd';
import { put, call } from 'redux-saga/effects';
import {
    getPromoCode,
    updatePromoCode,
    updatePromoCodeFinish,
    updatePromoCodeError,
} from 'ducks/promocode/actions';

import { fetchers } from 'api';

export function* updatePromocodeRequest({ payload, cb }: ReturnType<typeof updatePromoCode>) {
    try {
        const res = yield call(fetchers.updatePromocode, payload);

        message.open({ type: 'success', content: res });

        yield put(updatePromoCodeFinish());
        yield put(getPromoCode({ pagination: { page: 1 } }));
        cb(false);
    } catch (error) {
        yield put(updatePromoCodeError());
    }
}
