import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import { Formik } from 'formik';
import {
    Form,
    Typography,
    Input,
    Button,
} from 'antd';

import { createOrder, editOrder } from 'ducks/orders/actions';

import { UploadFile } from 'components/upload-file';

import { CreateOrderForm } from 'form-helpers/order/types';
import { getInitialValues } from 'form-helpers/order/mapping-initial-values';
import { orderFormFields } from 'form-helpers/order/fields';
import { schema } from 'form-helpers/order/schema';
import { getError, getErrorStatus } from 'form-helpers/validation';

import { history } from 'App';

import {
    OrderType,
    Image,
    Order,
    OrderLoading,
    OrderFormType,
} from 'types/orders';
import { Status } from 'types/status';

import { optimizeDataImage } from 'form-helpers/utils';
import { ROUTES } from 'constants/routes';
import { getImageErrors } from 'utils/orders';

import { HELPER_TEXT } from 'constants/text';
import styles from './styles.module.scss';

const { Title } = Typography;
const { TextArea } = Input;

const initialValues = schema.cast({});

const getLabel = (text: string) => (
    <Title className={ styles.label } level={ 5 }>{ text }</Title>
);

type TProps = {
    ordersLoading: OrderLoading;
    order: Order | null;
};

export const CreateOrderChoosecolor = ({ order, ordersLoading }: TProps) => {
    const put = useDispatch();

    const onSubmit = useCallback((values: CreateOrderForm) => {
        const data = cloneDeep(values);

        if (data.image_before) {
            data.image_before = optimizeDataImage(data.image_before);
        }

        data.form = OrderFormType.choosecolor;
        data.formType = OrderType.choosecolor;

        forEach(data, (value, field) => {
            if (!orderFormFields.includes(field)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                delete data[field];
            }
        });

        if (values.id) {
            if (values.status === Status.errorImages) data.status = Status.new;
            put(editOrder(data));
        } else {
            put(createOrder(data));
        }
    }, []);

    const goToOrdersPage = useCallback(() => history.push(ROUTES.MAIN.path), []);

    const isEditDisabled = !!order && order?.status !== Status.new && order?.status !== Status.errorImages && order?.status !== Status.notFilledFields;
    const isChangeImageDisabled = !!order && order?.status !== Status.new && order?.status !== Status.errorImages && order?.status !== Status.notFilledFields;

    const buttonText = order?.status === Status.errorImages ? 'Сохранить и отправить на проверку' : 'Сохранить';
    const imageErrors = getImageErrors(order?.image_before_error, order?.image_after_error, styles.imageError);

    return (
        <Formik<CreateOrderForm>
            key="create-order"
            initialValues={ getInitialValues(initialValues, order as Order) }
            onSubmit={ onSubmit }
            validationSchema={ schema }
        >
            { ({
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                errors,
                touched,
            }) => {
                const errorList = {
                    image_before: getError(errors, touched, true, 'image_before'),
                    aboutChooseColor: getError(errors, touched, true, 'aboutChooseColor'),
                };

                const handleChangeTextarea = (e: any) => {
                    const event = {
                        target: {
                            name: e.target.name,
                            value: e.target.value,
                            id: e.target.id,
                        },
                    };

                    handleChange(event);
                };

                const onChangeFile = (name: string) => (file: any) => {
                    setFieldValue(name, file, true);
                };

                return (
                    <Form
                        onFinish={ handleSubmit }
                        labelAlign="left"
                        layout="vertical"
                        className={ styles.creatingOrder }
                    >
                        { imageErrors.any.message }
                        <Form.Item
                            label={ getLabel('Загрузите 3 фотографии ваших волос') }
                            className={ styles.formItem }
                            extra={ errorList.image_before || imageErrors.before.message }
                            validateStatus={ getErrorStatus(!!errorList.image_before) }
                            tooltip={ HELPER_TEXT.ORDER_IMAGE_TOOLTIP_FOR_COLOR }
                        >
                            <UploadFile
                                onChange={ onChangeFile('image_before') }
                                count={ 3 }
                                defaultValue={ values.image_before as Image[] }
                                disabled={ isChangeImageDisabled }
                            />
                        </Form.Item>
                        <Form.Item
                            label={ getLabel('Комментарий') }
                            tooltip="Информация, которая поможет нам узнать вас немного лучше: расскажите о предыдущих прическах (удачных и неудачных), с какими оттенками вы чувствуете себя наиболее комфортно или наименее комфортно, какой стиль одежды и цветовую гамму чаще всего предпочитаете, ваши хобби. Если есть определенные пожелания, опишите их"
                            className={ styles.formItem }
                            extra={ errorList.aboutChooseColor }
                            validateStatus={ getErrorStatus(!!errorList.aboutChooseColor) }
                        >
                            <TextArea
                                id="aboutChooseColor"
                                name="aboutChooseColor"
                                onChange={ handleChangeTextarea }
                                autoSize={ { minRows: 4, maxRows: 6 } }
                                placeholder="Информация, которая поможет нам узнать вас немного лучше: расскажите о предыдущих прическах (удачных и неудачных), с какими оттенками вы чувствуете себя наиболее комфортно или наименее комфортно, какой стиль одежды и цветовую гамму чаще всего предпочитаете, ваши хобби. Если есть определенные пожелания, опишите их."
                                defaultValue={ values.aboutChooseColor }
                                disabled={ isEditDisabled }
                            />
                        </Form.Item>

                        <div className={ styles.creatingOrder_buttons }>
                            <Button
                                size="large"
                                className={ styles.return }
                                onClick={ goToOrdersPage }
                            >
                                Назад
                            </Button>
                            <Button
                                htmlType="submit"
                                type="primary"
                                loading={ ordersLoading.create || ordersLoading.edit }
                                size="large"
                            >
                                { buttonText }
                            </Button>
                        </div>
                    </Form>
                );
            } }
        </Formik>
    );
};
