import { message } from 'antd';
import { put, call } from 'redux-saga/effects';
import {
    createBlog,
    createBlogError,
    createBlogFinish,
} from 'ducks/blog/actions';
import { fetchers } from 'api';

export function* createBlogRequest({ payload, Callback }: ReturnType<typeof createBlog>) {
    try {
        const response = yield call(fetchers.createBlog, payload);

        if (response.type === 'success') {
            message.success(response.message);
            if (Callback) Callback();
            yield put(createBlogFinish(response.data));
        } else {
            message.error(response.message);
        }
    } catch (error) {
        yield put(createBlogError());
    }
}
