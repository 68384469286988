import { ApplicationState } from 'ducks/application-state';

export const selectBlog = (state: ApplicationState) => state.blog.data;
export const selectBlogLoading = (state: ApplicationState) => state.blog.loading;
export const selectBlogPagination = (state: ApplicationState) => state.blog.pagination;
export const selectBlogFilter = (state: ApplicationState) => state.blog.filter;
export const selectBlogCategories = (state: ApplicationState) => state.blog.categories;
export const selectBlogDetail = (state: ApplicationState) => state.blog.detail;
export const selectBlogTagsList = (state: ApplicationState) => state.blog.tags;
export const selectBlogSimilar = (state: ApplicationState) => state.blog.similar;
export const selectBlogEditTags = (state: ApplicationState) => state.blog.editTags;
