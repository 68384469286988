import { ApplicationState } from 'ducks/application-state';

export const selectReviews = (state: ApplicationState) => state.reviews.list;
export const selectReviewsLoading = (state: ApplicationState) => state.reviews.loading;
export const selectReviewsPagination = (state: ApplicationState) => state.reviews.pagination;
export const selectReviewsSort = (state: ApplicationState) => state.reviews.sort;

export const selectReviewsLending = (state: ApplicationState) => state.reviews.data;
export const selectReviewsLendingLoading = (state: ApplicationState) => state.reviews.loading;
export const selectReviewsSize = (state: ApplicationState) => state.reviews.size;
export const selectReviewsAverageRating = (state: ApplicationState) => state.reviews.averageRating;
