import { put, call } from 'redux-saga/effects';

import {
    saveUser,
    saveUserError,
    saveUserFinish,
} from 'ducks/user/actions';

import { fetchers } from 'api';
import { message } from 'antd';

export function* saveUserRequest({ payload }: ReturnType<typeof saveUser>) {
    try {
        yield call(fetchers.saveUser, payload);
        message.success('Данные пользователя обновлены');

        yield put(saveUserFinish());
    } catch (error) {
        yield put(saveUserError());
    }
}
