import { InferValueTypes } from 'types/common';
import { Reviews } from 'types/reviews';
import { ReviewsActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type ReviewsState = {
    loading: {
        lending: boolean;
        get: boolean;
        edit: boolean;
    };
    list: Reviews[];
    data: Reviews[];
    size: number;
    averageRating: number;
    pagination: {
        pageSize: number;
        total: number;
        page: number;
    };
    sort: {
        sortField?: string;
        sortOrder?: string;
    };
};

export const initialState: ReviewsState = {
    loading: {
        lending: false,
        get: false,
        edit: false,
    },
    list: [],
    data: [],
    size: 0,
    averageRating: 0,
    pagination: {
        pageSize: 8,
        total: 0,
        page: 1,
    },
    sort: {
        sortField: 'status',
        sortOrder: 'asc',
    },
};

export function reviewsReducer(state = initialState, action: ActionTypes): ReviewsState {
    switch (action.type) {
        case ReviewsActionTypes.GET_REVIEWS_LENDING_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    lending: true,
                },
            };
        case ReviewsActionTypes.GET_REVIEWS_LENDING_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    lending: true,
                },
            };

        case ReviewsActionTypes.GET_REVIEWS_LENDING_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    lending: false,
                },
                size: action.response.size,
                averageRating: action.response.averageRating,
                data: Array.isArray(state.data) ? [...state.data, ...action.response.data] : action.response.data,
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
            };

        case ReviewsActionTypes.GET_REVIEWS_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
            };
        case ReviewsActionTypes.GET_REVIEWS_FINISH:
            return {
                ...state,
                list: action.response.data,
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
                sort: action.response.sort,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case ReviewsActionTypes.GET_REVIEWS_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case ReviewsActionTypes.EDIT_REVIEWS_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: true,
                },
            };
        case ReviewsActionTypes.EDIT_REVIEWS_FINISH:
            return {
                ...state,
                list: state.list.map((item) => (item.id === action.item.id ? { ...action.item } : item)),
                loading: {
                    ...state.loading,
                    edit: false,
                },
            };

        case ReviewsActionTypes.EDIT_REVIEWS_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,

                },
            };

        default: {
            return state;
        }
    }
}
