import { put, call } from 'redux-saga/effects';
import { message } from 'antd';
import {
    signIn,
    signUp,
    signUpError,
    signUpFinish,
} from 'ducks/app/actions';

import { fetchers } from 'api';
import { ResponseGenerator } from 'types/api';

export function* signUpRequest({ values, onCallback }: ReturnType<typeof signUp>) {
    try {
        const res: ResponseGenerator = yield call(fetchers.signUp, values);

        if (res.type === 'success') {
            message.success(res.message);
            if (onCallback) {
                yield put(signIn({ email: values.email, password: values.password }, onCallback));
            } else {
                yield put(signIn({ email: values.email, password: values.password }));
            }
        } else {
            message.error(res.message);
        }
        yield put(signUpFinish());
    } catch (error) {
        yield put(signUpError());
    }
}
