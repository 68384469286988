import { put, call, select } from 'redux-saga/effects';

import { GetPromoCodeResponse } from 'types/api';

import {
    getPromoCode,
    getPromoCodeError,
    getPromoCodeFinish,
} from 'ducks/promocode/actions';

import { selectPromoCodePagination } from 'ducks/promocode/selectors';

import { fetchers } from 'api';

export function* getPromoCodeRequest({ payload }: ReturnType<typeof getPromoCode>) {
    try {
        const { pageSize }: ReturnType<typeof selectPromoCodePagination> = yield select(selectPromoCodePagination);
        const response: GetPromoCodeResponse = yield call(fetchers.getPromocode, {
            ...payload,
            pagination: {
                ...payload.pagination,
                pageSize: payload.pagination.pageSize || pageSize,
            },
        });

        yield put(getPromoCodeFinish({
            data: response.data,
            pagination: {
                total: response.pagination.total,
                current: payload.pagination.page,
            },
        }));
    } catch (error) {
        yield put(getPromoCodeError());
    }
}
