import React, { useCallback } from 'react';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { Typography, Form, Input } from 'antd';
import { getOrder, sendExpertOrderForm } from 'ducks/orders/actions';
import { selectOrder, selectOrdersLoading } from 'ducks/orders/selectors';
import { selectProfile } from 'ducks/user/selectors';

import { Order } from 'types/orders';
import { Status } from 'types/status';

import { ExpertForm } from 'form-helpers/expert-form-choosecolor/types';
import { getInitialValues } from 'form-helpers/expert-form-choosecolor/mapping';
import { checkEditExpertField } from 'utils/orders';

import { ExpertOrderFormPayload } from 'types/api';
import { debounce } from 'form-helpers/utils';
import { UploadFileANek } from 'components/ui/upload-file';
import { ViewExpertForm } from './view';
import styles from './styles.module.scss';

const { Title } = Typography;
const { TextArea } = Input;

export const OrderExpertFormChoosecolor = () => {
    const put = useDispatch();
    const order = useSelector(selectOrder) as Order;
    const user = useSelector(selectProfile);
    const { sendExpertForm } = useSelector(selectOrdersLoading);

    const handleUpdateExpertForm = useCallback(debounce((values: any) => {
        const onlyFields: { [key: string]: any } = {};

        Object.keys(values).forEach((key) => {
            onlyFields[key] = values[key];
        });

        put(sendExpertOrderForm({
            id: order.id,
            ...onlyFields,
        } as ExpertOrderFormPayload));
    }, 700), []);

    return (
        <div className={ styles.container }>
            <Formik<ExpertForm>
                initialValues={ getInitialValues(order) }
                onSubmit={ () => { } }
            >
                { ({
                    values,
                    setValues,
                }) => {
                    const updateForm = (newValues?: any) => {
                        handleUpdateExpertForm(newValues || values);
                    };

                    const handleChangeTextarea = (e: any) => {
                        const { name, value } = e.target;

                        const updValues = {
                            ...values,
                            [name]: value,
                        };

                        setValues(updValues);
                        updateForm(updValues);
                    };

                    const editable = (
                        <React.Fragment>
                            <div className={ styles.itemGroup }>
                                <Title level={ 5 }>ПОДОБРАНО КОЛОРИСТОМ</Title>
                                <Form.Item
                                    className={ styles.formItem }
                                    help="Загрузите фотографии (макс 10шт)"
                                >
                                    <UploadFileANek
                                        action={
                                            {
                                                id: order.id,
                                                table: 'order',
                                                field: 'imageManager',
                                            }
                                        }
                                        count={ 10 }
                                        multiple={ true }
                                        accept=".png,.jpg,.jpeg"
                                        defaultList={ values.imageManager }
                                        callbackAfterLoad={ () => put(getOrder({ orderId: +order.id })) }
                                    />
                                </Form.Item>
                            </div>
                            <div className={ styles.itemGroup }>
                                <Title level={ 5 }>Комментарий</Title>
                                <Form.Item
                                    className={ styles.formItem }
                                >
                                    <TextArea
                                        id="messageManagerForClient"
                                        name="messageManagerForClient"
                                        onChange={ handleChangeTextarea }
                                        autoSize={ { minRows: 4, maxRows: 6 } }
                                        placeholder="Ваше сообщение для клиента."
                                        defaultValue={ values.messageManagerForClient }
                                    />
                                </Form.Item>
                            </div>
                        </React.Fragment>
                    );

                    return (
                        <Form
                            labelAlign="left"
                            layout="vertical"
                            className={ cn({ [styles.formLoading]: sendExpertForm }) }
                        >
                            {
                                order.status === Status.completed
                                    ? <ViewExpertForm values={ order } />
                                    : checkEditExpertField(
                                        user!.access_list,
                                        order.status,
                                        user!.id,
                                        order.expert_id,
                                        editable,
                                        null,
                                    )
                            }

                        </Form>
                    );
                } }
            </Formik>
        </div>
    );
};
