import * as yup from 'yup';
import { OrderType } from 'types/orders';

// import { REQUIRED_FIELD } from 'form-helpers/message';

export const schema = yup.object().shape({
    formType: yup.string().default(OrderType.choosecolor),
    image_before: yup.array().nullable(),
    image_after: yup.array().nullable(),
    density: yup.string().nullable(),
    shadeOfGloss: yup.string().nullable().default(''),
    brand: yup.string().nullable().default('other'),
    shade: yup.string().nullable().default(''),
    grayHair: yup.string().nullable().default(''),
    lastTimePainted: yup.string().nullable().default(''),
    theMainProblem: yup.string().nullable().default(''),
    aboutChooseColor: yup.string().nullable().default(''),
    theMainProblemImg: yup.object().nullable(),
    hairType: yup.array().default([]),
});
