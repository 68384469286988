import {
    CreateTicketPayload,
    GetTicketMessagePayload,
    GetTicketPayload,
    GetTicketsResponse,
    SendTicketMessagePayload,
    ChangeTicketStatusPayload,
    GetTicketsPayload,
} from 'types/api';
import { Ticket, TicketMessage, TicketStatus } from 'types/ticket';
import { TicketsActionTypes } from './action-types';

export const getTickets = (payload: GetTicketsPayload) => ({
    type: TicketsActionTypes.GET_TICKETS_START,
    payload,
});

export const getTicketsError = () => ({
    type: TicketsActionTypes.GET_TICKETS_ERROR,
});

export const getTicketsFinish = (response: GetTicketsResponse) => ({
    type: TicketsActionTypes.GET_TICKETS_FINISH,
    response,
});

export const getTicket = (payload: GetTicketPayload) => ({
    type: TicketsActionTypes.GET_TICKET_START,
    payload,
});

export const getTicketError = () => ({
    type: TicketsActionTypes.GET_TICKET_ERROR,
});

export const getTicketFinish = (response: Ticket) => ({
    type: TicketsActionTypes.GET_TICKET_FINISH,
    response,
});

export const createTicket = (data: CreateTicketPayload, isFromOrder: boolean, callback: () => void) => ({
    type: TicketsActionTypes.CREATE_TICKET_START,
    data,
    isFromOrder,
    callback,
});

export const createTicketError = () => ({
    type: TicketsActionTypes.CREATE_TICKET_ERROR,
});

export const createTicketFinish = () => ({
    type: TicketsActionTypes.CREATE_TICKET_FINISH,
});

export const getTicketMessages = (payload: GetTicketMessagePayload) => ({
    type: TicketsActionTypes.GET_TICKET_MESSAGES_START,
    payload,
});

export const getTicketMessagesError = () => ({
    type: TicketsActionTypes.GET_TICKET_MESSAGES_ERROR,
});

export const getTicketMessagesFinish = (response: TicketMessage[]) => ({
    type: TicketsActionTypes.GET_TICKET_MESSAGES_FINISH,
    response,
});

export const sendTicketMessage = (payload: SendTicketMessagePayload, callback: () => void) => ({
    type: TicketsActionTypes.SEND_TICKET_MESSAGES_START,
    payload,
    callback,
});

export const sendTicketMessageError = () => ({
    type: TicketsActionTypes.SEND_TICKET_MESSAGES_ERROR,
});

export const sendTicketMessageFinish = (message: TicketMessage) => ({
    type: TicketsActionTypes.SEND_TICKET_MESSAGES_FINISH,
    message,
});

export const changeTicketStatus = (payload: ChangeTicketStatusPayload) => ({
    type: TicketsActionTypes.CHANGE_TICKET_STATUS_START,
    payload,
});

export const changeTicketStatusError = () => ({
    type: TicketsActionTypes.CHANGE_TICKET_STATUS_ERROR,
});

export const changeTicketStatusFinish = (status: TicketStatus) => ({
    type: TicketsActionTypes.CHANGE_TICKET_STATUS_FINISH,
    status,
});

export const getCountNewMessage = () => ({
    type: TicketsActionTypes.GET_COUNT_NEW_MESSAGE_START,
});

export const getCountNewMessageError = () => ({
    type: TicketsActionTypes.GET_COUNT_NEW_MESSAGE_ERROR,
});

export const getCountNewMessageFinish = (count: number) => ({
    type: TicketsActionTypes.GET_COUNT_NEW_MESSAGE_FINISH,
    count,
});
