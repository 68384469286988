import { all, takeEvery } from 'redux-saga/effects';
import { PasswordRecoveryActionTypes, SignInActionTypes, SignUpActionTypes } from 'ducks/app/action-types';
import { OrdersActionTypes } from 'ducks/orders/action-types';
import { InfoActionTypes } from 'ducks/info/action-types';
import { PromoCodeActionTypes } from 'ducks/promocode/action-types';
import { UserActionTypes } from 'ducks/user/action-types';
import { PriceActionTypes } from 'ducks/price/action-types';
import { TicketsActionTypes } from 'ducks/tickets/action-types';
import { StatisticsActionTypes } from 'ducks/statistics/action-types';
import { ReviewsActionTypes } from 'ducks/reviews/action-types';
import { BlogActionTypes } from 'ducks/blog/action-types';

import { FaqActionTypes } from 'ducks/faq/action-types';
import { AdvertisingTypes } from 'ducks/advertising/action-types';

import { OrderFeedbackActionTypes } from 'ducks/orderFeedback/action-types';
import { signUpRequest } from './app/sign-up';
import { signInRequest } from './app/sign-in';
import { passwordRecoveryRequest } from './app/password-recovery';

import { getInfoRequest } from './info/get-info';
import { getColorsRequest } from './info/get-colors';

import { getOrdersRequest } from './orders/get-orders';
import { createOrderRequest } from './orders/create-order';
import { editOrderRequest } from './orders/edit-order';
import { getOrderRequest } from './orders/get-order';
import { removeOrderRequest } from './orders/remove-order';
import { sendExpertOrderFormRequest } from './orders/send-expert-form';
import { applyPromocodeToOrderRequest } from './orders/apply-promocode';
import { payOrderOnlineRequest } from './orders/pay-online';

import { changeOrderStatusRequest } from './orders/change-status';
import { getInfoPromoCodeRequest } from './promocode/get-info';
import { getPromoCodeRequest } from './promocode/get-promocode';
import { getPromoCodeIdRequest } from './promocode/get-promoId';
import { editPromocodeRequest } from './promocode/edit-promocode';
import { removePromoCodeRequest } from './promocode/remove-promocode';
import { createPromocodeRequest } from './promocode/create-promocode';
import { updatePromocodeRequest } from './promocode/update-promocode';
import { getPromocodeLendingRequest } from './promocode/get-promocode-linding';

import { getUserRequest } from './user/get-user';
import { getUsersRequest } from './user/get-users';
import { saveUserRequest } from './user/save-user';
import { getProfileRequest } from './user/get-profile';

import { getPriceRequest } from './price/get-price';
import { createPriceRequest } from './price/create-price';
import { editPriceRequest } from './price/edit-price';
import { removePriceRequest } from './price/delete-price';

import { getTicketsRequest } from './tickets/get-tickets';
import { createTicketRequest } from './tickets/create-ticket';
import { getTicketRequest } from './tickets/get-ticket';
import { getTicketMessagesRequest } from './tickets/get-ticket-messages';
import { sendTicketMessageRequest } from './tickets/send-ticket-message';
import { changeTicketStatusRequest } from './tickets/change-ticket-status';
import { getCountNewMessageRequest } from './tickets/get-count-new-message';
import { getFaqRequest } from './faq/get-faq';
import { getReviewsRequest } from './reviews/get-reviews';
import { createReviewsRequest } from './reviews/create-reviews';
import { editReviewsRequest } from './reviews/edit-reviews';
import { getReviewsLendingRequest } from './reviews/get-reviews-lending';

import { getStatisticsRequest } from './statistics/get-statistics';

import { getBlogRequest } from './blog/get-blog';
import { getBlogCategoriesRequest } from './blog/get-blog-categories';
import { getBlogDetailRequest } from './blog/get-blog-detail';
import { createBlogRequest } from './blog/create-blog';
import { removeBlogRequest } from './blog/remove-blog';
import { editBlogRequest } from './blog/edit-blog';
import { likeBlogRequest } from './blog/like-blog';
import { removeTagsBlogRequest } from './blog/remove-tags-blog';
import { getBlogSimilarRequest } from './blog/get-blog-similar';
import { getOrderFeedbakRequest } from './orderFeedbak/get-feedbak';
import { sendExpertOrderFeedbackFormRequest } from './orderFeedbak/expert-feedback';
import { getOrdersFeedbackRequest } from './orderFeedbak/gets-feedbak';
import { createAdvertisingPromocodeRequest } from './advertising/sales';

export default function* sagas() {
    yield all([
        takeEvery(UserActionTypes.GET_PROFILE_START, getProfileRequest),
        takeEvery(UserActionTypes.GET_USERS_START, getUsersRequest),
        takeEvery(UserActionTypes.GET_USER_START, getUserRequest),
        takeEvery(UserActionTypes.SAVE_USER_START, saveUserRequest),
        takeEvery(SignUpActionTypes.SIGN_UP_START, signUpRequest),
        takeEvery(SignInActionTypes.SIGN_IN_START, signInRequest),
        takeEvery(PasswordRecoveryActionTypes.PASSWORD_RECOVERY_START, passwordRecoveryRequest),
        // СПРАВОЧНИКИ
        takeEvery(InfoActionTypes.GET_INFO_START, getInfoRequest),
        takeEvery(InfoActionTypes.GET_COLORS_START, getColorsRequest),
        // ПРОМОКОДЫ
        takeEvery(PromoCodeActionTypes.GET_PROMOCODE_LENDING_START, getPromocodeLendingRequest),
        takeEvery(PromoCodeActionTypes.GET_PROMOCODE_START, getInfoPromoCodeRequest),
        takeEvery(PromoCodeActionTypes.GET_PROMO_START, getPromoCodeRequest),
        takeEvery(PromoCodeActionTypes.REMOVE_PROMO_START, removePromoCodeRequest),
        takeEvery(PromoCodeActionTypes.CREATE_PROMOCODE_START, createPromocodeRequest),
        takeEvery(PromoCodeActionTypes.GET_PROMO_DATAIL_START, getPromoCodeIdRequest),
        takeEvery(PromoCodeActionTypes.EDIT_PROMOCODE_START, editPromocodeRequest),
        takeEvery(PromoCodeActionTypes.UPDATE_PROMOCODE_START, updatePromocodeRequest),
        // ЗАЯВКА
        takeEvery(OrdersActionTypes.GET_ORDERS_START, getOrdersRequest),
        takeEvery(OrdersActionTypes.CREATE_ORDER_START, createOrderRequest),
        takeEvery(OrdersActionTypes.EDIT_ORDER_START, editOrderRequest),
        takeEvery(OrdersActionTypes.GET_ORDER_START, getOrderRequest),
        takeEvery(OrdersActionTypes.REMOVE_ORDER_START, removeOrderRequest),
        takeEvery(OrdersActionTypes.CHANGE_ORDER_STATUS_START, changeOrderStatusRequest),
        takeEvery(OrdersActionTypes.SEND_EXPERT_ORDER_FORM_START, sendExpertOrderFormRequest),
        takeEvery(OrdersActionTypes.APPLY_PROMOCODE_TO_ORDER_START, applyPromocodeToOrderRequest),
        takeEvery(OrdersActionTypes.PAY_ORDER_ONLINE_START, payOrderOnlineRequest),
        // ЦЕНЫ
        takeEvery(PriceActionTypes.GET_PRICE_START, getPriceRequest),
        takeEvery(PriceActionTypes.CREATE_PRICE_START, createPriceRequest),
        takeEvery(PriceActionTypes.EDIT_PRICE_START, editPriceRequest),
        takeEvery(PriceActionTypes.REMOVE_PRICE_START, removePriceRequest),
        // ТИКЕТЫ
        takeEvery(TicketsActionTypes.GET_TICKETS_START, getTicketsRequest),
        takeEvery(TicketsActionTypes.GET_TICKET_START, getTicketRequest),
        takeEvery(TicketsActionTypes.CREATE_TICKET_START, createTicketRequest),
        takeEvery(TicketsActionTypes.GET_TICKET_MESSAGES_START, getTicketMessagesRequest),
        takeEvery(TicketsActionTypes.SEND_TICKET_MESSAGES_START, sendTicketMessageRequest),
        takeEvery(TicketsActionTypes.CHANGE_TICKET_STATUS_START, changeTicketStatusRequest),
        takeEvery(TicketsActionTypes.GET_COUNT_NEW_MESSAGE_START, getCountNewMessageRequest),

        // ВОПРОСЫ ОТВЕТЫ
        takeEvery(FaqActionTypes.GET_FAQ_START, getFaqRequest),

        // СТАТИСТИКА
        takeEvery(StatisticsActionTypes.GET_STATISTICS_START, getStatisticsRequest),

        takeEvery(ReviewsActionTypes.GET_REVIEWS_LENDING_START, getReviewsLendingRequest),
        takeEvery(ReviewsActionTypes.GET_REVIEWS_START, getReviewsRequest),
        takeEvery(ReviewsActionTypes.CREATE_REVIEWS_START, createReviewsRequest),
        takeEvery(ReviewsActionTypes.EDIT_REVIEWS_START, editReviewsRequest),
        // blog
        takeEvery(BlogActionTypes.GET_BLOG_START, getBlogRequest),
        takeEvery(BlogActionTypes.GET_BLOG_CATEGORIES_START, getBlogCategoriesRequest),
        takeEvery(BlogActionTypes.GET_BLOG_DETAIL_START, getBlogDetailRequest),
        takeEvery(BlogActionTypes.GET_BLOG_SIMILAR_START, getBlogSimilarRequest),
        takeEvery(BlogActionTypes.CREATE_BLOG_START, createBlogRequest),
        takeEvery(BlogActionTypes.EDIT_BLOG_START, editBlogRequest),
        takeEvery(BlogActionTypes.REMOVE_BLOG_START, removeBlogRequest),
        takeEvery(BlogActionTypes.LIKE_BLOG_START, likeBlogRequest),
        takeEvery(BlogActionTypes.DELETE_BLOG_TAG_START, removeTagsBlogRequest),

        takeEvery(OrderFeedbackActionTypes.FEEDBACK_ORDER_START, getOrderFeedbakRequest),
        takeEvery(OrderFeedbackActionTypes.EDIT_FEEDBACK_ORDER_START, sendExpertOrderFeedbackFormRequest),
        takeEvery(OrderFeedbackActionTypes.GET_FEEDBACK_ORDERS_START, getOrdersFeedbackRequest),

        takeEvery(AdvertisingTypes.ADD_ADVERTISING_START, createAdvertisingPromocodeRequest),
    ]);
}
