import { put, call } from 'redux-saga/effects';
import {
    editPrice,
    editPriceError,
    editPriceFinish,
    getPrice,
} from 'ducks/price/actions';

import { fetchers } from 'api';

export function* editPriceRequest({ data, callback }: ReturnType<typeof editPrice>) {
    try {
        yield call(fetchers.editPrice, data);
        yield put(editPriceFinish());
        callback();
        yield put(getPrice());
    } catch (error) {
        yield put(editPriceError());
    }
}
