import { call, put } from 'redux-saga/effects';
import {
    getCountNewMessageError,
    getCountNewMessageFinish,
} from 'ducks/tickets/actions';

import { fetchers } from 'api';

export function* getCountNewMessageRequest() {
    try {
        const response = yield call(fetchers.getCountNewMessage);

        yield put(getCountNewMessageFinish(response));
    } catch (error) {
        yield put(getCountNewMessageError());
    }
}
