import { put, call } from 'redux-saga/effects';
import {
    removeBlogTags,
    removeBlogTagsError,
    removeBlogTagsFinish,
} from 'ducks/blog/actions';
import { fetchers } from 'api';

export function* removeTagsBlogRequest({ tags }: ReturnType<typeof removeBlogTags>) {
    try {
        yield call(fetchers.removeTagBlog, tags);

        yield put(removeBlogTagsFinish(tags));
    } catch (error) {
        yield put(removeBlogTagsError());
    }
}
