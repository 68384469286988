import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Pagination, Table, Typography,
} from 'antd';

import { getInfo } from 'ducks/info/actions';
import { getOrders, removeOrder } from 'ducks/orders/actions';
import {
    selectOrderFilters, selectOrders, selectOrdersLoading, selectOrdersPagination,
} from 'ducks/orders/selectors';
import { selectInfo } from 'ducks/info/selectors';
import { selectProfile } from 'ducks/user/selectors';

import { OrderFilter } from 'components/order-filter';
import { Layout } from 'components/ui/layout';

import { history } from 'App';

import { ROUTES } from 'constants/routes';
import { ACCESS } from 'constants/access';
import { checkAccess } from 'utils/access';

import { Cart } from 'components/ui/cart';
import { getColumns } from './columns';
import styles from './styles.module.scss';

const { Title } = Typography;

export const Main = React.memo(() => {
    const put = useDispatch();
    const info = useSelector(selectInfo);
    const orders = useSelector(selectOrders);
    const loading = useSelector(selectOrdersLoading);
    const pagination = useSelector(selectOrdersPagination);
    const filter = useSelector(selectOrderFilters);
    const user = useSelector(selectProfile);

    useEffect(() => {
        put(getOrders({ pagination: { page: 1 } }));
        put(getInfo());
    }, []);

    const goToCreateOrder = useCallback(() => {
        history.push(ROUTES.CREATE_ORDER.path);
    }, []);

    const onChangePagination = useCallback((page: number) => {
        put(getOrders({ pagination: { page }, filter }));
    }, [filter]);

    const onClickRow = useCallback((data: any) => () => {
        history.push(ROUTES.VIEW_ORDER.getPath({ id: data.id }));
    }, []);

    const goToEdit = useCallback((e: MouseEvent, id: number) => {
        e.stopPropagation();
        history.push(ROUTES.EDIT_ORDER.getPath({ id }));
    }, []);

    const handleDeleteOrder = useCallback((e: any, id: number) => {
        e.stopPropagation();
        put(removeOrder({ orderId: id }));
    }, []);

    const isCreateAllow = checkAccess(user?.access_list, ACCESS.CREATE_ORDER);
    const isEditAllow = checkAccess(user?.access_list, ACCESS.UPDATE_ORDER);
    const isDeleteAllow = checkAccess(user?.access_list, ACCESS.DELETE_ORDER);

    const columns = useMemo(() => getColumns(
        info,
        { handleDeleteOrder, goToEdit },
        { remove: loading.remove },
        { isEditAllow, isDeleteAllow },
        user!.id,
        user?.access === 'CLIENT',
    ), [info, user]);

    return (
        <Layout>
            <div className={ styles.header }>
                <Title level={ 2 }>Заказы рецептов</Title>
                { isCreateAllow && (
                    <Button
                        type="primary"
                        onClick={ goToCreateOrder }
                    >
                        Сделать заказ
                    </Button>
                ) }
            </div>
            <OrderFilter />
            <Cart
                footer={ (
                    <div className={ styles.pagination }>
                        <Pagination
                            total={ pagination.total * pagination.pageSize }
                            pageSize={ pagination.pageSize }
                            current={ pagination.current }
                            onChange={ onChangePagination }
                        />
                    </div>
                ) }
            >
                <Table
                    loading={ loading.get }
                    dataSource={ orders }
                    columns={ columns }
                    rowKey="id"
                    pagination={ false }
                    onRow={ (record: any) => ({
                        onClick: onClickRow(record),
                    }) }
                    className={ styles.table }
                />
            </Cart>
        </Layout>
    );
});
