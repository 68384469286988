import { InferValueTypes } from 'types/common';
import { FaqActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type FaqState = {
    loading: boolean;
    data: any[];
    pagination: {
        pageSize: number;
        page: number;
        total?: number;
    };
};

export const initialState: FaqState = {
    loading: false,
    data: [],
    pagination: {
        pageSize: 3,
        page: 1,
        total: 1,
    },
};

export function faqReducer(state = initialState, action: ActionTypes): FaqState {
    switch (action.type) {
        case FaqActionTypes.GET_FAQ_START:
            return {
                ...state,
                loading: true,
            };
        case FaqActionTypes.GET_FAQ_ERROR:
            return {
                ...state,
                loading: false,
            };

        case FaqActionTypes.GET_FAQ_FINISH:
            return {
                ...state,
                loading: false,
                data: Array.isArray(state.data) ? [...state.data, ...action.response.data] : action.response.data,
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
            };

        default: {
            return state;
        }
    }
}
