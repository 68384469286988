import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Route, Switch, Redirect, RouteProps,
} from 'react-router';
import jwt_decode from 'jwt-decode';

import { getProfile } from 'ducks/user/actions';
import { getCountNewMessage } from 'ducks/tickets/actions';
import { selectProfile, selectUserLoading } from 'ducks/user/selectors';

import { clearToken, getToken } from 'utils/token';

import { Home } from 'pages/Home';
import Career from 'pages/Career';
import DeliveryComponents from 'pages/DeliveryComponents';
import Blog from 'pages/Blog';
import BlogDetail from 'pages/Blog/detail';
import Cookie from 'pages/Cookie';
import { Auth } from './pages/Auth';
import { Registration } from './pages/Registration';
import { Main } from './pages/Main';
import { PasswordRecovery } from './pages/PasswordRecovery';
import { PageNotFound } from './pages/PageNotFound';
import { CreateOrder } from './pages/CreateOrder';
import { ViewOrder } from './pages/ViewOrder';
import { AboutService, HowItWorks, WhatIsTheAdvantage } from './pages/Static';
import { PromoCode } from './pages/PromoCode';
import { Price } from './pages/Price';
import { Tickets } from './pages/Tickets';
import { ViewTicket } from './pages/ViewTicket';
import { Reviews } from './pages/Reviews';
import { Statistics } from './pages/Statistics';
import { Profile } from './pages/Profile';
import { Users } from './pages/Users';
import { User } from './pages/User';
import { OrderFeedback } from './pages/OrderFeedback';

import { AppLoading } from './components/ui/app-loading';

import { ROUTES } from './constants/routes';

type PrivateRouteProps = RouteProps & {
    user: any;
    loading: boolean;
    access?: string;
};

const PrivateRoute = ({
    component: Component,
    user,
    loading,
    access,
    ...rest
}: PrivateRouteProps) => {
    if (!getToken()) {
        return <Redirect to={ ROUTES.AUTH.path } />;
    }

    // если нужен доступ для страницы, то ждем загрузки профиля
    if (access) {
        if (!user || loading) {
            return (
                <AppLoading />
            );
        }

        if (user && !user.access_list.includes(access)) {
            return (
                <PageNotFound />
            );
        }
    }

    if (!Component) return <PageNotFound />;

    return (
        <Route
            { ...rest }
            render={ (props) => <Component { ...props } /> }
        />
    );
};

export const Routing = () => {
    const put = useDispatch();
    const user = useSelector(selectProfile);
    const loading = useSelector(selectUserLoading);
    const token = getToken();

    const loadingProfile = loading.profile;

    useEffect(() => {
        if (token) {
            const decodedToken: any = jwt_decode(token);

            const id = decodedToken?.data?.id || null;

            if (!user) {
                put(getProfile({ userId: id }));
            }

            if (!id) {
                clearToken();
                window.location.replace('/');
            }

            put(getCountNewMessage());
        }
    }, []);

    return (
        <Switch>
            <Route { ...ROUTES.HOME } component={ Home } />
            <Route { ...ROUTES.COOKIE } component={ Cookie } />
            <Route { ...ROUTES.CAREER } component={ Career } />
            <Route { ...ROUTES.DELIVERY_COMPONENTS } component={ DeliveryComponents } />
            <Route { ...ROUTES.BLOG } component={ Blog } />
            <Route { ...ROUTES.BLOG_DETAIL } component={ BlogDetail } />
            <Route { ...ROUTES.REGISTRATION } component={ Registration } />
            <Route { ...ROUTES.AUTH } component={ Auth } />
            <Route { ...ROUTES.PASSWORD_RECOVERY } component={ PasswordRecovery } />
            <PrivateRoute { ...ROUTES.MAIN } user={ user } loading={ loadingProfile } component={ Main } />
            <PrivateRoute { ...ROUTES.CREATE_ORDER } user={ user } loading={ loadingProfile } component={ CreateOrder } />
            <PrivateRoute { ...ROUTES.EDIT_ORDER } user={ user } loading={ loadingProfile } component={ CreateOrder } />
            <PrivateRoute { ...ROUTES.ORDER_FEEDBACK } user={ user } loading={ loadingProfile } component={ OrderFeedback } />
            <PrivateRoute { ...ROUTES.VIEW_ORDER } user={ user } loading={ loadingProfile } component={ ViewOrder } />
            <PrivateRoute { ...ROUTES.ABOUTSERVICE } user={ user } loading={ loadingProfile } component={ AboutService } />
            <PrivateRoute { ...ROUTES.HOWITWORKS } user={ user } loading={ loadingProfile } component={ HowItWorks } />
            <PrivateRoute { ...ROUTES.WHATISTHEADVANTAGE } user={ user } loading={ loadingProfile } component={ WhatIsTheAdvantage } />
            <PrivateRoute { ...ROUTES.PROMOCODE } user={ user } loading={ loadingProfile } component={ PromoCode } />
            <PrivateRoute { ...ROUTES.PRICE } user={ user } loading={ loadingProfile } component={ Price } />
            <PrivateRoute { ...ROUTES.TICKETS } user={ user } loading={ loadingProfile } component={ Tickets } />
            <PrivateRoute { ...ROUTES.VIEW_TICKET } user={ user } loading={ loadingProfile } component={ ViewTicket } />
            <PrivateRoute { ...ROUTES.REVIEWS } user={ user } loading={ loadingProfile } component={ Reviews } />
            <PrivateRoute { ...ROUTES.VIEW_STATISTICS } user={ user } loading={ loadingProfile } component={ Statistics } />
            <PrivateRoute { ...ROUTES.PROFILE } user={ user } loading={ loadingProfile } component={ Profile } />
            <PrivateRoute { ...ROUTES.USERS } user={ user } loading={ loadingProfile } component={ Users } />
            <PrivateRoute { ...ROUTES.USER } user={ user } loading={ loadingProfile } component={ User } />
            <Route component={ PageNotFound } />
        </Switch>
    );
};
