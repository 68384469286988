import { InferValueTypes } from 'types/common';
import { InfoResponse, GetColorsResponse } from 'types/api';
import { InfoActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type InfoState = {
    loading: {
        getInfo: boolean;
        getColors: boolean;
    };
    data: InfoResponse | null;
    colors: GetColorsResponse | null;
};

export const initialState: InfoState = {
    loading: {
        getInfo: false,
        getColors: false,
    },
    data: null,
    colors: null,
};

export function infoReducer(state = initialState, action: ActionTypes): InfoState {
    switch (action.type) {
        case InfoActionTypes.GET_INFO_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getInfo: true,
                },
            };
        case InfoActionTypes.GET_INFO_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getInfo: false,
                },
                data: action.response,
            };

        case InfoActionTypes.GET_INFO_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getInfo: false,
                },
            };

        case InfoActionTypes.GET_COLORS_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getColors: true,
                },
            };
        case InfoActionTypes.GET_COLORS_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getColors: false,
                },
                colors: action.response,
            };

        case InfoActionTypes.GET_COLORS_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getColors: false,
                },
            };

        default: {
            return state;
        }
    }
}
