/* eslint-disable complexity */
import { InferValueTypes } from 'types/common';
import { Ticket, TicketMessage, TicketStatus } from 'types/ticket';
import { TicketsActionTypes } from './action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type TicketsState = {
    loading: {
        get: boolean;
        create: boolean;
        getDetail: boolean;
        getMessages: boolean;
        sendMessage: boolean;
        changeStatus: boolean;
        isNewMessage: boolean;
    };
    pagination: {
        pageSize: number;
        total: number;
        current: number;
    };
    filter: {
        status?: TicketStatus;
    };
    list: Ticket[];
    ticket: Ticket | null;
    messages: TicketMessage[];
    countNewMessage: number;
};

export const initialState: TicketsState = {
    loading: {
        get: false,
        create: false,
        getDetail: false,
        getMessages: false,
        sendMessage: false,
        changeStatus: false,
        isNewMessage: false,
    },
    pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
    },
    filter: {},
    list: [],
    ticket: null,
    messages: [],
    countNewMessage: 0,
};

export function ticketsReducer(state = initialState, action: ActionTypes): TicketsState {
    switch (action.type) {
        // ПОЛУЧИТЬ СПИСОК
        case TicketsActionTypes.GET_TICKETS_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
                filter: {
                    ...state.filter,
                    ...action.payload.filter,
                },
            };
        case TicketsActionTypes.GET_TICKETS_FINISH:
            return {
                ...state,
                list: action.response.data,
                loading: {
                    ...state.loading,
                    get: false,
                },
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
            };

        case TicketsActionTypes.GET_TICKETS_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        // ПОЛУЧИТЬ ДЕТАЛЬНО
        case TicketsActionTypes.GET_TICKET_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getDetail: true,
                },
            };
        case TicketsActionTypes.GET_TICKET_FINISH:
            return {
                ...state,
                ticket: action.response,
                loading: {
                    ...state.loading,
                    getDetail: false,
                },
            };

        case TicketsActionTypes.GET_TICKET_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getDetail: false,
                },
            };

        // СОЗДАНИЕ ТИКЕТА
        case TicketsActionTypes.CREATE_TICKET_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: true,
                },
            };
        case TicketsActionTypes.CREATE_TICKET_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: false,
                },
            };

        case TicketsActionTypes.CREATE_TICKET_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: false,
                },
            };

        // ПОЛУЧЕНИЕ СООБЩЕНИЙ
        case TicketsActionTypes.GET_TICKET_MESSAGES_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getMessages: true,
                },
            };

        case TicketsActionTypes.GET_TICKET_MESSAGES_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getMessages: false,
                },
            };

        case TicketsActionTypes.GET_TICKET_MESSAGES_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getMessages: false,
                },
                messages: action.response,
            };

        // ОТПРАВКА СООБЩЕНИЯ
        case TicketsActionTypes.SEND_TICKET_MESSAGES_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    sendMessage: true,
                },
            };

        case TicketsActionTypes.SEND_TICKET_MESSAGES_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    sendMessage: false,
                },
            };

        case TicketsActionTypes.SEND_TICKET_MESSAGES_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    sendMessage: false,
                },
                messages: [
                    ...state.messages,
                    action.message,
                ],
            };

        // СМЕНА СТАТУСА
        case TicketsActionTypes.CHANGE_TICKET_STATUS_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    changeStatus: true,
                },
            };

        case TicketsActionTypes.CHANGE_TICKET_STATUS_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    changeStatus: false,
                },
                ticket: {
                    ...state.ticket as Ticket,
                    status: action.status,
                },
            };

        case TicketsActionTypes.CHANGE_TICKET_STATUS_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    changeStatus: false,
                },
            };

        // ЕСТЬ ЛИ НОВЫЕ СООБЩЕНИЯ
        case TicketsActionTypes.GET_COUNT_NEW_MESSAGE_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    isNewMessage: true,
                },
            };

        case TicketsActionTypes.GET_COUNT_NEW_MESSAGE_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    isNewMessage: false,
                },
                countNewMessage: action.count,
            };

        case TicketsActionTypes.GET_COUNT_NEW_MESSAGE_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    isNewMessage: false,
                },
            };

        default: {
            return state;
        }
    }
}
