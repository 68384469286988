/* eslint-disable complexity */
import { InferValueTypes } from 'types/common';
import {
    OrderType, Order, OrderLoading,
} from 'types/orders';
import * as actions from './actions';
import { OrdersActionTypes } from './action-types';

type ActionTypes = ReturnType<InferValueTypes<
    typeof actions
>>;

export type OrdersState = {
    loading: OrderLoading;
    list: any;
    pagination: {
        pageSize: number;
        total: number;
        current: number;
    };
    filter: {
        status?: string;
        brand?: string;
        formType?: OrderType;
        dataCreate?: string;
        user_id?: number;
    };
    detail: Order | null;
};

export const initialState: OrdersState = {
    loading: {
        get: false,
        create: false,
        edit: false,
        getDetail: false,
        remove: false,
        changeStatus: false,
        sendExpertForm: false,
        applyPromocode: false,
        payOnline: false,
        feedback: false,
    },
    list: [],
    pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
    },
    filter: {},
    detail: null,
};

export function ordersReducer(state = initialState, action: ActionTypes): OrdersState {
    switch (action.type) {
        case OrdersActionTypes.GET_ORDERS_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
                filter: {
                    ...action.payload.filter,
                },
            };
        case OrdersActionTypes.GET_ORDERS_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                list: action.response.data,
                pagination: {
                    ...state.pagination,
                    ...action.response.pagination,
                },
            };

        case OrdersActionTypes.GET_ORDERS_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
            };

        case OrdersActionTypes.CREATE_ORDER_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: true,
                },
            };

        case OrdersActionTypes.CREATE_ORDER_ERROR:
        case OrdersActionTypes.CREATE_ORDER_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: false,
                },
            };

        case OrdersActionTypes.EDIT_ORDER_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: true,
                },
            };

        case OrdersActionTypes.EDIT_ORDER_ERROR:
        case OrdersActionTypes.EDIT_ORDER_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    edit: false,
                },
            };

        case OrdersActionTypes.GET_ORDER_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getDetail: true,
                },
            };

        case OrdersActionTypes.GET_ORDER_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getDetail: false,
                },
                detail: action.response,
            };

        case OrdersActionTypes.GET_ORDER_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getDetail: false,
                },
            };

        case OrdersActionTypes.REMOVE_ORDER_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    remove: true,
                },
            };

        case OrdersActionTypes.REMOVE_ORDER_ERROR:
        case OrdersActionTypes.REMOVE_ORDER_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    remove: false,
                },
            };

        case OrdersActionTypes.CLEAR_DETAIL_DATA:
            return {
                ...state,
                detail: null,
            };

        case OrdersActionTypes.CHANGE_ORDER_STATUS_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    changeStatus: true,
                },
            };

        case OrdersActionTypes.CHANGE_ORDER_STATUS_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    changeStatus: false,
                },
                detail: {
                    ...state.detail as Order,
                    status: action.status,
                    expert_id: action.expertId,
                },
            };

        case OrdersActionTypes.CHANGE_ORDER_STATUS_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    changeStatus: false,
                },
            };

        case OrdersActionTypes.SEND_EXPERT_ORDER_FORM_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    sendExpertForm: true,
                },
            };

        case OrdersActionTypes.SEND_EXPERT_ORDER_FORM_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    sendExpertForm: false,
                },
            };

        case OrdersActionTypes.SEND_EXPERT_ORDER_FORM_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    sendExpertForm: false,
                },
                detail: {
                    ...state.detail as Order,
                    ...action.data,
                },
            };

        case OrdersActionTypes.APPLY_PROMOCODE_TO_ORDER_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    applyPromocode: true,
                },
            };

        case OrdersActionTypes.APPLY_PROMOCODE_TO_ORDER_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    applyPromocode: false,
                },
            };

        case OrdersActionTypes.APPLY_PROMOCODE_TO_ORDER_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    applyPromocode: false,
                },
                detail: {
                    ...state.detail as Order,
                    paymentInfo: action.data,
                },
            };

        case OrdersActionTypes.PAY_ORDER_ONLINE_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    payOnline: true,
                },
            };

        case OrdersActionTypes.PAY_ORDER_ONLINE_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    payOnline: false,
                },
            };

        case OrdersActionTypes.PAY_ORDER_ONLINE_FINISH:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    payOnline: false,
                },
                detail: {
                    ...state.detail as Order,
                    paymentInfo: {
                        ...state.detail!.paymentInfo,
                        paymentUrl: action.data.paymentUrl,
                    },
                },
            };

        default: {
            return state;
        }
    }
}
