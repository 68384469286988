import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {
    Form, Input, Button,
} from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { FormLayout } from 'components/ui/form-layout';

import { signUp } from 'ducks/app/actions';

import { selectSignUpLoading } from 'ducks/app/selectors';

import { schema } from 'form-helpers/registration/schema';
import { RegistrationFormType } from 'form-helpers/registration/types';
import { getError, getErrorStatus } from 'form-helpers/validation';
import { history } from 'App';
import { ROUTES } from 'constants/routes';
import { getToken } from 'utils/token';

const initialValues = schema.cast({});

export const Registration = () => {
    const loading = useSelector(selectSignUpLoading);
    const put = useDispatch();

    useEffect(() => {
        if (getToken()) {
            history.push(ROUTES.MAIN.path);
        }
    }, []);

    const itemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const onSubmit = (values: RegistrationFormType) => {
        put(signUp({ ...values, phone: `+${values.phone.replaceAll(' ', '')}` }));
    };

    return (
        <FormLayout title="Регистрация">
            <Formik<RegistrationFormType>
                initialValues={ initialValues }
                onSubmit={ onSubmit }
                validationSchema={ schema }
            >
                { ({
                    handleSubmit,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    values,
                }) => {
                    const errorList = {
                        firstName: getError(errors, touched, true, 'firstName'),
                        lastName: getError(errors, touched, true, 'lastName'),
                        phone: getError(errors, touched, true, 'phone'),
                        email: getError(errors, touched, true, 'email'),
                        password: getError(errors, touched, true, 'password'),
                    };

                    const handleChangePhone = (value: string) => {
                        setFieldValue('phone', value || null, true);
                    };

                    return (
                        <Form
                            onFinish={ handleSubmit }
                            labelAlign="left"
                            { ...itemLayout }
                        >
                            <Form.Item
                                label="Ваше имя"
                                extra={ errorList.firstName }
                                validateStatus={ getErrorStatus(!!errorList.firstName) }
                            >
                                <Input
                                    name="firstName"
                                    placeholder="Ваше имя"
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Ваша фамилия"
                                extra={ errorList.lastName }
                                validateStatus={ getErrorStatus(!!errorList.lastName) }
                            >
                                <Input
                                    name="lastName"
                                    placeholder="Ваша фамилия"
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Ваш номер"
                                extra={ errorList.phone }
                                validateStatus={ getErrorStatus(!!errorList.phone) }
                            >
                                <PhoneInput
                                    country="ru"
                                    inputProps={ {
                                        name: 'phone',
                                        required: true,
                                    } }
                                    placeholder="+7(000)-000-00-00"
                                    onChange={ handleChangePhone }
                                    onBlur={ handleBlur }
                                    value={ values.phone }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Ваш E-mail"
                                extra={ errorList.email }
                                validateStatus={ getErrorStatus(!!errorList.email) }
                            >
                                <Input
                                    name="email"
                                    placeholder="Ваш E-mail"
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Пароль"
                                extra={ errorList.password }
                                validateStatus={ getErrorStatus(!!errorList.password) }
                            >
                                <Input
                                    name="password"
                                    placeholder="Пароль"
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                    type="password"
                                />
                            </Form.Item>

                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={ loading }
                            >
                                Зарегистрироваться
                            </Button>
                            <p style={ { marginTop: 24, fontSize: 11 } }>
                                Регистрируясь на сайте вы соглашаетесь с &nbsp;
                                <a href="https://hairtone.ru/Soglasheniepolzovatelskoe.pdf" target="_blank" rel="noopener noreferer noreferrer">Пользовательским соглашением</a>&nbsp;
                                и &nbsp;
                                <a href="https://hairtone.ru/Politikakonfidencialnosti.pdf" target="_blank" rel="noopener noreferer noreferrer">Политикой конфедециальности</a>
                            </p>
                        </Form>
                    );
                } }
            </Formik>
        </FormLayout>
    );
};
